import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
// MUI:
import { Stack } from "@mui/material";

// Custom components:
import Text from "components/Text";
import { getBrandPlateOrdersAsync, selectOrders } from "state/brandPlateSlice";
import BrandPlateOrderListItem from "../BrandPlateOrderListItem";

import styles from "./BrandPlateOrderHistory.module.scss";

function BrandPlateOrderHistory() {
  const dispatch = useDispatch();
  const orders = useSelector(selectOrders);

  useEffect(() => {
    dispatch(getBrandPlateOrdersAsync());
  }, []);

  return (
    <div>
      <Stack className={styles.brandPlateorderHistory}>
        {orders.length === 0 && (
          <Text
            className="ta-left text--georgia order__list__no-orders-text"
            variant="h5"
            color="gray"
          >
            You have no past Brand Plate orders.
          </Text>
        )}

        {orders.map((order) => (
          <Stack key={order.id} className={styles.item}>
            <BrandPlateOrderListItem order={order} />
          </Stack>
        ))}
      </Stack>
    </div>
  );
}

export default BrandPlateOrderHistory;
