import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { GenericErrorNotification } from "components/Notifications";

import PageHeader from "components/PageHeader";
import AccountNav from "components/AccountNav";

import AccountConnectStore from "./components/AccountConnectStore";
import AccountStoreConnected from "./components/AccountStoreConnected";

import styles from "./AccountStoreIntegrationView.module.scss";

export default function AccountStoreIntegrationView() {
  const navigate = useNavigate();
  const shop = useSelector((state) => state.profile.shop);

  useEffect(() => {
    // check if we are attempting to connect the store
    const connectStore = new URLSearchParams(window.location.search).get(
      "connect_store"
    );

    // show error if shop already has a store connected
    if (shop.url && connectStore) {
      toast.error(
        <GenericErrorNotification text="You cannot connect multiple stores to the same account" />
      );
      // remove url check
      navigate("/profile/connect");
    }
  }, []);

  return (
    <div className="template-profile-store">
      <PageHeader title="My Account" />
      <AccountNav />
      <div
        className={styles.storeConnectContainer}
        data-testid="ProfileStoreConnect"
      >
        {shop.store_connected ? (
          <AccountStoreConnected shop={shop} />
        ) : (
          <AccountConnectStore shop={shop} />
        )}
      </div>
    </div>
  );
}
