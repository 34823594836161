import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Text from "components/Text";
import { useMutation } from "react-query";

import { sendPasswordReset } from "api";

import Button from "components/Buttons/Button";
import OnboardingWrapper from "views/OnboardingView/steps/Onboarding/OnboardingWrapper";

function PasswordResetView() {
  const [resetEmail, setResetEmail] = useState("");
  const [message, setMessage] = useState({
    message: "",
    status: "success",
  });

  const passwordResetMutation = useMutation(sendPasswordReset);

  const handlePasswordReset = () => {
    passwordResetMutation.mutate(
      { email: resetEmail },
      {
        onSuccess: () => {
          setMessage({
            message:
              "If your email is valid, a password reset link will be sent to your inbox.",
            status: "success",
          });
        },
        onError: () => {
          setMessage({
            message:
              "If your email is valid, a password reset link will be sent to your inbox.",
            status: "success",
          });
        },
      }
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // setPasswordReset();
  };

  return (
    <div className="password-reset-template">
      <OnboardingWrapper>
        <div className="password-reset-content">
          <div className="signup-container mb-10">
            <Text className="signup-container__title text--nunito" color="gray">
              Password Reset
            </Text>
          </div>

          <div className="form-container">
            <form
              className="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
            >
              <TextField
                id="Email"
                className="input-fields"
                label="Email"
                value={resetEmail}
                required
                InputLabelProps={{ shrink: true }}
                name="email"
                type="email"
                variant="outlined"
                onChange={(e) => setResetEmail(e.target.value)}
              />

              <NavLink
                to="/login"
                className="signup-container__sign-in text--nunito"
              >
                Return to login
              </NavLink>

              <Button
                disabled={!resetEmail}
                className="button__reset"
                variant="contained"
                color="primary"
                onClick={handlePasswordReset}
              >
                Reset Password
              </Button>
            </form>
            <p className={`text__${message.status}`}>{message.message}</p>
            {/* <p className={`text__${messagesStatus.success_status}`}>{successMessage}</p> */}
          </div>
        </div>
      </OnboardingWrapper>
    </div>
  );
}

export default PasswordResetView;
