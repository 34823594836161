import apiService from "api/apiService";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { setShop } from "state/profileSlice";

export const useShopMutations = () => {
  const dispatch = useDispatch();

  const onUpdateShop = async ({ id, ...data }) => {
    try {
      const response = await apiService.patch(`/shop/${id}/`, data);

      if (response?.data) {
        return response?.data;
      }
    } catch (error) {
      console.log("ERROR", error);
    }

    return null;
  };

  const updateShopMutation = useMutation(onUpdateShop, {
    onSuccess: (data) => {
      dispatch(setShop(data));
    },
  });

  return { updateShopMutation };
};
