import React from "react";

// MUI:
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";

// customized components:
import Text from "components/Text";

// third-party libraries:
import moment from "moment";

import styles from "./ExpiringTag.module.scss";

export default function ExpiringTag(props) {
  const { expiryDate = null, isFullWidth } = props;

  const expiringTagClass = isFullWidth
    ? styles.expiringTagFull
    : styles.expiringTag;

  return (
    <div className={expiringTagClass}>
      <AccessAlarmsIcon className={styles.tagIcon} />
      <Text className={styles.tag}>
        Discontinuing: {moment(expiryDate).format("MMM Do, YYYY")}
      </Text>
    </div>
  );
}
