import { Box, Typography } from "@mui/material";
import React from "react";

import Button from "components/Buttons/Button";
import { mockups } from "constants/generatorLogo";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./BrandPlateInProgress.module.scss";

function BrandPlateInProgress({ order }) {
  const navigate = useNavigate();
  const { logos, isLoading: isLoadingLogos } = useSelector(
    (state) => state.myLogo
  );

  // need to make this component dry, seems like this is in 3 or more areas of the app
  const handleMockupImageClassName = (index) => {
    switch (index) {
      case 0:
        return styles.mockupLogoDefault;
      case 1:
        return styles.mockupLogoInverted;
      case 2:
        return styles.mockupLogoThin;
      case 3:
        return styles.mockupLogoInvertedThin;
      default:
        return styles.mockupLogoDefault;
    }
  };

  return (
    <Box>
      <Box className={styles.inProgressContent}>
        <Box className={styles.inProgressAlert}>
          <Typography
            fontWeight={700}
            fontSize={14}
            fontStyle="italic"
            color="#246071"
            display="flex"
            alignItems="center"
            gap={1}
          >
            <svg
              width="15"
              height="18"
              viewBox="0 0 15 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.16683 15.666H10.8335V13.166C10.8335 12.2493 10.5071 11.4646 9.85433 10.8118C9.20155 10.1591 8.41683 9.83268 7.50016 9.83268C6.5835 9.83268 5.79877 10.1591 5.146 10.8118C4.49322 11.4646 4.16683 12.2493 4.16683 13.166V15.666ZM7.50016 8.16602C8.41683 8.16602 9.20155 7.83963 9.85433 7.18685C10.5071 6.53407 10.8335 5.74935 10.8335 4.83268V2.33268H4.16683V4.83268C4.16683 5.74935 4.49322 6.53407 5.146 7.18685C5.79877 7.83963 6.5835 8.16602 7.50016 8.16602ZM0.833496 17.3327V15.666H2.50016V13.166C2.50016 12.3188 2.69808 11.5237 3.09391 10.7806C3.48975 10.0375 4.04183 9.44379 4.75016 8.99935C4.04183 8.5549 3.48975 7.96115 3.09391 7.2181C2.69808 6.47504 2.50016 5.6799 2.50016 4.83268V2.33268H0.833496V0.666016H14.1668V2.33268H12.5002V4.83268C12.5002 5.6799 12.3022 6.47504 11.9064 7.2181C11.5106 7.96115 10.9585 8.5549 10.2502 8.99935C10.9585 9.44379 11.5106 10.0375 11.9064 10.7806C12.3022 11.5237 12.5002 12.3188 12.5002 13.166V15.666H14.1668V17.3327H0.833496Z"
                fill="#246071"
              />
            </svg>
            Your Brand Plate is being created...
          </Typography>
        </Box>

        <Box className={styles.imagesWrapper}>
          <Box className={styles.inProgressImages}>
            {mockups.map((mockup, index) => (
              <Box className={styles.mockupImageWrapper}>
                <Box className={handleMockupImageClassName(index)}>
                  <img
                    src={logos[index]?.logo || logos[0]?.logo}
                    alt=""
                    className={styles.mockupLogoImg}
                  />
                </Box>
                <img src={mockup.image} alt="" className={styles.mockupImage} />
              </Box>
            ))}
          </Box>
          <Box className={styles.imagesDescription}>
            <Typography
              fontWeight={400}
              fontSize={{ xs: 14, md: 16 }}
              variant="span"
              color="#5E5E5E"
            >
              In the meantime, check out hundreds of products!
            </Typography>
            <Button
              onClick={() => navigate("/")}
              size="small"
              variant="contained"
              color="primary"
            >
              Browse Products
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BrandPlateInProgress;
