import { Box, Card, CardContent, Stack } from "@mui/material";
import SuccessTag from "components/Labels/SuccessTag";
import Text from "components/Text";

import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentOrder } from "state/brandPlateSlice";
import BrandPlateMockupFiles from "../BrandPlateMockupFiles";

import styles from "./BrandPlateMockupAccepted.module.scss";

function BrandPlateMockupAccepted() {
  const currentOrder = useSelector(selectCurrentOrder);

  return (
    <Box mt={2}>
      <Stack className={styles.stackContainer}>
        <Card className={styles.pendingReviewBox}>
          <CardContent>
            <Text
              color="primary"
              fontSize="16"
              variant="h2"
              className={styles.pendingReviewTitle}
            >
              Your Brand Plate is being created!
            </Text>
            <Box className={styles.pendingReviewContainer}>
              <Box className={styles.downloadContainer}>
                <Text
                  className={styles.mockupTitle}
                  variant="body1"
                  fontSize="14"
                >
                  Product mock-up:
                </Text>

                <BrandPlateMockupFiles mockups={currentOrder.mockups} />
              </Box>
              <SuccessTag>
                <Text fontSize="14" variant="body1" className="text--bold">
                  You&apos;ve accepted your pending Brand Plate
                </Text>
                <Text fontSize="14" variant="body1">
                  We&apos;ll be processing these and making them live, please
                  check back soon. When moved to Approved it&apos;s ready for
                  your orders!
                </Text>
              </SuccessTag>
            </Box>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default BrandPlateMockupAccepted;
