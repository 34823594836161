import React from "react";
import PropTypes from "prop-types";

import BlankaMyLogoThinLightPreview from "assets/images/blanka-my-logo-thin-light-preview.png";
import { LOGO_THIN } from "constants/generatorLogo";
import MyLogoStepTemplate from "./MyLogoStepTemplate";

function MyLogoStepFour(props) {
  const { handleNext, handlePrevious } = props;

  return (
    <MyLogoStepTemplate
      step="Step 2.2"
      subTitle="Thin light product preview"
      lead="We recommend uploading a dark logo"
      caption="Preview of your logo on light thin products"
      imagePreview={BlankaMyLogoThinLightPreview}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      logoType={LOGO_THIN}
    />
  );
}

MyLogoStepFour.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
};

MyLogoStepFour.defaultProps = {};

export default MyLogoStepFour;
