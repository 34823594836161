import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import Text from "components/Text";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Helmet } from "react-helmet";
import blankaLogo from "assets/images/logo.png";
import { Controller, useForm } from "react-hook-form";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import { Divider } from "@mui/material";
import Button from "components/Buttons/Button";
import { setToken } from "state/sessionSlice";
import { login, getNewAccessToken, googleSSOLogin, appleSSOLogin } from "api";

import AppleLoginButton from "components/Buttons/AppleLoginButton";

import GoogleLoginButton from "components/Buttons/GoogleLoginButton";
import styles from "./Login.module.scss";

export default function Login() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.session.isLoggedIn);

  const loginMutation = useMutation(login);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const [refreshToken, setRefreshToken] = useState();
  const [connectStore, setConnectStore] = useState(false);

  const [redirect, setRedirect] = useState();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (isLoggedIn) {
      if (connectStore) {
        navigate({
          pathname: "/profile/connect",
          search: window.location.search,
        });
      }

      // redirect after login
      if (redirect) {
        const url = new URL(`${window.location}`);
        const params = new URLSearchParams(url.search);
        params.delete("redirect_url");
        navigate({
          pathname: redirect,
          search: params.toString(),
        });
      } else {
        navigate({
          pathname: "/",
          search: window.location.search,
        });
      }
    }
  }, [isLoggedIn]);

  // save shop url for when they login
  useEffect(() => {
    // check if login codes in url
    const refreshToken = new URLSearchParams(window.location.search).get(
      "refresh_token"
    );
    const accessToken = new URLSearchParams(window.location.search).get(
      "access_token"
    );

    if (refreshToken && accessToken) {
      // log user in with tokens
      dispatch(setToken({ access: accessToken, refresh: refreshToken }));

      if (connectStore) {
        navigate({
          pathname: "/profile/connect",
          search: "", // clear tokens from url
        });
      }
    }

    const redirectURL = new URLSearchParams(window.location.search).get(
      "redirect_url"
    );

    if (redirectURL) {
      setRedirect(redirectURL);
    }

    /// /// connection a store  /////////
    const storeURL = new URLSearchParams(window.location.search).get(
      "shop_url"
    );
    const storeToken = new URLSearchParams(window.location.search).get("token");
    if (storeURL && storeToken) {
      localStorage.setItem(
        "store",
        JSON.stringify({ url: storeURL, token: storeToken })
      );
    }
    /// /////////////////////////////////

    // check if persist token exists
    const storageToken = JSON.parse(localStorage.getItem("PersistToken"));

    if (storageToken && storageToken.token) {
      setRefreshToken(storageToken);
    }

    // check if there is an atempt to connect store
    const connectShopifyStore = JSON.parse(
      sessionStorage.getItem("shopify_store")
    );
    const connectWoocommerceStore = JSON.parse(
      sessionStorage.getItem("woocomerce_store")
    );

    const connectWixStore = JSON.parse(sessionStorage.getItem("wix_store"));

    if (connectShopifyStore || connectWoocommerceStore || connectWixStore) {
      setConnectStore(true);
    }
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    loginMutation.mutate(data, {
      onSuccess: (response) => {
        dispatch(setToken(response.data));
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
        setMessage(`Oops! Incorrect email or password.`);
      },
    });
  };

  const onPersistentLogin = async () => {
    try {
      const response = await getNewAccessToken(refreshToken.token);
      dispatch(setToken(response.data));
      localStorage.removeItem("PersistToken");
    } catch (err) {
      setRefreshToken(null);
      localStorage.removeItem("PersistToken");
    }
  };

  const handleGoogleLogin = async (googleData) => {
    setLoading(true);
    try {
      const response = await googleSSOLogin(googleData.access_token);
      dispatch(
        setToken({
          access: response.data.access,
          refresh: response.data.refresh,
        })
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setMessage(
        `Oops! Could not login with your Google account. ${
          err.response?.data?.non_field_errors[0]
            ? err.response?.data.non_field_errors[0]
            : ""
        }`
      );
    }
  };

  const handleAppleLogin = async (code) => {
    try {
      const response = await appleSSOLogin(code);
      dispatch(
        setToken({
          access: response.data.access,
          refresh: response.data.refresh,
        })
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setMessage("We encountered an error logging in with your apple account");
    }
  };

  return (
    <div className={styles.templateLogin}>
      <Helmet>
        <title>Login - Blanka</title>
        <meta
          name="description"
          content="Official app login page app for Shopify, WooCommerce and more"
        />
      </Helmet>

      <Card className={styles.card} variant="outlined">
        <div className={styles.welcomeContainer}>
          <Text
            className="text--bold text--nunito text--primary text--spacing-6"
            fontSize={34}
            variant="h4"
          >
            WELCOME TO{" "}
          </Text>
          <img src={blankaLogo} alt="Logo" className={styles.blankaLogo} />
        </div>

        <div className={styles.headerTextContainer}>
          <Text fontSize={24} className="text--blue text--bold">
            Please login to access the app.
          </Text>
          <Link
            to="/signup"
            className={`${styles.textLink} text--nunito text--info`}
          >
            Or <span className={styles.textRed}>sign up for free!</span>
          </Link>
        </div>

        <div className={styles.ssoContainer}>
          <GoogleLoginButton handleLogin={handleGoogleLogin} />
          <AppleLoginButton handleLogin={handleAppleLogin} />
        </div>

        <div className={styles.dividerWrapper}>
          <Divider className={styles.divider} variant="middle" flexItem>
            OR
          </Divider>
        </div>

        <div className={styles.loginContainer}>
          {refreshToken && (
            <div className={styles.recentLogins}>
              <Text fontSize={22} variant="h2">
                Recent Logins
              </Text>
              <Card sx={{ minWidth: 275 }} className={styles.tokenBox}>
                <CardContent>
                  <Text fontSize={16}>{refreshToken.username}</Text>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onPersistentLogin()}
                  >
                    Login
                  </Button>
                </CardActions>
              </Card>
            </div>
          )}

          <div className={styles.formContainer}>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    name="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => setMessage("")}
                  />
                )}
                rules={{ required: true }}
              />

              <div className={styles.controllerContainer}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="password"
                      label="Password"
                      name="password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => setMessage("")}
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>

              <div className={styles.resetPasswordContainer}>
                <Text fontSize={12} className={styles.textReset}>
                  Forgot your password? &nbsp;
                </Text>

                <Link
                  to="/password-reset"
                  className={`${styles.textLink} text--nunito text--info`}
                >
                  Reset it here.
                </Link>
              </div>

              <div className={styles.loginButtonWrapper}>
                <div className={styles.termsConditionsContainer}>
                  <p className={styles.termsText}>
                    By signing in you agree to Blanka&apos;s{" "}
                    <a
                      target="__blank"
                      href="https://blankabrand.com/blanka-terms-and-conditions/"
                    >
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      target="__blank"
                      href="https://faq.blankabrand.com/en/articles/5924132-how-does-blanka-handle-subscription-refunds"
                    >
                      Refund &amp; Cancellation Policy.
                    </a>
                  </p>
                </div>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  loading={loading}
                  disabled={!isDirty || !isValid}
                  color="secondary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  LOGIN
                </Button>

                <Text
                  fontSize={12}
                  className={`text--nunito text--error mt-10 ${styles.textError}`}
                >
                  {" "}
                  {message}
                </Text>
              </div>
            </form>
          </div>
        </div>
      </Card>
    </div>
  );
}

Login.propTypes = {
  error: PropTypes.string,
};

Login.defaultProps = {
  error: "",
};
