import React from "react";
import PropTypes from "prop-types";

import {
  DashedIncompleteCheckMarkIcon,
  DashedActiveCheckMarkIcon,
  DashedCompleteCheckMarkIcon,
} from "components/Icons";

import { StepIcon } from "@mui/material";

function CustomStepIcon(props) {
  const { active, completed } = props;
  const incompleteIcon = <DashedIncompleteCheckMarkIcon />;
  const activeIcon = <DashedActiveCheckMarkIcon />;
  const completeIcon = <DashedCompleteCheckMarkIcon />;

  if (completed) {
    return <StepIcon completed={completed} icon={completeIcon} />;
  }

  if (active) {
    return <StepIcon icon={activeIcon} />;
  }

  return <StepIcon icon={incompleteIcon} />;
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

CustomStepIcon.defaultProps = {
  active: false,
  completed: false,
};

export default CustomStepIcon;
