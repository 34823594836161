const getAttributeValues = (generatorAttributes) => {
  const attributes = {};

  generatorAttributes?.forEach(({ attribute, options }) => {
    const val = options.findIndex((value) => value?.is_default);
    attributes[attribute?.slug] = options?.[val]?.value_json;
  });

  return attributes;
};

export default getAttributeValues;
