import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function SampleOrdersIcon(props) {
  return (
    <div className="icon-root">
      <SvgIcon {...props} className="icon icon--hover" viewBox="0 0 20 20">
        <g id="Layer_2_00000104697432161337194910000002869125291444332686_">
          <g id="Sample_Orders_Icon">
            <rect
              y="0"
              className="st0"
              width="18"
              height="18"
              style={{ fill: "#fff" }}
            />
            <g id="Sample_Colour">
              <path
                className="st1"
                style={{ fill: "#5E5F5F" }}
                d="M11.8,8.6V0.8C11.8,0.4,11.4,0,11,0c-0.2,0-0.4,0.1-0.6,0.2L6.5,4.1C6.3,4.3,6.2,4.6,6.2,4.9v3.7H5.2V18h7.5
				V8.6H11.8z M8.1,5.2L10,3.3v5.3H8.1V5.2z M10.9,16.1H7.1v-5.7h3.8V16.1z"
              />
              <polygon
                className="st2"
                style={{ fill: "#E88A80" }}
                points="9.9,3.3 9.9,8.6 8.1,8.6 8.1,5.2"
              />
            </g>
          </g>
        </g>
      </SvgIcon>

      <SvgIcon {...props} className="icon icon--normal" viewBox="0 0 20 20">
        <g id="Layer_2_00000048497126855938356700000008460097206628905091_">
          <g id="Sample_Orders_Icon">
            <rect
              y="0"
              className="st0"
              width="18"
              height="18"
              style={{ fill: "#FFF" }}
            />
            <path
              id="Sample_Grey"
              style={{ fill: "#5E5F5F" }}
              className="st1"
              d="M11.8,8.6V0.8C11.8,0.4,11.4,0,11,0c-0.2,0-0.4,0.1-0.6,0.2L6.5,4.1
			C6.3,4.3,6.2,4.6,6.2,4.9v3.7H5.2V18h7.5V8.6H11.8z M8.1,5.2L10,3.3v5.3H8.1V5.2z M10.9,16.1H7.1v-5.7h3.8V16.1z"
            />
          </g>
        </g>
      </SvgIcon>
    </div>
  );
}
