import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function AlertGearIcon(props) {
  const { className } = props;
  return (
    <SvgIcon
      {...props}
      data-testid="AlertGearIcon"
      className={`icon ${className}`}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g id="Icon / alert_decagram_outline">
        <path
          id="Vector"
          d="M38.3337 19.9997L34.267 24.6331L34.8337 30.7664L28.817 32.1331L25.667 37.4331L20.0003 34.9997L14.3337 37.4497L11.1837 32.1497L5.16699 30.7831L5.73366 24.6331L1.66699 19.9997L5.73366 15.3497L5.16699 9.21641L11.1837 7.86641L14.3337 2.56641L20.0003 4.99974L25.667 2.56641L28.817 7.86641L34.8337 9.23307L34.267 15.3664L38.3337 19.9997ZM33.8837 19.9997L30.8337 16.4831L31.2337 11.8331L26.667 10.8331L24.3003 6.78307L20.0003 8.63307L15.7003 6.78307L13.3337 10.8331L8.76699 11.8164L9.16699 16.4664L6.11699 19.9997L9.16699 23.4997L8.76699 28.1664L13.3337 29.1664L15.7003 33.2164L20.0003 31.3497L24.3003 33.1997L26.667 29.1664L31.2337 28.1497L30.8337 23.4997L33.8837 19.9997ZM18.3337 24.9997H21.667V28.3331H18.3337V24.9997ZM18.3337 11.6664H21.667V21.6664H18.3337V11.6664Z"
          fill="#D56F5B"
        />
      </g>
    </SvgIcon>
  );
}
