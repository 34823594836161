import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  onSetChangeModalProps,
  toggleChangePlanModal,
} from "state/changePlanModalSlice";

const useChangePlanModal = () => {
  const dispatch = useDispatch();

  // Combined useSelector to reduce re-renders
  const {
    isOpen,
    title,
    subtitle,
    isFreePlanLimit,
    freePlanLimitSubText,
    onSuccess,
    cadence,
  } = useSelector(
    (state) => ({
      isOpen: state.changePlanModal.isOpen,
      ...state.changePlanModal.planProps,
    }),
    shallowEqual
  );

  // Memoized callbacks
  const onCloseModal = useCallback(() => {
    dispatch(toggleChangePlanModal(false));
  }, [dispatch]);

  const onOpenModal = useCallback(
    (includedProps) => {
      if (includedProps) {
        dispatch(onSetChangeModalProps(includedProps));
      }
      dispatch(toggleChangePlanModal(true));
    },
    [dispatch]
  );

  return {
    isOpen,
    title,
    subtitle,
    isFreePlanLimit,
    freePlanLimitSubText,
    onSuccess,
    onCloseModal,
    onOpenModal,
    cadence,
  };
};

export default useChangePlanModal;
