import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function CloseButton({ closeToast }) {
  return (
    <CloseOutlinedIcon
      className="text--nunito notifications__close-button"
      onClick={closeToast}
    />
  );
}
