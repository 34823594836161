/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { PRODUCT_STATE_ALL, PRODUCT_TYPE_CUSTOM } from "constants/constants";

const myProductSlice = createSlice({
  name: "myProduct",
  initialState: {
    currentProductState: PRODUCT_STATE_ALL,
    currentProductStateType: PRODUCT_TYPE_CUSTOM,
  },
  reducers: {
    updateProductState: (state, action) => {
      state.currentProductState = action.payload;
    },
    updateProductStateType: (state, action) => {
      state.currentProductStateType = action.payload;
    },
  },
});

export const { updateProductState, updateProductStateType } =
  myProductSlice.actions;

export default myProductSlice.reducer;
