import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// customized components:
import ModalWrapper from "components/ModalWrapper";
import Text from "components/Text";
import Button from "components/Buttons/Button";
import styles from "./CartProductStatusModal.module.scss";

export default function CartProductStatusModal(props) {
  const { open, handleClose } = props;

  return (
    <ModalWrapper
      isOpen={open}
      handleClose={handleClose}
      className={styles.cartProductStatusModal}
    >
      <Text variant="h1" className={styles.title}>
        Some products in your cart are unavailable
      </Text>
      <Text className={styles.message}>
        Please remove any discontinued or out of stock products from your
        shopping cart before proceeding to checkout.{" "}
      </Text>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClose}
        className={styles.cta}
      >
        Review Cart
      </Button>
    </ModalWrapper>
  );
}

CartProductStatusModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

CartProductStatusModal.defaultProps = {
  open: false,
  handleClose: () => {},
};
