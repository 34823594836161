import React from "react";
import PropTypes from "prop-types";
import Text from "components/Text";
import { Stack, LinearProgress, Box } from "@mui/material";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import zxcvbn from "zxcvbn";
import styles from "./PasswordStrengthBar.module.scss";

export default function PasswordStrengthBar({
  password,
  width,
  margin,
  className,
  handleStrengthChange,
  isTop,
  hideStrengthText,
}) {
  const passwordTestResult = zxcvbn(password);
  const normalizedScoreValue = (passwordTestResult.score * 100) / 4;

  const labelScoreMapper = () => {
    switch (passwordTestResult.score) {
      case 0:
        return "Very Weak";
      case 1:
        return "Weak";
      case 2:
        return "Neutral";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Very Weak";
    }
  };

  const colorScoreMapper = () => {
    handleStrengthChange(passwordTestResult.score);
    switch (passwordTestResult.score) {
      case 0:
        return "#999999";
      case 1:
        return "#AB0000";
      case 2:
        return "#FF9000";
      case 3:
        return "#246071";
      case 4:
        return "#00AA51";
      default:
        return "#999999";
    }
  };

  const changeProgreesBarColor = () => ({
    background: `rgb(150,150,150,0.3)`,
    "& .MuiLinearProgress-bar": {
      backgroundColor: colorScoreMapper(),
    },
  });

  return (
    <>
      <Stack
        data-testid="PasswordStrengthBar"
        spacing={2}
        sx={{ width, margin }}
        className={`${styles.passwordStrengthBar} ${className}`}
      >
        <LinearProgress
          variant="determinate"
          value={normalizedScoreValue}
          sx={changeProgreesBarColor}
        />
        <Text
          variant="body1"
          className={isTop ? styles.labelTextTop : styles.labelTextBottom}
          style={{ color: colorScoreMapper() }}
        >
          {labelScoreMapper()}
        </Text>
      </Stack>
      {!hideStrengthText && (
        <Box className={styles.flexContainer}>
          <GppMaybeIcon className={styles.iconPasswordFeedback} />
          <Text variant="body2" className={styles.labelPasswordFeedback}>
            Please choose a password that is at least 9 characters long and
            contains at least one letter or number.
          </Text>
        </Box>
      )}
    </>
  );
}

PasswordStrengthBar.propTypes = {
  password: PropTypes.string,
  handleStrengthChange: PropTypes.func,
  isTop: PropTypes.bool,
};

PasswordStrengthBar.defaultProps = {
  password: "",
  handleStrengthChange: () => {},
  isTop: true,
};
