// make a basic react component to show a tag with a success message
import React from "react";
import { Box } from "@mui/material";
import Text from "components/Text";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styles from "./SuccessTag.module.scss";

export default function SuccessTag({ children }) {
  return (
    <Box className={styles.successTag}>
      <CheckCircleOutlineIcon className={styles.successIcon} />
      <div className={styles.successText}>{children}</div>
    </Box>
  );
}
