import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpIcon from "@mui/icons-material/Help";
import { useMediaQuery } from "react-responsive";
import { SMALL } from "constants/breakpoints";

import { useSelector } from "react-redux";

import BlankaLipstickYourLogo from "assets/images/blanka-lipstick-your-logo.png";

import Text from "components/Text";
import Button from "components/Buttons/Button";

import styles from "../MyLogoStepperProgress/MyLogoStepperProgress.module.scss";

function MyLogoStepZero(props) {
  const { handleGetStarted } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const shop = useSelector((state) => state.profile.shop);

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  // This flag will be used to track whether the component is still mounted
  const isMounted = useRef(true);

  useEffect(() => {
    if (shop?.custom_product_status === "IN_PROGRESS") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [shop?.custom_product_status]);

  useEffect(
    () =>
      // When the component is unmounted, set isMounted to false
      () => {
        isMounted.current = false;
      },
    []
  );

  const handleOnClick = async () => {
    setIsLoading(true);
    await handleGetStarted();

    // Only update the state if the component is still mounted
    if (isMounted.current) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {!isMobile && (
        <>
          <Text className={`${styles.textTitle}`}>Overview</Text>
          <Divider className={styles.divider} />
        </>
      )}
      <Stack
        direction={isMobile ? "column" : "row"}
        className={styles.stepContainer}
      >
        <Grid item xs={!isMobile ? 8 : 12} className={styles.leftSection}>
          {isMobile && <Text className={`${styles.textTitle}`}>Overview</Text>}
          <Text className={`${styles.sectionTitle}`}>
            Welcome to your brand builder!
          </Text>
          <Text className={`${styles.sectionSubTitle}`}>
            Add your logo to products in 3 simple steps
          </Text>
          <Text className={`${styles.warningText}`}>
            Before we get started, we&apos;ll need the following:
          </Text>
          <ol className={styles.warningList}>
            <li>Dark logo used for products with light packaging</li>
            <li>Light logo used for products with dark packaging (optional)</li>
            <li>
              Thin versions of your dark and light logo to use on thin products
              (optional)
            </li>
          </ol>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={styles.btnStyle}
            onClick={handleOnClick}
            loading={isLoading}
            fullWidth={isMobile}
            disabled={isDisabled}
          >
            {isDisabled ? "Visualization In Progress" : "Get Started"}
          </Button>
          {!isMobile && (
            <>
              <Text className={styles.sectionFAQ} variant="h3">
                Questions?
              </Text>
              <Text className={styles.faq}>
                Read more on logo best practices{" "}
                <b>
                  <a
                    href="https://faq.blankabrand.com/en/articles/5214993-what-are-the-logo-requirements"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                </b>
                .
              </Text>
            </>
          )}
          {isMobile && (
            <Box className={styles.brandingTipsContainer}>
              <HelpIcon className={styles.helpIconStyling} />
              <Box className={styles.stepperLabel}>
                <span className={styles.title}>Branding tips!</span>
                <span className={styles.subTitle}>
                  Logo best practice{" "}
                  <a
                    href="https://faq.blankabrand.com/en/articles/5214993-what-are-the-logo-requirements"
                    target="_blank"
                    style={{ color: "white" }}
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </span>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={isMobile ? 12 : 4} className={styles.rightSection}>
          <img
            src={BlankaLipstickYourLogo}
            alt=""
            className={styles.logoImage}
          />
        </Grid>
      </Stack>
    </div>
  );
}

MyLogoStepZero.propTypes = {
  handleGetStarted: PropTypes.func.isRequired,
};

export default MyLogoStepZero;
