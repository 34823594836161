import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Card from "@mui/material/Card";
import Text from "components/Text";
import Button from "components/Buttons/Button";
import { Link, useNavigate } from "react-router-dom";
import { CART_TYPE_SAMPLE, CART_TYPE_INVENTORY } from "constants/constants";
import { removeCart, updateCart } from "state/cartSlice";
import { SMALL } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";
import PageHeader from "components/PageHeader";
import CheckoutFooter from "views/CheckoutView/components/CheckoutFooter";

import styles from "./CartEmpty.module.scss";

function CartEmpty(props) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const navigate = useNavigate();

  return (
    <div className="cart-template">
      <PageHeader title="My Shopping Cart" />
      <Card variant="outlined" className={styles.card}>
        <Text variant="h3" fontSize={isMobile ? 16 : 24} className="mb-10">
          Your cart is empty
        </Text>
        <Link to="/" className={styles.overide}>
          <Button
            variant="contained"
            color="primary"
            className={isMobile && styles.cardButton}
            onClick={() => {
              navigate("/");
            }}
          >
            Continue browsing
          </Button>
        </Link>
      </Card>
    </div>
  );
}

export default CartEmpty;
