import React, { useState } from "react";
import Button from "components/Buttons/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Link, useHistory, useNavigate } from "react-router-dom";

import styles from "./CheckoutFooter.module.scss";

function getSteps() {
  return [
    { name: "Cart", url: "/cart", step: 0 },
    { name: "Shipping", url: "/checkout/shipping", step: 1 },
    { name: "Payment", url: "/checkout/payment", step: 3 },
  ];
}

export default function CheckoutFooter({ onSubmit, backLink, activeStep }) {
  const steps = getSteps();
  const navigate = useNavigate();

  const isActive = (step) => {
    if (step === activeStep) {
      return true;
    }
    return false;
  };

  const handleClick = () => {
    if (!isActive) {
      // handleBack();
    }
  };

  const isDisabled = () => {
    if (activeStep !== 1) {
      return false;
    }
    return true;
  };

  return (
    <div className="breadcrumbs">
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step
              key={label.name}
              disabled={!isDisabled()}
              {...stepProps}
              onClick={(e) => (index < activeStep ? navigate(label.url) : "")}
              active={index === activeStep}
              className={index === activeStep ? styles.active : ""}
            >
              <StepLabel
                active
                className={!isActive(index) ? "pointer" : ""}
                onClick={(e) => handleClick(isActive(index))}
                {...labelProps}
              >
                {label.name}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
