import React, { Suspense } from "react";

// MUI components
import { Box } from "@mui/material";

// Custom components
import sidebarBlackFriday from "assets/images/black-friday/sidebar-ribbon.svg";
import Button from "components/Buttons/Button";
import Text from "components/Text";
import useChangePlanModal from "hooks/useChangePlanModal";

import styles from "./BlackFridaySidebar.module.scss";

export default function BlackFridaySidebar() {
  const { onOpenModal } = useChangePlanModal();

  return (
    <Box className={styles.blackFridaySidebar}>
      <img
        src={sidebarBlackFriday}
        alt="Black Friday Sidebar"
        className={styles.ribbon}
      />
      <Text variant="body1" className={styles.info}>
        Limited time only
      </Text>
      <Text variant="h3" className={styles.title}>
        Get $200 to elevate your brand
      </Text>

      <Button
        variant="contained"
        color="secondary"
        size="small"
        className={styles.unlockCta}
        onClick={() => onOpenModal({ cadence: "annual" })}
      >
        Unlock Offer
      </Button>
      <a
        className={styles.terms}
        target="_blank"
        href="https://faq.blankabrand.com/en/articles/10034545-black-friday-cyber-monday-promo-2024"
        rel="noreferrer"
      >
        Terms & Conditions
      </a>
    </Box>
  );
}
