import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line import/no-cycle
import { getPaymentHistory } from "../api/endpoints/billingApi";

// eslint-disable-next-line consistent-return
export const getBillingAsync = createAsyncThunk(
  "billing",
  // eslint-disable-next-line consistent-return
  async ({ page, date, limit, dateFilter }) => {
    try {
      const response = await getPaymentHistory(page, limit, date, dateFilter);
      return response.data;
    } catch (error) {
      return "Error";
    }
  }
);

export const billingSlice = createSlice({
  name: "billing",
  initialState: {
    value: {
      pages: [],
      nextPage: null,
      pageNumber: 0,
    },
    dateFiltersOptions: [
      {
        value: "today",
        query: "today",
      },
      {
        value: "yesterday",
        query: "yesterday",
      },
      {
        value: "this week",
        query: "this_week",
      },
      {
        value: "last week",
        query: "last_week",
      },
      {
        value: "this month",
        query: "this_month",
      },
      {
        value: "last month",
        query: "last_month",
      },
      {
        value: "last 6 months",
        query: "last_six_months",
      },
      {
        value: "last year",
        query: "last_year",
      },
    ],
    status: "loading",
  },
  reducers: {
    clearBilling: (state) => {
      // this.state = initialState;
    },
    setPreviousPage(state) {
      let item;
      if (state?.value?.pages.length > 1) {
        item = state.value.pages.pop();
      } else {
        // eslint-disable-next-line prefer-destructuring
        item = state.value.pages[0];
      }
      // eslint-disable-next-line no-param-reassign
      state.value.pageNumber = state.value.pages.length;

      // eslint-disable-next-line no-param-reassign
      state.value.nextPage =
        state.value.pages[state.value.pages.length - 1].nextPage;
    },
    resetPages(state) {
      // eslint-disable-next-line no-param-reassign
      state.value.pages = [];
      // eslint-disable-next-line no-param-reassign
      state.value.pageNumber = 0;
      // eslint-disable-next-line no-param-reassign
      state.value.nextPage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBillingAsync.pending, (state) => {
        // eslint-disable-next-line no-param-reassign
        state.status = "loading";
      })
      .addCase(getBillingAsync.fulfilled, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.status = "idle";

        let page = {
          results: [],
          nextPage: null,
        };

        if (action?.payload?.results?.length > 0) {
          page = {
            results: action.payload.results,
            nextPage: action.payload.next_page,
          };
          // eslint-disable-next-line no-param-reassign
          state.value.pageNumber += 1;
        } else {
          // eslint-disable-next-line no-param-reassign
          state.value.pageNumber = 0;
          // eslint-disable-next-line no-param-reassign
          state.value.nextPage = null;
        }

        // eslint-disable-next-line no-param-reassign
        state.value.pages.push(page);

        // eslint-disable-next-line no-param-reassign
        state.value.nextPage = page.nextPage;
      });
  },
});

export const { setPreviousPage, resetPages } = billingSlice.actions;

export const selectPaymentHistory = (state) => state.billing.value;

export const selectStatus = (state) => state.billing.status;

export const selectPageNumber = (state) => state.billing.value.pageNumber;

export const selectAllDateFilters = (state) => state.billing.dateFiltersOptions;

export default billingSlice.reducer;
