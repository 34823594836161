import React from "react";
import PropTypes from "prop-types";

// MUI components:
import { Box, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Custom components & assets:
import Text from "components/Text";
// import { CheckMarkIcon, CrossMarkIcon } from "components/Icons";
import { PLAN_PRICING_TABLE_INFO } from "constants/plans";

import styles from "./PlanDescription.module.scss";

function PlanDescription(props) {
  const { planId } = props;

  // Assuming the base plan is at index 0:
  const basePlanFeatures = PLAN_PRICING_TABLE_INFO[0].features_list.map(
    (feature) => feature.feature
  );

  // If I'm not comparing with the base plan, I want to filter the feature.feature === "" (empty string)
  const targetPlanFeatures = PLAN_PRICING_TABLE_INFO[planId].features_list.map(
    (feature) => feature.feature
  );

  // The max size of the array is the length of the base plan features list
  const MAX_SIZE = basePlanFeatures.length - 1;

  // // Helper to check if the target plan has a specific feature
  // const isFeatureIncluded = (feature) =>
  //   targetPlanFeatures.features_list.some((f) => f.feature === feature);

  const compareCurrentPlanWithServices = (index) => {
    if (targetPlanFeatures[index] === "Check")
      return <CheckCircleIcon className={styles.checkCircleIcon} />;
    if (targetPlanFeatures[index] === "Cross")
      return <CloseIcon className={styles.closeIcon} />;
    if (targetPlanFeatures[index] === "")
      return <span className={styles.upgradeLink} />;

    return (
      <span className={styles.featureFormat}>{targetPlanFeatures[index]}</span>
    );
  };

  return (
    <Box className={styles.planDescription}>
      <Text variant="h2" className={styles.stackTitle}>
        {PLAN_PRICING_TABLE_INFO[planId].plan_type} PLAN
      </Text>
      {basePlanFeatures.map((feature, index) => (
        <>
          <Box className={styles.featureRow}>
            <Text
              className={`${styles.featureText} ${
                targetPlanFeatures[index] === "" && styles.strikeThrough
              }`}
            >
              {feature}
            </Text>
            {compareCurrentPlanWithServices(index)}
          </Box>
          {index !== MAX_SIZE ? <Divider className={styles.divider} /> : ""}
        </>
      ))}
    </Box>
  );
}

PlanDescription.propTypes = {
  planId: PropTypes.number,
};

PlanDescription.defaultProps = {
  planId: 0,
};

export default PlanDescription;
