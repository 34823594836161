import React, { useMemo } from "react";

import Grid from "@mui/material/Grid";
import Text from "components/Text";
import PropTypes from "prop-types";

import UpgradePlanNotification from "components/Notifications/UpgradePlanNotification/UpgradePlanNotification";
import { SMALL } from "constants/breakpoints.js";
import { useProfile } from "hooks";
import { useMediaQuery } from "react-responsive";

import { useLocation } from "react-router-dom";
import cn from "utils/cn";
import styles from "./PageHeader.module.scss";

function PageHeader({ title, children, leftAction, className }) {
  const profile = useProfile();
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  // const { needsUpgrade } = useProfile();
  const { pathname } = useLocation();

  const isInBrandSetupView = useMemo(
    () => pathname.includes("brand-setup"),
    [pathname]
  );

  return (
    <>
      <Grid
        container
        className={cn(
          className,
          isMobile && styles.dropShadow,
          isMobile && styles.minimumnPageHeaderContainer,
          !isMobile && styles.pageHeaderContainer,
          !isInBrandSetupView && styles.addTopBorder
        )}
      >
        {leftAction && leftAction}
        {!isMobile && profile.isOverLimit() && <UpgradePlanNotification />}
        <Text className="text__page-header">{title}</Text>
        <div className={styles.actionContainer}>{children}</div>
      </Grid>
      {isMobile && profile.isOverLimit() && <UpgradePlanNotification />}
    </>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string,
};

PageHeader.defaultProps = {
  title: "",
};

export default PageHeader;
