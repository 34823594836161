import { QUERY } from "api";
import queryClient from "api/queryClient";
import { BrandSetupSuccessGennyNotification } from "components/Notifications/Notifications";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  onResetSocketReducer,
  onSetSocketDataReducer,
} from "state/wsGeneratorSlice";
import useDebounce from "./useNewDebounce";

const { REACT_APP_WEBSOCKET_URL } = process.env;

const useWebSocket = (onMessage) => {
  const [webSocketData, setWebSocketData] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const webSocketRef = useRef(null);

  const { token, isLoggedIn } = useSelector((state) => state.session);
  const dispatch = useDispatch();

  const debounceNotification = useDebounce(() => {
    toast.success(<BrandSetupSuccessGennyNotification />);
  }, 300);

  const onSetSocketData = (message) => {
    const payload = {
      progress: message.progress,
      message: `${message.completed_tasks} tasks completed out of ${message.total_tasks}`,
      totalTasks: message.total_tasks,
      completedTasks: message.completed_tasks,
    };

    dispatch(onSetSocketDataReducer(payload));

    if (message.progress === 100) {
      debounceNotification();
      queryClient.refetchQueries([QUERY.getUser]);
      dispatch(onResetSocketReducer());
    }
  };

  const connectWebSocket = useCallback(() => {
    webSocketRef.current = new WebSocket(
      `wss://${REACT_APP_WEBSOCKET_URL}/ws/generator/progress/?token=${token.access_token}`
    );

    webSocketRef.current.onopen = () => {
      console.log("websocket - connected");
      setIsConnected(true);
    };

    webSocketRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("websocket - onmessage", data);
      onSetSocketData(JSON.parse(data?.message));
      setWebSocketData(data);
      if (onMessage) onMessage(data);
    };

    // webSocketRef.current.onclose = () => {
    //   console.log("websocket - closed");
    //   setIsConnected(false);
    // };

    webSocketRef.current.onclose = (event) => {
      console.error(
        `websocket - closed unexpectedly, code: ${event.code}, reason: ${event.reason}`
      );
      setIsConnected(false);
    };

    webSocketRef.current.onerror = (error) => {
      console.error("websocket - error occurred:", error);
      setError(error);
    };
  }, [token]);

  const disconnectWebSocket = useCallback(() => {
    if (webSocketRef.current) {
      webSocketRef.current.close();
      console.log("websocket - disconnected");
    }
  }, []);

  const sendMessage = useCallback(
    (message) => {
      if (isConnected && webSocketRef.current) {
        webSocketRef.current.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected");
      }
    },
    [isConnected]
  );

  useEffect(() => {
    if (token?.access_token && isLoggedIn && !isConnected) {
      connectWebSocket();
    }

    return () => {
      if (webSocketRef.current) {
        disconnectWebSocket();
      }
    };
  }, [token?.access_token, isLoggedIn, connectWebSocket, disconnectWebSocket]);

  return {
    webSocketData,
    isConnected,
    error,
    sendMessage,
  };
};

export default useWebSocket;
