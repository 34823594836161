import TextField from "@mui/material/TextField";
import Button from "components/Buttons/Button";
import PasswordStrengthBar from "components/PasswordStrengthBar";
import Text from "components/Text";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import {
  appleSSOLogin,
  createUser,
  googleSSOLogin,
  login,
  updateUser,
} from "api";
import { TEST_ONBOARDING_COOKIE_NAME } from "constants/ab_tests";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { setToken } from "state/sessionSlice";

import { verifyRecaptcha } from "api/endpoints/shopApi";
import AppleLoginButton from "components/Buttons/AppleLoginButton";
import GoogleLoginButton from "components/Buttons/GoogleLoginButton";
import { useAnalytics } from "hooks";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import styles from "./SignUpForm.module.scss";

export default function SignUpForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.session.isLoggedIn);
  const navigate = useNavigate();

  const [message, setMessage] = useState();
  const analytics = useAnalytics();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [loading, setLoading] = useState(false);
  const mutation = useMutation(createUser);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isValid },
  } = useForm({ mode: "onChange" });
  const watchPassword = watch("password");

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  const getSignupSource = () => {
    let signupSource = "organic";
    if (sessionStorage.getItem("woocommerce_store")) {
      signupSource = "woocomerce";
    } else if (sessionStorage.getItem("wix_store")) {
      signupSource = "wix";
    } else if (sessionStorage.getItem("shopify_store")) {
      signupSource = "shopify";
    }

    return signupSource;
  };

  const sendEvent = () => {
    analytics.sendEvent(analytics.USER_REGISTRATION, {
      source: getSignupSource(),
    });
  };

  const verifyRecaptchaMutation = useMutation({
    mutationFn: (token) => verifyRecaptcha(token),
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  const onSubmit = async (data) => {
    mutation.mutate(
      {
        ...data,
        signup_source: getSignupSource(),
        signup_versionsignup_version: Cookies.get(TEST_ONBOARDING_COOKIE_NAME),
      },
      {
        onSuccess: () => {
          login({ username: data.email, password: data.password }).then(
            (res) => {
              sendEvent();
              dispatch(setToken(res.data));
              setLoading(false);
            }
          );
        },
        onError: (err) => {
          setLoading(false);
          if (err.response.data?.error_code === "ERROR_PASSWORD_STRENGTH") {
            setMessage(err.response.data.message);
            return;
          }
          setMessage(
            err.response.data.message
              ? err.response.data.message
              : "There was an error creating your account"
          );
        },
      }
    );
  };

  const handleReCaptchaVerify = useCallback(
    async (data) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      setLoading(true);
      const recaptchaToken = await executeRecaptcha("SIGNUP");

      if (recaptchaToken) {
        verifyRecaptchaMutation.mutate(recaptchaToken, {
          onSuccess: (response) => {
            console.log("Response:", response);
            if (response?.data?.success) {
              onSubmit(data);
            } else {
              setLoading(false);
              setMessage("There was an error verifying the reCAPTCHA");
            }
          },
          onError: (error) => {
            setLoading(false);
            console.log("Error:", error);
            setMessage("There was an error verifying the reCAPTCHA");
            console.error(error);
          },
        });
      }
    },
    [executeRecaptcha]
  );

  const handleGoogleLogin = async (googleData) => {
    try {
      setLoading(true);
      const response = await googleSSOLogin(googleData.access_token);
      dispatch(
        setToken({
          access: response.data.access,
          refresh: response.data.refresh,
        })
      );
      sendEvent();
      updateUser({ signup_version: Cookies.get(TEST_ONBOARDING_COOKIE_NAME) });

      setLoading(false);
    } catch (err) {
      setLoading(false);

      setMessage(
        `Oops! Could not login with your Google account. ${
          err.response?.data?.non_field_errors[0]
            ? err.response?.data.non_field_errors[0]
            : ""
        }`
      );
    }
  };

  const handleAppleLogin = async (code) => {
    try {
      setLoading(true);
      const response = await appleSSOLogin(code);
      dispatch(
        setToken({
          access: response.data.access,
          refresh: response.data.refresh,
        })
      );
      sendEvent();
      updateUser({ signup_version: Cookies.get(TEST_ONBOARDING_COOKIE_NAME) });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setMessage(
        `Oops! Could not login with your Apple account. ${
          err.response?.data?.non_field_errors[0]
            ? err.response?.data.non_field_errors[0]
            : ""
        }`
      );
    }
  };

  return (
    <div className={styles.signUpFormContainer}>
      <form
        className={styles.form}
        onSubmit={handleSubmit(handleReCaptchaVerify)}
      >
        <div />

        <div className={styles.ssoContainer}>
          <GoogleLoginButton handleLogin={handleGoogleLogin} />
          <AppleLoginButton handleLogin={handleAppleLogin} />
        </div>

        <Divider className={styles.divider} variant="middle">
          OR
        </Divider>

        <div className={styles.fullNameContainer}>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="First Name"
                className={styles.marginTen}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            rules={{ required: true }}
          />

          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Last Name"
                className={styles.padding}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            rules={{ required: true }}
          />
        </div>

        <div className={styles.emailPassContainer}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true, pattern: /^\S+@\S+$/ }}
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.textFieldWidth}
                label="Email"
                type="email"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>

        <div className={styles.emailPassContainer}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                className={styles.textFieldWidth}
                type="password"
              />
            )}
            rules={{ required: true }}
          />
          <PasswordStrengthBar
            password={watchPassword}
            width="300px"
            margin="10px 0 0 0"
            handleStrengthChange={(strength) => setPasswordStrength(strength)}
            className={styles.passwordStrengthBar}
          />

          <div className={styles.termsConditionsContainer}>
            <p className={styles.termsText}>
              By signing up you agree to Blanka&apos;s{" "}
              <a
                target="__blank"
                href="https://blankabrand.com/blanka-terms-and-conditions/"
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                target="__blank"
                href="https://faq.blankabrand.com/en/articles/5924132-how-does-blanka-handle-subscription-refunds"
              >
                Refund &amp; Cancellation Policy.
              </a>
            </p>
          </div>
          <div className={styles.recaptchaContainer}>
            <p className={styles.recaptchaText}>
              <span>This site is protected by reCAPTCHA and the Google </span>
              {"  "}
              <a target="__blank" href="https://policies.google.com/privacy">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a target="__blank" href="https://policies.google.com/terms">
                Terms of Service
              </a>{" "}
              apply.
            </p>
          </div>
        </div>

        <div className={styles.actionContainer}>
          <Button
            loading={loading}
            disabled={
              !isDirty ||
              !isValid ||
              watchPassword.length < 8 ||
              passwordStrength < 3
            }
            color="secondary"
            type="submit"
            variant="contained"
            size="large"
          >
            SIGN ME UP
          </Button>

          <Text
            fontSize={10}
            className={`text--nunito text--error ${styles.textError}`}
          >
            {" "}
            {message}
          </Text>
        </div>
      </form>
    </div>
  );
}
