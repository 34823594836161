import { InfoOutlined } from "@mui/icons-material";
import React, { useMemo } from "react";
import styles from "./Alert.module.scss";

function Alert({ text, variant }) {
  const style = useMemo(() => {
    switch (variant) {
      case "error":
        return styles.alertError;

      case "success":
        return styles.alertSuccess;

      // make more variants
      default:
        return styles.alertError;
    }
  }, []);
  return (
    <div className={style}>
      <InfoOutlined />
      <span>{text}</span>
    </div>
  );
}

export default Alert;
