import { useCallback } from "react";
import { useSelector } from "react-redux";

export default function useAnalytics() {
  const user = useSelector((state) => state.profile.profile);

  return {
    // event constants
    EVENT_DUNNING_DOWNGRADE_TO_FREE: "dunning_downgrade_to_free",
    EVENT_DUNNING_ADD_PAYMENT_METHOD: "dunning_add_payment_method",
    DUNNING_CLICK_UPDATE_BILLING: "dunning_click_update_billing",
    DUNNING_ATTEMPT_TO_LEAVE: "dunning_attempt_to_leave",
    EVENT_CHANGE_MY_LOGO_STEP: "my_logo_change_step",
    EVENT_MY_LOGO_COMPLETE: "my_logo_complete",
    PAYWALL_HIT: "paywall_hit",
    SHOP_ONBOARDING_STEP_CHANGE: "shop_onboarding_step_change",
    EVENT_HEADER_BAR_START_FREE_TRIAL: "header_bar_start_free_trial",

    ACCOUNT_CONNECTION_SQUARE_CLICK: "account_connection_square_click",

    USER_REGISTRATION: "user_registration",
    PAGE_VIEW: "page_view",

    ONBOARDING_SELECT_PLAN: "onboarding_select_plan",
    ONBOARDING_CONNECT_STORE_ATTEMPT: "onboarding_connect_store_attempt",
    ONBOARDING_CONNECT_STORE_SUCCESS: "onboarding_connect_store_success",
    ONBOARDING_CONNECT_STORE_SKIP: "onboarding_connect_store_skip",
    ONBOARDING_COMPLETE: "onboarding_complete",
    ONBOARDING_QUESTIONS_SUBMIT: "onboarding_questions_submit",
    ONBOARDING_QUESTION_SKPIPPED: "onboarding_question_skipped",
    BLANKA_FORMULATOR_OPEN_TYPEFORM: "blanka_formulator_open_typeform",

    GENNY_START: "genny_start",
    GENNY_CLOSE: "genny_close",
    GENNY_COMPLETE: "genny_complete",

    DOWNGRADE_FLOW_START: "downgrade_flow_start",
    DOWNGRADE_FLOW_COMPLETE: "downgrade_flow_complete",
    DOWNGRADE_FLOW_STEP_CHANGE: "downgrade_flow_step_change",
    DOWNGRADE_FLOW_EXIT: "downgrade_flow_exit",
    DOWNGRADE_FLOW_KEEP_CURRENT_PLAN: "downgrade_flow_keep_current_plan",

    CATEGORY_SELECTED: "category_selected",

    LINK_CLICKED_LATEST_FROM_THE_BLOG: "link_clicked_latest_from_the_blog",
    SIDEBAR_NAVIGATION: "sidebar_navigation",

    BRAND_PLATE_VIP_PLAN_CHOICE: "brand_plate_vip_plan_choice",

    UPLOAD_LOGO_ERROR: "upload_logo_error",

    UPGRADE_BLOCK_CLICK: "upgrade_block_click",

    PRODUCT_PAGE_VIEW: "product_page_view",
    PRODUCT_SEARCH: "product_search",

    BRAND_SETUP_UPLOAD_VIEW: "brand_setup_upload_view",
    BRAND_SETUP_UPLOAD_LOGO_ERROR: "brand_setup_upload_logo_error",
    BRAND_SETUP_UPLOAD_LOGO_SUCCESS: "brand_setup_upload_logo_success",

    BRAND_SETUP_UPLOAD_ALT_LOGO: "brand_setup_upload_alt_logo",
    BRAND_SETUP_UPLOAD_ALT_LOGO_SUCCESS: "brand_setup_upload_alt_logo_success",
    BRAND_SETUP_UPLOAD_ALT_LOGO_ERROR: "brand_setup_upload_alt_logo_error",

    BRAND_SETUP_UPLOAD: "brand_setup_upload",
    BRAND_SETUP_CREATE_MOCKUP: "brand_setup_create_mockup",
    BRAND_SETUP_REVIEW_CONFIRMATION: "brand_setup_review_confirmation",
    BRAND_SETUP_APPROVED: "brand_setup_approved",

    sendEvent: useCallback((eventName, eventData = {}) => {
      try {
        if (user.is_test) {
          return;
        }

        if (process.env.NODE_ENV !== "production") {
          console.log("sendEvent", eventName, eventData);
        }

        window.analytics.track(eventName, eventData);
      } catch (e) {
        console.log(e);
      }
    }),
  };
}
