/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import {
  LOGO_DEFAULT,
  LOGO_INVERTED,
  LOGO_INVERTED_THIN,
  LOGO_THIN,
} from "constants/generatorLogo";

export const myLogoSlice = createSlice({
  name: "myLogo",
  initialState: {
    LOGO_ORDER: [LOGO_DEFAULT, LOGO_INVERTED, LOGO_THIN, LOGO_INVERTED_THIN],
    activeOuterStep: -1,
    activeInnerStep: 0,
    isOpen: false,
    isGeneratorStarted: false,
    isLogoUploaded: false,
    isGeneratorCompleted: false,
    isLoading: false,
    logos: [],
  },
  reducers: {
    nextOuterStep: (state, action) => {
      const { innerStepsLength } = action.payload;
      state.activeOuterStep =
        state.activeInnerStep === innerStepsLength - 1
          ? state.activeOuterStep + 1
          : state.activeOuterStep;
      state.activeInnerStep =
        state.activeInnerStep === innerStepsLength - 1
          ? 0
          : state.activeInnerStep + 1;
    },
    prevOuterStep: (state, action) => {
      const { prevInnerStepsLength } = action.payload;
      state.activeOuterStep =
        state.activeInnerStep === 0
          ? state.activeOuterStep - 1
          : state.activeOuterStep;
      state.activeInnerStep =
        state.activeInnerStep === 0
          ? prevInnerStepsLength - 1
          : state.activeInnerStep - 1;
    },
    nextInnerStep: (state, action) => {
      const { innerStepsLength } = action.payload;
      state.activeInnerStep =
        state.activeInnerStep === innerStepsLength - 1
          ? state.activeInnerStep
          : state.activeInnerStep + 1;
    },
    prevInnerStep: (state) => {
      state.activeInnerStep =
        state.activeInnerStep === 0
          ? state.activeInnerStep
          : state.activeInnerStep - 1;
    },
    setActiveOuterStep: (state, action) => {
      state.activeOuterStep = action.payload;
    },
    setActiveInnerStep: (state, action) => {
      state.activeInnerStep = action.payload;
    },
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setIsGeneratorStarted: (state, action) => {
      state.isGeneratorStarted = action.payload;
    },
    setIsLogoUploaded: (state, action) => {
      state.isLogoUploaded = action.payload;
    },
    setIsGeneratorCompleted: (state, action) => {
      state.isGeneratorCompleted = action.payload;
    },
    addLogo(state, action) {
      const newLogo = action.payload;
      const index = state.logos.findIndex(
        (logo) => logo.logo_type === newLogo.logo_type
      );

      // If the logo_type already exists, update the logo
      if (index !== -1) {
        state.logos[index] = newLogo;
      } else {
        // If the logo_type doesn't exist, add the new logo
        state.logos.push(newLogo);
      }

      // Ensure logos array has exactly 4 elements by adding placeholders if needed
      if (state.logos.length < 4) {
        const missingLogos = state.LOGO_ORDER.filter(
          (logoType) => !state.logos.some((logo) => logo.logo_type === logoType)
        );

        missingLogos.forEach((logoType) => {
          state.logos.push({ logo_type: logoType, logo: null }); // Placeholder logo object
        });
      }

      // Sort logos by LOGO_ORDER
      state.logos.sort(
        (a, b) =>
          state.LOGO_ORDER.indexOf(a.logo_type) -
          state.LOGO_ORDER.indexOf(b.logo_type)
      );

      // Ensure logos array doesn't exceed 4 elements (this should not trigger if above logic works correctly)
      while (state.logos.length > 4) {
        state.logos.pop();
      }
    },
    deleteLogo(state, action) {
      const logoType = action.payload;
      state.logos = state.logos.filter((logo) => logo.logo_type !== logoType);
    },
    getLogo(state, action) {
      const logoType = action.payload;
      // return state.logos.find((logo) => logo.logo_type === logoType);
      return {
        ...state,
        selectedLogo: state.logos.find((logo) => logo.logo_type === logoType),
      };
    },
    // updateLogo(state, action) {
    //   const updatedLogo = action.payload;
    //   const index = state.logos.findIndex(
    //     (logo) => logo.logo_type === updatedLogo.logo_type
    //   );
    //   if (index !== -1) {
    //     state.logos[index] = updatedLogo;
    //   }
    // },
    setLogo: (state, action) => {
      state.logos = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  nextOuterStep,
  prevOuterStep,
  nextInnerStep,
  prevInnerStep,
  setActiveOuterStep,
  setActiveInnerStep,
  setIsOpen,
  setIsGeneratorStarted,
  setIsLogoUploaded,
  addLogo,
  deleteLogo,
  getLogo,
  updateLogo,
  setIsGeneratorCompleted,
  setLogo,
  setLoading,
} = myLogoSlice.actions;

export default myLogoSlice.reducer;
