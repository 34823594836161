import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { useCalcs, useCart } from "hooks";

import Button from "components/Buttons/Button";
import { clearCartDiscount, setCart } from "state/cartSlice";
import { checkCoupon } from "api/endpoints/cartApi";

import { useMediaQuery } from "react-responsive";
import { SMALL } from "constants/breakpoints";

import styles from "./CheckoutDiscountForm.module.scss";

export default function CheckoutDiscountForm({ disabled }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [couponMessage, setCouponMessage] = useState(false);
  const [code, setCode] = useState("");

  const cart = useSelector((state) => state.cart);
  const cartDiscount = useSelector((state) => state.cart.discount);
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const cartHook = useCart();

  useEffect(() => {
    if (cartDiscount) {
      setCode(cartDiscount.code || "");
    }
  }, [cartDiscount]);

  const handleCheckCoupon = async () => {
    if (cartDiscount?.code) {
      dispatch(clearCartDiscount());
      await cartHook.removeCartDiscountCode();
      dispatch(setCart({ ...cart, discount: null }));
      setCode("");
      return;
    }

    try {
      setLoading(true);

      const response = await checkCoupon(cart.cartId, code);

      if (!response.data.valid) {
        setCouponMessage("Invalid code entered.");
        setLoading(false);
        setCode("");
        return;
      }

      await cartHook.getActiveCartFromServer();

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className={styles.checkoutDiscountForm}>
      <TextField
        id="coupon"
        label="Discount Code"
        variant="outlined"
        disabled={disabled}
        className={styles.couponInput}
        value={code}
        InputProps={{ readOnly: !!cartDiscount?.code }}
        InputLabelProps={{
          shrink: true,
        }}
        required
        onChange={(e) => {
          setCode(e.target.value);
          setCouponMessage("");
        }}
      />

      {couponMessage && (
        <span className={styles.errorMessage}>{couponMessage}</span>
      )}

      <Button
        disabled={disabled || !code}
        onClick={handleCheckCoupon}
        color="primary"
        variant="outlined"
        loading={loading}
        className={styles.couponCta}
      >
        {cartDiscount?.code ? "Remove" : "Apply"} Discount
      </Button>
    </div>
  );
}
