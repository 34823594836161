import React, { useEffect, useState } from "react";
import { GenericSuccessNotification } from "components/Notifications";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Link,
  Paper,
  Stack,
  Card,
  CardActions,
  CardContent,
  Input,
} from "@mui/material";
import Text from "components/Text";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { formatDateTime } from "utils/date";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import {
  getBrandPlateOrdersAsync,
  selectCurrentOrder,
  selectOrders,
  setAssets,
  setStep,
  getLatestApprovedOrder,
  selectBrandPlateOrderStatus,
} from "state/brandPlateSlice";
import {
  BRAND_PLATES_TYPES,
  STATUSES,
  BRAND_PLATE_STATUS,
} from "constants/brandPlate";
import { sendFeedbackForBrandPlateMockupApi } from "api/endpoints/ordersApi";
import BrandPlateStatusIndicator from "../BrandPlateStatusIndicator";
import BrandPlateMockupFiles from "../BrandPlateMockupFiles";

import styles from "./BrandPlateInRevision.module.scss";

function BrandPlateInRevision() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentOrder = useSelector(selectCurrentOrder);
  const orders = useSelector(selectOrders);
  const brandPlateOrderStatus = useSelector(selectBrandPlateOrderStatus);
  const [revisionText, setRevisionText] = useState("");

  const handleSendFeedback = async () => {
    const response = await sendFeedbackForBrandPlateMockupApi(
      currentOrder.id,
      revisionText
    );

    if (response.status === 200) {
      await dispatch(getBrandPlateOrdersAsync());
      toast.success(
        <GenericSuccessNotification text="Brand plate feedback sent" />
      );
    }
  };

  return (
    <div className={styles.brandPlateDefaultContent}>
      <Stack className={styles.stackContainer}>
        <Card className={styles.pendingReviewBox}>
          <CardContent>
            <Text
              color="primary"
              fontSize="16"
              variant="h2"
              className={styles.pendingReviewTitle}
            >
              You have requested revisions
            </Text>
            <Box className={styles.pendingReviewContainer}>
              <Box className={styles.downloadContainer}>
                <Text
                  className={styles.mockupTitle}
                  variant="body1"
                  fontSize="14"
                >
                  Product mock-up:
                </Text>
                <BrandPlateMockupFiles mockups={currentOrder.mockups} />
              </Box>
              <Text variant="body1" fontSize="16" className="text--bold">
                What can I change?
              </Text>
              <Text fontSize="14" variant="body1">
                You may request small visual adjustments to your logo placement
                based on what you see in your Brand Plate product mock-up.
                <ul>
                  <li>Logo size (within max area)</li>
                  <li>Logo rotation</li>
                  <li>Logo selection (Default or Pencil/Thin)</li>
                  <li>Logo color (2 or multi-color Brand Plates only)</li>
                </ul>
                <a
                  href="https://faq.blankabrand.com/en/articles/9261512-how-do-brand-plate-revisions-work"
                  target="_blank"
                  rel="noreferrer"
                >
                  See FAQ for more details
                </a>
              </Text>
            </Box>
          </CardContent>
          <CardActions className={styles.actionContainer}>
            <Input
              onChange={(e) => {
                setRevisionText(e.target.value);
              }}
              className={styles.inputBox}
              aria-label="feedback"
              multiline
              placeholder="Eg. Product Name, rotate logo 90 degress "
            />

            <Box className={styles.actionBox}>
              <Button
                size="small"
                variant="contained"
                onClick={handleSendFeedback}
              >
                Submit request
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Stack>
    </div>
  );
}

export default BrandPlateInRevision;
