import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Button from "components/Buttons/Button";
import Text from "components/Text";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import {
  CUSTOM_PRODUCT_STATUS_COMPLETE,
  CUSTOM_PRODUCT_STATUS_IN_PROGRESS,
  CUSTOM_PRODUCT_STATUS_NONE,
} from "constants/constants";

import { deactivateExistingLogos } from "api";

import Typography from "@mui/material/Typography";
import { SMALL } from "constants/breakpoints.js";
import { mockups } from "constants/generatorLogo";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import MyLogoConfirmNotification from "../MyLogoNotification/MyLogoConfirmNotification";

import styles from "./MyLogoStepTemplate.module.scss";

function MyLogoStepSix(props) {
  const { handleResetGenerator, handleConfirmLogoGenerated, handlePrevious } =
    props;

  const [isOpen, setIslOpen] = useState(false);
  const [isLoadingConfirmCta, setIsLoadingConfirmCta] = useState(false);
  const [isLoadingUploadNewLogoCta, setIsLoadingUploadNewLogoCta] =
    useState(false);

  const { logos, isGeneratorCompleted, LOGO_ORDER } = useSelector(
    (state) => state.myLogo
  );
  const shop = useSelector((state) => state.profile.shop);

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const handleConfirm = () => {
    setIslOpen(true);
  };

  const handleOnClickResetGenerator = async () => {
    setIsLoadingUploadNewLogoCta(true);
    // deactivates any logos that are currently active in the database
    await deactivateExistingLogos();
    await handleResetGenerator();
    setIsLoadingUploadNewLogoCta(false);
  };

  const handleOnClickConfirmLogoGenerated = async () => {
    setIsLoadingConfirmCta(true);
    await handleConfirmLogoGenerated(logos);
    setIsLoadingConfirmCta(false);
  };

  const handleMockupImageClassName = (index) => {
    switch (index) {
      case 0:
        return styles.mockupLogoDefault;
      case 1:
        return styles.mockupLogoInverted;
      case 2:
        return styles.mockupLogoThin;
      case 3:
        return styles.mockupLogoInvertedThin;
      default:
        return styles.mockupLogoDefault;
    }
  };

  return (
    <div className={styles.myLogoStepTemplate}>
      {!isMobile && (
        <Box className={styles.boxContainer}>
          {!isGeneratorCompleted && (
            <>
              <Button variant="text" color="secondary" onClick={handlePrevious}>
                <div className={styles.backBtn}>
                  <KeyboardBackspaceIcon />
                  Back
                </div>
              </Button>

              <Text className={styles.textTitle}>Step 3</Text>
            </>
          )}
          {isGeneratorCompleted && (
            <Text className={`${styles.textTitle} ${styles.mt}`}>
              Current Brand
            </Text>
          )}
        </Box>
      )}
      {!isMobile && <Divider className={styles.divider} />}
      <Stack direction="row" className={styles.stepContainer}>
        {isMobile && !isGeneratorCompleted && (
          <Text className={styles.textTitle}>Step 3</Text>
        )}
        {isMobile && isGeneratorCompleted && (
          <Text className={styles.textTitle}>Current brand</Text>
        )}
        <Grid item className={styles.stepSection}>
          <Stack direction="row" justifyContent="space-between">
            {!isGeneratorCompleted && (
              <Text className={styles.sectionSubTitle}>
                Product mockup review
              </Text>
            )}
            {isGeneratorCompleted && (
              <Text className={styles.sectionSubTitle}>
                Active product mockup preview
              </Text>
            )}
          </Stack>
          <Grid container className={styles.mockupGridWrapper}>
            {mockups.map((mockup, index) => (
              // console.log(mockup.logo_type === logos[index]?.logo_type);
              <Grid
                item
                key={`${mockup.image}-${mockup.caption}`}
                className={styles.mockupGrid}
              >
                <Box className={handleMockupImageClassName(index)}>
                  <img
                    src={logos[index]?.logo || logos[0]?.logo}
                    alt=""
                    className={styles.mockupLogoImg}
                  />
                </Box>
                <img src={mockup.image} alt="" className={styles.mockupImage} />
                <Text className={styles.mockupImageCaption}>
                  {mockup.caption}
                </Text>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {!isGeneratorCompleted && (
          <div
            className={
              isMobile ? (styles.center, styles.mt) : styles.gridContainer
            }
          >
            <Text className={styles.mockupTitleText}>
              Happy with your mockups?
            </Text>
            <Text className={styles.mockupText}>
              Click “YES” and we’ll generate the rest of your product mockups.
              This will take a few minutes and you’ll receive an email when it’s
              complete.
            </Text>
          </div>
        )}

        {isGeneratorCompleted &&
          shop?.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE &&
          shop?.brand_plate_created && (
            <div
              className={
                isMobile ? (styles.center, styles.mt) : styles.gridContainer
              }
            >
              <Text className={styles.mockupTitleText}>
                Want to change your logo?
              </Text>
              <Text className={styles.mockupText}>
                <strong>
                  You already have a BRAND PLATE created with this logo.
                </strong>{" "}
                If you change your logo you will have to purchase a new brand
                plate. In addition, your branded product images will need to be
                recreated.
              </Text>
            </div>
          )}
        {isGeneratorCompleted &&
          shop?.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE &&
          !shop?.brand_plate_created && (
            <div
              className={
                isMobile ? (styles.center, styles.mt) : styles.gridContainer
              }
            >
              <Text className={styles.mockupTitleText}>
                Want to change your logo?
              </Text>
              <Text className={styles.mockupText}>
                When you click “YES” below, this will replace all of your
                existing product mockups.
              </Text>
            </div>
          )}

        {isGeneratorCompleted &&
          shop?.custom_product_status === CUSTOM_PRODUCT_STATUS_IN_PROGRESS && (
            <div
              className={
                isMobile ? (styles.center, styles.mt) : styles.gridContainer
              }
            >
              <Text className={styles.mockupTitleText}>
                Hang tight! We’re creating your custom product images.
              </Text>
              <Text className={styles.mockupText}>
                You’ll receive an email when they are complete.
              </Text>
            </div>
          )}

        <Grid
          container
          className={
            isMobile ? (styles.center, styles.mt) : styles.gridContainer
          }
          classes={{
            root: styles.center,
          }}
        >
          {isGeneratorCompleted &&
          shop?.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE ? (
            <Button
              variant="contained"
              color="primary"
              className={styles.btnWidthConstraint}
              onClick={handleConfirm}
              loading={isLoadingConfirmCta}
            >
              Yes, change my logo
            </Button>
          ) : (
            <div />
          )}
          {!isGeneratorCompleted && (
            <>
              <Button
                variant="contained"
                color="primary"
                className={styles.btnWidthConstraint}
                onClick={handleOnClickConfirmLogoGenerated}
                loading={isLoadingConfirmCta}
                disabled={isLoadingUploadNewLogoCta}
                fullWidth
              >
                Yes, let&apos;s do this!
              </Button>

              <Button
                variant="outlined"
                color="primary"
                className={`${styles.btnWidthConstraint} ${
                  isMobile ? styles.mb : ""
                }`}
                onClick={handleOnClickResetGenerator}
                disabled={isLoadingConfirmCta}
                loading={isLoadingUploadNewLogoCta}
              >
                No, upload new logo
              </Button>
            </>
          )}
        </Grid>
        {isLoadingConfirmCta && (
          <Typography className={`text--align-center ${styles.mt}`}>
            Hang tight, we&apos;re uploading your logos...
          </Typography>
        )}
      </Stack>

      <MyLogoConfirmNotification
        isOpen={isOpen}
        setIsOpen={setIslOpen}
        onConfirm={handleOnClickResetGenerator}
        isBrandPlateCreated={shop?.brand_plate_created}
      />
    </div>
  );
}

MyLogoStepSix.propTypes = {
  handleResetGenerator: PropTypes.func.isRequired,
  handleConfirmLogoGenerated: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
};

MyLogoStepSix.defaultProps = {};

export default MyLogoStepSix;
