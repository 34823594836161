import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "components/Buttons/Button";
import Text from "components/Text";
import { useAnalytics } from "hooks";
import useChangePlanModal from "hooks/useChangePlanModal";
import React from "react";
import { useSelector } from "react-redux";
import styles from "./UpgradeBlock.module.scss";

export default function UpgradeBlock() {
  const shop = useSelector((state) => state.profile.shop);
  const analytics = useAnalytics();
  const { onOpenModal } = useChangePlanModal();

  const onOpenModalClick = () => {
    onOpenModal();
    analytics.sendEvent(analytics.UPGRADE_BLOCK_CLICK, {});
  };

  return (
    <Card className={styles.upgradeBlock}>
      <CardContent className={styles.content}>
        <Text color="white" variant="h3" fontSize={18} className={styles.text}>
          Start selling branded products today!
        </Text>
      </CardContent>
      <CardActions className={styles.actions}>
        <Button
          variant="contained"
          color="primary"
          onClick={onOpenModalClick}
          className={`text--spacing-0 ${styles.button}`}
          hasChevron
        >
          {shop.has_trial ? "TRY FOR FREE" : "CHANGE PLAN"}
        </Button>
      </CardActions>
    </Card>
  );
}
