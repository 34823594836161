import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function MyBrandPlateIcon(props) {
  return (
    <div className="icon-root">
      <SvgIcon
        {...props}
        data-testid="MyBrandPlateIcon"
        className="icon icon--hover"
        viewBox="0 0 20 20">
        <g id="Icons">
          <g id="Outlined" transform="translate(-307.000000, -2947.000000)">
            <g id="Image" transform="translate(100.000000, 2626.000000)">
              <g
                id="Outlined-_x2F_-Image-_x2F_-center_x5F_focus_x5F_weak"
                transform="translate(204.000000, 318.000000)">
                <g>
                  <path
                    id="_xD83D__xDD39_-Icon-Color"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#5E5E5E"
                    d="M5,15H3v4c0,1.1,0.9,2,2,2h4
                  v-2H5V15z M5,5h4V3H5C3.9,3,3,3.9,3,5v4h2V5z M12,8c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S14.2,8,12,8z M12,14
                  c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,14,12,14z M19,3h-4v2h4v4h2V5C21,3.9,20.1,3,19,3z M19,19h-4v2h4c1.1,0,2-0.9,2-2
                  v-4h-2V19z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <circle
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D46E5A"
          cx="9"
          cy="9"
          r="2"
        />
      </SvgIcon>

      <SvgIcon {...props} className="icon icon--normal" viewBox="0 0 20 20">
        <g id="Icons">
          <g id="Outlined" transform="translate(-307.000000, -2947.000000)">
            <g id="Image" transform="translate(100.000000, 2626.000000)">
              <g
                id="Outlined-_x2F_-Image-_x2F_-center_x5F_focus_x5F_weak"
                transform="translate(204.000000, 318.000000)">
                <g>
                  <path
                    id="_xD83D__xDD39_-Icon-Color"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#5E5E5E"
                    d="M5,15H3v4c0,1.1,0.9,2,2,2h4
                    v-2H5V15z M5,5h4V3H5C3.9,3,3,3.9,3,5v4h2V5z M12,8c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S14.2,8,12,8z M12,14
                    c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,14,12,14z M19,3h-4v2h4v4h2V5C21,3.9,20.1,3,19,3z M19,19h-4v2h4c1.1,0,2-0.9,2-2
                    v-4h-2V19z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </SvgIcon>
    </div>
  );
}
