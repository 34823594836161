import React from "react";
import PropTypes from "prop-types";

// mui:
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Stack,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

// components & constants:
import Text from "components/Text";
import { PLAN_PRICING_TABLE_INFO } from "constants/plans";
import { CheckMarkIcon } from "components/Icons";

import styles from "./PlanVisualizerCard.module.scss";

export default function PlanVisualizerCard(props) {
  const { plan } = props;

  const features = PLAN_PRICING_TABLE_INFO[plan.planId].features_list.filter(
    (item) => item.feature !== "Cross"
  );

  let featuresInfo = PLAN_PRICING_TABLE_INFO[0].features_list.slice(
    0,
    features.length
  );

  const planName = PLAN_PRICING_TABLE_INFO[plan.planId].name.split("-")[0];

  featuresInfo = featuresInfo.map((item) => item.feature);

  return (
    <Box className={styles.planVisualizerCard}>
      <Text variant="h2" className={styles.planName}>
        {planName}
      </Text>
      <Stack className={styles.stackContainer}>
        <Box className={styles.sideBox}>
          {featuresInfo.map((item) => (
            <Text key={item} className={styles.feature}>
              {item}
            </Text>
          ))}
        </Box>
        <Box className={styles.sideBox}>
          {features.map((item) => {
            if (item.feature === "Check") {
              return (
                <Text key={item} className={styles.featureItem}>
                  <CheckIcon className={styles.checkIcon} />
                </Text>
              );
            }

            return (
              <Text key={item} className={styles.featureItem}>
                {item.feature}
              </Text>
            );
          })}
        </Box>
      </Stack>
    </Box>
  );
}

PlanVisualizerCard.propTypes = {
  plan: PropTypes.shape({
    planId: PropTypes.number.isRequired,
    planType: PropTypes.string.isRequired,
  }).isRequired,
};
