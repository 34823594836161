import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Woocommerce.module.scss";

export default function Woocommerce() {
  const navigate = useNavigate();

  // save shop url for when they login
  useEffect(() => {
    /// /// connection a store  /////////
    const storeURL = new URLSearchParams(window.location.search).get("user_id");
    const storeToken = new URLSearchParams(window.location.search).get("token");

    if (storeURL) {
      window.sessionStorage.setItem(
        "woocommerce_store",
        JSON.stringify({ url: storeURL, token: storeToken })
      );
    }

    // always go to the connect page, main will intercept if the store has not finished onboarding
    navigate("/profile/connect");
  }, []);

  return <div className={styles.templateLogin} />;
}
