import React from "react";
import styles from "./BrandSetupGroupProductCardSkeleton.module.scss";

function BrandSetupGroupProductCardSkeleton() {
  return (
    <div className={styles.productCard}>
      <div className={styles.productVariantsSide}>
        <div className={styles.variantCircleSkeletonSide} />
      </div>
      <div className={styles.productImages}>
        <div className={styles.imageSkeleton} />
      </div>
      <div className={styles.productContent}>
        <div>
          <div className={styles.nameSkeleton} />
        </div>
        <div className={styles.productVariants}>
          <div className={styles.variantCircleSkeleton} />
          <div className={styles.variantCircleSkeleton} />
        </div>
      </div>
    </div>
  );
}

export default BrandSetupGroupProductCardSkeleton;
