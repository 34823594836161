import React from "react";
import PropTypes from "prop-types";

import { Modal, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import Text from "components/Text";
import Button from "components/Buttons/Button";

import img from "assets/images/icon-error.png";

import styles from "./MyLogoConfirmNotification.module.scss";

function MyLogoConfirmNotification(props) {
  const { isOpen, setIsOpen, onConfirm, isBrandPlateCreated } = props;

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal
      data-testid="LogoModal"
      aria-labelledby="Logo Modal"
      aria-describedby="LogoModal"
      open={isOpen}
      onClose={handleClose}
      className={styles.myLogoConfirmNotification}
    >
      <div className={styles.body} style={{ backgroundImage: `url(${img})` }}>
        <Typography
          onClick={() => handleClose()}
          className={`${styles.closeButton} close__button`}
        >
          [x] close
        </Typography>
        <Stack className={styles.stackContainer}>
          <Text className={`${styles.title} `}>
            ARE YOU SURE YOU WANT TO CHANGE YOUR LOGO?
          </Text>
          {!isBrandPlateCreated && (
            <Text className={styles.warningText}>
              <strong>
                You already have BRANDED PRODUCTS created with this logo
              </strong>
              . When you click “YES” below, this will replace all of your
              existing product mockups.
            </Text>
          )}
          {isBrandPlateCreated && (
            <Text className={styles.warningText}>
              <strong>
                You already have BRANDED PRODUCTS created with this logo
              </strong>
              . If you change your logo you will have to purchase a new brand
              plate. In addition, your branded product images will need to be
              recreated.
            </Text>
          )}
          <Button variant="contained" color="secondary" onClick={handleConfirm}>
            Yes,Change my logo
          </Button>
        </Stack>
      </div>
    </Modal>
  );
}

MyLogoConfirmNotification.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isBrandPlateCreated: PropTypes.bool,
};

MyLogoConfirmNotification.defaultProps = {
  isBrandPlateCreated: false,
};

export default MyLogoConfirmNotification;
