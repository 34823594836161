import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { useStripe } from "@stripe/react-stripe-js";

export default function CheckoutConfirmPayment({
  clientSecret,
  handlePaymentSuccess,
  handlePaymentError,
}) {
  const stripe = useStripe();

  const handleConfirmPayment = async () => {
    const response = await stripe.confirmCardPayment(clientSecret);
    if (response?.paymentIntent?.status === "succeeded") {
      handlePaymentSuccess();
    } else {
      toast.error(
        "An error occurred while trying to process your payment, please try again"
      );
      handlePaymentError();
    }
  };

  useEffect(() => {
    if (clientSecret) {
      handleConfirmPayment();
    }
  }, [clientSecret]);

  return <div />;
}
