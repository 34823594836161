const isFeatureEnabled = (
  featureFlags = [],
  allowedFlags = [],
  excludeFlags = []
) => {
  // Check if all featured flags are enabled
  const areRequiredFlagsEnabled = allowedFlags.every((flag) =>
    featureFlags.includes(flag)
  );

  // talk to @doug about feature flag constants - this might not be needed if we add FEATURE_BRAND_PLATE_ENABLED
  // Check if none of the excluded flags are present
  // used in the case of when user has FEATURE_BRAND_SETUP_ENABLED=true
  // we render the Brand Plate Feature instead
  const areExcludeFlagsAbsent = excludeFlags.every(
    (flag) => !featureFlags.includes(flag)
  );

  return areRequiredFlagsEnabled && areExcludeFlagsAbsent;
};

export default isFeatureEnabled;
