// eslint-disable-next-line max-classes-per-file
import moment from "moment";
import { EventEmitter } from "fbemitter";
import { createSpan, createTransaction } from "./endpoints/traceApi";

export class Span {
  #id;

  #operation;

  #description;

  #start_time;

  #end_time;

  #parent;

  #children;

  #status;

  #data;

  #transaction;

  constructor(operation, description) {
    this.id = null;
    this.operation = operation;
    this.description = description;
  }

  get id() {
    return this.#id;
  }

  set id(id) {
    this.#id = id;
  }

  get parent() {
    return this.#parent;
  }

  set parent(parent) {
    this.#parent = parent;
  }

  get children() {
    return this.#children;
  }

  set children(children) {
    this.#children = children;
  }

  get operation() {
    return this.#operation;
  }

  set operation(operation) {
    this.#operation = operation;
  }

  get description() {
    return this.#description;
  }

  set description(description) {
    this.#description = description;
  }

  get startTime() {
    return this.#start_time;
  }

  set startTime(startTime) {
    this.#start_time = startTime;
  }

  get endTime() {
    return this.#end_time;
  }

  set endTime(endTime) {
    this.#end_time = endTime;
  }

  get status() {
    return this.#status;
  }

  // eslint-disable-next-line camelcase
  set status(status) {
    this.#status = status;
  }

  get data() {
    return this.#data;
  }

  // eslint-disable-next-line camelcase
  set data(data) {
    this.#data = data;
  }

  get transaction() {
    return this.#transaction;
  }

  set transaction(transaction) {
    this.#transaction = transaction;
  }

  startChild(parentSpan) {
    const childSpan = new Span();
    childSpan.startTime = moment().utc();
    childSpan.children = [];

    if (parentSpan) {
      childSpan.parent = parentSpan;
      childSpan.transaction = parentSpan.transaction;
      parentSpan.children.push(childSpan);
    } else {
      childSpan.parent = this.transaction.span;
      childSpan.transaction = this.transaction;
      this.transaction.span.children.push(childSpan);
    }
    return childSpan;
  }

  finishSpan() {
    this.endTime = moment.utc();
  }
}

// eslint-disable-next-line max-classes-per-file
export class Transaction {
  #id;

  #name;

  #span;

  #queue;

  get span() {
    return this.#span;
  }

  set span(span) {
    this.#span = span;
  }

  get id() {
    return this.#id;
  }

  set id(id) {
    this.#id = id;
  }

  get name() {
    return this.#name;
  }

  set name(name) {
    this.#name = name;
  }

  get queue() {
    return this.#queue;
  }

  set queue(queue) {
    this.#queue = queue;
  }

  constructor(name, operation, description) {
    this.id = null;
    this.name = name;
    this.span = new Span(operation, description);
    this.span.startTime = moment.utc();
    this.span.children = [];
    this.span.parent = null;
    // Since finishTransaction is an async operation,
    // this emitter avoids sending post requests multiple times
    this.emitter = new EventEmitter();
    this.queue = [];
    this.addFinishTransactionListener();
  }

  static startTransaction(name, operation, description) {
    const transaction = new Transaction(name, operation, description);
    transaction.span.transaction = transaction;
    return transaction;
  }

  addFinishTransactionListener() {
    let token;
    const finishTransaction = async () => {
      try {
        this.span.finishSpan();

        if (this.id == null) {
          try {
            this.queue.push(this.span);
            const response = await createTransaction(this.span.transaction);
            this.span.transaction.id = response.data.id; // This sets `this.id`
          } catch (e) {
            this.queue.shift();
            this.span.transaction.id = null;
            throw new Error(e);
          }
        }

        while (this.queue.length > 0) {
          const span = this.queue.shift();

          // eslint-disable-next-line no-await-in-loop
          const createdSpan = await createSpan(span);

          span.id = createdSpan.data.id;

          span.children.map((child) => {
            // eslint-disable-next-line no-param-reassign
            child.transaction.id = this.id; // this sets the transaction id to the span
            this.queue.push(child);
          });
        }
      } catch (e) {
        if (token) {
          token.remove();
          token = this.emitter.once("finishTransaction", finishTransaction);
        }
      }
    };
    token = this.emitter.once("finishTransaction", finishTransaction);
  }

  finishTransaction() {
    // finishTransaction must run only one time.  If there is an error, the listener will be removed
    // and added again.
    this.emitter.emit("finishTransaction");
  }
}
