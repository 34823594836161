import React from "react";
import PropTypes from "prop-types";
import StarIcon from "@mui/icons-material/Star";

import {
  PRODUCT_TYPE_UNBRANDED,
  PRODUCT_TYPE_CUSTOM,
  PRODUCT_TYPE_BLANKA_LABS,
} from "constants/constants";
import styles from "./ProductTag.module.scss";

export default function ProductTag({ productType, isPremium, className }) {
  const getTag = () => {
    switch (productType) {
      case PRODUCT_TYPE_UNBRANDED:
        return styles.tagUnbranded;
      case PRODUCT_TYPE_CUSTOM:
        return styles.tagCustom;
      case PRODUCT_TYPE_BLANKA_LABS:
        return styles.tagBlankaLabs;
      default:
        return null;
    }
  };

  const getLabel = () => {
    switch (productType) {
      case PRODUCT_TYPE_UNBRANDED:
        return "UNBRANDED";
      case PRODUCT_TYPE_CUSTOM:
        return "BRANDED";
      case PRODUCT_TYPE_BLANKA_LABS:
        return "BLANKA LABS";
      default:
        return null;
    }
  };

  return (
    <div data-testid="ProductTag" className={`${styles.tag} ${getTag()}  `}>
      {getLabel()}
      {isPremium && (
        <div className={`${styles.premiumStar} ${className}`}>
          <StarIcon size={8} />
        </div>
      )}
    </div>
  );
}

ProductTag.propTypes = {
  productType: PropTypes.string,
};

ProductTag.defaultProps = {
  productType: PRODUCT_TYPE_CUSTOM,
};
