import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import styles from "./FindProductsImageContainer.module.scss";

export default function FindProductsImageContainer({ product }) {
  const [loading, setLoading] = useState(true);

  return (
    <div className={styles.mainContainer}>
      {/* {loading && (
        <Skeleton
          className={styles.circle}
          variant="circular"
          height={150}
          width={150}
          animation="wave"
          style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
        />
      )} */}

      <div className="product-image__wrapper">
        <img
          alt={product?.name}
          className={styles.productImage}
          src={product?.image}
          loading="lazy"
          // delayTime={0}
          // height={120}
          // threshold={1500}
          // style={{ maxWidth: "250px", maxHeight: "250px" }}
          // afterLoad={() => {
          //   setLoading(false);
          // }}
        />
      </div>
    </div>
  );
}

FindProductsImageContainer.propTypes = {
  product: PropTypes.object,
};

FindProductsImageContainer.defaultProps = {
  product: {},
};
