import { SMALL } from "constants/breakpoints";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  onSetActiveAccordion,
  onSetImageType,
  onSetLogo,
  onSetLogoContainerDimensions,
  onSetLogoExceedsContainer,
  onSetLogoRotation,
  onSetLogoSize,
} from "state/logoCustomizeSlice";

const originalWidth = 2000;
const originalHeight = 2000;
// const containerHeight = 500;

const useLogoCustomize = () => {
  // state
  const [error, setError] = useState({ size: "", rotations: "" });
  const {
    logoWidth,
    logoHeight,
    logoRotation,
    logoContainerDimensions,
    activeAccordion,
    logo,
    logoExceedsContainer,
    originalLogoWidth,
    originalLogoHeight,
  } = useSelector((state) => state.logoCustomize);

  const {
    positionX,
    positionY,
    maxWidth: logoContainerWidth,
    maxHeight: logoContainerHeight,
  } = logoContainerDimensions;

  // reducers
  const dispatch = useDispatch();

  const setLogoSize = (props) => {
    dispatch(onSetLogoSize(props));
  };

  const setLogoExceedsContainer = (props) => {
    dispatch(onSetLogoExceedsContainer(props));
  };

  const setLogoRotation = (rotation) => {
    dispatch(onSetLogoRotation(rotation));
  };

  const setImageType = (imageType) => {
    dispatch(onSetImageType(imageType));
  };

  const setLogoContainerDimensions = (props) => {
    dispatch(onSetLogoContainerDimensions(props));
  };

  const setActiveAccordion = (slug) => {
    dispatch(onSetActiveAccordion(slug));
  };

  const setLogo = (logo) => {
    dispatch(onSetLogo(logo));
  };

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const containerHeight = useMemo(() => {
    if (isMobile) {
      return 320;
    }
    return 500;
  }, [isMobile]);

  // calculations for scaling since values are based on our 2000 x 2000 image
  const newWidth = Math.ceil(
    (originalWidth / originalHeight) * containerHeight
  );
  const scaledX = Math.ceil((Number(positionX) / originalWidth) * newWidth);
  const scaledY = Math.ceil(
    (Number(positionY) / originalHeight) * containerHeight
  );
  const scaledLogoContainerWidth = Math.ceil(
    (logoContainerWidth / originalWidth) * newWidth
  );
  const scaledLogoContainerHeight = Math.ceil(
    (logoContainerHeight / originalHeight) * containerHeight
  );

  // style for container based on scale
  const logoContainerStyles = useMemo(
    () => ({
      position: "absolute",
      zIndex: 20,
      top: `${scaledY - scaledLogoContainerHeight / 2}px`,
      left: `${scaledX - scaledLogoContainerWidth / 2}px`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: `${scaledLogoContainerHeight}px`,
      width: `${scaledLogoContainerWidth}px`,
    }),
    [scaledY, scaledLogoContainerHeight, scaledX, scaledLogoContainerWidth]
  );

  const scaledLogoWidth = (logoWidth / originalWidth) * newWidth;

  const logoStyles = useMemo(() => {
    const isTallLogo = originalLogoHeight > originalLogoWidth;

    if (logoExceedsContainer) {
      if (isTallLogo) {
        if (Number(logoRotation) === 90 || Number(logoRotation) === 270) {
          return {
            // background: "green",
            maxWidth: `${scaledLogoContainerHeight}px`,
            maxHeight: `${scaledLogoContainerWidth}px`,
            transform: `rotate(-${logoRotation}deg)`,
            margin: "auto",
          };
        }

        return {
          // background: "green",
          maxWidth: `${scaledLogoContainerWidth}px`,
          maxHeight: `${scaledLogoContainerHeight}px`,
          transform: `rotate(-${logoRotation}deg)`,
          margin: "auto",
        };
      }

      if (Number(logoRotation) === 90 || Number(logoRotation) === 270) {
        return {
          // background: "blue",
          maxWidth: `${scaledLogoContainerHeight}px`,
          maxHeight: `${scaledLogoContainerWidth}px`,
          minWidth: `${scaledLogoContainerHeight}px`,
          transform: `rotate(-${logoRotation}deg)`,
          margin: "auto",
        };
      }

      return {
        // background: "blue",
        maxWidth: `${scaledLogoContainerWidth}px`,
        maxHeight: `${scaledLogoContainerHeight}px`,
        minWidth: `${scaledLogoContainerWidth}px`,
        transform: `rotate(-${logoRotation}deg)`,
        margin: "auto",
      };
    }

    return {
      // background: "pink",
      width: `${scaledLogoWidth}px`,
      minWidth: `${scaledLogoWidth}px`,
      maxHeight: "100%",
      maxWidth: `${scaledLogoWidth}px`,
      transform: `rotate(-${logoRotation}deg)`,
      margin: "auto",
    };

    // rain's note: why not use size percentages instead of pixel values for sizing S, M, L?
    // i think having different values for height and width logos set by our admins is hard to manage especially with other logos.
    // plus as a user it's frustrating to choose sizes in the customizer and suddenly come up to all the error boundaries.
    // so this allows all logos to be always inside the logoContainer and we won't show the error container anymore.

    // implementation for percentages
    // maybe there's a good way to calculate size_mm based on the percentage of the container size = logo size
    // return {
    //   maxHeight: "100%",
    //   width: "100%",
    //   maxWidth: "100%",
    //   transform: `rotate(-${logoRotation}deg)`,
    //   margin: "auto",
    // };
  }, [
    scaledLogoWidth,
    scaledLogoContainerWidth,
    scaledLogoContainerHeight,
    logoHeight,
    logoWidth,
    logoRotation,
    logo,
    logoExceedsContainer,
    originalLogoHeight,
    originalLogoWidth,
  ]);

  // side effect for checking of error boundary based on logo size and container size.
  useEffect(() => {
    const checkLogoBounds = () => {
      const logoElement = document.getElementById("logo");
      const logoContainerBoundary = document.getElementById(
        "logoContainerBoundary"
      );

      if (logoElement && logoContainerBoundary) {
        const logoRect = logoElement.getBoundingClientRect();
        const containerRect = logoContainerBoundary.getBoundingClientRect();

        // Checking if logoRect exceeds the boundaries of the containerRect without considering rotation
        const isOutOfSizeBounds =
          logoRect.width > containerRect.width ||
          logoRect.height - 6 > containerRect.height;

        // Checking if the logo exceeds the container when rotation is applied
        // @doug:this becomes a problem with exceeds logo and the container coordinates and sizes are wrong.
        const isOutOfRotationBounds =
          logoRect.left < containerRect.left ||
          logoRect.right > containerRect.right ||
          logoRect.top + 3 < containerRect.top ||
          logoRect.bottom - 3 > containerRect.bottom;

        if (isOutOfSizeBounds) {
          setError({ size: "Logo size exceeds the container." });
        } else if (isOutOfRotationBounds) {
          setError({
            rotations: "Logo rotation exceeds the container boundary.",
          });
        } else {
          setError("");
        }
      }
    };

    checkLogoBounds();
  }, [
    positionX,
    positionY,
    logoWidth,
    logoHeight,
    logoRotation,
    logoContainerWidth,
    logoContainerHeight,
    logo,
  ]);

  const withError = useMemo(
    () =>
      Object.values(error).some(
        (value) => value !== "" && value !== null && value !== undefined
      ),
    [error]
  );

  return {
    error,
    withError,
    logoContainerStyles,
    logoStyles,
    containerHeight,
    activeAccordion,
    logo,
    logoExceedsContainer,
    setActiveAccordion,
    setImageType,
    setLogoSize,
    setLogoRotation,
    setLogoContainerDimensions,
    setLogo,
    setLogoExceedsContainer,
  };
};

export default useLogoCustomize;
