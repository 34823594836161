import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function MultipleUnCheckMarkIcon(props) {
  return (
    <SvgIcon {...props} className="icon" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.28739 1.04878H11.1429V10.9543H1.28739V1.04878Z"
        fill="white"
        stroke="#5E5E5E"
        strokeWidth="0.500234"
      />
    </SvgIcon>
  );
}

export default MultipleUnCheckMarkIcon;
