import React from "react";

// Material UI Components
import Chip from "@mui/material/Chip";

import styles from "./AddressStatusTag.module.scss";

export default function AddressStatusTag() {
  return (
    <Chip
      data-testid="AddressStatusTag"
      label="Default"
      className={styles.addressStatusTag}
    />
  );
}
