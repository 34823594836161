import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Text from "components/Text";
import styles from "./BrandPlateMockupFiles.module.scss";

function BrandPlateMockupFiles({ mockups, showAcceptedOnly }) {
  return (
    <div className={styles.fileMockupContainer}>

      {mockups.length === 0 && (
        <div className={styles.fileDownload}>
          <FileDownloadIcon /> 
          <Text fontSize="14">Please reach out to {" "}<a href="mailto:hello@blankabrand.com">hello@blankabrand.com</a>{" "} for your approved Brand Plate file.</Text>
        </div>
      )} 

      {mockups.length > 0 && (
        showAcceptedOnly ? (
          <div className={styles.fileDownload}>
            <FileDownloadIcon />{" "}
            <a target="_blank" href={mockups[0]?.mockup_file} rel="noreferrer">
              Download mock-up sheet - version {mockups[0]?.blanka_order_id.slice(-1)}
            </a>
          </div>
        ) : (
          mockups.map((mockup) => (
            <div key={mockup.id} className={styles.fileDownload}>
              <FileDownloadIcon />{" "}
              <a target="_blank" href={mockup.mockup_file} rel="noreferrer">
                Download mock-up sheet - version {mockup.blanka_order_id.slice(-1)}
              </a>
            </div>
          ))
        )
      )}
    </div>
  );
}

export default BrandPlateMockupFiles;