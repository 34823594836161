import useLogoCustomize from "hooks/useLogoCustomize";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import cn from "utils/cn";
import styles from "./BrandSetupCustomizeImages.module.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css";

function BrandSetupCustomizeImages({
  images,
  selectedItem = 0,
  onChangeCarouselImage,
}) {
  const {
    logo,
    withError,
    logoContainerStyles,
    logoStyles,
    containerHeight,
    activeAccordion,
  } = useLogoCustomize();

  // rain notes: need to have inline styles due to dynamic styling for rendering
  // we can utilize css variables but way more complex and still needs to have inline styles like style={{ '--dynamic-width': `${width}px` }}
  const renderLogo = () => (
    <div
      id="logoContainerBoundary"
      style={logoContainerStyles}
      className={styles.logoBoundary}
    >
      <div className={styles.logoBoundaryTopLeft} />
      <div className={styles.logoBoundaryTopRight} />
      <div className={styles.logoBoundaryBottomLeft} />
      <div className={styles.logoBoundaryBottomRight} />
      <div className={styles.logoWrapper}>
        <img
          id="logo"
          className={cn(
            activeAccordion === "size" && styles.logoSizeActive,

            styles.logo
          )}
          style={logoStyles}
          src={logo}
          alt="logo sample"
        />

        {withError && (
          <div className={styles.errorContainer}>
            <div className={styles.errorIcon}>!</div>
          </div>
        )}
      </div>
    </div>
  );

  const handleThumbnailClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    onChangeCarouselImage(index);
  };

  // Generate custom thumbnails based on the images passed
  const renderCustomThumbs = () =>
    images.map((image, index) => (
      <div key={`${index + 1}`} onClick={(e) => handleThumbnailClick(e, index)}>
        <img src={image?.image} alt={`Thumbnail ${index}`} />
      </div>
    ));

  return (
    <Carousel
      autoPlay={false}
      infiniteLoop
      dynamicHeight
      className={styles.brandSetupCustomizeImages}
      data-testid="ImageCarousel"
      showArrows={false}
      showStatus={false}
      renderIndicator={false}
      selectedItem={selectedItem}
      onChange={onChangeCarouselImage}
      renderThumbs={renderCustomThumbs} // Render custom thumbnails
    >
      {images.map((image) => (
        <div
          key={image?.image}
          style={{
            height: `${containerHeight}px`,
            width: `${containerHeight}px`,
            pointerEvents: "none",
          }}
          className={styles.imageContainer}
        >
          <img
            src={image?.image}
            className={cn(
              styles.productImage,
              activeAccordion === "size" && styles.productImageIsSize
            )}
            alt="product sample"
          />
          {renderLogo()}
        </div>
      ))}
    </Carousel>
  );
}

export default BrandSetupCustomizeImages;
