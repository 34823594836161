import Text from "components/Text";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Link } from "react-router-dom";
import OnboardingWrapper from "views/OnboardingView/steps/Onboarding/OnboardingWrapper";
import styles from "./OnboardingStepOne.module.scss";

import SignUpForm from "./SignUpForm";

// need to ask @doug about env files of the app
const { REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY } = process.env;

export default function OnboardingStepOne() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}>
      <OnboardingWrapper>
        <div className={styles.onboardingStepOne}>
          <div className={styles.signUpText}>
            <Text fontSize={24} className="text--bold text--blue">
              Sign up for free!
            </Text>

            <Link
              to="/login"
              className={`${styles.textLogin} text--nunito text--info`}
            >
              Already have an account?{" "}
              <span className={styles.redText}>Log in</span>
            </Link>
          </div>
          <SignUpForm />
        </div>
      </OnboardingWrapper>
    </GoogleReCaptchaProvider>
  );
}
