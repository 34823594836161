/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import {
  LOGO_DEFAULT,
  LOGO_INVERTED,
  LOGO_INVERTED_THIN,
  LOGO_THIN,
} from "constants/generatorLogo";

export const brandSetupSlice = createSlice({
  name: "brandSetup",
  initialState: {
    LOGO_ORDER: [LOGO_DEFAULT, LOGO_INVERTED, LOGO_THIN, LOGO_INVERTED_THIN],
    isLogoUploaded: false,
    logos: [],
    status: "idle",
  },
  reducers: {
    setLogo: (state, action) => {
      const logoObject = action.payload;
      const logoTypes = [LOGO_INVERTED, LOGO_THIN, LOGO_INVERTED_THIN];

      return {
        ...state,
        logos: [
          logoObject,
          ...logoTypes.map((type) => ({
            logo: logoObject.logo,
            logo_type: type,
          })),
        ],
      };
    },
    setLogoIsUploaded: (state, action) => ({
      ...state,
      isLogoUploaded: action.payload,
    }),
    setLogoByType: (state, action) => {
      // eslint-disable-next-line camelcase
      const { logo_type, logo } = action.payload;
      const index = state.logos.findIndex(
        // eslint-disable-next-line camelcase
        (item) => item.logo_type === logo_type
      );
      if (index !== -1) {
        state.logos[index].logo = logo; // Update the specific logo.
      } else {
        // eslint-disable-next-line camelcase
        state.logos.push({ logo_type, logo });
      }
    },
    setFetchedLogos: (state, action) => {
      const fetchedLogos = action.payload;

      // Find the default logo from the fetched logos.
      const defaultLogo = fetchedLogos.find(
        (logo) => logo.logo_type === LOGO_DEFAULT
      )?.logo;

      // Map over the logo types and ensure each has a value, falling back to the default.
      // eslint-disable-next-line camelcase
      state.logos = state.LOGO_ORDER.map((logo_type) => {
        // eslint-disable-next-line camelcase
        const found = fetchedLogos.find((logo) => logo.logo_type === logo_type);
        return {
          // eslint-disable-next-line camelcase
          logo_type,
          logo: found?.logo || defaultLogo || null, // Use the found logo, fallback to the default, or null.
        };
      });
    },
  },
});

export const {
  // ...
  setLogo,
  setLogoIsUploaded,
  setFetchedLogos,
  setLogoByType,
  // ...
} = brandSetupSlice.actions;

export default brandSetupSlice.reducer;
