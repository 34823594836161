import getImageDimensions from "./getImageDimensions";

const getRotationAutoValue = async (image) => {
  const dimensions = await getImageDimensions(image);
  const aspectRatio = dimensions.width / dimensions.height;

  if (aspectRatio > 1) {
    return "90";
  }

  return "0";
};

export default getRotationAutoValue;
