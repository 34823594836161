import React, { useEffect } from "react";
import BrandPlateNav from "components/BrandPlateNav";
import PageHeader from "components/PageHeader";
import BrandPlateOrderHistory from "./components/BrandPlateOrderHistory/BrandPlateOrderHistory";
import styles from "./BrandPlatePastOrders.module.scss";

function BrandPlatePastOrdersView() {
  return (
    <div className={styles.pastOrdersView}>
      <PageHeader title="My brand plate" />
      <BrandPlateNav />
      <div className={styles.ordersList}>
        <BrandPlateOrderHistory />
      </div>
    </div>
  );
}

export default BrandPlatePastOrdersView;
