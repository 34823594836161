import React from "react";

// MUI components:
import { Autocomplete, TextField } from "@mui/material";

// components and constants:
import Text from "components/Text";

// third-party libraries:
import { Controller } from "react-hook-form";

import styles from "./CountryAutocomplete.module.scss";

function CountryAutocomplete(props) {
  const { name, label, control, defaultValue, countries, rules } = props;

  const handleCountryChange = (onChange, data) => {
    if (!data || data === "") {
      onChange("");
      return;
    }
    onChange(data);
  };

  return (
    <div className={styles.countryAutocomplete}>
      <Text className={styles.label}>{label}</Text>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({
          field: { onChange, value, ...field },
          fieldState: { error },
        }) => (
          <Autocomplete
            {...field}
            value={value}
            className={styles.countryAutocomplete}
            options={countries}
            getOptionLabel={(option) => (option ? option.name : "")}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                helperText={error ? error.message : ""}
                fullWidth
              />
            )}
            onChange={(event, data) => handleCountryChange(onChange, data)}
          />
        )}
      />
    </div>
  );
}

export default CountryAutocomplete;
