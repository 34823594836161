import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function SaveUpArrowIcon(props) {
  return (
    <SvgIcon {...props} className="icon" viewBox="0 0 24 24">
      <path
        id="Save Up Arrow"
        d="M13.9293 1.60917C14.2027 1.64823 14.4559 1.45828 14.495 1.18491C14.534 0.911543 14.3441 0.658277 14.0707 0.619225L13.9293 1.60917ZM3.59038 9.40057C3.74873 9.6268 4.0605 9.68182 4.28672 9.52347L7.97331 6.94295C8.19954 6.7846 8.25456 6.47284 8.09621 6.24661C7.93786 6.02038 7.62609 5.96536 7.39987 6.12371L4.1229 8.4175L1.82911 5.14053C1.67075 4.9143 1.35899 4.85928 1.13276 5.01763C0.906535 5.17599 0.851512 5.48775 1.00987 5.71398L3.59038 9.40057ZM14.0707 0.619225C12.8671 0.447278 10.6231 0.308371 8.46947 1.3519C6.2818 2.41193 4.28087 4.64561 3.50761 9.02694L4.49239 9.20075C5.21913 5.08298 7.05154 3.15017 8.90553 2.25182C10.7936 1.33697 12.7996 1.44779 13.9293 1.60917L14.0707 0.619225Z"
        fill="#246071"
      />
    </SvgIcon>
  );
}

export default SaveUpArrowIcon;
