import React from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

// MUI:
import Chip from "@mui/material/Chip";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

// Constants & custom components:
import { SMALL } from "constants/breakpoints";

import styles from "./FreeTrialTag.module.scss";

function FreeTrialTag() {
  const shop = useSelector((state) => state.profile.shop);
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const navigate = useNavigate();

  return (
    <Chip
      onClick={() => navigate("/profile")}
      data-testid="FreeTrialTag"
      size={isMobile ? "small" : "medium"}
      icon={<AccessTimeIcon color="secondary" className={styles.iconSize} />}
      label={`Free trial: ${shop?.trial_days_remaining} days`}
      className={styles.freeTrialTag}
    />
  );
}

export default FreeTrialTag;
