import { addProduct, QUERY } from "api";
import queryClient from "api/queryClient";
import {
  addSampleToCartSuccess,
  GenericSuccessNotification,
  InventoryWithSampleError,
} from "components/Notifications";
import {
  CART_TYPE_SAMPLE,
  PRODUCT_TYPE_CUSTOM,
  PRODUCT_TYPE_UNBRANDED,
} from "constants/constants";
import { FILTER_PREMIUM } from "constants/filters";
import { PLAN_FREE_ID, PLAN_GROWTH_ID } from "constants/plans";
import { useAnalytics, useCart, useProfile } from "hooks";
import useChangePlanModal from "hooks/useChangePlanModal";
import React, { useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const getBaseSku = (product) => {
  if (product?.product_type === PRODUCT_TYPE_CUSTOM) {
    return product?.sku.substr(product.sku.indexOf("-") + 1);
  }

  return product?.sku;
};

const useProductView = () => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState("");
  const addToStoreMutation = useMutation(addProduct);
  const shop = useSelector((state) => state.profile.shop);
  const profileHook = useProfile();
  const { add } = useCart();
  const analytics = useAnalytics();
  const { onOpenModal } = useChangePlanModal();

  const isPremiumProductAndFreeUser = useMemo(() => {
    if (product) {
      if (
        shop.plan_type === PLAN_FREE_ID &&
        product.categories.includes(FILTER_PREMIUM)
      ) {
        return true;
      }
    }

    return false;
  }, [product, shop.plan]);

  const isVipProductAndNotVipUser = useMemo(() => {
    if (product) {
      return !profileHook.isVIPPlan() && product?.product_base?.vip_only;
    }

    return false;
  }, [product, shop.plan]);

  const handleAddToStore = () => {
    if (isPremiumProductAndFreeUser) {
      onOpenModal();
      analytics.sendEvent(analytics.PAYWALL_HIT, {
        location: "premuim_products_button",
      });
      return;
    }

    if (isVipProductAndNotVipUser) {
      onOpenModal();
      analytics.sendEvent(analytics.PAYWALL_HIT, {
        location: "vip_product_view",
      });
      return;
    }

    if (
      shop.plan_type === PLAN_GROWTH_ID &&
      product.product_type === PRODUCT_TYPE_CUSTOM &&
      shop.count_number_of_custom_products > 10
    ) {
      onOpenModal();
      return;
    }

    if (
      shop.plan === PLAN_FREE_ID &&
      product.product_type === PRODUCT_TYPE_UNBRANDED &&
      shop.count_number_of_unbranded_products > 10
    ) {
      onOpenModal();
      return;
    }

    setLoading("store");

    addToStoreMutation.mutate(
      {
        id: product.id,
      },
      {
        onSuccess: async () => {
          await queryClient.refetchQueries([QUERY.getProduct]);

          toast.success(
            <GenericSuccessNotification text="This product has been added." />
          );
          setLoading("");
        },
        onError: () => {
          setLoading("");
        },
      }
    );
  };

  const handleOrderSamples = async () => {
    if (isPremiumProductAndFreeUser) {
      onOpenModal();
      analytics.sendEvent(analytics.PAYWALL_HIT, {
        location: "premuim_products_button",
      });
      return;
    }

    if (isVipProductAndNotVipUser) {
      onOpenModal();
      analytics.sendEvent(analytics.PAYWALL_HIT, {
        location: "vip_product_view",
      });
      return;
    }

    try {
      await add(
        {
          type: CART_TYPE_SAMPLE,
          item: {
            id: product.id,
            sku: getBaseSku(product),
            quantity: 1,
            product: { ...product, product_type: PRODUCT_TYPE_UNBRANDED },
          },
        },
        CART_TYPE_SAMPLE
      );

      toast.success(addSampleToCartSuccess);
    } catch (err) {
      toast.error(InventoryWithSampleError);
    }
  };

  return { handleOrderSamples, handleAddToStore, loading, setProduct };
};

export default useProductView;
