import React from "react";
import PropTypes from "prop-types";

// MUI:
import { Badge, Box, LinearProgress } from "@mui/material";
import ErrorRounded from "@mui/icons-material/ErrorRounded";

// custom components and constants:
import Text from "components/Text";

import styles from "./YourPlanUsageBar.module.scss";

function YourPlanUsageBar({ title, description, count, planLimit }) {
  const mapLimits = () => {
    // unlimited product plan limit
    if (planLimit === null) {
      return <span color="primary">Unlimited</span>;
    }

    return count <= Number(planLimit) || planLimit === null ? (
      <Badge>{planLimit}</Badge>
    ) : (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={
          <ErrorRounded color="error" className={styles.badgeIcon} />
        }
      >
        {planLimit}
      </Badge>
    );
  };

  const getBarColor = () => {
    if (planLimit === null) {
      return "success";
    }

    if (count <= Number(planLimit)) {
      return "info";
    }

    return count <= Number(planLimit) ? "success" : "error";
  };

  const getNumberOfProducts = () => {
    if (planLimit === null) {
      return 100;
    }

    if (planLimit === 0) {
      return 0;
    }

    return Math.min((count / Number(planLimit)) * 100, 100);
  };

  return (
    <div className={styles.progressBarContainer}>
      <Box className={styles.flexContainer}>
        <Text variant="body1" className={styles.mb}>
          {title}
        </Text>
        <Text variant="body1" className={styles.mb}>
          <span className={styles.exposeNumber}>{count}</span> /{" "}
          {mapLimits(planLimit)}
        </Text>
      </Box>
      <LinearProgress
        variant="determinate"
        value={getNumberOfProducts()}
        className={styles.linearBar}
        color={getBarColor()}
      />
      <Text variant="body1" className={styles.mt}>
        {description}
      </Text>
    </div>
  );
}

YourPlanUsageBar.propTypes = {
  description: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

export default YourPlanUsageBar;
