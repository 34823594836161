import React from "react";

// Mui:
import { Box } from "@mui/material";

// Custom components:
import Button from "components/Buttons/Button";
import ModalWrapper from "components/ModalWrapper";
import Text from "components/Text";

import styles from "./BrandSetupReplaceBrandModal.module.scss";

export default function BrandSetupReplaceBrandModal(props) {
  const { open, handleClose, handleConfirm, isLoading } = props;

  return (
    <ModalWrapper
      isOpen={open}
      handleClose={handleClose}
      className={styles.brandSetupReplaceBrandModal}
    >
      <Box>
        <Text variant="h1" className={styles.title}>
          Are you sure you want to replace your current brand?
        </Text>
        <Text variant="body1" className={styles.info}>
          Note: You may only have one brand per Blanka account. Setting up a new
          brand will replace your current branded products.{" "}
          <a
            href="https://faq.blankabrand.com/en/articles/8823695-how-do-i-update-my-brand-plate"
            target="_blank"
            className={styles.link}
            rel="noreferrer"
          >
            (FAQ)
          </a>
        </Text>
        <br />
        <Text variant="body1" className={styles.info}>
          After your first complimentary brand setup, a new brand setup has a
          fee of $149. Please note that this is non-refundable.
        </Text>
      </Box>
      <Box className={styles.productCard}>
        <Text variant="h3" className={styles.productInfo}>
          New brand setup
        </Text>
        <Text variant="h3" className={styles.productInfo}>
          $149
        </Text>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleConfirm}
        disabled={isLoading}
        loading={isLoading}
      >
        Create New Brand
      </Button>
    </ModalWrapper>
  );
}
