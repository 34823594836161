import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function DashedActiveCheckMarkIcon(props) {
  return (
    <SvgIcon
      {...props}
      className="icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="9.5"
        stroke="white"
        fill="none"
        strokeDasharray="2 2"
      />
      <circle cx="10.0002" cy="10" r="6.92308" fill="white" />
    </SvgIcon>
  );
}

export default DashedActiveCheckMarkIcon;
