import { Box, LinearProgress } from "@mui/material";
import React from "react";

import Button from "components/Buttons/Button";
import Text from "components/Text";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "routes/routePaths";
import styles from "./BrandSetupProgress.module.scss";

function BrandSetupProgress() {
  const navigate = useNavigate();
  const { progress } = useSelector((state) => state.wsGenerator);
  const { shop } = useSelector((state) => state.profile);

  return (
    <Box className={styles.brandSetupCard}>
      <Box className={styles.brandSetupCardContent}>
        <Text className={styles.brandSetupCardText}>
          {progress > 0 ? "Generating Brand..." : "My Brand Setup"}
        </Text>
        {progress > 0 && (
          <LinearProgress
            color="secondary"
            style={{ width: "100%" }}
            variant="determinate"
            value={progress}
          />
        )}
        {shop.brand_setup_step > 0 && progress <= 0 && (
          <LinearProgress
            color="secondary"
            style={{ width: "100%" }}
            variant="determinate"
            value={(shop.brand_setup_step / 3) * 100}
          />
        )}
      </Box>
      <Button
        onClick={() =>
          navigate(
            progress > 0
              ? ROUTE_PATHS.BRAND_SETUP_REVIEW
              : ROUTE_PATHS.BRAND_SETUP
          )
        }
        color="primary"
        variant="contained"
        hasChevron
        size="xsmall"
        fullWidth
      >
        {progress > 0 || shop.brand_setup_step > 0 ? "resume" : "get started"}
      </Button>
    </Box>
  );
}

export default BrandSetupProgress;
