import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiButton from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import styles from "./Button.module.scss";

export default function Button({
  loading = false,
  disabled = false,
  color = "success",
  variant = "contained",
  size = "medium",
  onClick = () => {},
  style,
  hasChevron = false,
  fullWidth = false,
  children,
  className = "",
  cssTarget = "",
  ...props
}) {
  // call this local handler to exam if the button is loading or now
  const handleClick = (event) => {
    try {
      if (!loading) {
        onClick(event); // continue with event
      }
    } catch (e) {
      onClick(event); // click event if any errors occurs
    }
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onClick(event);
      }
    },
    [onClick]
  );

  const handleSize = (size) => {
    switch (size) {
      case "xsmall":
        return 10;
      case "small":
        return 10;
      case "medium":
        return 22;
      case "large":
        return 24;
      default:
        return 20;
    }
  };

  const handleChevronSize = (size) => {
    switch (size) {
      case "xsmall":
        return 16;
      case "small":
        return 20;
      case "medium":
        return 24;
      case "large":
        return 28;
      default:
        return 24;
    }
  };

  const buttonClassName = useMemo(() => {
    switch (size) {
      case "xsmall":
        return styles.buttonExtraSmall;

      case "small":
        return styles.buttonSmall;

      case "medium":
        return styles.buttonMedium;

      case "large":
        return styles.buttonLarge;
      default:
        return styles.buttonMedium;
    }
  }, [size]);

  return (
    <MuiButton
      data-testid="Button"
      disabled={disabled || loading}
      color={color}
      style={style}
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      className={`${buttonClassName} ${styles.button} ${className} ${cssTarget}`}
      onClick={handleClick}
      {...props}
      startIcon={loading ? null : props.startIcon}
      onKeyDown={handleKeyDown}
    >
      <div className={`btnLabel ${loading ? styles.hidden : ""}`}>
        {children}
      </div>

      <div className={styles.buttonIconWrapper}>
        {loading && (
          <CircularProgress
            data-testid="CircularProgress"
            color="inherit"
            size={handleSize(size)}
          />
        )}
      </div>

      {hasChevron && (
        <ChevronRightIcon
          style={
            loading
              ? { visibility: "hidden", fontSize: handleChevronSize(size) }
              : { fontSize: handleChevronSize(size) }
          }
        />
      )}
    </MuiButton>
  );
}

Button.propTypes = {
  children: PropTypes.any.isRequired, // this parm was modified because we have buttons with children that contains components too...
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  hasChevron: PropTypes.bool,
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "error",
    "info",
    "warning",
  ]),
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  cssTarget: PropTypes.string,
};

Button.defaultProps = {
  onClick: undefined,
  loading: false,
  fullWidth: false,
  disabled: false,
  hasChevron: false,
  color: "success",
  variant: "contained",
  size: "medium",
  cssTarget: "",
};
