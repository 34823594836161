import PropTypes from "prop-types";
import React from "react";

import { Box, Link, Typography } from "@mui/material";
import sampleLogoOne from "assets/images/brand-plate/brand-plate-sample-logo-one.svg";
import sampleLogoTwo from "assets/images/brand-plate/brand-plate-sample-logo-two.svg";
import circle from "assets/images/brand-plate/circle.svg";
import jar from "assets/images/brand-plate/jar.svg";
import lipStick from "assets/images/brand-plate/lip-stick.svg";
import squares from "assets/images/brand-plate/squares.svg";
import ModalWrapper from "components/ModalWrapper";

import LaunchOutlined from "@mui/icons-material/LaunchOutlined";
import Button from "components/Buttons/Button";
import styles from "./BrandSetupExplainModal.module.scss";

export default function BrandSetupExplainModal({ open, handleClose }) {
  return (
    <ModalWrapper
      isOpen={open}
      handleClose={handleClose}
      data-testid="BrandPlateExplainModal"
      className={styles.brandSetupExplainModalContainer}
    >
      <Box
        display="flex"
        flexDirection="column"
        rowGap={2}
        py={2}
        px={{ xs: 2, md: 4 }}
        textAlign="center"
      >
        <Box>
          <Typography
            variant="h1"
            fontSize={{ xs: 20, md: 24 }}
            color="#5E5E5E"
          >
            What are the branded mockups for?
          </Typography>
          <Typography
            display="block"
            mt={2}
            variant="p"
            fontSize={{ xs: 14, md: 16 }}
            color="#5E5E5E"
          >
            You can see a preview of how your logo will look on all
            <br />
            your branded products before it gets produced.
          </Typography>
        </Box>
        <Box className={styles.imagesContainer}>
          <Box className={styles.leftImages}>
            <img src={sampleLogoOne} alt="Sample Logo One" />
            <img src={sampleLogoTwo} alt="Sample Logo Two" />
          </Box>
          <Box className={styles.middleImage}>
            <Box mb={3}>
              <svg
                className={styles.arrowCurl}
                width="40"
                height="22"
                viewBox="0 0 40 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.83447 7.52772C6.17171 12.6476 13.4513 18.5038 21.805 14.1026C24.7757 12.5375 26.4605 11.63 27.4898 8.89223C29.2663 4.16672 22.5255 0.216417 19.5547 3.68101C17.5144 6.06136 17.4373 9.34662 18.6162 12.6476C19.3201 14.6186 21.8974 16.7348 23.7045 17.7674C27.0537 19.6813 32.924 20.5093 38.2423 19.4741"
                  stroke="#CCCCCC"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
              <svg
                className={styles.arrow}
                width="11"
                height="16"
                viewBox="0 0 11 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.39404 2.46875L8.75054 6.34993L4.29785 13.6078"
                  stroke="#CCCCCC"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Box>
          </Box>
          <Box className={styles.rightImages}>
            <Box className={styles.jar}>
              <img className={styles.shapes} src={jar} alt="Jar" />
              <Box className={styles.jarSquare} />
            </Box>
            <Box className={styles.lipStick}>
              <img className={styles.shapes} src={lipStick} alt="Lipstick" />
              <Box className={styles.lipStickSquare} />
            </Box>
            <Box className={styles.circle}>
              <img className={styles.shapes} src={circle} alt="Lipstick" />
              <Box className={styles.circleSquare} />
            </Box>
            <Box className={styles.squares}>
              <img className={styles.shapes} src={squares} alt="Lipstick" />
              <Box className={styles.squaresSquare} />
              <Box className={styles.squaresSquareTwo} />
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" rowGap={1}>
          <Typography
            display="block"
            mt={2}
            variant="span"
            fontSize={{ xs: 12, md: 14 }}
            color="#5E5E5E"
          >
            Have a logo design already?
          </Typography>
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://blankabrand.com/blogs/beyond-the-brand-beauty-blog/logo-size-print-beauty-products"
            fontSize={{ xs: 12, md: 14 }}
            color="#5E5E5E"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            Follow these tips to get the best results
            <LaunchOutlined sx={{ fontSize: 14 }} />
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://blankabrand.com/collections/marketing-creative/products/logo-design"
            fontSize={{ xs: 12, md: 14 }}
            mb={4}
            color="#5E5E5E"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            Don&apos;t have a logo yet? <LaunchOutlined sx={{ fontSize: 14 }} />
          </Link>

          <Button
            variant="outlined"
            color="primary"
            type="button"
            size="small"
            onClick={handleClose}
          >
            Got it
          </Button>
        </Box>
      </Box>
    </ModalWrapper>
  );
}

BrandSetupExplainModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

BrandSetupExplainModal.defaultProps = {
  open: false,
};
