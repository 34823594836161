// eslint-disable-next-line import/no-cycle
import apiService from "api/apiService";

export const getShippingCost = (countryCode, postal, weight) =>
  apiService.post("/inventory-orders/get-shipping-cost/", {
    country_code: countryCode,
    postal_code: postal,
    weight,
  });

export const completeInventoryOrder = (data) =>
  apiService.post("/inventory-orders/new-order/", data);

export const completeSamplesOrder = (order) =>
  apiService.post("/sample-orders/new-order/", {
    items: order.items,
    shipping: order.shipping,
    discount_id: order.discount_id,
  });

export const checkCoupon = (id, coupon) =>
  apiService.post(`/payments/check-coupon/`, {
    cart_id: id,
    code: coupon,
  });

// CRUD for cart:
export const createCart = (data) => apiService.post("/cart/", data);

export const getActiveCart = () => apiService.get("/cart/");
export const getCartById = (id) => apiService.get(`/cart/${id}/`);

export const updateCartItems = (id, data) =>
  apiService.put(`/cart/${id}/`, data);

// tiered discounts
export const getTiersByProductId = async (productId) =>
  apiService.get(`/vouchers/tiers/${productId}/`);
