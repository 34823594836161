import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function UpgradeAlertIcon(props) {
  return (
    <SvgIcon
      {...props}
      className="icon"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
    >
      <path
        d="M38.8337 20L34.767 24.6333L35.3337 30.7666L29.317 32.1333L26.167 37.4333L20.5003 35L14.8337 37.45L11.6837 32.15L5.66699 30.7833L6.23366 24.6333L2.16699 20L6.23366 15.35L5.66699 9.21665L11.6837 7.86665L14.8337 2.56665L20.5003 4.99998L26.167 2.56665L29.317 7.86665L35.3337 9.23332L34.767 15.3667L38.8337 20ZM34.3837 20L31.3337 16.4833L31.7337 11.8333L27.167 10.8333L24.8003 6.78332L20.5003 8.63332L16.2003 6.78332L13.8337 10.8333L9.26699 11.8167L9.66699 16.4667L6.61699 20L9.66699 23.5L9.26699 28.1667L13.8337 29.1667L16.2003 33.2167L20.5003 31.35L24.8003 33.2L27.167 29.1667L31.7337 28.15L31.3337 23.5L34.3837 20ZM18.8337 25H22.167V28.3333H18.8337V25ZM18.8337 11.6667H22.167V21.6667H18.8337V11.6667Z"
        fill="#D56F5B"
      />
    </SvgIcon>
  );
}

export default UpgradeAlertIcon;
