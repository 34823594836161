import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// Material UI Components
import Grid from "@mui/material/Grid";

// Project Components & Constants
import { SMALL } from "constants/breakpoints";
import { selectOrders } from "state/brandPlateSlice";

import styles from "./AccountNav.module.scss";

const ACCOUNT_NAV = [
  {
    slug: "/profile",
    title: "Profile",
  },
  {
    slug: "/profile/addresses",
    title: "Addresses",
  },
  {
    slug: "/profile/billing",
    title: "Payment",
  },
  {
    slug: "/profile/connect",
    title: "My Store",
  },
];

function AccountNav() {
  const navigate = useNavigate();
  const orders = useSelector(selectOrders);
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const handleSlugOnClick = (e, slug) => {
    e.preventDefault();
    navigate(slug);
  };

  return (
    <Grid className="paper " container spacing={2}>
      <Grid item xs={12} md={12}>
        <div className={`nav-div ${isMobile && styles["mt-10"]}`}>
          {ACCOUNT_NAV.map((item, index) => (
            <>
              <NavLink
                key={`${item.slug}-${item.title}`}
                to={item.slug}
                end
                className={({ isActive }) => {
                  if (index === 0) {
                    return isActive
                      ? styles.navItemFirstActive
                      : styles.navItemFirst;
                  }
                  return isActive ? styles.navItemActive : styles.navItem;
                }}
                onClick={(e) => handleSlugOnClick(e, item.slug)}
              >
                {item.title}
              </NavLink>
              {index !== ACCOUNT_NAV.length - 1 && (
                <span key={`${item.slug}-${item.title}`}> | </span>
              )}
            </>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}

export default AccountNav;
