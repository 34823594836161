import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// MUI
import { Box } from "@mui/material";

// Custom Components
import BrandSetupWrapper from "components/BrandSetup/BrandSetupWrapper";
import Button from "components/Buttons/Button";
import Text from "components/Text";
import { SMALL } from "constants/breakpoints";

// API
import { QUERY } from "api";
import { getCurrentLogos, updateShop } from "api/endpoints/shopApi";

// Redux
import { setFetchedLogos } from "state/brandSetupSlice";

import BrandSetupReplaceBrandModal from "components/BrandSetup/BrandSetupReplaceBrandModal";
import { BRAND_SETUP_STEPS } from "constants/constants";
import {
  LOGO_DEFAULT,
  LOGO_INVERTED,
  LOGO_INVERTED_THIN,
  LOGO_THIN,
} from "constants/generatorLogo";
import ROUTE_PATHS from "routes/routePaths";
import styles from "./BrandSetupView.module.scss";

const isEven = (num) => num % 2 === 0;

function BrandSetupView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const shop = useSelector((state) => state.profile.shop);
  const { logos } = useSelector((state) => state.brandSetup);

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openPaywallModal, setOpenPaywallModal] = useState(false);

  useQuery([QUERY.getCurrentLogos], getCurrentLogos, {
    onSuccess: (response) => {
      dispatch(setFetchedLogos(response.data));
    },
  });

  const handleLinkRouting = () => {
    navigate(ROUTE_PATHS.HOME);
  };

  const handleUploadLogoRouting = async () => {
    setIsLoading(true);
    try {
      if (!shop.has_payment_method) {
        setIsLoading(false);
        toast.error("Please update your billing information to proceed.");
        navigate(`${ROUTE_PATHS.PROFILE_BILLING_UPDATE}?from=brand-setup`);
        return;
      }

      await updateShop(shop.id, {
        brand_setup_step: BRAND_SETUP_STEPS.UPLOAD,
      });
      navigate(ROUTE_PATHS.BRAND_SETUP_UPLOAD);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // console.error(error);
    }
  };

  const mapLogoType = (logoType) => {
    switch (logoType) {
      case LOGO_DEFAULT:
        return "Light products";
      case LOGO_INVERTED:
        return "Dark products";
      case LOGO_THIN:
        return "Thin light products";
      case LOGO_INVERTED_THIN:
        return "Thin dark products";
      default:
        return "";
    }
  };

  const handlePaywallModal = () => {
    setOpenPaywallModal(true);
  };

  return (
    <BrandSetupWrapper pageTitle="My Brand">
      <Box className={styles.boxContainer}>
        {/* <Alert
          text={
            <Text variant="body1" fontSize="16" className={styles.alertText}>
              We’ve launched new products, please review and approve your logo.{" "}
              <span className={styles.alertLink} onClick={handleLinkRouting}>
                Review now
              </span>
            </Text>
          }
          variant="success"
        /> */}
        <Box className={styles.logosContainer}>
          <Box className={styles.header}>
            <Box gap="4px">
              <Text variant="h2" color="gray" className={styles.title}>
                Current logo
              </Text>
              {!isMobile && (
                <Text variant="body1" className={styles.subtitle}>
                  Brand Approved on{" "}
                  {moment(shop.last_brand_setup_approval).format("MMM DD yyy")}
                </Text>
              )}
            </Box>

            <Button
              variant="text"
              color="primary"
              hasChevron
              onClick={handleLinkRouting}
              className={styles.mockupsButton}
            >
              See Mockups
            </Button>
          </Box>
          {isMobile && (
            <Text variant="body1" className={styles.subtitle}>
              Brand Approved on{" "}
              {moment(shop.last_brand_setup_approval).format("MMM DD yyy")}
            </Text>
          )}
          <Box className={styles.content}>
            {logos.map((logoObject, index) => (
              <Box>
                <Box
                  key={logoObject.logo}
                  className={`${styles.logos} ${isEven(index) ? styles.lightBG : styles.darkBg}`}
                >
                  <img src={logoObject.logo} alt="logo" />
                </Box>
                <Text variant="body1" className={styles.text}>
                  {mapLogoType(logoObject.logo_type)}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
        <Box>
          <Text variant="h3" color="gray" className={styles.footerTitle}>
            Want to rebrand and change your logo?
          </Text>
          <Text variant="body1" className={styles.footerSubitle}>
            Note: You may only have one brand per Blanka account. Setting up a
            new brand will replace your current branded products.
          </Text>
          <Box mt={2}>
            <Button
              onClick={
                shop.brand_plate_created
                  ? handlePaywallModal
                  : handleUploadLogoRouting
              }
              color="primary"
              variant="contained"
              hasChevron
              size="small"
            >
              setup new brand
            </Button>
          </Box>
        </Box>
      </Box>

      <BrandSetupReplaceBrandModal
        open={openPaywallModal}
        handleClose={() => setOpenPaywallModal(false)}
        handleConfirm={handleUploadLogoRouting}
        isLoading={isLoading}
      />
    </BrandSetupWrapper>
  );
}

export default BrandSetupView;
