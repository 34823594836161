import React from "react";

// MUI:
import Chip from "@mui/material/Chip";

import styles from "./ActiveTag.module.scss";

export default function ActiveTag({ className }) {
  return (
    <Chip
      data-testid="ActiveTag"
      label="Active"
      className={`${styles.activeTag} ${className}`}
    />
  );
}
