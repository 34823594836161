import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function CheckMarkIcon(props) {
  return (
    <SvgIcon {...props} className="icon" viewBox="0 0 24 24">
      <circle cx="10.5708" cy="10.9375" r="10" fill="#246071" />
      <path
        d="M4.57056 11.3006L7.47965 14.5733L16.5706 7.48242"
        stroke="white"
        strokeWidth="2.72727"
      />
    </SvgIcon>
  );
}

export default CheckMarkIcon;
