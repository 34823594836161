const darkenColor = (hex, percent) => {
  let newHex = "";
  newHex = hex.replace(/^#/, "");

  let r = parseInt(newHex.substring(0, 2), 16);
  let g = parseInt(newHex.substring(2, 4), 16);
  let b = parseInt(newHex.substring(4, 6), 16);

  r = Math.round(r * (1 - percent / 100));
  g = Math.round(g * (1 - percent / 100));
  b = Math.round(b * (1 - percent / 100));

  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  const toHex = (value) => value.toString(16).padStart(2, "0");
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export default darkenColor;
