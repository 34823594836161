import Box from "@mui/material/Box";
import { createSetupIntent, getUser } from "api";
import AccountNav from "components/AccountNav";
import Alert from "components/Alert";
import Button from "components/Buttons/Button";
import AccountCard from "components/Cards/AccountCard";
import UpdatePaymentStripe from "components/Modals/UpdatePaymentStripe";
import PageHeader from "components/PageHeader/PageHeader";
import Text from "components/Text";
import { PLAN_INFO } from "constants/plans";
import { useQueryParam } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "routes/routePaths";
import styles from "../AccountBillingView/AccountBillingView.module.scss";

function AccountBillingUpdateView() {
  const queryParams = useQueryParam();
  const navigate = useNavigate();
  const shop = useSelector((state) => state.profile.shop);

  const [clientSecret, setClientSecret] = useState("");
  const [redirectSuccess, setRedirectSuccess] = useState(false);
  const [error, setError] = useState(false);

  const backToPreviousFeature = useMemo(() => {
    const fromQuery = queryParams.get("from");
    if (fromQuery) {
      switch (fromQuery) {
        case "checkout":
          return {
            label: "Back to checkout",
            route: ROUTE_PATHS.CHECKOUT_PAYMENT,
          };
        case "brand-setup":
          return {
            label: "Back to brand setup",
            route: ROUTE_PATHS.BRAND_SETUP,
          };

        default:
          break;
      }
    }

    return false;
  }, []);

  const getClientSecret = async () => {
    try {
      setClientSecret("");
      await getUser();
      const response = await createSetupIntent(PLAN_INFO[shop.plan].price);
      setClientSecret(response.data.client_secret);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!shop.plan) {
      return;
    }

    getClientSecret();
  }, []);

  const onErrorUpdate = async () => {
    // need to recall intent since first intent was accomplished when adding card
    // this is due to the 1$ card hold failing but 1st intent succeeding
    setError(true);
    await getClientSecret();
  };

  return (
    <div className="profile-billing-template root">
      <PageHeader title="My Account" />
      <AccountNav />

      {error && (
        <Alert
          variant="error"
          text="Could not process your payment. Please use a different card."
        />
      )}

      <AccountCard>
        {redirectSuccess ? (
          <div>
            <Text variant="h1" className={styles.title}>
              Your payment method has been saved.
            </Text>
            <Box mt={2}>
              {backToPreviousFeature ? (
                <Button
                  onClick={(e) => {
                    navigate(backToPreviousFeature.route);
                  }}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  {backToPreviousFeature.label}
                </Button>
              ) : (
                <Button
                  onClick={(e) => {
                    navigate("/profile/billing");
                  }}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  Back to payments
                </Button>
              )}
            </Box>
          </div>
        ) : (
          <UpdatePaymentStripe
            clientSecret={clientSecret}
            onError={onErrorUpdate}
            onSuccess={() => {
              setError(false);
              setRedirectSuccess(true);
            }}
            hideErrorMessage
          />
        )}
      </AccountCard>
    </div>
  );
}

export default AccountBillingUpdateView;
