import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Card,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Text from "components/Text";
import Button from "components/Buttons/Button";

import { CART_TYPE_SAMPLE, CART_TYPE_INVENTORY } from "constants/constants";

// import CartTotals from "../CartTotals";
import CartTotals from "components/CartTotals/CartTotals";
import CartItemMobile from "../CartItemMobile/CartItemMobile";
import CheckoutFooter from "../../../CheckoutView/components/CheckoutFooter";

import styles from "./CartMobileView.module.scss";

function CartMobileView(props) {
  const {
    cart,
    handleUpdate,
    handleRemoveItem,
    handleContinueToCheckout,
    handleOnBlurredInputField,
  } = props;

  return (
    <div className={`cart-template ${styles.cartMobileView}`}>
      <CheckoutFooter activeStep={0} />

      <Divider orientation="horizontal" className={styles.divider} />

      <Card variant="outlined" className={styles.card}>
        <TableContainer>
          <Table aria-label="" sx={{ border: "none" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={styles.columnName}
                  style={{ width: "50%" }}
                >
                  Product
                </TableCell>
                {/* {cart.type === CART_TYPE_INVENTORY && (
                  <TableCell>
                    Branded Boxes
                    <div>
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={isSelectAll()}
                        onChange={(event) => {
                          handleSelectAll(event.target.checked);
                        }}
                      />
                      Select All
                    </div>
                  </TableCell>
                )} */}
                <TableCell
                  className={styles.columnName}
                  style={{ width: "10%" }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  className={styles.columnName}
                  style={{ width: "20%" }}
                  align="right"
                >
                  Total Cost
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.items.map((item) => (
                <CartItemMobile
                  key={item.id}
                  item={item}
                  handleUpdate={handleUpdate}
                  handleRemoveItem={handleRemoveItem}
                  cartType={cart.type}
                  handleOnBlurredInputField={handleOnBlurredInputField}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {/* <Divider /> */}

      <Box width="100%">
        <CartTotals cart={cart} step="cart" />
      </Box>

      <div className={styles.footerContainer}>
        {cart.type === CART_TYPE_SAMPLE && (
          <Text className={styles.importantNote}>
            NOTE: ALL SAMPLE ORDERS ARE SHIPPED WITH UNBRANDED PRODUCTS
          </Text>
        )}
      </div>
      <Divider />

      <div className={styles.actionContainer}>
        <Button
          onClick={handleContinueToCheckout}
          variant="contained"
          size="small"
          color="primary"
          className="button button--primary"
          fullWidth
          loading={cart.status === "loading"}
        >
          Continue To Shipping{" "}
        </Button>
        <Text className={styles.warning}>
          *Discount and shipping calculated at checkout
        </Text>
      </div>
    </div>
  );
}

CartMobileView.propTypes = {
  cart: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  handleContinueToCheckout: PropTypes.func.isRequired,
};

CartMobileView.defaultProps = {};

export default CartMobileView;
