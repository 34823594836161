import React from "react";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import styles from "./Slider.module.scss";

export default function Slider(props) {
  const { numberOfSlides, children } = props;

  return (
    <Carousel
      autoPlay
      interval={3500}
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      showIndicators={false}
    >
      {children}
    </Carousel>
  );
}
