// eslint-disable-next-line import/no-cycle
import apiService from "api/apiService";

export const changePassword = (data) =>
  apiService.put("/auth/password/change/", {
    old_password: data.old_password,
    new_password: data.new_password,
  });

export const getShippingAddresses = () =>
  apiService.get("/shop-shipping-address/");

export const getShippingAddressById = (id) =>
  apiService.get(`/shop-shipping-address/${id}/`);

export const createShippingAddress = (data) =>
  apiService.post("/shop-shipping-address/", data);

export const updateShippingAddressById = (id, data) =>
  apiService.put(`/shop-shipping-address/${id}/`, data);

export const deleteShippingAddressById = (id) =>
  apiService.delete(`/shop-shipping-address/${id}/`);

export const getProfileDataPointsMetadata = () =>
  apiService.get(`/profile-data-points-metadata/`);
