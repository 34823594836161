import React, { useEffect, useState } from "react";

// MUI components:
import { Box, Checkbox, Stack, FormControlLabel } from "@mui/material";

// custom components and constants:
import Button from "components/Buttons/Button";
import Input from "components/Input/Input";
import CountryAutocomplete from "components/Input/CountryAutocomplete/CountryAutocomplete";
import AddressAutocomplete from "components/Input/AddressAutocomplete";
import { ACCOUNT_ADDRESSES_FIELDS } from "constants/accountAddresses";
import { COUNTRIES } from "constants/countries";

// third-party libraries:
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

// styles:
import styles from "./AccountAddressesForm.module.scss";

// Auxiliary function to transform address data from autocomplete
const sanitize = (str) => str.replace(/[^a-zA-Z0-9 ]/g, "");

// Auxiliary function to handle phone number validation
// const phoneRegex = /^[+\d]+$/;

// util function to clean the phone number by removing spaces, parentheses, and hyphens
const cleanPhoneNumber = (phoneNumber) => phoneNumber.replace(/[\s().-]/g, "");

export default function AccountAddressesForm(props) {
  const { onSubmit, onCancel } = props;

  const { isEditing, addressToEdit, status } = useSelector(
    (state) => state.accountAddresses
  );

  // internal state:
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const { control, register, handleSubmit, setValue, watch, setError } =
    useForm({
      country:
        addressToEdit?.country === undefined ? "" : addressToEdit.country,
      default: addressToEdit?.default || false,
    });

  const watchDefault = watch("default", false);
  const watchFields = watch([
    "first_name",
    "last_name",
    "address",
    "city",
    "state",
    "zip_code",
  ]);
  const watchCountry = watch("country", "");
  const watchPhone = watch("phone", "");

  // this useEffect is how we maintain the pattern of only enabling the save
  // button when all the required fields are filled out
  useEffect(() => {
    // check fields on custom Input:
    const allFieldsFilled = watchFields.every(
      (fieldValue) => fieldValue && fieldValue !== ""
    );

    // check country on CountryAutocomplete:
    // country data structure: {name: string, code: string}
    const isCountryFilled = watchCountry && watchCountry.name !== "";

    // check if phone number is valid & update the register variable:
    const cleanedWatchPhone = cleanPhoneNumber(watchPhone);

    // console.log({ cleanedWatchPhone });
    const isPhoneValid =
      cleanedWatchPhone &&
      cleanedWatchPhone.length >= 10 &&
      cleanedWatchPhone.length <= 15;

    if (cleanPhoneNumber.length > 15) {
      setError("phone", {
        type: "manual",
        message: "Phone number should not exceed 15 digits",
      });
    }
    // if allFieldsFilled and isCountryFilled are true, then enable save button
    setIsSaveEnabled(allFieldsFilled && isCountryFilled && isPhoneValid);
  }, [watchFields]);

  useEffect(() => {
    if (
      status === "idle" &&
      isEditing &&
      Object.keys(addressToEdit).length > 0
    ) {
      const transformedData = {
        ...addressToEdit,
        address: addressToEdit.address_one,
        unit: sanitize(addressToEdit.address_two),
      };

      // Update form fields
      Object.keys(transformedData).forEach((key) => {
        if (key === "country") {
          // Assuming country is an object with name and code
          setValue(key, {
            name: transformedData[key].name,
            code: transformedData[key].code,
          });
        } else {
          setValue(key, transformedData[key]);
        }
      });
    }
  }, [status]);

  const handleAutoCompleteAddress = (addressData) => {
    // Update form fields based on the address data from autocomplete
    // Use setValue from react-hook-form to update each field
    setValue("address", sanitize(addressData.fullAddress));
    setValue("city", addressData.city);
    setValue("state", addressData.state);
    setValue("zip_code", addressData.zipCode);
    setValue("country", addressData.country);
  };

  if (status !== "idle") {
    return null;
  }

  return (
    <form className={styles.accountAddressesForm}>
      <Stack className={styles.stackContainer}>
        <Box className={styles.singleLine}>
          <Input
            label={`${ACCOUNT_ADDRESSES_FIELDS.first_name}*`}
            name="first_name"
            control={control}
            defaultValue=""
            rules={{
              required: "First name is required",
            }}
          />
          <Input
            label={`${ACCOUNT_ADDRESSES_FIELDS.last_name}*`}
            name="last_name"
            control={control}
            defaultValue=""
            rules={{
              required: "Last name is required",
            }}
          />
        </Box>
        <Input
          label={ACCOUNT_ADDRESSES_FIELDS.company}
          name="company"
          control={control}
          defaultValue=""
        />

        <AddressAutocomplete
          control={control}
          name="address"
          label="Address*"
          rules={{
            required: "Address is required",
          }}
          handleAutoCompleteAddress={handleAutoCompleteAddress}
        />

        <Input
          label={ACCOUNT_ADDRESSES_FIELDS.unit}
          name="unit"
          control={control}
          defaultValue=""
          rules={{
            pattern: {
              value: /^[\w\W]*$/i,
              message: "Please enter a unit/suite number",
            },
          }}
        />

        <Box className={styles.singleLine}>
          <Input
            label={`${ACCOUNT_ADDRESSES_FIELDS.city}*`}
            name="city"
            control={control}
            defaultValue=""
            rules={{
              required: "City name is required",
            }}
          />
          <Input
            label={`${ACCOUNT_ADDRESSES_FIELDS.state}*`}
            name="state"
            control={control}
            defaultValue=""
            rules={{
              required: "State name is required",
            }}
          />
          <Input
            label={`${ACCOUNT_ADDRESSES_FIELDS.zip_code}*`}
            name="zip_code"
            control={control}
            defaultValue=""
            rules={{ required: "Zip code is required" }}
          />
        </Box>

        <div className={styles.autoCompleteContainer}>
          <CountryAutocomplete
            control={control}
            name="country"
            defaultValue=""
            label={`${ACCOUNT_ADDRESSES_FIELDS.country}*`}
            countries={COUNTRIES}
            rules={{
              required: "Country is required",
              validate: (value) =>
                value !== undefined || "Value cannot be undefined",
            }}
          />
        </div>

        <Input
          label={`${ACCOUNT_ADDRESSES_FIELDS.phone}*`}
          name="phone"
          control={control}
          defaultValue=""
          rules={{
            required: "Phone is required",
          }}
          setError={setError}
        />

        <FormControlLabel
          control={<Checkbox {...register("default")} checked={watchDefault} />}
          label="Use this as my default shipping address"
        />

        <Box className={styles.singleLine}>
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={handleSubmit(onSubmit)}
            disabled={!isSaveEnabled}
          >
            Save
          </Button>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
