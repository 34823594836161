import React from "react";
import { useNavigate } from "react-router-dom";

import { Card, TextField, Box } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import PageHeader from "components/PageHeader/PageHeader";

import Text from "components/Text";
import Button from "components/Buttons/Button";
import styles from "./AccountPasswordView.module.scss";

function AccountPasswordView() {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/profile/password/update");
  };

  const handleBackBtn = () => {
    window.history.back();
  };

  return (
    <div className="template-profile-password root">
      <PageHeader title="My Account" />
      <Box className={styles.backBtn} onClick={handleBackBtn}>
        <ArrowBack /> Back
      </Box>

      <Card className={`card ${styles.cardWrapper}`} variant="outlined">
        <div className={`header-text-container ${styles.formControlWrapper}`}>
          <Text className="text__title text--georgia text--bold" color="gray">
            Password
          </Text>

          <Text
            className={styles.encryptedPassword}
            variant="body1"
            color="textPrimary"
          >
            All passwords are secure and encrypted.
          </Text>

          <Text className={styles.passwordLabel}>Password</Text>

          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            value="****************"
          />

          <div className="flex--flex">
            <Button
              color="primary"
              className={styles.changePasswordButton}
              variant="contained"
              onClick={handleOnClick}
            >
              Change Password
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default AccountPasswordView;
