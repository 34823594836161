import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "components/Buttons/Button";
import PageHeader from "components/PageHeader";
import Text from "components/Text";
import { PLAN_FREE_ID } from "constants/plans";
import React from "react";
import { useSelector } from "react-redux";

import { SMALL } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";

import useChangePlanModal from "hooks/useChangePlanModal";
import styles from "./CheckoutCompleteView.module.scss";

export default function CheckoutCompleteView() {
  const shop = useSelector((state) => state.profile.shop);
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const { onOpenModal } = useChangePlanModal();

  return (
    <div className="">
      {!isMobile && <PageHeader title="Checkout - Thank You" />}
      <Card className={styles.card} variant="outlined">
        <Text color="default" variant="h3" fontSize={24}>
          Thank You
        </Text>
        <Text
          color="default"
          className="text--upper mt-25"
          variant="body1"
          fontSize={12}
        >
          <span className="text--bold">PLEASE Check your email</span> for your
          order confirmation.
        </Text>
        <Text
          color="default"
          className="text--upper mt-10"
          variant="body1"
          fontSize={12}
        />

        {shop.plan_type === PLAN_FREE_ID && (
          <Box className={styles.upsellContainer}>
            <Text variant="h3" fontSize={!isMobile ? 34 : 24}>
              Ready for the next level
            </Text>

            <ul>
              <li>Sell branded products</li>
              <li>Unlock special features &amp; More!</li>
            </ul>

            <Button
              onClick={onOpenModal}
              variant="contained"
              color="secondary"
              size={!isMobile ? "large" : "small"}
              className={`isMobile ? ${styles.btnFont} : ""`}
            >
              Browse Our plans
            </Button>
          </Box>
        )}
      </Card>
    </div>
  );
}
