import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function LoginRedirect() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const url = new URL(`${window.location}`);
    const params = new URLSearchParams(url.search);
    params.set("redirect_url", location.pathname);
    navigate({
      pathname: "/login",
      search: params.toString(),
    });
  }, []);

  return null;
}

export default LoginRedirect;
