import { Box, Button, Container, Grid, Paper } from "@mui/material";
import PageHeader from "components/PageHeader";
import Text from "components/Text";
import React from "react";

import BlankaFormulatorStepFourIcon from "components/Icons/BlankaFormulatorStepFourIcon";
import BlankaFormulatorStepOneIcon from "components/Icons/BlankaFormulatorStepOneIcon";
import BlankaFormulatorStepThreeIcon from "components/Icons/BlankaFormulatorStepThreeIcon";
import BlankaFormulatorStepTwoIcon from "components/Icons/BlankaFormulatorStepTwoIcon";

import BlankaLabsLogoImg from "assets/images/blanka_labs_logo.png";
import FormulatorImage from "assets/images/formulator_image.webp";
import BlankaFormulatorLogoImg from "assets/images/logo-blanka-formulator.svg";
import { HUBSPOT_CALENDAR_URL, TYPEFORM_URL } from "constants/externalLinks";
import { useAnalytics, useProfile } from "hooks";

import useChangePlanModal from "hooks/useChangePlanModal";

// import { toast } from "react-toastify";
// import {
//   GenericErrorNotification,
//   GenericSuccessNotification,
// } from "components/Notifications";
import styles from "./BlankaFormulatorView.module.scss";

function BlankaFormulatorView() {
  const profileHook = useProfile();
  const analytics = useAnalytics();
  const { profile, shop } = profileHook;
  const { onOpenModal } = useChangePlanModal();

  const getTypeFormUrl = () => {
    const url = `${TYPEFORM_URL}?user_id=${profile.id}&email=${profile.email}&first_name=${encodeURIComponent(profile.first_name)}&last_name=${encodeURIComponent(profile.last_name)}&shop_id=${shop.id}`;
    return url;
  };

  const handleTypeformLinkClick = () => {
    if (!profileHook.isFreePlan()) {
      window.open(getTypeFormUrl(), "_blank");
      analytics.sendEvent(analytics.BLANKA_FORMULATOR_OPEN_TYPEFORM, {});
    } else {
      onOpenModal({ isFreePlanLimit: true });
      analytics.sendEvent(analytics.PAYWALL_HIT, {
        location: "blanka_labs_view",
        paywall_name: "blanka_labs_landing_page",
      });
    }
  };

  return (
    <div className={`${styles.blankaLabsView} blanka-labs-view`}>
      <PageHeader title="Blanka Formulator" />
      <Container maxWidth="l" className={styles.blankaLabsContainer}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={styles.alignCenter}
          >
            <Box textAlign="center" className={styles.infoBox}>
              <img
                className={styles.productImg}
                src={FormulatorImage}
                alt="Blanka Formulator Products"
              />
              <img
                className={styles.logo}
                src={BlankaFormulatorLogoImg}
                alt="Blanka Formulator Logo"
              />

              <Text variant="body1" fontSize={16} gutterBottom>
                Blanka formulator is no longer unavailable. We recommend you
                check out our ready to brand category of Blanka products. If you
                are looking for more advanced custom formulations and packaging,
                please contact our sales team to learn more about our Labs
                program.
              </Text>

              <a
                href="https://blankabrand.com/pages/blanka-labs?srsltid=AfmBOoryojp9IOmDI8ckTY5rROWKf2d4-9HUKJGmbC_OnCf6u-U-HY1v"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="contained" color="primary">
                  BLANKA LABS
                </Button>
              </a>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default BlankaFormulatorView;
