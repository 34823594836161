import React, { useEffect, useState } from "react";
import { getAvailableCredit } from "api/endpoints/shopApi";
import Text from "components/Text";
import styles from "./AvailableCredit.module.scss";

function AvailableCredit() {
  const [credit, setCredit] = useState(null);

  useEffect(() => {
    const fetchCredit = async () => {
      try {
        const response = await getAvailableCredit();

        setCredit(response.data.available_credit);
      } catch (error) {
        console.error("Error retrieving available credit:", error);
      }
    };

    fetchCredit();
  }, []);

  // dont show if no credit
  if (Number(credit) <= 0) {
    return null;
  }

  return (
    <div className={styles.availableCreditComponent}>
      <Text fontSize={16} variant="h2" className={styles.title}>
        Account Credit
      </Text>
      <Text>
        We will automatically apply this credit to your future payments before
        processing the remainder with your default billing method.
      </Text>
      <div className={styles.credit}>
        <Text className={styles.creditValue}>
          ${credit} <span className={styles.usd}>USD</span>
        </Text>
      </div>
    </div>
  );
}

export default AvailableCredit;
