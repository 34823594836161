import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// redux states:
import { useDispatch, useSelector } from "react-redux";
import { addLogo, setIsLogoUploaded, updateLogo } from "state/myLogoSlice";

// MUI components:
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Button, Divider, Grid, Stack } from "@mui/material";

// our components:
import LogoDropzone from "components/LogoDropzone";
import Text from "components/Text";

// third party libraries:
import { useMediaQuery } from "react-responsive";

// constants:
import { SMALL } from "constants/breakpoints.js";
import {
  LOGO_DEFAULT,
  LOGO_INVERTED,
  LOGO_INVERTED_THIN,
  LOGO_THIN,
} from "constants/generatorLogo";

import { getMyLogoGenerated, uploadMyLogoGenerated } from "api";
import { set } from "react-hook-form";
import MyLogoErrorNotification from "../MyLogoNotification/MyLogoErrorNotification";

import styles from "./MyLogoStepTemplate.module.scss";

function MyLogoStepTemplate(props) {
  const {
    step,
    subTitle,
    lead,
    imagePreview,
    caption,
    handleNext,
    handlePrevious,
    logoType,
  } = props;

  const { logos, LOGO_ORDER } = useSelector((state) => state.myLogo);

  const [droppedFile, setDroppedFile] = useState(null);
  const [base64Logo, setLogosetBase64LogoUploaded] = useState(null);
  const [fileRejectionItems, setFileRejectionItems] = useState([]);
  const [fileWarningItems, setFileWarningItems] = useState([]);
  const [isLoadingUseLogo, setIsLoadingUseLogo] = useState(false);
  const [isLoadingChangeLogo, setIsLoadingChangeLogo] = useState(false);
  const [logo, setLogo] = useState(logos[0]?.logo); // logos[0].logo
  const [data, setData] = useState({
    logo:
      logos.find((logo) => logo.logo_type === logoType)?.logo || logos[0]?.logo,
    logo_type: logoType,
  });
  const [isOpen, setIsOpen] = useState(false);

  // redux state manager:
  const dispatch = useDispatch();

  // handle media query:
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  useEffect(() => {
    const logoForTheStep = logos
      .filter((logo) => logo.logo_type === logoType)
      .map((l) => l.logo)[0];

    if (logoForTheStep) {
      setLogo(logoForTheStep);
    } else {
      setLogo(logos[0]?.logo);
    }
  }, [logos, logoType]);

  const handleFileAccepted = async (file) => {
    setFileRejectionItems([]);
    setFileWarningItems([]);
    setIsLoadingChangeLogo(true);
    setDroppedFile(file);

    if (file) {
      try {
        const base64Logo = await fileToBase64(file);
        setLogosetBase64LogoUploaded(base64Logo);
        setData({ logo: base64Logo, logo_type: logoType });
        setIsLoadingChangeLogo(false);
      } catch (error) {
        // handleOnError(error);
        setIsLoadingChangeLogo(false);
      }
    }
  };

  const handleFileError = (fileRejections) => {
    setFileRejectionItems(fileRejections);
    if (isMobile) {
      setIsOpen(true);
    }
  };

  const handleFileWarning = (fileWarnings) => {
    setFileWarningItems(fileWarnings);
    if (isMobile) {
      setIsOpen(true);
    }
  };

  const handleContinue = () => {
    if (fileWarningItems.length > 0 && fileRejectionItems.length === 0) {
      handleNext(); // Proceed to the next step
    }
  };

  const handleUseThisLogo = async () => {
    setIsLoadingUseLogo(true);

    try {
      // If the user has uploaded a new logo:
      if (droppedFile && LOGO_ORDER.includes(logoType)) {
        await uploadMyLogoGenerated({ logo: droppedFile, logo_type: logoType });
      }
      // Dispatch the addLogo action to either add or update the logo in the Redux state
      // console.log(data.logo_type);
      dispatch(addLogo(data));

      setIsLoadingUseLogo(false);
      handleNext();
    } catch (error) {
      setIsLoadingUseLogo(false);
    }
  };

  const handleLogoTypeStyle = (logoType) => {
    switch (logoType) {
      case LOGO_DEFAULT:
        return styles.logoDefault;
      case LOGO_INVERTED:
        return styles.logoInverted;
      case LOGO_THIN:
        return styles.logoThin;
      case LOGO_INVERTED_THIN:
        return styles.logoInvertedThin;
      default:
        return styles.logoDefault;
    }
  };

  return (
    <div className={styles.myLogoStepTemplate}>
      {!isMobile && (
        <Box className={styles.boxContainer}>
          <Button variant="text" color="secondary" onClick={handlePrevious}>
            <div className={styles.backBtn}>
              <KeyboardBackspaceIcon />
              Back
            </div>
          </Button>
          <Text className={styles.stepTextTitle}>{step}</Text>
        </Box>
      )}
      {!isMobile && <Divider className={styles.divider} />}
      <Stack direction="row" className={styles.stepContainer}>
        {isMobile && <Text className={styles.textTitle}>{step}</Text>}
        <Grid item className={styles.stepSection}>
          <Stack mb={3} direction="row" justifyContent="space-between">
            <Box>
              <Text className={styles.sectionSubTitle}>{subTitle}</Text>
              <Text className={styles.sectionLead}>{lead}</Text>
            </Box>
          </Stack>
          <Box className={styles.stepImageSection}>
            <Box className={styles.stepImageContainer}>
              <Box className={handleLogoTypeStyle(logoType)}>
                {fileRejectionItems.length === 0 && (
                  <img
                    src={base64Logo || logo || data?.logo}
                    alt=""
                    className={styles.logoImg}
                  />
                )}
              </Box>
              <img src={imagePreview} alt="" className={styles.stepImage} />
            </Box>
            <Text className={styles.imageCaption}>{caption}</Text>
          </Box>

          <Grid container className={styles.gridContainer}>
            <Button
              variant="contained"
              color="primary"
              className={styles.btnWidthConstraint}
              onClick={handleUseThisLogo}
              loading={isLoadingUseLogo}
              disabled={isLoadingChangeLogo || fileRejectionItems.length > 0} // fullWidth={isMobile}
            >
              Use this logo
            </Button>
            <LogoDropzone
              onFileAccepted={handleFileAccepted}
              onFileError={handleFileError}
              onFileWarning={handleFileWarning}
            >
              <Button
                variant="outlined"
                color="primary"
                className={styles.btnWidthConstraint}
                loading={isLoadingChangeLogo}
                disabled={isLoadingUseLogo}
              >
                Change this logo
              </Button>
            </LogoDropzone>
            {fileRejectionItems.length > 0 && !isMobile && (
              <div className={styles.centerWithMargin}>
                <ul className={styles.fileRejection}>
                  <Text
                    className={`${styles.fileRejection} ${styles.errorText}`}
                  >
                    Oops! Your logo does not meet our requirements:
                  </Text>
                  {fileRejectionItems.map((e) => (
                    <li key={e} className={styles.errorText}>
                      <Text className={styles.errorText}>{e}</Text>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {fileWarningItems.length > 0 &&
              fileRejectionItems.length === 0 &&
              !isMobile && (
                <>
                  {fileWarningItems.map((e) => (
                    <li key={e} className={styles.errorText}>
                      <Text className={styles.errorText}>{e}</Text>
                    </li>
                  ))}
                  <Button
                    variant="contained"
                    color="secondary"
                    className={styles.btnWidthConstraint}
                    onClick={handleContinue}
                  >
                    Continue Anyway
                  </Button>
                </>
              )}
          </Grid>
        </Grid>
      </Stack>

      {isMobile && (
        <MyLogoErrorNotification
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onError={fileRejectionItems}
        />
      )}
    </div>
  );
}

MyLogoStepTemplate.propTypes = {
  step: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  imagePreview: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  logoType: PropTypes.string,
};

MyLogoStepTemplate.defaultProps = {
  logoType: LOGO_DEFAULT,
};

export default MyLogoStepTemplate;
