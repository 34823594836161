import React from "react";
import PropTypes from "prop-types";

import styles from "./Ribbon.module.scss";

export default function Ribbon({ children }) {
  return (
    <div
      data-testid="ribbon"
      className={`${styles.ribbon} ${styles.ribbonTopRight}`}
    >
      <span>{children}</span>
    </div>
  );
}

Ribbon.propTypes = {
  children: PropTypes.string.isRequired,
};
