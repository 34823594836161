/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  progress: 0,
  totalTasks: 0,
  completedTasks: 0,
};

const wsGeneratorSlice = createSlice({
  name: "wsGenerator",
  initialState,
  reducers: {
    onSetSocketDataReducer: (state, action) => {
      state.progress = action.payload.progress;
      state.message = action.payload.message;
      state.totalTasks = action.payload.totalTasks;
      state.completedTasks = action.payload.completedTasks;
    },
    onResetSocketReducer: (state) => {
      state.progress = 0;
      state.message = "";
      state.totalTasks = 0;
      state.completedTasks = 0;
    },
  },
});

export const { onSetSocketDataReducer, onResetSocketReducer } =
  wsGeneratorSlice.actions;

export default wsGeneratorSlice.reducer;
