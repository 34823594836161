import { LOGO_ERROR_MESSAGES } from "constants/generatorLogo";
import { useAnalytics } from "hooks";
import React from "react";
import { useDropzone } from "react-dropzone";

function LogoDropzone({
  onFileAccepted,
  onFileError,
  children,
  onDropCallback,
}) {
  const analytics = useAnalytics();
  const onDrop = (acceptedFiles, fileRejections) => {
    // The current component only works with one file.
    // This callback is to support a generic behaviour and keep it
    // backward compatible.
    if (onDropCallback) {
      onDropCallback(acceptedFiles, fileRejections);
      return;
    }

    try {
      if (acceptedFiles.length > 0) {
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;

          image.onload = () => {
            onFileAccepted(acceptedFiles[0]);
          };
        };

        reader.readAsDataURL(acceptedFiles[0]);
      }

      if (fileRejections.length > 0) {
        if (onFileError) {
          const rejectionItems = LOGO_ERROR_MESSAGES;

          onFileError(rejectionItems);
        }
      }

      if (fileRejections.length > 0) {
        if (onFileError) {
          const errorCodes = fileRejections.map((item) =>
            item.errors.map((error) => error.code)
          );
          analytics.sendEvent(analytics.UPLOAD_LOGO_ERROR, {
            errors: errorCodes,
          });

          const rejectionItems = LOGO_ERROR_MESSAGES;

          onFileError(rejectionItems);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [],
    },

    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
}

export default LogoDropzone;
