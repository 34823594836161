/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imageType: "",
  logoWidth: 0,
  logoHeight: 0,
  logoRotation: 0,
  logoExceedsContainer: false,
  logoContainerDimensions: {
    positionX: 0,
    positionY: 0,
    maxWidth: 0,
    maxHeight: 0,
  },
  activeAccordion: "size",
  logo: "",
  originalLogoWidth: 0,
  originalLogoHeight: 0,
  productGroupsOpened: ["beauty-and-skincare"],
};

const logoCustomizeSlice = createSlice({
  name: "logoCustomize",
  initialState,
  reducers: {
    onSetImageType: (state, action) => {
      state.imageType = action.payload;
    },
    onSetLogoSize: (state, action) => {
      state.logoWidth = action.payload.logoWidth;
      state.logoHeight = action.payload.logoHeight;
    },
    onSetLogoRotation: (state, action) => {
      state.logoRotation = action.payload;
    },
    onSetLogoContainerDimensions: (state, action) => {
      state.logoContainerDimensions = action.payload;
    },
    onSetActiveAccordion: (state, action) => {
      state.activeAccordion = action.payload;
    },
    onSetLogo: (state, action) => {
      state.logo = action.payload.file_url;
      state.originalLogoHeight = action.payload.height;
      state.originalLogoWidth = action.payload.width;
    },
    onSetProductGroupsOpened: (state, action) => {
      state.productGroupsOpened = action.payload;
    },
    onSetLogoExceedsContainer: (state, action) => {
      state.logoExceedsContainer = action.payload;
    },
  },
});

export const {
  onSetImageType,
  onSetLogoSize,
  onSetLogoRotation,
  onSetLogoContainerDimensions,
  onSetActiveAccordion,
  onSetLogo,
  onSetProductGroupsOpened,
  onSetLogoExceedsContainer,
} = logoCustomizeSlice.actions;

export default logoCustomizeSlice.reducer;
