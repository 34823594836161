import React, { useState } from "react";

// mui components:
import { Box } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./UnavailableProductNotification.module.scss";

export default function UnavailableProductNotification(props) {
  const { handleClose } = props;

  const [isVisible, setIsVisible] = useState(true);

  const handleAnimationEnd = () => {
    if (!isVisible) {
      handleClose();
    }
  };

  const handleCloseClick = () => {
    setIsVisible(false);
  };

  return (
    <Box
      className={`${styles.unavailableProductNotification} ${isVisible ? styles.slideIn : styles.slideOut}`}
      onAnimationEnd={handleAnimationEnd}
    >
      <span className={styles.title}>
        <WarningAmberRoundedIcon fontSize="small" />
        Oops...
      </span>
      <span className={styles.text}>
        There are one or more unavailable products in your cart. Please remove
        before proceeding to checkout.{" "}
      </span>{" "}
      <div onClick={() => handleCloseClick()}>
        <CloseIcon className={styles.closeIcon} />
      </div>
    </Box>
  );
}
