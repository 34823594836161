import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// mui:
import { Box, Stack, TextField } from "@mui/material";

// custom components and constants:
import poweredByStripeImg from "assets/images/powered-by-stripe.png";
import AccountNav from "components/AccountNav";
import Button from "components/Buttons/Button";
import AccountCard from "components/Cards/AccountCard";
import EnableAutomaticPaymentModal from "components/Modals/EnableAutomaticPaymentModal";
import PageHeader from "components/PageHeader/PageHeader";
import BlSwitch from "components/Switch";
import Text from "components/Text";

import BlankaddPaymentMethod from "assets/images/blanka-add-payment-method.png";

// api:
import { useProfile } from "hooks";
import { useGlobalFlags } from "hooks/useGlobalFlags";
import { setAutomaticPaymentsThunk } from "state/shopSettignsSlice";
import styles from "./AccountBillingView.module.scss";
import AvailableCredit from "./components/AvailableCredit";
import PaymentHistory from "./components/PaymentHistory";

function AccountBillingView() {
  const shop = useSelector((state) => state.profile.shop);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileHook = useProfile();

  const [open, setOpen] = useState(false);
  const [enableAutomaticPayment, setEnableAutomaticPayment] = useState(
    useSelector(
      (state) => state.profile.shop.config_flags.SHOP_AUTO_PAY_CUSTOMER_ORDERS
    )
  );

  console.log(shop);

  const handleCardNumberDisplayed = () => {
    const encryptedCardNumber = "**** **** **** ****";
    const ecryptedCardNumberDisplayingFourLastDigits =
      encryptedCardNumber.slice(0, -4) +
      String(shop?.billing_address?.credit_card_digits);

    if (shop?.billing_address) {
      return ecryptedCardNumberDisplayingFourLastDigits;
    }
    return encryptedCardNumber;
  };

  const handleUpdatePaymentMethod = () => {
    navigate("/profile/billing/update");
  };

  const handleConfirmSetAutomaticPayment = async (value) => {
    // await updateShopAutomaticPayments(value);
    try {
      await dispatch(setAutomaticPaymentsThunk(value));
      profileHook.refreshProfile();
      setEnableAutomaticPayment(value);
      toast.success(`Automatic payments ${value ? "enabled" : "disabled"}.`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAutomaticPaymentChecked = () => {
    if (enableAutomaticPayment) {
      handleConfirmSetAutomaticPayment(false);
      return;
    }
    setOpen(true);
  };

  return (
    <div className={styles.accountBillingView}>
      <PageHeader title="My Account" />
      <AccountNav />

      {!shop?.billing_address ? (
        <AccountCard>
          <Text variant="h1" className={styles.title}>
            Billing method
          </Text>
          <Text className={styles.encryptionMessage}>
            All transactions are secure and encrypted.
          </Text>
          <Box className={styles.billingMethodBox}>
            <img
              src={BlankaddPaymentMethod}
              alt="Add a billing method"
              className={styles.imgStyle}
            />
            <Text variant="h2" className={styles.addMethodMessage}>
              You don’t have any payment methods in your account yet
            </Text>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdatePaymentMethod}
            >
              Add a billing method
            </Button>
          </Box>
        </AccountCard>
      ) : (
        <>
          <AccountCard>
            <Stack className={styles.stackContainer}>
              <Text variant="h1" className={styles.title}>
                Billing method
              </Text>
              <Text className={styles.encryptionMessage}>
                All transactions are secure and encrypted.
              </Text>

              <Text variant="body1" className={styles.creditCardLabel}>
                Credit card number
              </Text>
              <TextField value={handleCardNumberDisplayed()} disabled />
              <Text variant="body1" className={styles.creditCardWarning}>
                Need to update your information? Click “UPDATE INFORMATION”.
              </Text>

              <Button
                variant="contained"
                color="primary"
                className={styles.updateButton}
                onClick={handleUpdatePaymentMethod}
              >
                UPDATE Information
              </Button>

              {useGlobalFlags().showCustomerOrderAutomaticPayments() && (
                <Text variant="h2" className={styles.subTitle}>
                  Automatic payments
                </Text>
              )}
            </Stack>
            <Stack className={styles.flexContainer}>
              <Box className={styles.boxContainer}>
                {useGlobalFlags().showCustomerOrderAutomaticPayments() &&
                  useSelector(
                    (state) => state.shopSettings?.shopAutomaticPayments
                  ) && (
                    <>
                      <BlSwitch
                        loading={useSelector(
                          (state) =>
                            state.shopSettings.shopAutomaticPayments.loading
                        )}
                        checked={!!enableAutomaticPayment}
                        handleChange={handleAutomaticPaymentChecked}
                      />
                      <Text>Allow automatic payments for customer orders</Text>
                    </>
                  )}
              </Box>
              <img
                className={styles.poweredByStripe}
                src={poweredByStripeImg}
                alt="powered by stripe"
              />
            </Stack>

            <AvailableCredit />
          </AccountCard>
          <br />
          <PaymentHistory />
        </>
      )}
      <EnableAutomaticPaymentModal
        open={open}
        handleClose={() => setOpen(false)}
        setEnableAutomaticPayment={handleConfirmSetAutomaticPayment}
      />
    </div>
  );
}

export default AccountBillingView;
