import React from "react";
import PropTypes from "prop-types";

import { Box, Divider, Stepper, Step, StepLabel } from "@mui/material";

import { useSelector } from "react-redux";

import { DashedCompleteCheckMarkIcon } from "components/Icons";
import { useScrollPosition } from "hooks/useScrollPosition";
import CustomStepIcon from "../CustomStepIcon/CustomStepIcon";

import styles from "./MobileStepper.module.scss";

function MobileStepper(props) {
  const { steps } = props;

  const scrollPosition = useScrollPosition();

  const {
    activeOuterStep,
    activeInnerStep,
    isGeneratorStarted,
    isGeneratorCompleted,
  } = useSelector((state) => state.myLogo);

  const handleStepLabel = (activeOuterStep) => {
    if (activeOuterStep !== 1) {
      return (
        <Box className={styles.labelBox}>
          <span className={styles.title}>{steps[activeOuterStep].title}</span>
          <span className={styles.subTitle}>
            {steps[activeOuterStep].subTitle}
          </span>
        </Box>
      );
    }

    return (
      <Box className={styles.labelBox}>
        <span className={styles.title}>Additional logos</span>
        <span className={styles.subTitle}>Other colors & sizes (Optional)</span>
      </Box>
    );
  };

  return (
    <div className={styles.mobileStepper}>
      <Divider
        className={scrollPosition > 80 ? styles.hidden : styles.divider}
      />
      <Stepper
        activeStep={isGeneratorStarted ? activeOuterStep : -1}
        orientation="horizontal"
        className={styles.horizontalStepper}
        alternativeLabel
      >
        {steps.map((step) => (
          <Step key={`${step.title}-${step.subTitle}`}>
            {isGeneratorCompleted ? (
              <StepLabel StepIconComponent={DashedCompleteCheckMarkIcon} />
            ) : (
              <StepLabel StepIconComponent={CustomStepIcon} />
            )}
          </Step>
        ))}
      </Stepper>
      {handleStepLabel(activeOuterStep, activeInnerStep)}
    </div>
  );
}

MobileStepper.propTypes = {
  steps: PropTypes.array,
};

MobileStepper.defaultProps = {
  steps: [],
};

export default MobileStepper;
