export const ACCOUNT_ADDRESSES_FIELDS = {
  first_name: "First Name",
  last_name: "Last Name",
  company: "Company (Optional)",
  address: "Address",
  unit: "Unit/Suite (Optional)",
  city: "City",
  state: "State",
  country: "Country",
  zip_code: "Zip Code",
  phone: "Phone",
};

export const MAX_ACCOUNT_ADDRESSES_TO_DISPLAY_FOR_CHECKOUT = 5;

export const ACCOUNT_ADDRESSES_UPDATED = "updated";
export const ACCOUNT_ADDRESSES_CREATED = "created";
