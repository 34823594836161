import { useCallback, useRef } from "react";

const useDebounce = (callback, delay) => {
  const debounceTimeoutRef = useRef();

  const debouncedFunction = useCallback(
    (...args) => {
      clearTimeout(debounceTimeoutRef.current);
      debounceTimeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  return debouncedFunction;
};

export default useDebounce;
