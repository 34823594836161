import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

function ExclamationPointIcon({ color = "#246071", ...props }) {
  return (
    <SvgIcon
      width="52"
      height="53"
      viewBox="0 0 52 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.9859 39.3004C26.662 39.3004 27.2333 39.0717 27.7 38.6145C28.1667 38.1571 28.4 37.5905 28.4 36.9145C28.4 36.2384 28.1714 35.6671 27.7141 35.2004C27.2567 34.7337 26.6901 34.5004 26.0141 34.5004C25.338 34.5004 24.7667 34.729 24.3 35.1863C23.8333 35.6437 23.6 36.2103 23.6 36.8863C23.6 37.5624 23.8286 38.1337 24.2859 38.6004C24.7433 39.0671 25.3099 39.3004 25.9859 39.3004ZM23.6 29.7004H28.4V13.7004H23.6V29.7004ZM26.0184 52.1004C22.495 52.1004 19.1778 51.4337 16.0667 50.1004C12.9556 48.7671 10.2333 46.9337 7.89999 44.6004C5.56666 42.2671 3.73333 39.5458 2.39999 36.4365C1.06666 33.3272 0.399994 30.005 0.399994 26.4699C0.399994 22.9347 1.06666 19.6226 2.39999 16.5337C3.73333 13.4448 5.56666 10.7337 7.89999 8.40039C10.2333 6.06706 12.9546 4.23372 16.0639 2.90039C19.1732 1.56706 22.4954 0.900391 26.0305 0.900391C29.5657 0.900391 32.8778 1.56706 35.9667 2.90039C39.0556 4.23372 41.7667 6.06706 44.1 8.40039C46.4333 10.7337 48.2667 13.4498 49.6 16.5487C50.9333 19.6475 51.6 22.9587 51.6 26.482C51.6 30.0054 50.9333 33.3226 49.6 36.4337C48.2667 39.5448 46.4333 42.2671 44.1 44.6004C41.7667 46.9337 39.0506 48.7671 35.9517 50.1004C32.8528 51.4337 29.5417 52.1004 26.0184 52.1004ZM26 47.3004C31.7778 47.3004 36.6889 45.2782 40.7333 41.2337C44.7778 37.1893 46.8 32.2782 46.8 26.5004C46.8 20.7226 44.7778 15.8115 40.7333 11.7671C36.6889 7.72261 31.7778 5.70039 26 5.70039C20.2222 5.70039 15.3111 7.72261 11.2667 11.7671C7.22222 15.8115 5.19999 20.7226 5.19999 26.5004C5.19999 32.2782 7.22222 37.1893 11.2667 41.2337C15.3111 45.2782 20.2222 47.3004 26 47.3004Z"
        fill={color}
      />
    </SvgIcon>
  );
}

export default ExclamationPointIcon;
