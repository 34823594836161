import { Skeleton, Stack } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Alert from "components/Alert";
import React, { useState } from "react";
import { STRIPE_THEME } from "theme";
import StripeComponent from "./StripeComponent";
import styles from "./UpdatePaymentStripe.module.scss";

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_KEY));

function UpdatePaymentStripe({
  clientSecret,
  onSuccess,
  onError,
  onCancel,
  style,
  hideCancel,
  submitText,
  showFreeTrialText,
  planId,
  hideErrorMessage,
}) {
  const [errorMessage, setErrorMessage] = useState(false);

  if (!clientSecret) {
    return (
      <div style={style} className={styles.updatePaymentStripeContainer}>
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rectangular" height={60} />
        </Stack>
      </div>
    );
  }

  const onSuccessStripe = () => {
    if (onSuccess) {
      setErrorMessage("");
      onSuccess();
    }
  };

  const onErrorStripe = () => {
    if (onError) {
      setErrorMessage(true);
      onError();
    }
  };

  return (
    <div style={style} className={styles.updatePaymentStripeContainer}>
      {errorMessage && !hideErrorMessage && (
        <Alert
          variant="error"
          text="Could not process your payment. Please use a different card."
        />
      )}
      <Elements
        options={{ clientSecret, appearance: STRIPE_THEME }}
        stripe={stripePromise}
      >
        <StripeComponent
          onSuccess={onSuccessStripe}
          onError={onErrorStripe}
          onCancel={onCancel}
          hideCancel={hideCancel}
          submitText={submitText}
          showFreeTrialText={showFreeTrialText}
          planId={planId}
        />
      </Elements>
    </div>
  );
}

export default UpdatePaymentStripe;
