import React from "react";
import PropTypes from "prop-types";

// material UI Components
import { Box, Stack, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// components & constants:
// import Button from "components/Buttons/Button";
import AccountAddressCard from "components/Cards/AccountAddressCard";
import ModalWrapper from "components/ModalWrapper";
import Text from "components/Text";

import styles from "./ConfirmRemoveAddressModal.module.scss";

export default function ConfirmRemoveAddressModal({
  address,
  open,
  handleConfirm,
  handleClose,
}) {
  return (
    <ModalWrapper
      isOpen={open}
      handleClose={handleClose}
      data-testid="ConfirmRemoveAddressModal"
    >
      <div className={styles.ConfirmRemoveAddressModal}>
        <Stack className={styles.contentContainer}>
          <Text className={styles.textHeader} variant="h1">
            Confirm Address Removal
          </Text>

          <Box>
            <AccountAddressCard address={address} modal />
          </Box>

          <Box className={styles.actionContainer}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => handleClose()}
              className={styles.cta}
            >
              Never Mind
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleConfirm()}
              className={styles.cta}
            >
              <CloseIcon />
              Remove
            </Button>
          </Box>
        </Stack>
      </div>
    </ModalWrapper>
  );
}

ConfirmRemoveAddressModal.propTypes = {
  open: PropTypes.bool,
  handleConfirm: PropTypes.func,
};

ConfirmRemoveAddressModal.defaultProps = {
  open: false,
  handleConfirm: () => {},
};
