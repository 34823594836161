import React, { useMemo } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import poweredByStripeImg from "assets/images/powered-by-stripe.png";
import Button from "components/Buttons/Button";
import Text from "components/Text";
import { ANNUAL_PLAN_IDS, PLAN_INFO } from "constants/plans";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { usePlanChange } from "hooks";
import styles from "./ChangePlanPaymentConfirmation.module.scss";

export default function ChangePlanPaymentConfirmation(props) {
  const { shop, pendingPlanId, handleConfirm, handleCancelConfirm } = props;
  const { isDowngrading } = usePlanChange();

  const planLabel = shop?.plan_label;

  const isDownGrade = useMemo(
    () => isDowngrading(shop.plan, pendingPlanId),
    [pendingPlanId, shop.plan]
  );

  const isFree = useMemo(
    () => PLAN_INFO[pendingPlanId]?.name.includes("Free"),
    [pendingPlanId]
  );

  const isAnnual = useMemo(
    () => PLAN_INFO[pendingPlanId]?.name.includes("Annual"),
    [pendingPlanId]
  );

  if (isFree) {
    return (
      <>
        <Text
          className={`text__header ${styles.header}`}
          fontSize={24}
          // color="medium-gray"
        >
          Confirm your subscription.
        </Text>

        <Text
          fontSize={16}
          variant="h4"
          color="peach"
          className={styles.planName}
        >
          Free Plan
        </Text>

        <div className={styles.planDetailsContainer}>
          <KeyboardBackspaceIcon
            onClick={() => handleCancelConfirm()}
            className={styles.backIcon}
          />

          <div className={styles.planDetails}>
            <div className="text--align-left">
              {shop.has_trial && (
                <Text fontSize={14} className="text--bold">
                  Billed in {shop?.trial_days_remaining} day
                  {shop?.trial_days_remaining > 1 ? "s" : ""}:
                </Text>
              )}

              {isAnnual && <Text fontSize={12}>Annual savings:</Text>}
            </div>
            <div className="text--align-right">
              <Text fontSize={14} className="text--bold">
                ${PLAN_INFO[pendingPlanId]?.price}/{isAnnual ? "yr" : "mo"}
              </Text>
              {isAnnual && (
                <Text>${PLAN_INFO[pendingPlanId]?.annual_savings}/yr</Text>
              )}
            </div>
          </div>

          <Button
            onClick={() => handleConfirm()}
            color="primary"
            variant="contained"
            className="button text--nunito button__change-plan"
            hasChevron
            fullWidth
          >
            Confirm
          </Button>
          <Text
            onClick={() => handleCancelConfirm()}
            fontSize={10}
            className={styles.keepCurrentPlanText}
          >
            Keep current plan <ChevronRightIcon />
          </Text>
        </div>

        <img
          className={styles.poweredByStripe}
          src={poweredByStripeImg}
          alt="powered by stripe"
        />

        <Text fontSize={10} className={styles.TandC}>
          By updating your account, you agree to Blanka&apos;s{" "}
          <a
            target="__blank"
            href="https://blankabrand.com/blanka-terms-and-conditions/"
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            target="__blank"
            href="https://faq.blankabrand.com/en/articles/4880267-how-does-blanka-handle-returns"
          >
            Refund & Cancellation Policy
          </a>
        </Text>
      </>
    );
  }

  return (
    <>
      <Text
        className={`text__header ${styles.header}`}
        fontSize={24}
        // color="medium-gray"
      >
        Confirm your subscription.
      </Text>

      <Text
        fontSize={16}
        variant="h4"
        color="peach"
        className={styles.planName}
      >
        {PLAN_INFO[pendingPlanId]?.name}
      </Text>

      <div className={styles.planDetailsContainer}>
        <KeyboardBackspaceIcon
          onClick={() => handleCancelConfirm()}
          className={styles.backIcon}
        />

        <div className={styles.planDetails}>
          <div className="text--align-left">
            {shop.has_trial && (
              <Text fontSize={14} className="text--bold">
                Billed in {shop?.trial_days_remaining} days:
              </Text>
            )}
            {!shop.has_trial && (
              <Text fontSize={14} className="text--bold">
                Billed now:
              </Text>
            )}
            {isAnnual && <Text fontSize={12}>Annual savings:</Text>}
          </div>
          <div className="text--align-right">
            <Text fontSize={14} className="text--bold">
              ${PLAN_INFO[pendingPlanId]?.price}/{isAnnual ? "yr" : "mo"}
            </Text>
            {isAnnual && (
              <Text>${PLAN_INFO[pendingPlanId]?.annual_savings}/yr</Text>
            )}
          </div>
        </div>

        {shop.has_trial && (
          <p className="text--fs-10 text--align-left">
            After your free trial ends, you will automatically be charged $
            {PLAN_INFO[pendingPlanId]?.price}{" "}
            {isAnnual ? "annually" : "monthly"} until you cancel your
            subscription.
          </p>
        )}

        {!shop.has_trial && (
          <p className="text--fs-10 text--align-left">
            You will be charged ${PLAN_INFO[pendingPlanId]?.price}{" "}
            {isAnnual ? "annually" : "monthly"}
            until you cancel your subscription.
          </p>
        )}

        <Button
          onClick={() => handleConfirm()}
          color="primary"
          variant="contained"
          className="button text--nunito button__change-plan"
          hasChevron
          fullWidth
        >
          {isDownGrade ? "Downgrade" : "Upgrade"}
        </Button>
        <Text
          onClick={() => handleCancelConfirm()}
          fontSize={10}
          className={styles.keepCurrentPlanText}
        >
          Keep current plan <ChevronRightIcon />
        </Text>
      </div>

      <img
        className={styles.poweredByStripe}
        src={poweredByStripeImg}
        alt="powered by stripe"
      />

      <Text fontSize={10} className={styles.TandC}>
        By updating your account, you agree to Blanka&apos;s{" "}
        <a
          target="__blank"
          href="https://blankabrand.com/blanka-terms-and-conditions/"
        >
          Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          target="__blank"
          href="https://faq.blankabrand.com/en/articles/4880267-how-does-blanka-handle-returns"
        >
          Refund & Cancellation Policy
        </a>
      </Text>
    </>
  );
}
