/* eslint no-param-reassign: 0 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BRAND_PLATE_STATUS,
  BRAND_PLATE_STATUS_V2,
} from "constants/brandPlate";

// eslint-disable-next-line import/no-cycle
import {
  getBrandPlateOrders,
  getBrandPlateOrdersAssets,
  updateBrandPlateOrders,
} from "../api/endpoints/ordersApi";

// eslint-disable-next-line consistent-return
export const getBrandPlateOrdersAsync = createAsyncThunk(
  "brandPlateOrderList",
  async () => {
    try {
      const response = await getBrandPlateOrders();
      return response.data;
    } catch (error) {
      return "Error";
    }
  }
);

export const getBrandPlateOrdersAssetsAsync = createAsyncThunk(
  "brandPlateOrderAssetsList",
  async (orderId) => {
    try {
      const response = await getBrandPlateOrdersAssets(orderId);
      return response.data;
    } catch (error) {
      return "Error";
    }
  }
);

export const updateBrandPlateOrdersAsync = createAsyncThunk(
  "updateBrandPlateOrderAssets",
  async (payload) => {
    try {
      const { orderId, data } = payload;
      const response = await updateBrandPlateOrders(orderId, data);
      return response.data;
    } catch (error) {
      return "Error";
    }
  }
);

export const brandPlateSlice = createSlice({
  name: "brandPlate",
  initialState: {
    currentOrder: {},
    currentOrderAssets: [],
    orderAssetsStatus: "loading",
    ordersStatus: "loading",
    orders: [],
    step: BRAND_PLATE_STATUS_V2.UPLOAD_YOUR_LOGO[0],
    latestApprovedOrder: {},
  },
  reducers: {
    setCurrentOrder(state, action) {
      state.currentOrder = action.payload;
    },
    setStep(state, action) {
      state.step = action.payload;
    },
    getLatestApprovedOrder(state) {
      state.latestApprovedOrder = state.orders.find(
        (order) => order.status === BRAND_PLATE_STATUS_V2.APPROVED[0]
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrandPlateOrdersAsync.pending, (state) => {
        state.ordersStatus = "loading";
      })
      .addCase(getBrandPlateOrdersAsync.fulfilled, (state, action) => {
        state.ordersStatus = "idle";

        // const [paymentRequiredStep] = BRAND_PLATE_STATUS.PAYMENT_REQUIRED;

        if (action.payload.length > 0) {
          [state.currentOrder] = action.payload;

          if (state.currentOrder.status === BRAND_PLATE_STATUS.APPROVED[0]) {
            state.orders = action.payload;
          } else state.orders = action.payload.splice(1); // remove the first element of the list
        } else {
          const [stepStatus] = BRAND_PLATE_STATUS_V2.UPLOAD_YOUR_LOGO;
          state.step = stepStatus;
          // this flow is for v1
          // state.step = paymentRequiredStep;
        }
      })
      .addCase(getBrandPlateOrdersAssetsAsync.pending, (state, action) => {
        state.orderAssetsStatus = "loading";
      })
      .addCase(getBrandPlateOrdersAssetsAsync.fulfilled, (state, action) => {
        state.orderAssetsStatus = "idle";

        state.currentOrderAssets = action.payload;
      })
      .addCase(updateBrandPlateOrdersAsync.fulfilled, (state, action) => {
        state.currentOrder = action.payload;
      });
  },
});

export const { setStep, getLatestApprovedOrder, setCurrentOrder } =
  brandPlateSlice.actions;

export const selectCurrentOrder = (state) => state.brandPlate.currentOrder;

export const selectOrders = (state) => state.brandPlate.orders;

export const selectCurrentAssets = (state) =>
  state.brandPlate.currentOrderAssets;

export const selectLatestApprovedOrder = (state) =>
  state.brandPlate.latestApprovedOrder;

export const selectBrandPlateOrderStatus = (state) =>
  state.brandPlate.ordersStatus;

export const selectStep = (state) => state.brandPlate.step;

export const setAssets = () => (dispatch, getState) => {
  const { id } = selectCurrentOrder(getState());
  if (id !== undefined) {
    dispatch(getBrandPlateOrdersAssetsAsync(id));
  }
};

export default brandPlateSlice.reducer;
