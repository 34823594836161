import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function DashedIncompleteCheckMarkIcon(props) {
  return (
    <SvgIcon
      {...props}
      className="icon"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10.1172"
        r="9.5"
        stroke="#979797"
        strokeDasharray="2 2"
        fill="transparent"
      />
    </SvgIcon>
  );
}

export default DashedIncompleteCheckMarkIcon;
