import React, { useRef } from "react";

// MUI components:
import { TextField } from "@mui/material";

// components and constants:
import Text from "components/Text";
import { COUNTRIES } from "constants/countries";

// third-party libraries:
import { Controller } from "react-hook-form";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";

import styles from "./AddressAutocomplete.module.scss";

const libraries = ["places"];

const mapFormattedCountries = () =>
  COUNTRIES.map((country) => country.code.toLowerCase());

const autoCompleteFieldsMapped = (place) => {
  const addressObj = {};

  if (!place || !place[0] || !place[0].address_components) {
    // return empty object if place data is not valid
    return addressObj;
  }

  place[0].address_components.forEach((component) => {
    switch (component.types[0]) {
      case "street_number":
        addressObj.streetNumber = component.long_name;
        break;
      case "route":
        addressObj.streetName = component.long_name;
        break;
      case "sublocality_level_1":
        addressObj.neighborhood = component.long_name;
        break;

      // This section treats the city name according to a simple example:
      // USA and Canada uses "locality" for city.
      // Brazil uses "administrative_area_level_2" for city.
      case "locality":
        addressObj.city = component.long_name;
        break;
      case "administrative_area_level_2":
        if (!addressObj.city) {
          addressObj.city = component.long_name;
        }
        break;

      case "administrative_area_level_1":
        addressObj.state = component.short_name;
        break;
      case "country":
        addressObj.country = {
          name: component.long_name,
          code: component.short_name,
        };
        break;
      case "postal_code":
        addressObj.zipCode = component.long_name;
        break;
      default:
        break;
    }
  });

  // Combine street number and street name into a full address
  addressObj.fullAddress = addressObj.streetNumber
    ? `${addressObj.streetNumber} ${addressObj.streetName}`
    : addressObj.streetName;

  return addressObj;
};

// https://github.com/JustFly1984/react-google-maps-api/issues/70#issuecomment-542203340
class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true;
    const isBrowser = typeof document !== "undefined"; // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded =
      window.google &&
      window.google.maps &&
      document.querySelector("body.first-hit-completed"); // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        console.error("google api is already presented");
        return;
      }

      this.isCleaningUp().then(this.injectScript);
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true });
    }
  }
}

export default function AddressAutocomplete(props) {
  const {
    control,
    name,
    label,
    rules,
    defaultValue = "",
    handleAutoCompleteAddress,
  } = props;

  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const place = inputRef.current.getPlaces();
    if (place) {
      const autoCompleteObj = autoCompleteFieldsMapped(place);
      handleAutoCompleteAddress(autoCompleteObj);
    }
  };

  return (
    <div>
      <LoadScriptOnlyIfNeeded
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={libraries}
        region={mapFormattedCountries()}
        id="load-script-only-needed"
      >
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          render={({ field, fieldState: { error } }) => (
            <div className={styles.customAutocomplete}>
              <Text className={styles.label}>{label}</Text>
              <StandaloneSearchBox
                onLoad={(ref) => (inputRef.current = ref)}
                onPlacesChanged={handlePlaceChanged}
              >
                <TextField
                  {...field}
                  aria-label={label}
                  variant="outlined"
                  error={!!error}
                  helperText={error ? error.message : ""}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder=""
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </StandaloneSearchBox>
            </div>
          )}
        />
      </LoadScriptOnlyIfNeeded>
    </div>
  );
}
