import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Text from "components/Text";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Button from "components/Buttons/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import {
  getBillingAsync,
  resetPages,
  selectAllDateFilters,
  selectPageNumber,
  selectPaymentHistory,
  selectStatus,
  setPreviousPage,
} from "state/billingSlice";
import moment from "moment";
import { MenuItem, Select } from "@mui/material";
import styles from "./PaymentHistory.module.scss";

function formatDateTime(inputDateTime) {
  const momentObj = moment(inputDateTime, "YYYY-MM-DD HH:mm:ssZ");
  return momentObj.format("MMM DD, YYYY");
}

function AccountPaymentHistoryView() {
  const paymentHistory = useSelector(selectPaymentHistory);
  const pageNumber = useSelector(selectPageNumber);
  const allDateFilters = useSelector(selectAllDateFilters);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();

  const [dateFilter, setDateFilter] = useState("last_six_months");
  const [today] = useState(moment());

  const handleChange = (event) => {
    setDateFilter(event.target.value);
    dispatch(resetPages());
    dispatch(
      getBillingAsync({
        page: null,
        date: today.toISOString(),
        limit: 10,
        dateFilter: event.target.value,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getBillingAsync({
        page: paymentHistory.nextPage,
        limit: 10,
        date: today.toISOString(),
        dateFilter,
      })
    );
  }, []);

  return (
    <div className={styles.billingList}>
      <div className="profile-billing-template root">
        <Card className="card" variant="outlined">
          <div className={styles.titleContainer}>
            <Text className={styles.title}>Payment History</Text>
            <Select
              className={styles.select}
              labelId="date-filter"
              id="date-filter"
              value={dateFilter}
              onChange={handleChange}
            >
              {allDateFilters.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={index} value={item.query}>
                  {item.value.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.header}>Payment Invoice</div>
            <div className={styles.header}>Date</div>

            <div className={styles.header}>amount</div>

            <div className={styles.header}>order type</div>
            <div className={styles.header} />
          </div>
          {pageNumber > 0 &&
            status === "idle" &&
            paymentHistory.pages[pageNumber - 1].results.map((row) => (
              <div key={row.payment_invoice} className={styles.itemContainer}>
                <div className={styles.itemLabel}>Payment Invoice</div>
                <div className={styles.item}>
                  {row.payment_invoice.slice(-8)}
                </div>
                <div className={styles.itemLabel}>Date</div>
                <div className={styles.item}>{formatDateTime(row.date)}</div>
                <div className={styles.itemLabel}>amount</div>
                <div className={styles.item}>
                  ${row.amount.toFixed(2)}{" "}
                  {row.amount_refunded > 0 && (
                    <Tooltip title={`Refunded: $-${row.amount_refunded}`}>
                      <IconButton className={styles.refundIcon}>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
                <div className={styles.itemLabel}>order type</div>
                <div className={styles.item}>{row.order_type}</div>
                <div className={styles.item}>
                  <div className={styles.actionButton}>
                    {row.url && (
                      <a href={`${row.url}`} target="_blank" rel="noreferrer">
                        {"view details >"}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          <div className={styles.footer}>
            <div className={styles.paginationControls}>
              {status === "idle" && paymentHistory.pageNumber > 1 && (
                <div className={styles.paginationControl}>
                  <Button
                    color="primary"
                    variant="text"
                    className=""
                    onClick={() => dispatch(setPreviousPage())}
                  >
                    {"< prev"}
                  </Button>
                </div>
              )}
              {status === "idle" && paymentHistory.nextPage !== null && (
                <div className={styles.paginationControl}>
                  <Button
                    color="primary"
                    variant="text"
                    className=""
                    onClick={() =>
                      dispatch(
                        getBillingAsync({
                          page: paymentHistory.nextPage,
                          dateFilter,
                          date: today.toISOString(),
                        })
                      )
                    }
                  >
                    {"next >"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default AccountPaymentHistoryView;
