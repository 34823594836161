import { Edit, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import Text from "components/Text";
import React, { useEffect, useState } from "react";

import Button from "components/Buttons/Button";
import useLogoCustomize from "hooks/useLogoCustomize";
import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "routes/routePaths";
import cleanCustomizerInputs from "utils/cleanValueJson";
import cn from "utils/cn";
import getImageDimensions from "utils/getImageDimensions";
import styles from "./BrandSetupFormCustomize.module.scss";

function BrandSetupFormCustomize({
  attributes,
  setSelectedValues,
  selectedValues,
  blocker,
  setIsDirty,
  onConfirm,
  isSubmitting,
  defaultValues,
  setErrors,
  activeFilter,
}) {
  const {
    logoExceedsContainer,
    withError,
    error,
    setImageType,
    setLogoRotation,
    setLogoSize,
    setActiveAccordion,
    setLogo,
  } = useLogoCustomize();
  const [expanded, setExpanded] = useState(
    logoExceedsContainer ? "rotations" : "size"
  );
  const [isNotDefault, setIsNotDefault] = useState(false);
  const navigate = useNavigate();

  const handleChange = (value, key) => {
    setIsDirty(true);
    setSelectedValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    const fetchDimensions = async () => {
      if (selectedValues["logo-type"]) {
        const selectedLogoType = attributes
          ?.find(({ attribute }) => attribute?.slug === "logo-type")
          ?.options?.find(
            (option) => option?.value_json === selectedValues["logo-type"]
          );

        if (selectedLogoType?.file_url) {
          try {
            const dimensions = await getImageDimensions(
              selectedLogoType.file_url
            );

            setLogo({
              file_url: selectedLogoType.file_url,
              height: dimensions.height,
              width: dimensions.width,
            });
          } catch (error) {
            console.error("Error fetching image dimensions:", error);
          }
        }
      }
    };

    fetchDimensions();
  }, [selectedValues["logo-type"], attributes]);

  useEffect(() => {
    if (withError) {
      setErrors(error);
    } else {
      setErrors({});
    }
  }, [withError, error]);

  useEffect(() => {
    if (
      selectedValues.size === defaultValues.size &&
      selectedValues.rotations === defaultValues.rotations &&
      selectedValues["logo-type"] === defaultValues["logo-type"]
    ) {
      setIsNotDefault(false);
    } else {
      setIsNotDefault(true);
    }
  }, [selectedValues, defaultValues]);

  useEffect(() => {
    const sizeOption = attributes?.find(
      ({ attribute }) => attribute?.slug === "size"
    )?.options?.[selectedValues?.size];

    const height = sizeOption?.value_json?.height || "auto";
    const width = sizeOption?.value_json?.width || "auto";

    setLogoRotation(selectedValues?.rotations);
    setLogoSize({ logoHeight: height, logoWidth: width });
    setImageType(selectedValues["logo-type"]);
  }, [selectedValues, activeFilter]);

  const renderInputs = (type, inputs) => {
    const cleanedInputs = cleanCustomizerInputs(inputs);
    const optionsLength = cleanedInputs?.length ?? 3;

    switch (type) {
      case "size":
        return (
          <Slider
            color="secondary"
            aria-label="sizes-slider"
            defaultValue={0}
            value={selectedValues[type] || ""}
            onChange={(event) => {
              handleChange(event.target.value, type);
            }}
            step={null}
            valueLabelDisplay="off"
            marks={cleanedInputs?.map((input, index) => ({
              ...input,
              value: index,
              label: (
                <SliderLabel
                  label={input?.label}
                  size={input?.value_json?.width_mm}
                />
              ),
            }))}
            max={optionsLength - 1}
            className={styles.formSlider}
            classes={{
              mark: styles.formSliderMark,
              root: styles.formSliderRoot,
              thumb: styles.formSliderThumb,
            }}
          />
        );

      case "rotations":
        return cleanedInputs
          .filter((input) => input?.value_json !== "auto")
          .map((input) => (
            <RadioGroup
              aria-labelledby={`radio-buttons-group-${type}`}
              name={`radio-buttons-group-${type}`}
              value={String(selectedValues[type]) || ""}
              onChange={(event) => handleChange(event.target.value, type)}
            >
              <FormControlLabel
                value={String(input?.value_json)}
                control={<Radio color="secondary" />}
                label={
                  <Text
                    textTransform="capitalize"
                    fontSize={14}
                    variant="body1"
                    color="gray"
                  >
                    {input?.label}
                  </Text>
                }
                className={styles.formRadio}
              />
            </RadioGroup>
          ));

      case "logo-type":
        return cleanedInputs
          .filter((option) => !!option?.file_url)
          .map((input) => {
            const isInverted = input?.name?.includes("Inverted");

            return (
              <RadioGroup
                aria-labelledby={`radio-buttons-group-${type}`}
                name={`radio-buttons-group-${type}`}
                value={selectedValues[type] || ""}
                onChange={(event) => {
                  handleChange(event.target.value, type);
                }}
                className={styles.radioLogoTypes}
              >
                <FormControlLabel
                  value={input?.value_json}
                  control={<Radio color="secondary" />}
                  label={
                    <Box
                      className={cn(
                        styles.radioLogoTypesOption,
                        isInverted && styles.radioLogoTypesOptionInverted
                      )}
                    >
                      <img
                        className={styles.radioLogoTypesOptionImage}
                        src={input?.file_url}
                        alt={input?.label}
                      />
                      <Text
                        className={styles.radioLogoTypesOptionLabel}
                        textTransform="capitalize"
                        fontSize={14}
                        variant="body1"
                        color="gray"
                      >
                        {input?.label}
                      </Text>
                    </Box>
                  }
                  className={styles.formRadio}
                />
              </RadioGroup>
            );
          });
      default:
        return cleanedInputs.map((input) => (
          <RadioGroup
            aria-labelledby={`radio-buttons-group-${type}`}
            name={`radio-buttons-group-${type}`}
            value={selectedValues[type] || ""}
            onChange={(event) => handleChange(event.target.value, type)}
          >
            <FormControlLabel
              value={input?.value_json}
              control={<Radio color="secondary" />}
              label={
                <Text
                  textTransform="capitalize"
                  fontSize={14}
                  variant="body1"
                  color="gray"
                >
                  {input?.label}
                </Text>
              }
              className={styles.formRadio}
            />
          </RadioGroup>
        ));
    }
  };

  const renderForm = () => {
    if (attributes) {
      return attributes.map(({ attribute, options }, index) => {
        let isCustomized;

        if (logoExceedsContainer) {
          isCustomized =
            attribute.slug === "size"
              ? false
              : selectedValues[attribute?.slug] !==
                defaultValues[attribute?.slug];
        } else {
          isCustomized =
            selectedValues[attribute?.slug] !== defaultValues[attribute?.slug];
        }

        return (
          <Accordion
            id={`accordion-${attribute?.slug}`}
            slotProps={{
              transition: { unmountOnExit: true, timeout: 300 },
            }}
            expanded={expanded === attribute?.slug}
            onChange={() => {
              setExpanded(attribute?.slug);
              setActiveAccordion(attribute?.slug);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id={`accordion-${attribute?.slug}-summary`}
              className={cn(
                styles.accordionHeader,
                expanded === attribute?.slug && styles.accordionHeaderExpanded
              )}
            >
              <Box className={styles.accordionHeaderTitle}>
                <Text
                  textTransform="uppercase"
                  variant="body1"
                  fontSize="12"
                  color="gray"
                  letterSpacing="4.8px"
                >
                  {attribute?.name}
                </Text>
                {error[attribute?.slug] && (
                  <Box className={styles.errorIcon}>!</Box>
                )}
              </Box>
              {isCustomized && (
                <Chip
                  icon={<Edit color="secondary" className={styles.icon} />}
                  className={styles.customizedChip}
                  size="small"
                  label="Customized"
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              {logoExceedsContainer && attribute.slug === "size" ? (
                <Text fontSize={14} variant="body1" color="gray">
                  Custom resizing not available
                </Text>
              ) : (
                <FormControl fullWidth>
                  {renderInputs(attribute?.slug, options)}
                </FormControl>
              )}
            </AccordionDetails>
          </Accordion>
        );
      });
    }

    return null;
  };

  return (
    <>
      <Box className={styles.formContainer}>
        <Box
          className={styles.brandSetupFormCustomizer}
          display="flex"
          flexDirection="column"
        >
          {renderForm()}
        </Box>
      </Box>

      {withError && (
        <Box mt={2}>
          {Object.values(error).map((value) => (
            <Text color="error">{value}</Text>
          ))}
        </Box>
      )}

      <Box mt={2}>
        {isNotDefault && (
          <Box
            onClick={() => {
              setIsDirty(false);
              setSelectedValues(defaultValues);
            }}
            className={styles.defaultButtonContainer}
          >
            <Text className={styles.defaultButtonText}>Reset to default</Text>
          </Box>
        )}

        <Box className={styles.formActions} display="flex" gap={2}>
          <Button
            disabled={isSubmitting || withError}
            loading={isSubmitting}
            fullWidth
            onClick={onConfirm}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
          <Button
            fullWidth
            onClick={() => {
              blocker.proceed?.();
              navigate(ROUTE_PATHS.BRAND_SETUP_REVIEW);
            }}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}

function SliderLabel({ label, size }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={1}
    >
      <Text fontSize={12} fontWeight={700}>
        {label}
      </Text>
      <Text fontSize={10}>{size}mm</Text>
    </Box>
  );
}

export default BrandSetupFormCustomize;
