import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleIcon } from "components/Icons";
import { PropTypes } from "prop-types";
import styles from "./GoogleLoginButton.module.scss";

export default function GoogleLoginButton({ handleLogin }) {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleLogin(tokenResponse),
  });

  return (
    <div className={styles.googleSsoWrapper}>
      <button
        type="button"
        onClick={() => login()}
        className={styles.googleSsoBtn}
      >
        <GoogleIcon />
        Continue with Google
      </button>
    </div>
  );
}

GoogleLoginButton.propTypes = {
  handleLogin: PropTypes.func,
};

GoogleLoginButton.defaultProps = {
  handleLogin: () => {},
};
