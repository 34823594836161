import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Modal, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import Text from "components/Text";
import img from "assets/images/icon-error.png";

import styles from "./MyLogoErrorNotification.module.scss";

function MyLogoErrorNotification(props) {
  const { isOpen, setIsOpen, onError } = props;

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      data-testid="LogoErrorModal"
      aria-labelledby="Logo Error Modal"
      aria-describedby="LogoErrorModal"
      anchor="bottom"
      open={isOpen}
      onClose={handleClose}
      className={styles.myLogoErrorNotification}
    >
      <div className={styles.body} style={{ backgroundImage: `url(${img})` }}>
        <Typography
          onClick={(e) => handleClose()}
          className={`${styles.closeButton} close__button`}
        >
          [x] close
        </Typography>
        <Stack className={styles.stackContainer}>
          <Text className={`${styles.errorText} `}>
            Oops! Your logo does not meet our requirements:
          </Text>
          <ul className={styles.fileRejection}>
            {onError?.map((e) => (
              <li key={e} className={styles.errorText}>
                <Text className={styles.errorText}>{e}</Text>
              </li>
            ))}
          </ul>
        </Stack>
      </div>
    </Modal>
  );
}

MyLogoErrorNotification.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onError: PropTypes.array,
};

MyLogoErrorNotification.defaultProps = {
  onError: [],
};

export default MyLogoErrorNotification;
