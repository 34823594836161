import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function CustomerOrderIcon(props) {
  return (
    <div className="icon-root">
      <SvgIcon {...props} className="icon icon--hover" viewBox="0 0 20 20">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Customer_Orders_Icon" data-name="Customer Orders Icon">
            <rect
              className="cls-1"
              width="18"
              height="18"
              style={{ fill: "#fff" }}
            />
            <g id="Customer_Colour" data-name="Customer Colour">
              <path
                className="cls-2"
                style={{ fill: "#5e5f5f" }}
                fill="#5e5f5f"
                d="M13.16,4.84V4.57A4.38,4.38,0,0,0,9,0,4.38,4.38,0,0,0,4.84,4.57v.27H1.61v5.77a7.39,7.39,0,0,0,14.78,0V4.84ZM9,16.12a5.51,5.51,0,0,1-5.51-5.51V6.72H4.84v3.89a.94.94,0,1,0,1.88,0V6.72h4.56v3.89a.94.94,0,0,0,1.88,0V6.72h1.35v3.89A5.51,5.51,0,0,1,9,16.12ZM6.72,4.57A2.51,2.51,0,0,1,9,1.88a2.51,2.51,0,0,1,2.28,2.69v.27H6.72Z"
              />
              <path
                style={{ fill: "#e88a80" }}
                className="cls-3"
                d="M13.16,6.72v3.89a.94.94,0,0,1-.94.94.72.72,0,0,1-.32-.08,5.51,5.51,0,0,1-5.18,3.66,5.23,5.23,0,0,1-1-.09,6.27,6.27,0,0,0,.86.51,5.47,5.47,0,0,0,7.9-4.94V6.72Z"
              />
            </g>
          </g>
        </g>
      </SvgIcon>

      <SvgIcon {...props} className="icon icon--normal" viewBox="0 0 20 20">
        <g id="Layer_2_00000096738397761483228810000008459743171045333929_">
          <g id="Customer_Orders_Icon">
            <path
              id="Customer_Grey"
              className="st1"
              d="M13.2,4.8V4.6C13.3,2.2,11.4,0.1,9,0C6.6,0.1,4.7,2.2,4.8,4.6v0.3H1.6v5.8
			C1.6,14.7,4.9,18,9,18c4.1,0,7.4-3.3,7.4-7.4V4.8H13.2z M6.7,4.6C6.6,3.2,7.6,2,9,1.9c1.4,0.1,2.4,1.3,2.3,2.7v0.3H6.7V4.6z
			 M14.5,10.6c-0.2,3-2.8,5.4-5.8,5.2c-2.8-0.2-5-2.4-5.2-5.2V6.7h1.3v3.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9V6.7h4.6v3.9
			c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V6.7h1.4L14.5,10.6z"
            />
          </g>
        </g>
      </SvgIcon>
    </div>
  );
}
