import PropTypes from "prop-types";
import React from "react";

import { Box, Typography } from "@mui/material";
import ModalWrapper from "components/ModalWrapper";

import Button from "components/Buttons/Button";
import styles from "./ConfirmModal.module.scss";

// turn this into reusable component once i have github - so we wont keep recreating confirm modals even with confirm delete
export default function ConfirmModal({
  open,
  handleClose,
  handleConfirm,
  isLoading,
}) {
  return (
    <ModalWrapper
      isOpen={open}
      handleClose={handleClose}
      data-testid="ConfirmModal"
      className={styles.confirmModal}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        rowGap={2}
        py={2}
        px={4}
      >
        <Box>
          <Typography
            variant="h1"
            fontSize={{ xs: 20, md: 24 }}
            color="#5E5E5E"
            textAlign="center"
          >
            Lock in your logo!
          </Typography>
          <Typography
            display="block"
            mt={2}
            variant="p"
            fontSize={{ xs: 12, md: 16 }}
            color="#5E5E5E"
            maxWidth={440}
            textAlign="justify"
          >
            By clicking &quot;ORDER BRAND PLATE&quot;, we will produce your free
            Brand Plate with the logo provided. Please note, we allow 2
            revisions to change the size, color or rotation of your logo without
            a charge. If you want to replace your logo in the future with a new
            logo, additional costs will apply.
          </Typography>
        </Box>

        <Box className={styles.confirmModalActions}>
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={handleConfirm}
            style={{ minWidth: "120px" }}
            loading={isLoading}
          >
            Order Brand Plate
          </Button>
          <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={handleClose}
            style={{ minWidth: "120px" }}
            disabled={isLoading}
          >
            Never mind
          </Button>
        </Box>
      </Box>
    </ModalWrapper>
  );
}

ConfirmModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  open: false,
  isLoading: false,
};
