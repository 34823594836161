import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "routes/routePaths";
// MUI Components
import { Box, Stack } from "@mui/material";

// Custom Components
import SuccessImage from "assets/images/brand-setup/circle-with-image.svg";
import Button from "components/Buttons/Button";
import Text from "components/Text";

import { QUERY } from "api";
import queryClient from "api/queryClient";
import BrandSetupWrapper from "components/BrandSetup/BrandSetupWrapper";
import { BRAND_SETUP_STEPS } from "constants/constants";
import { useShopMutations } from "hooks/mutations/useShopMutations";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "state/cartSlice";
import styles from "./BrandSetupSuccessView.module.scss";

export default function BrandSetupSuccessView() {
  const { shop } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { updateShopMutation } = useShopMutations();

  useEffect(() => {
    if (updateShopMutation.isSuccess) {
      navigate(ROUTE_PATHS.HOME);
    }
  }, [updateShopMutation.isSuccess]);

  const handleCta = async () => {
    setIsLoading(true);
    dispatch(clearCart());
    await queryClient.refetchQueries([QUERY.getUser]);
    await updateShopMutation.mutateAsync({
      id: shop.id,
      brand_setup_step: BRAND_SETUP_STEPS.COMPLETE,
    });
  };

  return (
    <BrandSetupWrapper>
      <Box className={styles.brandSetupSuccessView}>
        <Box className={styles.confetti1} />
        <Box className={styles.confetti2} />
        <Stack className={styles.stackContainer}>
          <Text variant="h1" className={styles.title}>
            Your branded products are ready for launch!
          </Text>
          <Box className={styles.imageContainer}>
            <img
              src={SuccessImage}
              alt="brand setup success"
              className={styles.successImg}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCta}
            loading={isLoading}
            disabled={isLoading}
          >
            Let&apos;s Go
          </Button>
        </Stack>
      </Box>
    </BrandSetupWrapper>
  );
}
