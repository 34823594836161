import PageHeader from "components/PageHeader";
import { BRAND_SETUP_STEPS } from "constants/constants";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTE_PATHS from "routes/routePaths";
import styles from "./BrandSetupWrapper.module.scss";

function BrandSetupWrapper({
  children,
  pageTitle = "My Brand Setup",
  wrapperClassName,
  leftAction,
}) {
  const { shop } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    // don't redirect on customize form
    if (!pathname.includes("customize")) {
      switch (shop.brand_setup_step) {
        case BRAND_SETUP_STEPS.UPLOAD:
          navigate(ROUTE_PATHS.BRAND_SETUP_UPLOAD);
          break;

        case BRAND_SETUP_STEPS.MOCKUP:
          navigate(ROUTE_PATHS.BRAND_SETUP_MOCKUPS_CHECKOUT);
          break;

        case BRAND_SETUP_STEPS.REVIEW:
          navigate(ROUTE_PATHS.BRAND_SETUP_REVIEW);
          break;

        case BRAND_SETUP_STEPS.SUCCESS:
          navigate(ROUTE_PATHS.BRAND_SETUP_SUCCESS);
          break;

        // case BRAND_SETUP_STEPS.COMPLETE:
        //   navigate(ROUTE_PATHS.BRAND_SETUP);
        //   break;

        default:
          break;
      }
    }
  }, [shop, pathname]);

  return (
    <div className={styles.brandSetupWrapper}>
      <PageHeader leftAction={leftAction} title={pageTitle} />
      <div className={wrapperClassName}>{children}</div>
    </div>
  );
}

export default BrandSetupWrapper;
