import React from "react";
// import { Link } from "react-router-dom";

import PublishedDiscontinuedProductNotification from "components/Notifications/PublishedDiscontinuedProductNotification/PublishedDiscontinuedProductNotification";

import styles from "./HeaderBar.module.scss";

export default function HeaderBar() {
  return (
    <PublishedDiscontinuedProductNotification />

    // <Link to="/brand-plate">
    //   <div data-testid="HeaderBar" className={`${styles.headerBar}`}>
    //     <span className={styles.boldText}>BRAND PLATE SALE - </span>
    //     <span className={styles.littleText}>
    //       GET 20% OFF BRAND PLATES - SAVE NOW
    //     </span>
    //   </div>
    // </Link>
  );
}
