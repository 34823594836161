import React from "react";
import PropTypes from "prop-types";

import BlankaMyLogoPreview from "assets/images/blanka-my-logo-preview.png";
import { LOGO_INVERTED, LOGO_THIN } from "constants/generatorLogo";
import MyLogoStepTemplate from "./MyLogoStepTemplate";

function MyLogoStepThree(props) {
  const { handleNext, handlePrevious } = props;

  return (
    <MyLogoStepTemplate
      step="Step 2.1"
      subTitle="Dark product preview"
      lead="We recommend uploading a light logo"
      caption="Preview of your logo on dark products"
      imagePreview={BlankaMyLogoPreview}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      logoType={LOGO_INVERTED}
    />
  );
}

MyLogoStepThree.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
};

MyLogoStepThree.defaultProps = {};

export default MyLogoStepThree;
