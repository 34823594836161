/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
/* eslint-disable import/no-cycle */
import {
  getShopSettings,
  updateShopAutomaticPayments,
  createShopSettings,
} from "api/endpoints/shopApi";

export const setAutomaticPaymentsThunk = createAsyncThunk(
  "shopSettings/shopAutomaticPayments",
  async (value, thunkAPI) => {
    try {
      const response = await updateShopAutomaticPayments(value);
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getShopSettingsThunk = createAsyncThunk(
  "shopSettings/getShopSettings",
  async (value, thunkAPI) => {
    try {
      const response = await getShopSettings();
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const createShopSettingsThunk = createAsyncThunk(
  "shopSettings/createShopSettings",
  async (value, thunkAPI) => {
    try {
      const response = await createShopSettings(value);
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const initialState = {
  shopAutomaticPayments: {
    enabled: false,
    loading: false,
    error: false,
  },
  settings: {
    values: {},
    loading: true,
    error: false,
    errorMessage: "",
  },
};

export const shopSettingsSlice = createSlice({
  name: "shopSettings",
  initialState,
  reducers: {
    setAutomaticPayments: (state, action) => {
      state.shopAutomaticPayments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAutomaticPaymentsThunk.pending, (state) => {
        state.shopAutomaticPayments.loading = true;
        state.shopAutomaticPayments.error = false;
      })
      .addCase(setAutomaticPaymentsThunk.rejected, (state) => {
        state.shopAutomaticPayments.loading = false;
        state.shopAutomaticPayments.error = true;
      })
      .addCase(setAutomaticPaymentsThunk.fulfilled, (state, action) => {
        state.shopAutomaticPayments.enabled = action.payload.data;
        state.shopAutomaticPayments.loading = false;
        state.shopAutomaticPayments.error = false;
      })
      .addCase(getShopSettingsThunk.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          [state.settings.values] = action.payload;
        }
        state.settings.loading = false;
        state.settings.error = false;
      })
      .addCase(getShopSettingsThunk.pending, (state, action) => {
        state.settings.loading = true;
        state.settings.error = false;
      })
      .addCase(getShopSettingsThunk.rejected, (state, action) => {
        state.settings.loading = false;
        state.settings.error = true;
      })
      .addCase(createShopSettingsThunk.fulfilled, (state, action) => {
        state.settings.loading = false;
        state.settings.error = false;
        state.settings.values = action.payload;
      })
      .addCase(createShopSettingsThunk.pending, (state, action) => {
        state.settings.loading = true;
        state.settings.error = false;
      })
      .addCase(createShopSettingsThunk.rejected, (state, action) => {
        state.settings.loading = false;
        state.settings.error = true;
        state.settings.errorMessage = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setAutomaticPayments } = shopSettingsSlice.actions;

export const selectShopSettings = (state) => state.shopSettings.settings;

export default shopSettingsSlice.reducer;
