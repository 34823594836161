import PropTypes from "prop-types";
import React from "react";
// MUI:
import { Box, Step, StepConnector, StepLabel, Stepper } from "@mui/material";

// Components:
import Text from "components/Text";
import { BRAND_PLATE_STATUS } from "constants/brandPlate";
import styles from "./BrandPlateStatusIndicator.module.scss";

function BrandPlateStatusIndicator({ steps, currentStep }) {
  // Filter out the step if the condition is met and it's not the current step
  const filteredSteps = steps.filter(
    (step) =>
      step.id !== BRAND_PLATE_STATUS.IN_REVISION[0] || step.id === currentStep
  );

  // Calculate the active step index
  const activeStep = filteredSteps.findIndex((step) => step.id === currentStep);

  return (
    <Box className={styles.brandPlateStatusIndicator}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        orientation="vertical"
        classes={{
          root: styles.stepperRoot,
          vertical: styles.stepperVertical,
        }}
        connector={
          <StepConnector
            classes={{
              root: styles.stepConnectorRoot,
            }}
          />
        }
      >
        {filteredSteps.map((step) => (
          <Step key={step.title}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: styles.stepIconRoot,
                  active: styles.stepIconActive,
                  completed: styles.stepIconCompleted,
                  error: styles.stepIconError,
                },
              }}
              classes={{
                root: styles.stepLabelRoot,
                active: styles.stepLabelActive,
                completed: styles.stepLabelCompleted,
                error: styles.stepLabelError,
                alternativeLabel: styles.stepLabelAlternativeLabel,
              }}
            >
              {step.title}
              {step.id === currentStep && (
                <Text className={styles.subtitle}>{step.subtitle}</Text>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

BrandPlateStatusIndicator.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
    })
  ).isRequired,
  currentStep: PropTypes.string.isRequired,
};

export default BrandPlateStatusIndicator;
