import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function QuoteOpenIcon(props) {
  return (
    <SvgIcon {...props} width="35" height="28" viewBox="0 0 35 28" fill="none">
      <path
        id="open quote"
        d="M15.6641 21.1719C15.6641 22.8385 14.987 24.2969 13.6328 25.5469C12.3047 26.7969 10.7292 27.4219 8.90625 27.4219C6.04167 27.4219 3.84115 26.4714 2.30469 24.5703C0.768229 22.6693 0 20.0391 0 16.6797C0 13.5547 1.3151 10.4036 3.94531 7.22656C6.60156 4.04948 9.79167 1.64062 13.5156 0L15.2344 2.77344C12.2917 4.20573 9.98698 5.8724 8.32031 7.77344C6.65365 9.67448 5.6901 11.9792 5.42969 14.6875H7.61719C9.23177 14.6875 10.5469 14.8698 11.5625 15.2344C12.5781 15.599 13.3984 16.1068 14.0234 16.7578C14.6224 17.3828 15.0391 18.0729 15.2734 18.8281C15.5339 19.5833 15.6641 20.3646 15.6641 21.1719ZM34.9219 21.1719C34.9219 22.8385 34.2448 24.2969 32.8906 25.5469C31.5625 26.7969 29.987 27.4219 28.1641 27.4219C25.2995 27.4219 23.099 26.4714 21.5625 24.5703C20.026 22.6693 19.2578 20.0391 19.2578 16.6797C19.2578 13.5547 20.5729 10.4036 23.2031 7.22656C25.8594 4.04948 29.0495 1.64062 32.7734 0L34.4922 2.77344C31.5495 4.20573 29.2448 5.8724 27.5781 7.77344C25.9115 9.67448 24.9479 11.9792 24.6875 14.6875H26.875C28.4896 14.6875 29.8047 14.8698 30.8203 15.2344C31.8359 15.599 32.6562 16.1068 33.2812 16.7578C33.8802 17.3828 34.2969 18.0729 34.5312 18.8281C34.7917 19.5833 34.9219 20.3646 34.9219 21.1719Z"
        fill="#D56F5B"
      />
    </SvgIcon>
  );
}
