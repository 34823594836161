import { useEffect } from "react";
import { useSearchParams as useReactRouterSearchParams } from "react-router-dom";

/**
 * Custom hook for managing URL search parameters using React Router's useSearchParams.
 *
 * @returns {object} An object containing two functions for managing URL search parameters.
 */
const useSearchParams = () => {
  const [searchParams, setSearchParams] = useReactRouterSearchParams();

  /**
   * Adds or updates a search parameter in the URL.
   *
   * @param {string} name - The name of the parameter to add or update.
   * @param {string} value - The value of the parameter.
   */
  const add = (name, value) => {
    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set(name, value);
    setSearchParams(newSearchParams);
  };

  /**
   * Removes a search parameter from the URL.
   *
   * @param {string} name - The name of the parameter to remove.
   */
  const remove = (name) => {
    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.delete(name);
    setSearchParams(newSearchParams);
  };

  return {
    add,
    remove,
  };
};

export default useSearchParams;
