import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

// MUI components:
import { Box, Stack } from "@mui/material";

// Custom components & assets:
import Button from "components/Buttons/Button";
import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import Text from "components/Text";

import { PLAN_PRICING_TABLE_INFO } from "constants/plans";

import UpgradeAlertIcon from "components/Icons/UpgradeAlertIcon";
import YourPlanUsage from "views/AccountView/components/YourPlanUsage/YourPlanUsage";
import styles from "./PaywallUpgradeModal.module.scss";

function PaywallUpgradeModal(props) {
  const { open, handleClose, planId, handlePlanChange } = props;

  const profile = useSelector((state) => state.profile);

  const featuresList = PLAN_PRICING_TABLE_INFO[0]?.features_list.map(
    (feature) => feature.feature,
  );

  const featuresListInfo = PLAN_PRICING_TABLE_INFO[planId]?.features_list.map(
    (feature) => feature.feature,
  );

  const pairedFeatures = featuresList.map((feature, index) => [
    feature,
    featuresListInfo[index],
  ]);

  const handleSeePlans = () => {
    handlePlanChange();
    handleClose();
  };

  return (
    <ModalWrapper isOpen={open} handleClose={handleClose}>
      <Stack className={styles.paywallUpgradeModal}>
        <UpgradeAlertIcon />
        <Box>
          <Text className={styles.title} variant="h2">
            Upgrade to unlock this feature
          </Text>
          <Text className={styles.body} variant="body">
            Looks like you have gone over your plan’s limits. Instantly remove
            limits and access more features by upgrading your plan.
          </Text>
        </Box>
        <Button
          color="primary"
          hasChevron
          size="small"
          className={styles.ctaFont}
          onClick={handleSeePlans}
        >
          See Plans
        </Button>
        <Box className={styles.boxContainer}>
          <Text className={styles.boxText} variant="body">
            Your current Usage
          </Text>
          <YourPlanUsage
            pairedFeatures={pairedFeatures.slice(1, 4)}
            profile={profile}
            handleOpenChangePlanModal={() => console.log("Nothing")}
            title={PLAN_PRICING_TABLE_INFO[profile?.shop?.plan]?.plan_type}
          />
        </Box>
      </Stack>
    </ModalWrapper>
  );
}

PaywallUpgradeModal.prototype = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  planId: PropTypes.number,
  handlePlanChange: PropTypes.func,
};

PaywallUpgradeModal.defaultProps = {
  open: false,
  handleClose: () => {},
  planId: 0,
  handlePlanChange: () => {},
};

export default PaywallUpgradeModal;
