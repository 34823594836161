import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function LowStockIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.71249 4.68112V1.5498H10.1092V4.68112L12.1065 4.68112L8.98498 8.73993L5.71523 4.68112L7.71249 4.68112ZM3.7971 7.14222H1.40039V13.5334H3.7971V7.14222ZM4.59687 8.74001H6.99358V13.5334H4.59687V8.74001ZM10.1886 10.3378H7.79191V13.5334H10.1886V10.3378Z"
        fill="#AB0000"
      />
    </svg>
  );
}

export default LowStockIcon;
