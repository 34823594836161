import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function UploadIcon(props) {
  return (
    <div className="icon-root">
      <SvgIcon {...props} className="icon" viewBox="0 0 20 20">
        <path
          d="M1.68287 14.6672L11.5309 14.6672L11.5309 11.0008C11.5309 10.8279 11.5966 10.6956 11.7279 10.6037C11.8637 10.5146 12.038 10.47 12.2508 10.47C12.4682 10.47 12.6425 10.5146 12.7738 10.6037C12.9096 10.6956 12.9775 10.8279 12.9775 11.0008L12.9775 14.9994C12.9775 15.2479 12.8779 15.4288 12.6787 15.5423C12.4795 15.6584 12.1671 15.7165 11.7414 15.7165L1.47233 15.7165C1.05124 15.7165 0.741086 15.6597 0.541861 15.5463C0.338109 15.4329 0.236234 15.2506 0.236234 14.9994L0.236234 11.0008C0.236234 10.8279 0.304151 10.6956 0.439986 10.6037C0.571293 10.5146 0.74335 10.47 0.956159 10.47C1.17349 10.47 1.35008 10.5146 1.48591 10.6037C1.61722 10.6956 1.68287 10.8279 1.68287 11.0008L1.68287 14.6672Z"
          fill="white"
        />
        <path
          d="M7.05447 0.277327C6.80595 0.0288134 6.40303 0.0288134 6.15452 0.277327L2.10476 4.32708C1.85624 4.57559 1.85624 4.97851 2.10476 5.22703C2.35327 5.47554 2.75619 5.47554 3.0047 5.22703L6.60449 1.62725L10.2043 5.22703C10.4528 5.47555 10.8557 5.47555 11.1042 5.22703C11.3527 4.97852 11.3527 4.5756 11.1042 4.32709L7.05447 0.277327ZM7.24084 11.9696L7.24085 0.727301L5.96813 0.727299L5.96813 11.9696L7.24084 11.9696Z"
          fill="white"
        />
      </SvgIcon>
    </div>
  );
}
