import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./SuspenseLoading.module.scss";

function SuspenseLoading() {
  return (
    <div className={styles.container}>
      <CircularProgress size={30} />
    </div>
  );
}

export default SuspenseLoading;
