import PropTypes from "prop-types";
import React, { useState } from "react";

import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import Button from "components/Buttons/Button";

import ModalWrapper from "components/ModalWrapper/ModalWrapper";

import Text from "components/Text";
import { useAnalytics } from "hooks";

import { useNavigate } from "react-router-dom";

function DunningModal({ open, handleClose, allowClose }) {
  const [stripeSuccess] = useState(false);
  const shop = useSelector((state) => state.profile.shop);
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const setDunningCookie = () => {
    // 10/1440 is the fraction of a day that represents 10 minutes
    Cookies.set("bl-dunning", true, { expires: 10 / 1440 });
    analytics.sendEvent(analytics.DUNNING_CLICK_UPDATE_BILLING, {});
  };

  return (
    <ModalWrapper
      className="modal-dunning"
      handleClose={handleClose}
      isOpen={open}
      data-testid="PaymentMethodModal"
      allowClose={allowClose}
    >
      <div className={` modal__wrapper`}>
        {!stripeSuccess && (
          <div className="text--center ">
            <Text className="text--bold text__title" color="gray" fontSize={21}>
              Oops! We could not process your latest subscription payment.
            </Text>
            <Text variant="body1" color="grey" fontSize={14}>
              To continue using the{" "}
              <span className="text--bold">{shop.plan_label} plan</span>, please
              update your billing information.
            </Text>
          </div>
        )}

        <div className="modal-container mt-25">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/profile/billing/update");
              setDunningCookie();
              handleClose();
            }}
          >
            Update my billing information
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
}

DunningModal.propTypes = {
  open: PropTypes.bool,
};

DunningModal.defaultProps = {
  open: false,
};

export default DunningModal;
