import { Box, Divider } from "@mui/material";
import { updateShop } from "api";
import bigCommerceLogo from "assets/images/big-commerce-logo.png";
import BlankaLogo from "assets/images/blanka-lipstick-your-logo-gif.gif";
import WhiteBlankaLogoImg from "assets/images/blanka-white.png";
import shopifyLogo from "assets/images/shopify-logo.png";
import wixLogo from "assets/images/wix-logo.png";
import wooCommerceLogo from "assets/images/woocommerce_logo.png";
import Button from "components/Buttons/Button";
import SuccessTag from "components/Labels/SuccessTag";
import Text from "components/Text";
import {
  SHOP_INTEGRATION_TYPE_SHOPIFY,
  SHOP_INTEGRATION_TYPE_WIX,
  SHOP_INTEGRATION_TYPE_WOOCOMMERCE,
} from "constants/constants";
import { useAnalytics } from "hooks";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useSelector } from "react-redux";
import OnboardingWrapperB from "../OnboardingWrapperB";
import styles from "./OnboardingStepSixB.module.scss";

export default function OnboardingStepSixB() {
  const shop = useSelector((state) => state.profile.shop);
  const [loading, setLoading] = useState(false);
  const analytics = useAnalytics();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNextStep = async () => {
    setLoading(true);
    await updateShop(shop.id, { onboarding_step: 6 });

    analytics.sendEvent(analytics.ONBOARDING_COMPLETE, {
      plan_id: shop.plan,
    });
    // do a reload to show loading icon
    window.location.href = "/?onboarding_complete=true";
  };

  const getLogo = () => {
    if (shop.integration_type === SHOP_INTEGRATION_TYPE_SHOPIFY) {
      return (
        <img alt="Shopify Logo" className="shopify-icon" src={shopifyLogo} />
      );
    }
    if (shop.integration_type === SHOP_INTEGRATION_TYPE_WOOCOMMERCE) {
      return (
        <img
          alt="Shopify Logo"
          className="shopify-icon"
          src={wooCommerceLogo}
        />
      );
    }
    if (shop.integration_type === SHOP_INTEGRATION_TYPE_WOOCOMMERCE) {
      return (
        <img
          alt="Woocommerce Logo"
          className="shopify-icon"
          src={wooCommerceLogo}
        />
      );
    }

    if (shop.integration_type === SHOP_INTEGRATION_TYPE_WIX) {
      return <img alt="Wix Logo" className="shopify-icon" src={wixLogo} />;
    }

    return <p />;
  };

  return (
    <OnboardingWrapperB>
      <Box className={styles.sideBox}>
        <Box className={styles.welcomeContainer}>
          <LazyLoadImage
            src={BlankaLogo}
            alt="blanka logo"
            className={styles.blankaLogoGif}
          />
          <LazyLoadImage
            src={WhiteBlankaLogoImg}
            alt="blanka logo white"
            className={styles.blankaLogo}
          />
        </Box>
      </Box>
      <Box
        className={styles.onboardingStepFiveB}
        data-testid="OnboardingStepTwoB"
      >
        <Box className={styles.confettiGif}>
          <Box className={styles.upContainer}>
            <Text className={styles.title}>
              We’re super excited to help you
              <br /> bring your brand to life!
            </Text>

            <Button
              variant="contained"
              color="primary"
              className={styles.skipButton}
              onClick={handleNextStep}
              hasChevron
              loading={loading}
            >
              Buld your product line
            </Button>
          </Box>
        </Box>
        <div className={styles.divider}>OR</div>

        <Box>
          {!loading && (
            <Box>
              <div className={styles.successTagContainer}>
                <SuccessTag>
                  <Text fontSize={18} className="text--bold text--blue">
                    Congrats, your <span>{shop.integration_type}</span> store{" "}
                    <span>{shop.url_display_name}</span> is now connected!
                  </Text>
                </SuccessTag>
              </div>

              <Text className={styles.onlineShop}>
                Already have an online store?
              </Text>
              <Text className={styles.onlineShopText}>
                Select a platform below to connect your store with one click.{" "}
              </Text>
              <Box className={styles.storesContainer}>
                <Box
                  className={`${styles.storeContainer} ${styles.storeContainerActive} ${shop.integration_type === "SHOPIFY" ? styles.active : styles.disabled}`}
                >
                  <a target="_blank">
                    <LazyLoadImage
                      src={shopifyLogo}
                      alt="shopify logo"
                      width="125"
                      height="125"
                      effect="black-and-white"
                      visibleByDefault
                      placeholderSrc={shopifyLogo}
                    />
                  </a>
                  <Text
                    fontSize={12}
                    className={`text--nunito text--bold ${styles.textShopType}`}
                  >
                    Shopify
                  </Text>
                </Box>
                <Box
                  className={`${styles.storeContainer} ${styles.storeContainerActive} ${shop.integration_type === "WOOCOMERCE" ? styles.active : styles.disabled}`}
                >
                  <a target="_blank">
                    <LazyLoadImage
                      src={wooCommerceLogo}
                      alt="woocommerce logo"
                      width="125"
                      height="125"
                      effect="black-and-white"
                      visibleByDefault
                      placeholderSrc={wooCommerceLogo}
                    />
                  </a>

                  <Text
                    fontSize={12}
                    className={`text--nunito text--bold ${styles.textShopType}`}
                  >
                    WooCommerce
                  </Text>
                </Box>

                <Box
                  className={`${styles.storeContainer} ${styles.storeContainerActive} ${shop.integration_type === "WIX" ? styles.active : styles.disabled}`}
                >
                  <a target="_blank">
                    <LazyLoadImage
                      src={wixLogo}
                      alt="wix logo"
                      width="125"
                      height="125"
                      effect="black-and-white"
                      visibleByDefault
                      placeholderSrc={wixLogo}
                    />
                  </a>

                  <Text
                    fontSize={12}
                    className={`text--nunito text--bold ${styles.textShopType}`}
                  >
                    Wix
                  </Text>
                </Box>
                <Box
                  className={`${styles.storeContainer} ${styles.storeContainerInactive} ${styles.disabled}`}
                  style={{ marginRight: "0px" }}
                >
                  <LazyLoadImage
                    src={bigCommerceLogo}
                    alt="bigcommerce logo"
                    width="125"
                    height="125"
                    effect="black-and-white"
                    visibleByDefault
                    placeholderSrc={bigCommerceLogo}
                  />

                  <Text
                    fontSize={15}
                    className={`${styles.textComingSoon} text--bold text--white text--spacing-4`}
                  >
                    Coming Soon
                  </Text>
                  <Text
                    fontSize={12}
                    className={`text--nunito text--bold ${styles.textShopType}`}
                  >
                    BigCommerce
                  </Text>
                </Box>
              </Box>
            </Box>
          )}

          <Box className={styles.noticeContainer}>
            <Text fontSize={12} color="gray">
              P.S you can still use Blanka with SquareSpace, BigCommerce or
              other e-commerce platforms.Learn more.
              <a
                target="__blank"
                href="https://faq.blankabrand.com/en/articles/5265856-how-do-i-use-blanka-with-ecommerce-platforms-like-wix-and-woocommerce"
                className={`${styles.textRedirect} text--bold text--info`}
              >
                Learn more.
              </a>
            </Text>
          </Box>
        </Box>
      </Box>
    </OnboardingWrapperB>
  );
}
