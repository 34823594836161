import React from "react";

// custom components and constants:
import {
  PLAN_PRICING_TABLE_INFO,
  VIP_PLAN,
  VIP_PLAN_IDS,
  OLD_MONTHLY_PLAN_IDS,
  NEW_MONTHLY_PLAN_IDS,
  FREE_PLAN_ID,
} from "constants/plans";

// third party libraries:
import { useSelector } from "react-redux";

// buttons text:
const BUTTON_TEXT = {
  pendingPlan: "PENDING PLAN",
  keepPlan: "KEEP PLAN",
  getStarted: "GET STARTED",
  startFreeTrial: "START FREE TRIAL",
  downgrade: "DOWNGRADE",
  upgradeNow: "UPGRADE",
  current: "CURRENT",
};

// button variants:
const BUTTON_VARIANT = {
  outlined: "outlined",
  contained: "contained",
};

// pricing types:
const PRICING_TYPE = {
  NONE: "none",
  STANDARD: "standard",
  DISCOUNT: "discount",
};

const DURATION_TYPES = {
  ANNUAL: "Annual",
  MONTHLY: "Monthly",
};

const INCLUDED_FEATURE = "Included**";

/**
 * Custom hook for PlanTableCard
 * @returns {Object} - Object with all the functions and states
 */
export default function usePlanChange() {
  const shop = useSelector((state) => state.profile.shop);

  // derived clarified conditional:
  const isScheduledForDowngrade = shop?.scheduled_downgrade?.scheduled_plan_id;
  const isOnboardingStepOne = shop?.onboarding_step === 1;

  /**
   * Description: Return the button text based on its plan ID
   * @param {number} planId - Plan ID
   * @param {number} activePlan - Active plan ID
   * @returns {string} - Button text
   */
  const getButtonText = (planId, activePlan) => {
    // Extract pricing and plan type information
    const planPriceInfo = PLAN_PRICING_TABLE_INFO[planId].price;
    const activePlanPriceInfo = activePlan
      ? PLAN_PRICING_TABLE_INFO[activePlan].price
      : 0;

    // Boolean checks for plan categories and current plan status
    const isNewPlan = NEW_MONTHLY_PLAN_IDS.includes(planId);
    const isActivePlanOld = OLD_MONTHLY_PLAN_IDS.includes(activePlan);
    const isCurrentlyActivePlan = activePlan === planId;

    if (!activePlan || isOnboardingStepOne) {
      if (planId === FREE_PLAN_ID) {
        return BUTTON_TEXT.getStarted;
      }

      return BUTTON_TEXT.startFreeTrial;
    }

    if (isCurrentlyActivePlan) {
      return BUTTON_TEXT.current;
    }

    if (isNewPlan && isActivePlanOld) {
      // Special handling for transitions between old and new plans
      if (planPriceInfo === activePlanPriceInfo) {
        // If the prices are the same, but one is considered "new" and the other "old"
        return BUTTON_TEXT.current; // Or consider a specific message for this case
      }
      if (planPriceInfo > activePlanPriceInfo) {
        return BUTTON_TEXT.upgradeNow;
      }
      if (planPriceInfo < activePlanPriceInfo) {
        return BUTTON_TEXT.downgrade;
      }
    }

    // Handle generic upgrade/downgrade scenarios
    if (activePlanPriceInfo > planPriceInfo) {
      return BUTTON_TEXT.downgrade;
    }
    if (activePlanPriceInfo < planPriceInfo) {
      return BUTTON_TEXT.upgradeNow;
    }

    // Default case, if none of the above conditions are met
    return ""; // Consider a default action or message
  };
  /**
   * Description: Return the button variant based on its plan ID
   * @param {number} planId - Plan ID
   * @param {number} activePlan - Active plan ID
   * @returns {string} - Button variant
   */
  const getButtonVariant = (planId, activePlan) => {
    if (isOnboardingStepOne) {
      return BUTTON_VARIANT.contained;
    }

    if (isScheduledForDowngrade === planId || activePlan === planId) {
      return BUTTON_VARIANT.outlined;
    }
    return BUTTON_VARIANT.contained;
  };

  /**
   * Description: Return if the plan should use a ribbon
   * @param {number} planType - Plan type
   * @returns {boolean} - If the plan should use a ribbon
   */
  const useRibbon = (planId) => {
    if (PLAN_PRICING_TABLE_INFO[planId].plan_type === VIP_PLAN) {
      return true;
    }
    return false;
  };

  /**
   * Description: Return if the button should be disabled based on its plan ID and the onboarding state
   * @param {number} planId - Plan ID
   * @param {number} activePlan - Active plan ID
   * @returns {boolean} - If the button should be disabled
   */
  const isDisabled = (planId, activePlan) => {
    // clarified conditionals:
    const planIdScheduledForDowngrade =
      shop?.scheduled_downgrade?.scheduled_plan_id;

    const isNewPlan = NEW_MONTHLY_PLAN_IDS.includes(planId);
    const isActivePlanOld = OLD_MONTHLY_PLAN_IDS.includes(activePlan);

    if (isOnboardingStepOne) {
      return false;
    }

    if (isScheduledForDowngrade && planIdScheduledForDowngrade === planId) {
      return true;
    }

    if (
      isScheduledForDowngrade &&
      activePlan === planId &&
      !isOnboardingStepOne
    ) {
      return false;
    }

    if (activePlan === planId) {
      return true;
    }

    // Disable the button if it's the current active plan
    if (activePlan === planId) {
      return true;
    }

    if (isNewPlan && isActivePlanOld) {
      if (
        PLAN_PRICING_TABLE_INFO[planId].plan_type ===
        PLAN_PRICING_TABLE_INFO[activePlan].plan_type
      )
        return true;
    }

    return false;
  };

  /**
   * Description: Return the pricing text based on its plan ID
   * @param {number} planId - Plan ID
   * @returns {Object} - Pricing text
   */
  const getPricingText = (planId) => {
    const plan = PLAN_PRICING_TABLE_INFO[planId];
    if (!plan || plan.id <= 1) {
      return { type: PRICING_TYPE.NONE };
    }

    const pricingInfo = {
      type: "",
      price: `$${plan.price}/month`,
      originalPrice: null,
      note: null,
      compare_to_price: `$${plan.compare_to_price}`,
    };

    if (Number(plan.price) <= PLAN_PRICING_TABLE_INFO[8].price) {
      pricingInfo.type = PRICING_TYPE.STANDARD;

      if (shop.has_trial) {
        pricingInfo.note = "Then, ";
      }
    } else {
      pricingInfo.type = PRICING_TYPE.DISCOUNT;

      pricingInfo.originalPrice = `$${plan ? plan.compare_to_price : "N/A"}`;
      pricingInfo.price = `$${Number(plan.price / 12)}/month`;
      pricingInfo.note = `Billed annually ($${plan.price})`;
    }

    return pricingInfo;
  };

  /**
   * Description: Determines if changing from the current plan to the target plan represents an upgrade.
   * @param {number} currentPlan - The ID of the current plan.
   * @param {number} newPlanId - The ID of the target plan to compare.
   * @returns {boolean} - True if changing to the target plan is considered an upgrade; otherwise, false.
   */
  const isUpgrading = (currentPlan, newPlanID) => {
    if (
      PLAN_PRICING_TABLE_INFO[currentPlan].price >
      PLAN_PRICING_TABLE_INFO[newPlanID].price
    ) {
      return true;
    }
    return false;
  };

  /**
   * Description: Determines if changing from the current plan to the target plan represents a downgrade.
   * @param {number} currentPlan - The ID of the current plan.
   * @param {number} newPlanID - The ID of the target plan to compare.
   * @returns {boolean} - True if changing to the target plan is considered a downgrade; otherwise, false.
   */
  const isDowngrading = (currentPlan, newPlanID) => {
    if (
      PLAN_PRICING_TABLE_INFO[currentPlan].price >
      PLAN_PRICING_TABLE_INFO[newPlanID].price
    ) {
      return true;
    }

    return false;
  };

  return {
    getButtonText,
    getButtonVariant,
    useRibbon,
    isDisabled,
    getPricingText,
    isUpgrading,
    isDowngrading,
    DURATION_TYPES,
    PRICING_TYPE,
    INCLUDED_FEATURE,
    VIP_PLAN_IDS,
  };
}
