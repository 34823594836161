import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import ErrorIcon from "@mui/icons-material/Error";
import { Box, Stack } from "@mui/material";

import Text from "components/Text";
import { SMALL } from "constants/breakpoints";

import LogoDropzone from "components/LogoDropzone";
import cn from "utils/cn";
import styles from "./BrandSetupUploadFile.module.scss";

export default function BrandSetupUploadFile(props) {
  const { onLogoUpload, uploadedImage, onError } = props;

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const [isBoxHovered, setIsBoxHovered] = useState(false);

  const handleRenderLogoDrop = () => {
    if (uploadedImage) {
      return (
        <img
          src={uploadedImage}
          alt="uploaded logo"
          className={styles.logoPreview}
        />
      );
    }
    return (
      <>
        <Box
          className={cn(styles.logoFrame, isBoxHovered && styles.logoFrameCta)}
        />
        {!isBoxHovered ? <Box className={styles.logoDrop} /> : " "}
      </>
    );
  };

  return (
    <Stack
      onMouseEnter={() => setIsBoxHovered(true)}
      onMouseLeave={() => setIsBoxHovered(false)}
      className={cn(styles.brandSetupUploadFile, onError && styles.error)}
    >
      {onError && (
        <Box className={styles.errorIcon}>
          <ErrorIcon />
        </Box>
      )}
      <LogoDropzone
        onFileAccepted={onLogoUpload}
        onFileError={(error) => {
          // should we add analytics for handling error on image uploads?
          console.log("File upload logo error:", error);
        }}
      >
        <Box>
          <Text variant="body1" className={styles.title}>
            {!isMobile ? "Drag your file here to upload" : "Tap here to upload"}
          </Text>
          <Text variant="body2" className={styles.subtitle}>
            &#40; PNG &#41;
          </Text>
        </Box>
        <Box className={styles.blankaDefault}>{handleRenderLogoDrop()}</Box>
      </LogoDropzone>
    </Stack>
  );
}
