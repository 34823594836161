import apiService from "api/apiService";

export const getProductBrandSetupCustomization = () =>
  apiService.get("/v2/products/full-cover-foundation/?with_variants=false");

export const getProductGroups = () =>
  apiService.post("/categories/get-family/");

export const generateCustomizerConfig = (productDefinitionId) =>
  apiService.post("/generator/config/", {
    product_definition_id: productDefinitionId,
  });

export const postCustomizerAttributeValues = (payload) =>
  apiService.post("/attributes/assigned-values/?initiate_genny=true", payload);
