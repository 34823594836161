import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function DashedCompleteCheckMarkIcon(props) {
  return (
    <SvgIcon
      {...props}
      className="icon"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="white" />
      <path
        d="M4.61523 10.2367L7.22596 13.0769L15.3845 6.92308"
        stroke="#D56F5B"
        strokeWidth="1.3"
        fill="white"
      />
    </SvgIcon>
  );
}

export default DashedCompleteCheckMarkIcon;
