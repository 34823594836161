function isEmptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

const cleanCustomizerInputs = (inputs) =>
  inputs.filter(
    (item) =>
      item.value_json !== null &&
      item.value_json !== undefined &&
      item.value_json !== "" &&
      !isEmptyObject(item.value_json)
  );

export default cleanCustomizerInputs;
