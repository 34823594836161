import React from "react";

// Material UI Components
import { Box } from "@mui/material";

// custom components and constants:
import AddressStatusTag from "components/Labels/AddressStatusTag/AddressStatusTag";
import Text from "components/Text";

// third-party libraries:
import moment from "moment";

import styles from "./AccountAddressCard.module.scss";

export default function AccountAddressCard(props) {
  const {
    address,
    handleOnClickEditAddress,
    handleOpenConfirmRemoveAddressModal,
    modal = false,
  } = props;

  return (
    <Box className={styles.accountAddressesView}>
      <Box className={styles.leftSide}>
        <Text variant="body1" className={styles.username}>
          {address?.first_name} {address?.last_name}
        </Text>
        <Text variant="body1" className={styles.company}>
          {address?.company}
        </Text>
        <Text variant="body1" className={styles.addressAndPhone}>
          {address?.address_one}
        </Text>
        {address?.address_two && (
          <Text variant="body1" className={styles.addressAndPhone}>
            Unit/Suite {address?.address_two}
          </Text>
        )}
        <Text variant="body1" className={styles.addressAndPhone}>
          {address?.city}, {address?.state} {address?.zip},{" "}
          {address?.country.name}
        </Text>
        <Text variant="body1" className={styles.date}>
          Last updated: {moment(address?.updated_at).format("MMM DD, YYYY")}
        </Text>
      </Box>
      {!modal && (
        <Box className={styles.rightSide}>
          {address?.default && <AddressStatusTag />}
          <Text
            onClick={() => handleOnClickEditAddress(address?.id)}
            className={styles.selector}
          >
            Edit
          </Text>
          <Text
            variant="body1"
            onClick={() => handleOpenConfirmRemoveAddressModal(address?.id)}
            className={styles.selector}
          >
            Remove
          </Text>
        </Box>
      )}
    </Box>
  );
}
