import React from "react";
import styles from "./ProductViewSkeleton.module.scss";

function ProductViewSkeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.productView}>
        <div className={styles.left} />
        <div className={styles.right}>
          <div />
          <div />
        </div>
      </div>

      <div className={styles.bottom} />
    </div>
  );
}

export default ProductViewSkeleton;
