import React from "react";
import { useMediaQuery } from "react-responsive";

// MUI:
import { ChevronRight } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box } from "@mui/material";

import { Link } from "react-router-dom";
// custom components and constants:
import Text from "components/Text";
import { SMALL } from "constants/breakpoints.js";

// hooks:
// import { useProfile } from "hooks";

import useChangePlanModal from "hooks/useChangePlanModal";
import styles from "./UpgradePlanNotification.module.scss";

function UpgradePlanNotification() {
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const { onOpenModal } = useChangePlanModal();

  return (
    <Box className={styles.UpgradePlanNotification}>
      <Text className={styles.errorTitle} variant="body1">
        {!isMobile && <InfoOutlinedIcon className={styles.error} />}
        You are currently over your{" "}
        <Link to="/profile/" className={styles.reinforce}>
          limit(s) for this plan!
        </Link>
        You will need to upgrade before new customer orders can be processed.
      </Text>
      <Link
        onClick={() => onOpenModal()}
        underline="none"
        className={styles.red}
      >
        <Text className={`${styles.links} ${styles.error}`} variant="body2">
          UPGRADE <ChevronRight />
        </Text>
      </Link>
    </Box>
  );
}

UpgradePlanNotification.propTypes = {};

export default UpgradePlanNotification;
