import {
  DEBOUNCE_DELAY,
  PRODUCT_TYPE_BLANKA_LABS,
  PRODUCT_TYPE_CUSTOM,
} from "constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  addCart,
  createOrUpdate,
  deleteCartItem,
  getActiveCartAsyncThunk,
  setShippingCart,
  updateCart,
} from "state/cartSlice";
import { useDebounce } from "./useDebounce";

const useCart = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const sendCartToServer = async () => {
    dispatch(createOrUpdate());
  };

  const debouncedSendCartToServer = useDebounce(
    sendCartToServer,
    DEBOUNCE_DELAY
  );

  const updateQuantity = async (item, quantity) => {
    if (!item.id) {
      return;
    }

    if (quantity >= 0) {
      dispatch(updateCart({ item, quantity }));
    }
  };

  const updateCartBrandedBox = async (item) => {
    dispatch(updateCart({ item: item.item, quantity: item.item.quantity }));

    debouncedSendCartToServer();
  };

  const removeCartDiscountCode = async () => {
    dispatch(updateCart({ discount: {}, coupon: {} }));
    debouncedSendCartToServer();
  };

  const updateShipping = async (shipping) => {
    dispatch(setShippingCart(shipping));
    await sendCartToServer();
  };

  const getActiveCartFromServer = async () => {
    dispatch(getActiveCartAsyncThunk());
  };

  // add item to cart
  const add = async (item) => {
    if (cart.items.length > 0 && cart.type !== item.type) {
      console.log("LOOK -> CART", cart.items);
      throw new Error("Can't mix item types in the same cart");
    }
    dispatch(addCart(item));
    await sendCartToServer();
  };

  // remove item from cart
  const remove = async (itemId) => {
    dispatch(deleteCartItem(itemId));
    await sendCartToServer();
  };

  // check if cart has one item with a quantity equal or smaller than 0
  const hasInvalidQuantity = (items) =>
    items.some((item) => item.quantity <= 0);

  // check if cart has discontinued products:
  const hasDiscontinuedProduct = (items) =>
    items.some((item) => item?.product?.product_base?.is_discontinued);

  // check if cart has out of stock products:
  const hasOutOfStockProduct = (items) =>
    items.some((item) => item?.product?.product_base?.out_of_stock);

  // check if cart has discontinued or out of stock products
  const hasDiscontinuedOrOutOfStock = (items) =>
    hasDiscontinuedProduct(items) || hasOutOfStockProduct(items);

  // cart can't have inventory and blanka labs products
  const hasInventoryAndBlankaLabs = (items) =>
    items.some((item) => item.product.product_type === PRODUCT_TYPE_CUSTOM) &&
    items.some(
      (item) => item.product.product_type === PRODUCT_TYPE_BLANKA_LABS
    );

  return {
    cart,
    add,
    remove,
    updateCartBrandedBox,
    updateQuantity,
    getActiveCartFromServer,
    updateShipping,
    removeCartDiscountCode,
    debouncedSendCartToServer,
    hasInvalidQuantity,
    hasDiscontinuedOrOutOfStock,
    hasInventoryAndBlankaLabs,
  };
};

export default useCart;
