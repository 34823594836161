import { Box, Chip } from "@mui/material";
import LoadingGif from "assets/images/brand-setup/brand-setup-loading.gif";
import React, { useEffect, useMemo, useState } from "react";

import { Edit } from "@mui/icons-material";
import WandIcon from "components/Icons/WandIcon";
import Text from "components/Text";
import { useLocation, useNavigate } from "react-router-dom";

import checkIfDefaultAttribute from "utils/checkIfCustomizedAttribute";
import cn from "utils/cn";
import getAttributeValues from "utils/getAttributeValues";
import FindProductsImageContainer from "views/FindProductsView/components/FindProductsImageContainer";
import styles from "./BrandSetupGroupProductCard.module.scss";

function BrandSetupGroupProductCard({
  product,
  productGroup,
  productBox,
  productBoxGroup,
  refetch,
  isRefetching,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [showBoxImage, setShowBoxImage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isGenerating, setIsGenerating] = useState(false);

  const data = useMemo(() => {
    // logic to cater to scenario to render product image with logo but
    // save product image without logo for customizer
    if (product) {
      const val = {
        ...product?.default_variant,
        productImagePlain: product.default_variant?.image,
      };
      if (productGroup) {
        // need to add timestamp to override cached image
        val.image = `${productGroup?.image}?timestamp=${new Date().getTime()}`;
      }

      return val;
    }

    return null;
  }, [product, productGroup, isGenerating]);

  const boxData = useMemo(() => {
    // logic to cater to scenario to render product image with logo but
    // save product image without logo for customizer
    if (productBox) {
      const val = {
        ...productBox?.default_variant,
        productImagePlain: productBox.default_variant?.image,
      };

      if (productBoxGroup) {
        // need to add timestamp to override cached image
        val.image = `${productBoxGroup?.image}?timestamp=${new Date().getTime()}`;
      }

      return val;
    }

    return null;
  }, [productBox, productBoxGroup, isGenerating]);

  // const boxData = useMemo(() => productBox?.default_variant, [productBox]);

  const onNavigateToProductView = () => {
    try {
      // needed for saving scroll state and prevUrl when going back to products list
      const currentUrl = `${location.pathname}${location.search}`;
      const scrollPosition = window.scrollY;

      sessionStorage.setItem("prevUrl", currentUrl);
      sessionStorage.setItem("prevScrollPosition", scrollPosition);

      const queryString = new URLSearchParams({
        type: showBoxImage ? "BOX" : "PRODUCT",
      }).toString();

      navigate(`/brand-setup/customize/${product?.slug}?${queryString}`);
    } catch (error) {
      console.log("LOOK ERROR - onNavigateToProductView", error);
      // try to fix for BP-1150
      const queryString = new URLSearchParams({
        type: showBoxImage ? "BOX" : "PRODUCT",
      }).toString();

      navigate(`/brand-setup/customize/${product?.slug}?${queryString}`);
    }
  };

  const onClickCard = () => {
    try {
      console.log({
        ...data,
        box_data: boxData,
        generator_attributes: product?.generator_attributes,
      });
      localStorage.setItem(
        "customize",
        JSON.stringify({
          ...data,
          slug: product?.slug,
          box_data: {
            ...boxData,
            generator_attributes: productBox?.generator_attributes,
          },
          generator_attributes: product?.generator_attributes,
        })
      );
      onNavigateToProductView();
    } catch (error) {
      // try to fix for BP-1150
      const queryString = new URLSearchParams({
        type: showBoxImage ? "BOX" : "PRODUCT",
      }).toString();

      navigate(`/brand-setup/customize/${product?.slug}?${queryString}`);
    }
  };

  const isCustomizedProduct = useMemo(() => {
    if (product?.assigned_generator_attributes?.length > 0) {
      const generatorAttributes = product?.generator_attributes;
      const assignedGeneratorAttributes =
        product?.assigned_generator_attributes ?? [];

      const generatedAttributes = getAttributeValues(
        assignedGeneratorAttributes
      );
      const defaultAttributes = getAttributeValues(generatorAttributes);

      return !checkIfDefaultAttribute(generatedAttributes, defaultAttributes);
    }

    return false;
  }, [product]);

  const isCustomizedBox = useMemo(() => {
    if (productBox?.assigned_generator_attributes?.length > 0) {
      const generatorAttributes = productBox?.generator_attributes;
      const assignedGeneratorAttributes =
        productBox?.assigned_generator_attributes ?? [];

      const generatedAttributes = getAttributeValues(
        assignedGeneratorAttributes
      );
      const defaultAttributes = getAttributeValues(generatorAttributes);

      return !checkIfDefaultAttribute(generatedAttributes, defaultAttributes);
    }

    return false;
  }, [productBox]);

  // make getting the defaultValues a utility function
  const attributes = useMemo(() => {
    const generatorAttributes = product?.generator_attributes;
    const assignedGeneratorAttributes =
      product?.assigned_generator_attributes ?? [];

    if (assignedGeneratorAttributes?.length > 0) {
      return getAttributeValues(assignedGeneratorAttributes);
    }

    if (generatorAttributes?.length > 0) {
      return getAttributeValues(generatorAttributes);
    }

    return null;
  }, [product]);

  // make getting the defaultValues a utility function
  const boxAttributes = useMemo(() => {
    if (productBox) {
      const generatorAttributes = productBox?.generator_attributes;
      const assignedGeneratorAttributes =
        productBox?.assigned_generator_attributes ?? [];

      if (assignedGeneratorAttributes?.length > 0) {
        return getAttributeValues(assignedGeneratorAttributes);
      }

      if (generatorAttributes?.length > 0) {
        return getAttributeValues(generatorAttributes);
      }
    }

    return null;
  }, [[productBox]]);

  useEffect(() => {
    let refetchTimeout;
    let resetTimeout;

    const generating = sessionStorage.getItem("generating");

    if (generating === `${product?.default_variant?.product_definition}`) {
      setIsGenerating(true);

      // check for websocket message and remove hardcoded time
      refetchTimeout = setTimeout(() => {
        refetch();
      }, 1500);

      resetTimeout = setTimeout(() => {
        setIsGenerating(false);
        sessionStorage.removeItem("generating");
      }, 3000);
    }

    return () => {
      clearTimeout(refetchTimeout);
      clearTimeout(resetTimeout);
    };
  }, [product, productGroup]);

  return (
    <Box
      className={cn(isGenerating && styles.groupProductCardGenerating)}
      display="flex"
      flexDirection="column"
      gap="12px"
    >
      <Box
        onClick={() => {
          if (!isGenerating) {
            onClickCard();
          }
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={cn(styles.groupProductCard)}
      >
        {isCustomizedProduct && !showBoxImage && (
          <Chip
            icon={<Edit color="secondary" className={styles.icon} />}
            className={styles.customizedChip}
            size="small"
            label="Customized"
          />
        )}

        {isCustomizedBox && showBoxImage && (
          <Chip
            icon={<Edit color="secondary" className={styles.icon} />}
            className={styles.customizedChip}
            size="small"
            label="Customized"
          />
        )}

        <Box className={styles.wandContainer}>
          <WandIcon
            className="target__brand_setup_review--edit-icon"
            size={16}
            color="#D56F5B"
          />
        </Box>

        {showBoxImage && (
          <Box className={styles.boxLabel}>
            <Text
              variant="body1"
              size={10}
              color="gray"
              textTransform="uppercase"
              letterSpacing="1px"
            >
              BOX
            </Text>
          </Box>
        )}

        <Box className={cn(styles.editButton, isHovered && styles.visible)}>
          <WandIcon size={14} />
          <Text
            variant="body1"
            fontWeight={600}
            fontSize={10}
            color="white"
            letterSpacing="2px"
            textTransform="uppercase"
          >
            {isRefetching && isGenerating ? "generating" : "edit this logo"}
          </Text>
        </Box>
        {!isGenerating ? (
          <Box className={styles.productImages}>
            <FindProductsImageContainer
              product={showBoxImage ? boxData : data}
            />
          </Box>
        ) : (
          <div
            style={{
              position: "absolute",
              margin: "auto",
              left: "0",
              right: "0",
              bottom: "0",
              top: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              // className={styles.brandSetupLoadingGif}
              style={{
                width: "140px",
                height: "140px",
                marginBottom: "20%",
              }}
              src={LoadingGif}
              alt="loading"
            />
          </div>
        )}
        <Box className={cn(styles.productFooter, !isHovered && styles.visible)}>
          <Text className={styles.productName}>{data?.name}</Text>
        </Box>

        <Box className={cn(styles.infoContent, isHovered && styles.visible)}>
          <Box display="flex" gap="4px" alignItems="center">
            <Text fontWeight={600} fontSize={10} color="medium-gray">
              SIZE:
            </Text>
            <Text fontWeight={700} fontSize={12} color="gray">
              {showBoxImage
                ? boxAttributes?.size?.width_mm
                : attributes?.size?.width_mm}
              mm
            </Text>
          </Box>

          <Box display="flex" gap="4px" alignItems="center">
            <Text fontWeight={600} fontSize={10} color="medium-gray">
              LOGO:
            </Text>
            <Text fontWeight={700} fontSize={12} color="gray">
              {showBoxImage
                ? boxAttributes?.["logo-type"]
                : attributes?.["logo-type"]}
            </Text>
          </Box>
        </Box>
      </Box>

      {productBox && (
        <Box
          onClick={() => productBox && setShowBoxImage(!showBoxImage)}
          className={cn(
            styles.imageSwitcher,
            !productBox && styles.imageSwitcherNoBox
          )}
        >
          <Box
            className={cn(
              styles.imageSwitcherIndex,
              !showBoxImage && styles.imageSwitcherIndexActive
            )}
          />

          <Box
            className={cn(
              styles.imageSwitcherIndex,
              showBoxImage && styles.imageSwitcherIndexActive
            )}
          />
        </Box>
      )}
    </Box>
  );
}

export default BrandSetupGroupProductCard;
