import React from "react";
import PropTypes from "prop-types";

import { Box, Stepper, Step, StepLabel } from "@mui/material";

import CustomStepIcon from "../CustomStepIcon/CustomStepIcon";

import styles from "./InnerStepper.module.scss";

function InnerStepper(props) {
  const { steps, activeStep } = props;

  return (
    <Box className={styles.innerStepper}>
      <Stepper activeStep={activeStep} className={styles.stepperLabel}>
        {steps.map((step, index) => (
          <Step key={step.label} className={styles.step}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              completed={index < activeStep}
              active={(index === activeStep).toString()}
            >
              <span className={styles.stepLabel}>{step.label}</span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

InnerStepper.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number.isRequired,
};

InnerStepper.defaultProps = {
  steps: [],
};

export default InnerStepper;
