import { APP_FEATURE_FLAGS } from "constants/appFeatureFlags";
import { useSelector } from "react-redux";

export function useGlobalFlags() {
  const flags = useSelector((state) => state.profile.shop.global_flags);

  const showCustomerOrderAutomaticPayments = () =>
    flags[APP_FEATURE_FLAGS.SHOP_AUTO_PAY_CUSTOMER_ORDERS];

  return { showCustomerOrderAutomaticPayments };
}
