import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { QUERY, updateShopPayment } from "api";
import { createDollarHold } from "api/endpoints/paymentsApi";
import queryClient from "api/queryClient";
import poweredByStripeImg from "assets/images/powered-by-stripe.png";
import Button from "components/Buttons/Button";
import { SHOP_ONBOARDING_SHOPIFY } from "constants/constants";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Text from "components/Text";
import { ANNUAL_PLAN_IDS, PLAN_INFO } from "constants/plans";
import styles from "./UpdatePaymentStripe.module.scss";

export default function StripeComponent({
  onSuccess,
  onError,
  onCancel,
  hideCancel,
  submitText,
  showFreeTrialText,
  planId,
}) {
  const navigate = useNavigate();
  const shop = useSelector((state) => state.profile.shop);
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [ready, setReady] = useState(false);

  const onStripeSuccess = async (setupIntent) => {
    try {
      // saves payment method in backend
      const res = await updateShopPayment(setupIntent.payment_method);

      // need to ask doug about this
      if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
        window.location.href = res.data.confirmation_url;
      }

      // creates $1 hold
      const dollarRes = await createDollarHold();
      if (dollarRes.status === 200 || dollarRes.status === 201) {
        onSuccess();
        await queryClient.refetchQueries([QUERY.getUser]);
      }

      // to test no $1 hold
      // if (res.status === 200 || res.status === 201) {
      //   setLoading(false);
      //   onSuccess();
      //   await queryClient.refetchQueries([QUERY.getUser]);
      // }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
      onError();
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: "if_required", // Prevents automatic redirection
    });

    if (setupIntent) {
      switch (setupIntent?.status) {
        case "succeeded":
          onStripeSuccess(setupIntent);
          break;

        case "processing":
          console.log("processing");
          break;

        case "requires_payment_method":
          console.log(
            "Failed to process payment details. Please try another payment method."
          );
          break;
        default:
          break;
      }
    } else if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.log(error.message);
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div className={styles.updatePaymentStripe}>
      <div>
        <div className={styles.updatePaymentStripeComponent}>
          {!showFreeTrialText && (
            <div>
              <h1 className={styles.updatePaymentStripeComponentTitle}>
                Payment method
              </h1>
              <p className={styles.updatePaymentStripeComponentDescription}>
                All transactions are secure and encrypted.
              </p>
            </div>
          )}
          <PaymentElement
            onReady={() => setReady(true)}
            onChange={(event) => setFormComplete(event.complete)}
            id="payment-element"
          />
          {ready && (
            <div className={styles.termsContent}>
              <span className={styles.oneDollarContent}>
                <a
                  target="__blank"
                  href="https://faq.blankabrand.com/en/articles/9682154-how-does-blanka-verify-your-payment-method"
                >
                  Note: A $1 hold will be placed on your card to validate your{" "}
                  card in our system.
                </a>
              </span>{" "}
              This is an authorization only and you will not be charged.
            </div>
          )}
          {showFreeTrialText && ready && (
            <Text mt={2} className={styles.termsContent}>
              After your free trial ends, you will automatically be charged $
              {PLAN_INFO[planId].price}{" "}
              {ANNUAL_PLAN_IDS.includes(planId) ? "annually" : "monthly"} until
              you cancel your subscription. By providing your card information,
              you allow Blanka to charge your card for future payments in
              accordance with their terms.
            </Text>
          )}
        </div>
        {ready && (
          <div className={hideCancel ? styles.action : styles.actions}>
            <Button
              loading={loading}
              disabled={!stripe || !formComplete}
              onClick={(e) => handleSubmit(e)}
              color={showFreeTrialText ? "secondary" : "primary"}
              variant="contained"
              fullWidth={hideCancel}
            >
              {submitText || "Save"}
            </Button>
            {!hideCancel && (
              <Button
                disabled={!stripe || loading}
                onClick={() => {
                  if (onCancel) {
                    onCancel();
                  } else {
                    navigate(-1);
                  }
                }}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
            )}
          </div>
        )}
        <div
          className={
            showFreeTrialText
              ? styles.updatePaymentStripeFooterCenter
              : styles.updatePaymentStripeFooterLeft
          }
        >
          <Text>
            By signing up you agree to Blanka&apos;s&nbsp;
            <a
              target="__blank"
              href="https://blankabrand.com/blanka-terms-and-conditions/"
            >
              Terms and Conditions
            </a>
            &nbsp; and&nbsp;
            <a
              target="__blank"
              href="https://faq.blankabrand.com/en/articles/4880267-how-does-blanka-handle-returns"
            >
              Refund & Cancellation Policy
            </a>
          </Text>

          <img
            className={styles.stripeImage}
            src={poweredByStripeImg}
            alt="powered by stripe"
          />
        </div>
      </div>
    </div>
  );
}
