import React from "react";
import { useMediaQuery } from "react-responsive";

// MUI:
import { Box, Stack } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

// Custom components & constants:
import Text from "components/Text";
import { SMALL } from "constants/breakpoints";
import { BRAND_PLATES_TYPES, BRAND_PLATE_STATUS } from "constants/brandPlate";

// third party & utils:
import ActiveTag from "components/Labels/ActiveTag";
import { useSelector } from "react-redux";
import { selectLatestApprovedOrder } from "state/brandPlateSlice";
import { formatDateTime } from "utils/date";

import styles from "./BrandPlateOrderListItem.module.scss";

function BrandPlateOrderListItem(props) {
  const { order } = props;
  const latestApprovedOrder = useSelector(selectLatestApprovedOrder);
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  return (
    <Stack className={styles.brandPlateOrderListItem}>
      <Box className={styles.orderInfo}>
        {order.status === BRAND_PLATE_STATUS.APPROVED[0] ? (
          <CheckCircleOutlineIcon className={styles.success} />
        ) : (
          <WarningAmberIcon className={styles.error} />
        )}
        <Box>
          <Text variant="h2" className={styles.title}>
            Order {order.blanka_order_id} -{" "}
            {BRAND_PLATES_TYPES[order.brand_plate_type]}
            {latestApprovedOrder &&
              order.id === latestApprovedOrder.id &&
              !isMobile && (
                <span className={styles.logoStatusApproved}>
                  <ActiveTag />
                </span>
              )}
          </Text>
          <Text variant="body" className={styles.date}>
            {formatDateTime(order.created_at)}
            {latestApprovedOrder &&
              order.id === latestApprovedOrder.id &&
              isMobile && (
                <span className={styles.logoStatusApproved}>
                  <ActiveTag />
                </span>
              )}
          </Text>
        </Box>
      </Box>
      <Box className={styles.orderStatus}>
        <Text className={styles.title}>Status</Text>
        <Text
          className={`${styles.status} ${
            order.status === BRAND_PLATE_STATUS.APPROVED[0]
              ? styles.teal
              : styles.error
          }`}
        >
          {BRAND_PLATE_STATUS[order.status][1]}
        </Text>
        {order.status === BRAND_PLATE_STATUS.APPROVED[0] && (
          <Text className={styles.approvedText}>
            Your brand plate is ready!
          </Text>
        )}
      </Box>
    </Stack>
  );
}

export default BrandPlateOrderListItem;
