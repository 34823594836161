import React from "react";

// MUI components:
import { TextField } from "@mui/material";

// custom components and constants:
import Text from "components/Text";
import { Controller } from "react-hook-form";

import styles from "./Input.module.scss";

export default function Input(props) {
  const {
    control,
    name,
    label,
    defaultValue = "",
    rules = {},
    disabled = false,
    setError = null,
  } = props;

  const handlePhoneChange = (event, onChange) => {
    const phoneValue = event.target.value;
    onChange(phoneValue); // Update the form value

    // Custom validation logic for the phone number
    if (name === "phone" && phoneValue.length > 15) {
      setError("phone", {
        type: "manual",
        message: "Phone number should not exceed 15 digits",
      });
    }
    if (name === "phone" && phoneValue.length <= 15) {
      setError("phone", {
        type: "manual",
        message: "",
      });
    }
  };

  return (
    <div className={styles.customInput}>
      <Text className={styles.label}>{label}</Text>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            aria-label={label}
            variant="outlined"
            disabled={disabled}
            error={!!error}
            helperText={error ? error.message : ""}
            fullWidth
            // onChange={(e) => field.onChange(e.target.value)}
            onChange={(event) => handlePhoneChange(event, field.onChange)}
          />
        )}
      />
    </div>
  );
}
