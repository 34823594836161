import PropTypes from "prop-types";
import React, { useState } from "react";

import Button from "components/Buttons/Button";
import Text from "components/Text";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import { SMALL } from "constants/breakpoints.js";
import { useMediaQuery } from "react-responsive";

import BlankaMyLogoDrop from "assets/images/blanka-my-logo-drop.png";

import { useDispatch } from "react-redux";
import { addLogo, setIsLogoUploaded } from "state/myLogoSlice";

import { LOGO_DEFAULT } from "constants/generatorLogo";

import { uploadMyLogoGenerated } from "api";
import MyLogoDropzone from "../MyLogoDropzone/MyLogoDropzone";
import MyLogoErrorNotification from "../MyLogoNotification/MyLogoErrorNotification";

import styles from "./MyLogoStepTemplate.module.scss";

function MyLogoStepOne(props) {
  const { handlePrevious } = props;

  const dispatch = useDispatch();

  const [onError, setOnError] = useState([]);
  // const [onWarning, setOnWarning] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleOnFileAccepted = async (acceptedFiles) => {
    if (acceptedFiles) {
      const base64Logo = await fileToBase64(acceptedFiles);

      try {
        const data = { logo: base64Logo, logo_type: LOGO_DEFAULT };
        dispatch(addLogo(data));
        dispatch(setIsLogoUploaded(true));
        await uploadMyLogoGenerated({
          logo: acceptedFiles,
          logo_type: LOGO_DEFAULT,
        });
      } catch (error) {
        // handleOnError(error);
      }
    }
  };

  const handleOnFileError = (fileRejections) => {
    setOnError(fileRejections);
    if (isMobile) {
      setIsOpen(true);
    }
  };

  // const handleOnFileWarning = (fileWarnings) => {
  //   setOnWarning(fileWarnings);
  //   console.log(fileWarnings);
  // };

  return (
    <div className={styles.myLogoStepTemplate}>
      {!isMobile && (
        <Box className={styles.boxContainer}>
          <Button variant="text" color="secondary" onClick={handlePrevious}>
            <div className={styles.backBtn}>
              <KeyboardBackspaceIcon />
              Back
            </div>
          </Button>
          <Text className={styles.stepTextTitle}>Step 1</Text>
        </Box>
      )}
      {!isMobile && <Divider className={styles.divider} />}
      <Stack direction="row" className={styles.stepContainer}>
        {isMobile && <Text className={styles.textTitle}>Step 1</Text>}
        <Grid item className={styles.stepSection}>
          <Stack mb={3} direction="row" justifyContent="space-between">
            <Box>
              <Text className={styles.sectionSubTitle}>
                Light product preview
              </Text>
              <Text className={styles.sectionLead}>
                We recommend uploading a dark logo
              </Text>
            </Box>
          </Stack>
          <Box className={styles.stepImageSection}>
            <Box className={styles.stepImageContainer}>
              <MyLogoDropzone
                onFileAccepted={handleOnFileAccepted}
                onFileError={handleOnFileError}
              />
              <img src={BlankaMyLogoDrop} alt="" className={styles.stepImage} />
            </Box>
            <Text className={styles.imageCaption}>Upload your dark logo</Text>
          </Box>

          {onError.length > 0 && !isMobile ? (
            <div>
              <Text className={`${styles.fileRejection} ${styles.errorText}`}>
                Oops! Your logo does not meet our requirements:
              </Text>
              <ul className={styles.fileRejection}>
                {onError.map((e) => (
                  <li key={e} className={styles.errorText}>
                    <Text className={styles.errorText}>{e}</Text>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <Text className={styles.imageDisclaimer}>
              PNG with no background. Recommended width: 1200 pixels minimum.
            </Text>
          )}
          {/* {onWarning.length > 0 && !isMobile && (
            <div>
              <Text className={`${styles.fileRejection} ${styles.errorText}`}>
                WARNING!
              </Text>
              <ul className={styles.fileRejection}>
                {onWarning.map((e) => (
                  <li key={e} className={styles.errorText}>
                    <Text className={styles.errorText}>{e}</Text>
                  </li>
                ))}
              </ul>
            </div>
          )} */}
        </Grid>
      </Stack>
      {isMobile && (
        <MyLogoErrorNotification
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onError={onError}
        />
      )}
    </div>
  );
}

MyLogoStepOne.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
};

MyLogoStepOne.defaultProps = {};

export default MyLogoStepOne;
