// Billing
// eslint-disable-next-line import/no-cycle
import apiService from "api/apiService";

export const getPaymentHistory = (
  page = "",
  limit = 10,
  date = "",
  dateFilter = ""
) =>
  apiService.get(`/payments/invoices/`, {
    params: { limit, page, date, date_filter: dateFilter },
  });
