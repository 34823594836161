import PropTypes from "prop-types";
import React from "react";

import HelpIcon from "@mui/icons-material/Help";
import { Box, Divider, Step, StepLabel, Stepper } from "@mui/material";

import Text from "components/Text";

import { useSelector } from "react-redux";

import { DashedCompleteCheckMarkIcon } from "components/Icons";
import CustomStepIcon from "../CustomStepIcon/CustomStepIcon";
import InnerStepper from "../InnerStepper/InnerStepper";

import styles from "./OuterStepper.module.scss";

function OuterStepper(props) {
  const { steps } = props;

  const {
    activeOuterStep,
    activeInnerStep,
    isGeneratorStarted,
    isGeneratorCompleted,
  } = useSelector((state) => state.myLogo);

  return (
    <Box className={styles.outerStepper}>
      <div className={styles.outerStepperChildren}>
        <Text
          fontSize={24}
          color="white"
          className={styles.textTitle}
          variant="h1"
        >
          Let&apos;s build your brand
        </Text>

        <Divider className={styles.divider} />

        <Stepper
          activeStep={isGeneratorStarted ? activeOuterStep : -1}
          orientation="vertical"
          connector={null}
        >
          {steps.map((step, index) => (
            <Step key={`${step.title}-${step.subTitle}`}>
              <StepLabel
                StepIconComponent={
                  isGeneratorCompleted
                    ? DashedCompleteCheckMarkIcon
                    : CustomStepIcon
                }
              >
                <Box className={styles.stepperLabel}>
                  <span className={styles.stepperTitle}>{step.title}</span>
                  <span className={styles.stepperSubTitle}>
                    {step.subTitle}
                  </span>
                </Box>
              </StepLabel>
              {index === 1 && activeOuterStep === 1 && (
                <InnerStepper
                  steps={steps[activeOuterStep].innerSteps}
                  activeStep={activeInnerStep}
                />
              )}
            </Step>
          ))}
        </Stepper>

        <Box className={styles.brandingTipsContainer}>
          <HelpIcon className={styles.helpIconStyling} />
          <Box className={styles.stepperLabel}>
            <span className={styles.title}>Branding tips!</span>
            <span className={styles.subTitle}>
              Logo best practice{" "}
              <a
                href="https://faq.blankabrand.com/en/articles/5214993-what-are-the-logo-requirements"
                target="_blank"
                style={{ color: "white" }}
                rel="noreferrer"
              >
                here
              </a>
              .
            </span>
          </Box>
        </Box>
      </div>
    </Box>
  );
}

OuterStepper.propTypes = {
  steps: PropTypes.array,
};

OuterStepper.defaultProps = {
  steps: [],
};

export default OuterStepper;
