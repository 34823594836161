/* eslint-disable import/no-cycle */
import apiService from "api/apiService";

export const login = (data) =>
  apiService.post(`/token/`, {
    ...data,
  });

export const getNewAccessToken = (refreshToken) =>
  apiService
    .post("/token/refresh/", { refresh: refreshToken })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const sendPasswordReset = (data) =>
  apiService.post("/auth/password-reset/", data);

export const sendNewPassword = (data) =>
  apiService.post("/auth/password-reset/confirm/", data);

export const googleSSOLogin = (accessToken) =>
  apiService.post("/social-auth/google/", { access_token: accessToken });

export const appleSSOLogin = (code) =>
  apiService.post("/social-auth/apple/", {
    code,
  });
