import React from "react";

// custom components:
import Text from "components/Text/Text";

// third party libraries:
import moment from "moment";

import styles from "./DiscontinuedTag.module.scss";

export default function DiscontinuedTag(props) {
  const { expiredDate } = props;

  return (
    <Text className={styles.discontinuedTag}>
      <span className={styles.text}>Discontinued</span>
      <span className={styles.text}>
        {moment(expiredDate).format("MMM DD, YYYY")}
      </span>
    </Text>
  );
}
