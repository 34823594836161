import React from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

// MUI:
import Chip from "@mui/material/Chip";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useChangePlanModal from "hooks/useChangePlanModal";
import { useAnalytics } from "hooks";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
// Constants & custom components:
import { SMALL } from "constants/breakpoints";

import styles from "./StartTrialTag.module.scss";

function StartTrialTag() {
  const shop = useSelector((state) => state.profile.shop);
  const analytics = useAnalytics();
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const navigate = useNavigate();
  const { onOpenModal } = useChangePlanModal();

  const handleClick = () => {
    analytics.sendEvent(analytics.EVENT_HEADER_BAR_START_FREE_TRIAL, {});
    onOpenModal();
  };

  return (
    <Chip
      onClick={() => handleClick()}
      data-testid="StartTrialTag"
      size={isMobile ? "small" : "medium"}
      icon={<NewReleasesIcon color="secondary" className={styles.iconSize} />}
      label="Start your free trial"
      className={styles.startTrialTag}
    />
  );
}

export default StartTrialTag;
