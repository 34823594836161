/* eslint-disable no-param-reassign */
import axios from "axios";
import { notifications } from "components/Notifications/Notifications";
import { toast } from "react-toastify";
import { clearCart } from "state/cartSlice";
import { clearProfile } from "state/profileSlice";
import { logout, setToken } from "state/sessionSlice";
import { store } from "state/store";

import queryClient from "api/queryClient";
// import { NetworkError } from "../../components/notifications";

let BASE_URL = "";

if (process.env.REACT_APP_API_URL) {
  BASE_URL = process.env.REACT_APP_API_URL;
} else {
  BASE_URL = "https://api.blanka-staging.com/api/";
}

const apiService = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiService.interceptors.request.use(
  (config) => {
    if (store) {
      const state = store.getState();
      if (state.session.isLoggedIn) {
        config.headers.Authorization = `Bearer ${state.session.token.access_token}`;
      }
    }

    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
);

// Add a response interceptor
apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { dispatch } = store;
    const state = store.getState();
    const originalConfig = error.config;

    // if login or signup dont show error messages

    if (
      error.config.url === "/login" ||
      error.config.url === "/users/register/" || // the api endpoint url
      error.config.url === "/shop-settings/"
    ) {
      return Promise.reject(error);
    }

    switch (error.response?.status) {
      case 400:
        if (originalConfig.url === "/payments/create-payment-hold/") {
          break;
        }
        if (!error.response.data.error_code) {
          if (error.response.data.message) {
            notifications.showErrorMessage(error.response.data.message);
          } else if (error.response.data.length > 0) {
            notifications.showErrorMessage(error.response.data[0]);
          }
        }
        break;
      case 401:
      case 403:
        if (originalConfig.url !== "/login" && !originalConfig.retry) {
          originalConfig.retry = true;
          try {
            const response = await axios.post(`${BASE_URL}token/refresh/`, {
              refresh: state.session.token.refresh_token,
            });

            dispatch(setToken(response.data));
            return apiService(originalConfig);
          } catch (_error) {
            dispatch(logout());
            dispatch(clearProfile());
            dispatch(clearCart());
            queryClient.clear();

            return Promise.reject(_error);
          }
        }

        dispatch(logout(state.profile.profile.username));
        dispatch(clearProfile());
        dispatch(clearCart());
        queryClient.clear();
        break;
      case 500: // network or server error
        toast.error(
          "Oops, an error occured. We are currently working to fix the issue"
        );
        break;
      default:
    }

    if (error.code === "ERR_NETWORK") {
      // toast.error("Network Error. We are currently working to fix the issue", {
      //  timeout: 30000,
      // });
    }

    return Promise.reject(error);
  }
);

export default apiService;
