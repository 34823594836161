import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

// mui:
import { Box } from "@mui/material";

// custom components:
import blankaLogo from "assets/images/logo.png";
import Text from "components/Text";
import { SMALL } from "constants/breakpoints";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import OnboardingWrapperB from "views/OnboardingView/steps/OnboardingB/OnboardingWrapperB";
import SidePanelOnboardingStepOneB from "views/OnboardingView/steps/OnboardingB/components/SidePanelOnboardingStepB/SidePanelOnboardingStepB";
import SignUpForm from "./SignUpForm";

import styles from "./OnboardingStepOneB.module.scss";

// need to ask @doug about env files of the app
const { REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY } = process.env;

export default function OnboardingStepOneB() {
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  return (
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}>
      <OnboardingWrapperB>
        {!isMobile && (
          <>
            <Box className={styles.sideBox}>
              <Box className={styles.onboardingStepOneB}>
                <Box className={styles.welcomeContainer}>
                  <Text
                    className="text--bold text--nunito text--primary text--spacing-6"
                    fontSize={34}
                    variant="h4"
                  >
                    WELCOME TO{" "}
                  </Text>
                  <img
                    src={blankaLogo}
                    alt="Logo"
                    className={styles.blankaLogo}
                  />
                </Box>

                <Box className={styles.signUpText}>
                  <Text fontSize={24} className="text--bold text--blue">
                    Sign up for FREE!
                  </Text>

                  <Link
                    to="/login"
                    className={`${styles.textLogin} text--nunito text--info`}
                  >
                    Already have an account?{" "}
                    <span className={styles.redText}>Log in</span>
                  </Link>
                </Box>

                <SignUpForm />
              </Box>
            </Box>
            <Box className={styles.sideBox}>
              <SidePanelOnboardingStepOneB />
            </Box>
          </>
        )}
        {isMobile && (
          <>
            <Box className={styles.sideBox}>
              <SidePanelOnboardingStepOneB />
            </Box>
            <Box className={styles.sideBox}>
              <Box className={styles.onboardingStepOneB}>
                <Box className={styles.signUpText}>
                  <Text fontSize={24} className="text--bold text--blue">
                    Sign up for FREE!
                  </Text>

                  <Link
                    to="/login"
                    className={`${styles.textLogin} text--nunito text--info`}
                  >
                    Already have an account?{" "}
                    <span className={styles.redText}>Log in</span>
                  </Link>
                </Box>

                <SignUpForm />
              </Box>
            </Box>
          </>
        )}
      </OnboardingWrapperB>
    </GoogleReCaptchaProvider>
  );
}
