import React from "react";
import Card from "@mui/material/Card";
import Text from "components/Text";
import PropTypes from "prop-types";
import blankaLogo from "assets/images/logo.png";
import styles from "./OnboardingWrapper.module.scss";

export default function OnboardingWrapper({ children }) {
  return (
    <div className={styles.onboardingWrapper}>
      <Card className={styles.card}>
        <div className={styles.welcomeContainer}>
          <Text
            className="text--bold text--nunito text--primary text--spacing-6"
            fontSize={window.innerWidth < 600 ? 18 : 34}
            variant="h4"
          >
            WELCOME TO{" "}
          </Text>
          <img src={blankaLogo} alt="Logo" className={styles.blankaLogo} />
        </div>

        <div className={styles.modalContent}>{children}</div>
      </Card>
    </div>
  );
}

OnboardingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
