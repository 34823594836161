import React from "react";

// mui:
import { Grid, Card } from "@mui/material";

import styles from "./AccountCard.module.scss";

function AccountCard({ children }) {
  return (
    <div className={styles.accountCard}>
      <Grid container spacing={2} className={styles.card}>
        <Grid item xs={12} md={12}>
          <Card className={`card ${styles.cardWrapper}`} variant="outlined">
            {children}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default AccountCard;
