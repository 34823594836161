import React, { useState, useEffect } from "react";

// third party libraries

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// MUI components:

import { QUERY, getProduct } from "api";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Stack } from "@mui/material";
import {
  NoCustomProductsIcon,
  NoStoreIcon,
  AlertGearIcon,
} from "components/Icons";

import { useQuery } from "react-query";
// Custom components & assets:
import Text from "components/Text";

import { useProfile } from "hooks";

import styles from "./FeatureUsage.module.scss";

const EXAMPLE_CUSTOM_PRODUCT_SKU = "BLNK-PC-03-04-CR-AEC";

function FeatureUsage(props) {
  const {
    selectedPlan,
    planPair,
    handlePlanSelection,
    handleNext,
    handleKeepCurrentPlan,
  } = props;
  const shop = useSelector((state) => state.profile.shop);
  const changePlan = useSelector((state) => state.changePlan);
  const [open, setOpen] = useState(false);
  const profileHook = useProfile();
  const navigate = useNavigate();
  const isAnnualSelected = selectedPlan === planPair.annual;

  // make sure to redirect users that try to access the urls directly
  useEffect(() => {
    if (!shop.plan || !changePlan.trackPlan.new_plan) {
      navigate("/profile");
    }
  }, []);

  const { data, isLoading, isError } = useQuery(
    [QUERY.getProduct, shop.id],
    () => getProduct(`${shop.id}-${EXAMPLE_CUSTOM_PRODUCT_SKU}`),
  );

  const handleClose = () => {
    setOpen(false);
    // dispatch(setDowngradeStates(false));
    navigate("/downgrade/review");
  };
  // This check all the products types to see that some are above 0
  const checkAllProducts = () =>
    shop.count_number_of_custom_products > 0 ||
    shop.count_number_of_premuim_products > 0 ||
    shop.count_number_of_blanka_labs_products > 0 ||
    shop.count_number_of_unbranded_products > 0;

  // prevent users from accessing the page directly
  if (!changePlan.choosenPlan) {
    return <div />;
  }

  return (
    <>
      {!isLoading ? (
        <div className={styles.imgContainer}>
          <img
            alt="Branded Product"
            className={styles.productImage}
            src={data?.data?.image}
          />
        </div>
      ) : (
        <CircularProgress size={30} />
      )}

      <Text variant="h2" fontSize="21" className={styles.pageTitle}>
        Hold on, you will lose all your branded products!
      </Text>

      <div className={styles.usageContainer}>
        <div className={styles.usageBox}>
          <NoCustomProductsIcon className={styles.icon} />
          <Text
            color="primary"
            fontSize="16"
            className="text--bold"
            variant="body1"
          >
            You will lose access to custom branded mockups
          </Text>
          <Text color="primary" variant="body1" fontSize="14">
            You will no longer be able to order branded products, or pay for
            branded products sold on your site.
          </Text>
        </div>
        <div className={styles.usageBox}>
          <NoStoreIcon className={styles.icon} />
          <Text
            color="primary"
            fontSize="16"
            className="text--bold"
            variant="body1"
          >
            You&apos;ll be limited to publishing 10 unbranded products in total
          </Text>
        </div>
      </div>
      {profileHook.isOnOldMonthlyPlan(shop.plan) && (
        <div className={styles.usageBoxWideContainer}>
          <div className={styles.usageBoxWide}>
            <AlertGearIcon className={styles.iconGear} />
            <div className={styles.specialPricingText}>
              <Text
                color="primary"
                fontSize="16"
                className="text--bold"
                variant="body1"
              >
                Your account currently has access to special pricing!
              </Text>
              <Text color="primary" fontSize="14" className="" variant="body1">
                You&apos;re currently saving $
                {profileHook.getPlanSavings(shop.plan)}
                /month with your plan!. If you continue with this downgrade,
                you&apos;ll lose access to this special pricing.
              </Text>
            </div>
          </div>
        </div>
      )}

      <Box className={styles.featureUsage}>
        <Stack direction="row" className={styles.stackContainer}>
          <div className={styles.stackItemContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleKeepCurrentPlan("FEATURE_USAGE")}
              className={styles.ctaBtn}
            >
              Keep Current Plan
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleNext()}
              className={styles.ctaBtn}
            >
              I’m okay with losing these features
            </Button>
          </div>
        </Stack>
      </Box>
    </>
  );
}

export default FeatureUsage;
