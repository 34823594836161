import React from "react";
import PropTypes from "prop-types";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { UploadIcon } from "components/Icons";

import { useDropzone } from "react-dropzone";
import { useMediaQuery } from "react-responsive";

import Text from "components/Text";
import Button from "components/Buttons/Button";

import { SMALL } from "constants/breakpoints.js";

import LogoDropzone from "components/LogoDropzone";
import styles from "./MyLogoDropzone.module.scss";

function MyLogoDropzone({ onFileAccepted, onFileError }) {
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const handleFileAccepted = async (acceptedFiles) => {
    onFileAccepted(acceptedFiles);
  };

  const handleFileError = (fileRejections) => {
    onFileError(fileRejections);
  };

  const { isDragActive } = useDropzone({});

  return (
    <Box className={styles.myLogoDropzone}>
      <LogoDropzone
        onFileAccepted={handleFileAccepted}
        onFileError={handleFileError}
      >
        {isDragActive ? (
          <Stack className={styles.dragDropContainer} alignContent="center">
            <Box className={styles.dragDropZone}>
              <UploadIcon />
              <Text className={styles.dragDropText}>
                Drag & drop your logo here
              </Text>
            </Box>
          </Stack>
        ) : (
          <Stack className={styles.dragDropContainer}>
            {!isMobile && (
              <Box className={styles.dragDropZone}>
                <UploadIcon />
                <Text className={styles.dragDropText}>
                  Drag & drop your logo here
                </Text>
              </Box>
            )}
            {!isMobile && <Divider className={styles.divider}>OR</Divider>}

            <Button
              variant="outlined"
              color="secondary"
              className={styles.dragDropCta}
              fullWidth={false}
              onClick={() => {}}
            >
              <span className={styles.dragDropCtaText}>Browse files</span>
            </Button>
          </Stack>
        )}
      </LogoDropzone>
    </Box>
  );
}

MyLogoDropzone.propTypes = {
  onFileAccepted: PropTypes.func.isRequired,
  onFileError: PropTypes.func.isRequired,
};

MyLogoDropzone.defaultProps = {};

export default MyLogoDropzone;
