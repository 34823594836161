import { useMemo } from "react";
import { useSelector } from "react-redux";
import isFeatureEnabled from "utils/isFeatureEnabled";

import { APP_FEATURE_FLAGS } from "constants/appFeatureFlags";

/**
 * Custom hook to get the feature flags from the BE.
 * @returns {Object} An object with the feature flag functions.
 */
export function useFeatureFlags() {
  const flags = useSelector((state) => state.profile.shop?.feature_flags);
  const featureFlags =
    flags || JSON.parse(localStorage.getItem("featureFlags")) || [];

  const checkIfFeatureEnabled = (allowedFlags = [], excludeFlags = []) =>
    isFeatureEnabled(featureFlags, allowedFlags, excludeFlags);

  const showBlackFridayComponent = () => {
    try {
      return featureFlags.includes(APP_FEATURE_FLAGS.FEATURE_BFCM_ENABLED);
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  return { featureFlags, checkIfFeatureEnabled, showBlackFridayComponent };
}

export const useFeatureFlagRenderer = (
  Component,
  allowedFlags = [],
  excludeFlags = []
) => {
  const flags = useSelector((state) => state.profile.shop?.feature_flags);
  const featureFlags =
    flags || JSON.parse(localStorage.getItem("featureFlags")) || [];

  const isEnabled = useMemo(
    () => isFeatureEnabled(featureFlags, allowedFlags, excludeFlags),
    [allowedFlags, featureFlags, excludeFlags]
  );

  return isEnabled ? Component : null;
};
