import {
  BlankaFormulatorIcon,
  BrandSetupIcon,
  CustomerOrderIcon,
  FindProductIcon,
  InventoryOrderIcon,
  MyAccountIcon,
  MyBrandPlateIcon,
  MyLogoIcon,
  MyProductsIcon,
  SampleOrdersIcon,
} from "components/Icons";
import React, { lazy } from "react";

import AccountBillingUpdateView from "views/AccountBillingUpdateView";
import AccountBillingView from "views/AccountBillingView";
import AccountPasswordView from "views/AccountPasswordView";
import SignupView from "views/SignupView";

import AccountPasswordUpdateView from "views/AccountPasswordUpdateView";
import AccountStoreIntegrationView from "views/AccountStoreIntegrationView";
import CartView from "views/CartView";
import CheckoutCompleteView from "views/CheckoutCompleteView";
import CheckoutPaymentView from "views/CheckoutPaymentView";
import CheckoutShippingView from "views/CheckoutShippingView";
import Login from "views/Login";
import LogoutView from "views/LogoutView";
import PasswordResetView from "views/PasswordResetView";
import ShopifyLogin from "views/ShopifyLogin";
import WixLogin from "views/WixLogin";
import Woocommerce from "views/Woocommerce";

import AccountPersonalInformation from "views/AccountPersonalInformationView/AccountPersonalInformationView";
import PasswordResetUpdateView from "views/PasswordResetUpdateView";

import AccountAddressesUpdateView from "views/AccountAddressesUpdateView/AccountAddressesUpdateView";
import AccountAddressesView from "views/AccountAddressesView/AccountAddressesView";
import BlankaFormulatorView from "views/BlankaFormulatorView";
import ProductView from "views/ProductView";

// brand plate views
import { APP_FEATURE_FLAGS } from "constants/appFeatureFlags";
import isFeatureEnabled from "utils/isFeatureEnabled";
import BrandPlatePastOrdersView from "views/BrandPlatePastOrdersView";
import BrandPlateSetupView from "views/BrandPlateSetupView";
import BrandPlateView from "views/BrandPlateView";
import BrandSetupCustomizeView from "views/BrandSetupCustomizeView";
import BrandSetupMockupsCheckoutView from "views/BrandSetupMockupsCheckoutView";
import BrandSetupReviewView from "views/BrandSetupReviewView";
import BrandSetupSuccessView from "views/BrandSetupSuccessView";
import BrandSetupUploadView from "views/BrandSetupUploadView";
import BrandSetupView from "views/BrandSetupView";
import ROUTE_PATHS from "./routePaths";

const FindProductsView = lazy(() => import("views/FindProductsView"));
const MyProductsView = lazy(() => import("views/MyProductsView"));
const InventoryOrdersView = lazy(() => import("views/InventoryOrdersView"));
const CustomerOrdersView = lazy(() => import("views/CustomerOrdersView"));
const SampleOrdersView = lazy(() => import("views/SampleOrdersView"));
const AccountView = lazy(() => import("views/AccountView"));
const SearchView = lazy(() => import("views/SearchView"));

export const blankaLabs = "blanka Labs";

export const getProtectedRoutes = (userFlags) => [
  {
    hasDividerTop: true,
    sectionTitle: "products",
    name: "Find Products",
    path: ROUTE_PATHS.HOME,
    component: <FindProductsView />,
    menu: true,
    icon: <FindProductIcon />,
  },
  {
    name: "Search",
    path: ROUTE_PATHS.PRODUCTS_SEARCH,
    component: <SearchView />,
  },
  {
    name: "Product",
    path: ROUTE_PATHS.PRODUCTS_DETAILS,
    component: <ProductView />,
  },
  {
    name: "My Brand Plate",
    path: ROUTE_PATHS.BRAND_PLATE,
    component: <BrandPlateView />,
    menu: true,
    icon: <MyBrandPlateIcon />,
    showRoute: isFeatureEnabled(
      userFlags,
      [],
      [APP_FEATURE_FLAGS.FEATURE_BRAND_SETUP_ENABLED]
    ),
  },
  {
    name: "My Brand Plate",
    path: ROUTE_PATHS.BRAND_PLATE_SETUP,
    component: <BrandPlateSetupView />,
    showRoute: isFeatureEnabled(
      userFlags,
      [],
      [APP_FEATURE_FLAGS.FEATURE_BRAND_SETUP_ENABLED]
    ),
  },
  {
    name: "My Brand Plate",
    path: ROUTE_PATHS.BRAND_PLATE_PAST_ORDERS,
    component: <BrandPlatePastOrdersView />,
    showRoute: isFeatureEnabled(
      userFlags,
      [],
      [APP_FEATURE_FLAGS.FEATURE_BRAND_SETUP_ENABLED]
    ),
  },
  {
    name: "My Products",
    path: ROUTE_PATHS.MY_PRODUCTS,
    component: <MyProductsView />,
    menu: true,
    icon: <MyProductsIcon />,
  },
  {
    name: "Formulator",
    path: ROUTE_PATHS.BLANKA_FORMULATOR,
    component: <BlankaFormulatorView />,
    menu: false,
    icon: <BlankaFormulatorIcon />,
  },
  {
    hasDividerTop: true,
    sectionTitle: "orders",
    name: "Sample Orders",
    path: ROUTE_PATHS.SAMPLE_ORDERS,
    component: <SampleOrdersView />,
    menu: true,
    icon: <SampleOrdersIcon />,
  },
  {
    name: "Customer Orders",
    path: ROUTE_PATHS.CUSTOMER_ORDERS,
    component: <CustomerOrdersView />,
    menu: true,
    icon: <CustomerOrderIcon />,
    isParent: true,
  },
  {
    name: "Inventory Orders",
    path: ROUTE_PATHS.INVENTORY_ORDERS,
    component: <InventoryOrdersView />,
    menu: true,
    icon: <InventoryOrderIcon />,
  },
  {
    hasDividerTop: true,
    sectionTitle: "my account",
    name: "Profile",
    path: ROUTE_PATHS.PROFILE,
    component: <AccountView />,
    menu: true,
    icon: <MyAccountIcon />,
  },
  {
    name: "Addresses",
    path: ROUTE_PATHS.PROFILE_ADDRESSES,
    component: <AccountAddressesView />,
  },
  {
    name: "Update Addresses",
    path: ROUTE_PATHS.PROFILE_ADDRESSES_UPDATE,
    component: <AccountAddressesUpdateView />,
  },
  {
    name: "Update Addresses",
    path: ROUTE_PATHS.PROFILE_ADDRESSES_NEW,
    component: <AccountAddressesUpdateView />,
  },
  {
    name: "My Account",
    path: ROUTE_PATHS.PROFILE_BILLING,
    component: <AccountBillingView />,
  },
  {
    name: "My Account",
    path: ROUTE_PATHS.PROFILE_BILLING_UPDATE,
    component: <AccountBillingUpdateView />,
  },
  {
    name: "My Account",
    path: ROUTE_PATHS.PROFILE_PASSWORD,
    component: <AccountPasswordView />,
  },
  {
    name: "My Account",
    path: ROUTE_PATHS.PROFILE_PASSWORD_UPDATE,
    component: <AccountPasswordUpdateView />,
  },
  {
    name: "My Account",
    path: ROUTE_PATHS.PROFILE_STORE_CONNECT,
    component: <AccountStoreIntegrationView />,
  },
  {
    name: "My Account",
    path: ROUTE_PATHS.PROFILE_INFORMATION,
    component: <AccountPersonalInformation />,
  },
  {
    name: "Logout",
    path: ROUTE_PATHS.LOGOUT,
    component: <LogoutView />,
  },
  {
    name: "Cart",
    path: ROUTE_PATHS.CART,
    component: <CartView />,
  },
  {
    name: "Checkout Shipping",
    path: ROUTE_PATHS.CHECKOUT_SHIPPING,
    component: <CheckoutShippingView />,
  },
  {
    name: "Checkout Billing",
    path: ROUTE_PATHS.CHECKOUT_PAYMENT,
    component: <CheckoutPaymentView />,
  },
  {
    name: "Checkout Success",
    path: ROUTE_PATHS.CHECKOUT_COMPLETE,
    component: <CheckoutCompleteView />,
  },
  {
    name: "My Brand",
    path: ROUTE_PATHS.BRAND_SETUP,
    component: <BrandSetupView />,
    menu: true,
    icon: <BrandSetupIcon />,
    showRoute: isFeatureEnabled(userFlags, [
      APP_FEATURE_FLAGS.FEATURE_BRAND_SETUP_ENABLED,
    ]),
  },
  {
    name: "My Brand Setup: Upload",
    path: ROUTE_PATHS.BRAND_SETUP_UPLOAD,
    component: <BrandSetupUploadView />,
    showRoute: isFeatureEnabled(userFlags, [
      APP_FEATURE_FLAGS.FEATURE_BRAND_SETUP_ENABLED,
    ]),
  },
  {
    name: "My Brand Setup: Mockups Checkout",
    path: ROUTE_PATHS.BRAND_SETUP_MOCKUPS_CHECKOUT,
    component: <BrandSetupMockupsCheckoutView />,
    showRoute: isFeatureEnabled(userFlags, [
      APP_FEATURE_FLAGS.FEATURE_BRAND_SETUP_ENABLED,
    ]),
  },
  {
    name: "My Brand Setup: Customize",
    path: ROUTE_PATHS.BRAND_SETUP_CUSTOMIZE,
    component: <BrandSetupCustomizeView />,
    showRoute: isFeatureEnabled(userFlags, [
      APP_FEATURE_FLAGS.FEATURE_BRAND_SETUP_ENABLED,
    ]),
  },
  {
    name: "My Brand Setup: Review",
    path: ROUTE_PATHS.BRAND_SETUP_REVIEW,
    component: <BrandSetupReviewView />,
    showRoute: isFeatureEnabled(userFlags, [
      APP_FEATURE_FLAGS.FEATURE_BRAND_SETUP_ENABLED,
    ]),
  },
  {
    name: "My Brand Setup: Success",
    path: ROUTE_PATHS.BRAND_SETUP_SUCCESS,
    component: <BrandSetupSuccessView />,
    showRoute: isFeatureEnabled(userFlags, [
      APP_FEATURE_FLAGS.FEATURE_BRAND_SETUP_ENABLED,
    ]),
  },
  {
    name: "My Logo",
    path: ROUTE_PATHS.MY_LOGO,
    menu: true,
    icon: <MyLogoIcon />,
    showRoute: isFeatureEnabled(
      userFlags,
      [],
      [APP_FEATURE_FLAGS.FEATURE_BRAND_SETUP_ENABLED]
    ),
  },
  {
    name: "Search",
    path: ROUTE_PATHS.SEARCH,
    component: <SearchView />,
  },
  {
    name: "Search",
    path: "/search",
    component: <SearchView />,
    menu: false,
  },
];

export const publicRoutes = [
  {
    name: "Login",
    path: ROUTE_PATHS.LOGIN,
    component: <Login />,
  },
  {
    name: "Shopify Login",
    path: ROUTE_PATHS.SHOPIFY_LOGIN,
    component: <ShopifyLogin />,
  },
  {
    name: "Woocommerce Connect",
    path: ROUTE_PATHS.WOOCOMMERCE_LOGIN,
    component: <Woocommerce />,
  },
  {
    name: "Wix Login",
    path: ROUTE_PATHS.WIX_LOGIN,
    component: <WixLogin />,
  },
  {
    name: "Signup",
    path: ROUTE_PATHS.SIGNUP,
    component: <SignupView />,
  },
  {
    name: "Password Reset",
    path: ROUTE_PATHS.PASSWORD_RESET,
    component: <PasswordResetView />,
  },
  {
    name: "Password Reset",
    path: ROUTE_PATHS.PASSWORD_SET,
    component: <PasswordResetUpdateView />,
  },
];
