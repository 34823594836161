/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    currentCategory: "",
    currentProductType: "",
    page: 1,
  },
  reducers: {
    updateCategory: (state, action) => {
      state.currentCategory = action.payload;
      state.page = 1;
    },
    updateProductType: (state, action) => {
      state.currentProductType = action.payload;
      state.page = 1;
    },
    resetPage: (state) => {
      state.page = 1;
    },

    setPage: (state, action) => {
      // console.log(action.payload);

      state.page = action.payload.page;
    },
  },
});

export const { updateCategory, updateProductType, setPage, resetPage } =
  categorySlice.actions;

export default categorySlice.reducer;
