import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
// MUI components:
import { Box, Checkbox, Stack, TextField } from "@mui/material";
import { resetQuestions } from "state/changePlanSlice";
// Custom components & assets:
import Text from "components/Text";

import styles from "./Feedback.module.scss";

function Feedback(props) {
  const { questions, onFeedbackChange } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetQuestions());
  }, []);

  const handleOnCheck = (e, id) => {
    const isChecked = e.target.checked;
    const currentAnswer = questions.find((q) => q.id === id).answer;
    onFeedbackChange(id, isChecked, currentAnswer);
  };

  const handleInputChange = (e, id) => {
    const newAnswer = e.target.value;
    const { isChecked } = questions.find((q) => q.id === id);
    onFeedbackChange(id, isChecked, newAnswer);
  };

  return (
    <Box className={styles.feedback}>
      <Text className={styles.title}>Why are you changing your plan?</Text>
      <Box className={styles.cardWrapper}>
        <Stack>
          {questions?.map((question) => (
            <Box key={question.id} className={styles.questionWrapper}>
              <Box className={styles.checkboxAndQuestion}>
                <Checkbox
                  className={styles.checkMark}
                  onClick={(e) => handleOnCheck(e, question.id)}
                  checked={question.isChecked}
                />
                <Text className={styles.question}>{question.question}</Text>
              </Box>
              {question.hasReply && question.isChecked && (
                <Box className={styles.replyWrapper}>
                  <TextField
                    className={styles.reply}
                    placeholder="Please specify to continue"
                    value={question.answer}
                    onChange={(e) => handleInputChange(e, question.id)}
                    required
                  />
                </Box>
              )}
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
}

Feedback.propTypes = {
  questions: PropTypes.array.isRequired,
  onFeedbackChange: PropTypes.func.isRequired,
};

Feedback.defaultProps = {};

export default Feedback;
