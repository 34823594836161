import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function BlankaFormulatorIcon(props) {
  return (
    <div className="icon-root">
      <SvgIcon
        {...props}
        data-testid="BlankaFormulatorIcon"
        className="icon icon--hover"
        viewBox="0 0 20 20"
      >
        <path
          d="M6.96033 15.3016C6.96033 15.4825 7.00057 15.73 7.08341 16.0508C7.16388 16.3716 7.30826 16.688 7.51182 17.0002C7.71774 17.3124 8.00413 17.5793 8.371 17.8032C8.74024 18.0271 9.21835 18.139 9.80771 18.139C10.3971 18.139 10.8988 18.0379 11.3154 17.8376C11.7344 17.6374 12.0776 17.3769 12.3474 17.0562C12.6172 16.7354 12.8184 16.3716 12.9486 15.9625C13.0788 15.5535 13.145 15.1488 13.145 14.7462C13.145 14.1499 13.0432 13.588 12.8373 13.0605C12.6338 12.5331 12.319 12.0444 11.8929 11.5988C11.6136 11.3168 11.3154 11.0563 10.9959 10.8151C10.6764 10.5783 10.3545 10.3394 10.0278 10.1026C9.69883 9.83347 9.37219 9.56867 9.04556 9.30818C8.71656 9.04769 8.46094 8.73768 8.15088 8.44059C7.99467 8.25975 7.78875 8.00357 7.69407 7.87871C7.58993 7.74308 7.41241 7.48474 7.30353 7.35342C7.23962 7.28022 7.16625 7.25654 7.08578 7.28668C7.00294 7.31682 6.9627 7.37495 6.9627 7.46536V15.3038L6.96033 15.3016Z"
          fill="#D56F5B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.7078 1H4.90658V2.79696H5.7078V15.399C5.7078 15.4284 5.70841 15.4581 5.70961 15.488C5.73669 16.5145 6.23285 17.3746 7.02149 17.9867C7.69533 18.5808 8.61031 18.9998 9.72394 18.9998C9.81581 18.9998 9.90707 18.9979 9.99764 18.9941C10.0896 18.998 10.1822 19 10.2755 19C11.391 19 12.3071 18.5796 12.9813 17.9839C13.7677 17.3722 14.2624 16.5135 14.2898 15.489C14.291 15.4588 14.2916 15.4288 14.2916 15.3992C14.2916 15.362 14.2914 15.325 14.291 15.2884V2.79696H15.0936V1H14.291H5.7078ZM8.06809 16.503C8.10305 16.5394 8.1393 16.5748 8.17683 16.6092C8.57667 16.9186 9.17037 17.1531 9.998 17.196C10.8291 17.1535 11.4247 16.9179 11.8251 16.6071C11.8617 16.5734 11.8972 16.5387 11.9314 16.5032C12.3407 16.0776 12.4802 15.6312 12.4936 15.43C12.4939 15.4196 12.494 15.4093 12.494 15.399V15.2988C12.4769 13.9773 12.1212 13.2862 11.6993 12.7935C11.2258 12.2405 10.6065 11.84 9.67198 11.2357C9.56058 11.1636 9.44471 11.0887 9.32408 11.0103C8.76095 10.6442 8.1305 10.2208 7.50541 9.68371V15.3992C7.50541 15.4092 7.50555 15.4193 7.50583 15.4295C7.51909 15.6306 7.65856 16.0772 8.06809 16.503ZM10.6587 9.73307C11.3128 10.154 11.9453 10.561 12.494 11.0481V2.79699H7.50477V7.09045L7.50541 7.09129C8.42743 8.29714 9.57296 9.03433 10.6587 9.73306L10.6587 9.73307Z"
          fill="#5E5E5E"
        />
      </SvgIcon>

      <SvgIcon {...props} className="icon icon--normal" viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.70772 1H4.90649V2.79696H5.70772V15.399C5.70772 15.4284 5.70832 15.4581 5.70953 15.488C5.7366 16.5145 6.23277 17.3746 7.02141 17.9867C7.69525 18.5808 8.61023 18.9998 9.72386 18.9998C9.81573 18.9998 9.90699 18.9979 9.99755 18.9941C10.0895 18.998 10.1821 19 10.2754 19C11.3909 19 12.3071 18.5796 12.9812 17.9839C13.7676 17.3722 14.2623 16.5135 14.2897 15.489C14.2909 15.4588 14.2916 15.4288 14.2916 15.3992C14.2916 15.362 14.2913 15.325 14.2909 15.2884V2.79696H15.0935V1H14.2909H5.70772ZM8.06801 16.503C8.10297 16.5394 8.13922 16.5748 8.17675 16.6092C8.57659 16.9186 9.17029 17.1531 9.99792 17.196C10.8291 17.1535 11.4246 16.9179 11.825 16.6071C11.8617 16.5734 11.8971 16.5387 11.9313 16.5032C12.3406 16.0776 12.4801 15.6312 12.4935 15.43C12.4938 15.4196 12.4939 15.4093 12.4939 15.399V15.2988C12.4768 13.9773 12.1211 13.2862 11.6992 12.7935C11.2258 12.2405 10.6065 11.84 9.6719 11.2357C9.5605 11.1636 9.44463 11.0887 9.32399 11.0103C8.76087 10.6442 8.13042 10.2208 7.50533 9.68371V15.3992C7.50533 15.4092 7.50547 15.4193 7.50575 15.4295C7.51901 15.6306 7.65848 16.0772 8.06801 16.503ZM10.6586 9.73307C11.3128 10.154 11.9452 10.561 12.4939 11.0481V2.79699H7.50468V7.09045L7.50533 7.09129C8.42734 8.29714 9.57287 9.03433 10.6586 9.73306L10.6586 9.73307Z"
          fill="#5E5E5E"
        />
      </SvgIcon>
    </div>
  );
}
