import React from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";

import { SMALL } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";

import styles from "./DiscountTag.module.scss";

function DiscountTag(props) {
  const { label, isFaded, color, size } = props;
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  return (
    <Chip
      data-testid="DiscountTag"
      size={isMobile ? "small" : "medium"}
      label={`${label}`}
      className={`${styles.discountTag} ${styles[color]} ${
        isFaded ? styles.faded : ""
      } ${styles[size]}`}
    />
  );
}

DiscountTag.propTypes = {
  label: PropTypes.string.isRequired,
  isFaded: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium"]),
};

DiscountTag.defaultProps = {
  isFaded: false,
  color: "primary", // defaulting to primary
  size: "medium", // defaulting to medium
};

export default DiscountTag;
