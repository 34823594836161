import React from "react";
import PropTypes from "prop-types";

// mui:
import { Divider, Stack } from "@mui/material";

// custom components:
import Text from "components/Text";

import { CART_TYPE_INVENTORY, CART_TYPE_SAMPLE } from "constants/constants";
import styles from "./CartTotals.module.scss";

function CartTotals(props) {
  const { cart, step } = props;

  const handleShippingCost = (step) => {
    if (Number.isNaN(Number(cart.shipping.shipping_cost))) {
      return "-";
    }

    if (step === "cart") {
      return "-";
    }

    return `$${Number(
      cart.shipping.shipping_cost_with_savings
        ? cart.shipping.shipping_cost_with_savings
        : cart.shipping.shipping_cost
    ).toFixed(2)}`;
  };

  const handleBrandedBoxCost = () => {
    if (cart.total_branded_box_fees === "0.00") {
      return "";
    }

    return `$${Number(cart.total_branded_box_fees).toFixed(2)}`;
  };

  const handleDiscount = () =>
    cart.total_discount === "0.00" ? "" : `-$${cart.total_discount}`;

  return (
    <div className={styles.cartTotals}>
      {cart.type === CART_TYPE_INVENTORY && Number(cart.total_discount) > 0 && (
        <Stack className={`${styles.cartTotalsContainer}`}>
          <Text className={styles.textTitle}>Volume discount savings</Text>
          <Stack direction="column" width="100%" alignItems="end">
            <Text className={`${styles.textDiscount}`}>{handleDiscount()}</Text>
          </Stack>
        </Stack>
      )}

      {cart.type === CART_TYPE_SAMPLE && Number(cart.total_discount) > 0 && (
        <Stack className={`${styles.cartTotalsContainer}`}>
          <Text className={styles.textTitle}>Discount savings</Text>
          <Stack direction="column" width="100%" alignItems="end">
            <Text className={`${styles.textDiscount}`}>{handleDiscount()}</Text>
          </Stack>
        </Stack>
      )}

      {cart.type === CART_TYPE_INVENTORY &&
        cart.total_branded_box_fees !== "0.00" && (
          <Stack className={`${styles.cartTotalsContainer}`}>
            <Text className={styles.textTitle}>Branded Boxes</Text>
            <Stack direction="column" width="100%" alignItems="end">
              <Text className={`${styles.textCost}`}>
                {handleBrandedBoxCost()}
              </Text>
            </Stack>
          </Stack>
        )}

      <Stack className={`${styles.cartTotalsContainer}`}>
        <Text className={styles.textTitle}>Subtotal</Text>
        <Stack direction="column" width="100%" alignItems="end">
          {Number(cart.total_discount) > 0 ? (
            <Text className={`${styles.textCost}`}>
              ${cart.subtotal_with_savings}
            </Text>
          ) : (
            <Text className={`${styles.textCost}`}>${cart.subtotal}</Text>
          )}

          {cart.subtotal_with_savings !== "0.00" &&
            cart.type === CART_TYPE_INVENTORY &&
            cart.subtotal_with_savings !== cart.subtotal && (
              <Text className={`${styles.textOldCost}`}>${cart.subtotal}</Text>
            )}
        </Stack>
      </Stack>

      <Divider orientation="horizontal" className={styles.divider} />

      <Stack className={`${styles.cartTotalsContainer}`}>
        <Text className={styles.textTitle}>Shipping</Text>
        <Stack direction="column" width="100%" alignItems="end">
          <Text className={`${styles.textCost}`}>
            {handleShippingCost(step)}
          </Text>
        </Stack>
      </Stack>
      <Stack className={`${styles.cartTotalsContainer}`}>
        <Text className={styles.textTitle}>Processing Fee</Text>
        <Stack direction="column" width="100%" alignItems="end">
          <Text className={`${styles.textCost}`}>${cart.fees}</Text>
        </Stack>
      </Stack>

      <Divider orientation="horizontal" className={styles.divider} />

      <Stack className={`${styles.cartTotalsContainer}`}>
        <Text className={styles.textTitle}>Total</Text>
        <Stack direction="column" width="100%" alignItems="end">
          <Text className={`${styles.textCost}`}>${cart.total}</Text>
        </Stack>
      </Stack>
    </div>
  );
}

CartTotals.propTypes = {
  cart: PropTypes.object.isRequired,
  step: PropTypes.oneOf(["cart", "shipping", "payment", "invoice"]).isRequired,
};
CartTotals.defaultProps = {};

export default CartTotals;
