import { Box, Typography } from "@mui/material";
import BrandPlateNav from "components/BrandPlateNav";
import Button from "components/Buttons/Button";
import PageHeader from "components/PageHeader";
import Text from "components/Text";
import {
  BRAND_PLATE_PROCESS,
  BRAND_PLATE_STATUS_V2,
} from "constants/brandPlate";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getBrandPlateOrdersAsync } from "state/brandPlateSlice";
import styles from "./BrandPlateViewNew.module.scss";
import BrandPlateExplainModal from "./components/BrandPlateExplainModal";
import BrandPlateNew from "./components/BrandPlateNew";
import BrandPlateStatusProcess from "./components/BrandPlateStatusProcess";

function BrandPlateViewNew() {
  const [explainModal, setExplainModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const currentBrandPlate = useSelector(
    (state) => state.brandPlate.currentOrder
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBrandPlateOrdersAsync());
  }, []);

  const handleCloseExplain = () => {
    setExplainModal(false);
  };

  const handleOpenExplain = () => {
    setExplainModal(true);
  };

  /**
   * Checks to see if the status of the current brand plate is
   * IN_PROGRESS,IN_REVISION, PENDING_APPROVAL or MOCKUP_ACCEPTED
   */
  const isCurrentBrandPlateInProgress = (status) =>
    status === BRAND_PLATE_STATUS_V2.IN_PROGRESS[0] ||
    status === BRAND_PLATE_STATUS_V2.IN_REVISION[0] ||
    status === BRAND_PLATE_STATUS_V2.PENDING_APPROVAL[0] ||
    status === BRAND_PLATE_STATUS_V2.MOCKUP_ACCEPTED[0];

  return (
    <Box className={styles.brandPlatePage}>
      <PageHeader title="My Brand Plate Setup" />
      <BrandPlateNav />
      <Box className={styles.brandPlateHeading}>
        {isCurrentBrandPlateInProgress(currentBrandPlate.status) ? (
          <Box>
            <Text
              variant="h2"
              fontSize="24"
              color="gray"
              className={styles.completeCurrent}
            >
              Your current Brand Plate is in progress!
            </Text>
            <Text
              variant="body1"
              fontSize="18"
              className={styles.completeCurrent}
            >
              Please finish setting up your brand with your current Brand Plate
              before starting a new one!
            </Text>
          </Box>
        ) : (
          <>
            <Typography
              variant="h1"
              fontSize={{ xs: 20, md: 24 }}
              color="#5E5E5E"
            >
              Get your brand started!
            </Typography>
            <Typography
              display="block"
              mt={2}
              variant="p"
              fontSize={{ xs: 12, md: 14 }}
              color="#5E5E5E"
            >
              Your{" "}
              <span
                onClick={handleOpenExplain}
                className={styles.brandPlateModalLink}
              >
                Brand Plate
              </span>{" "}
              is the final step in preparing your brand for print production.
              This is essential for creating your custom branded products, and
              only needs to be created once unless you change your logo.
            </Typography>
          </>
        )}
      </Box>
      <Box className={styles.newBrandPlateContainer}>
        {isCurrentBrandPlateInProgress(currentBrandPlate.status) ? (
          <>
            <Box className={styles.newBrandPlateContent}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => navigate("/brand-plate/setup")}
              >
                Current Brand Plate
              </Button>
            </Box>
            <Box className={styles.newBrandPlateProcess}>
              <BrandPlateStatusProcess
                steps={BRAND_PLATE_PROCESS}
                currentStep={BRAND_PLATE_STATUS_V2.UPLOAD_YOUR_LOGO[0]}
              />
            </Box>
          </>
        ) : (
          <>
            <Box className={styles.newBrandPlateContent}>
              <BrandPlateNew />
            </Box>
            <Box className={styles.newBrandPlateProcess}>
              <BrandPlateStatusProcess
                steps={BRAND_PLATE_PROCESS}
                currentStep={BRAND_PLATE_STATUS_V2.UPLOAD_YOUR_LOGO[0]}
              />
            </Box>
          </>
        )}
      </Box>

      <BrandPlateExplainModal
        open={explainModal}
        handleClose={handleCloseExplain}
      />
    </Box>
  );
}

export default BrandPlateViewNew;
