/* eslint-disable import/no-cycle */
import apiService from "api/apiService";

export const createPaymentIntent = (total) =>
  apiService.post(`/payments/create-payment-intent/`, { total });

export const createSetupIntent = (amount) =>
  apiService.post(`/payments/create-setup-intent/`, { amount });

export const createDollarHold = (amount) =>
  apiService.post(`/payments/create-payment-hold/`, {});
