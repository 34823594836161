import { EventEmitter } from "fbemitter";
import React, { useState } from "react";

// MUI:
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Box, Stack, Typography } from "@mui/material";

// Custom Components:
import Button from "components/Buttons/Button";
import Text from "components/Text";

import { useAnalytics } from "hooks";

// Styles:
import { createDirectUrl, uploadFileToS3 } from "api/endpoints/shopApi";
import LogoDropzone from "components/LogoDropzone";
import { BRAND_PLATE_STATUS } from "constants/brandPlate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectCurrentAssets, selectCurrentOrder } from "state/brandPlateSlice";
import styles from "./BrandPlateFileUpload.module.scss";

function BrandPlateFileUpload({ onBack, onFilesUploaded }) {
  const [fileList, setFileList] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [loading, setLoading] = useState();
  const initialStateFileWithErrors = { file: { path: "" } };
  const [fileWithErrors, setFileWithErrors] = useState(
    initialStateFileWithErrors
  );
  const [uploadingList, setUploadingList] = useState([]);
  const analytics = useAnalytics();

  const emitter = new EventEmitter();
  const currentOrder = useSelector(selectCurrentOrder);
  const currentAssets = useSelector(selectCurrentAssets);

  const onDropCallback = (acceptedFiles, errors) => {
    if (errors.length > 0) {
      setFileWithErrors({ ...fileWithErrors, ...errors[0] });
      setErrorList(errors[0].errors);
      return;
    }
    setFileWithErrors(initialStateFileWithErrors);
    setErrorList([]);
    const updatedUploadingList = acceptedFiles.map((_) => false);
    setUploadingList([...uploadingList, ...updatedUploadingList]);
    setFileList([...fileList, ...acceptedFiles]);
  };

  const handleUploadProgress = (uploadingIndex) => {
    setUploadingList((previousState) =>
      previousState.map((item, index) => {
        if (index === uploadingIndex) {
          return !item;
        }
        return item;
      })
    );
  };

  emitter.addListener("uploading", handleUploadProgress);
  emitter.addListener("upload_done", handleUploadProgress);

  const createAllDirectUrl = async () =>
    Promise.all(
      fileList.map((file, index) =>
        createDirectUrl({ originalFileName: file.name }).then((res) => ({
          response: res.data,
          index,
        }))
      )
    );

  const uploadAllFiles = async (directUrlResponses) =>
    Promise.all(
      directUrlResponses.map((res) =>
        uploadFileToS3(res.response.url, fileList[res.index]).then(() => {
          emitter.emit("upload_done", res.index);
          return {
            fileName: res.response.file_name,
            index: res.index,
          };
        })
      )
    );

  const formatFileName = (fileName) => {
    if (fileName.length <= 10) {
      return fileName;
    }
    return `${fileName.slice(0, 10)}...${fileName.slice(-3).replace(".", "")}`;
  };

  const handleSubmitUploadFiles = async () => {
    setLoading(true);
    // Set the state of all files as uploading.
    fileList.map((_, index) => {
      emitter.emit("uploading", index);
    });

    // Get the temporary direct urls concurrently
    const directUrlResponses = await createAllDirectUrl();

    // Uploading to s3 concurrently
    const fileNames = await uploadAllFiles(directUrlResponses);

    setFileList([]);

    setLoading(false);
    toast.success("Files submitted successfully!");
    onFilesUploaded(fileNames);
  };

  return (
    <Stack className={styles.brandPlateFileUpload}>
      <Box className={styles.boxContainer}>
        <Typography variant="h3" fontSize={{ xs: 14, md: 16 }} color="#5E5E5E">
          Upload your print-ready vector logo
        </Typography>
        {currentOrder.status === BRAND_PLATE_STATUS.IN_PROGRESS[0] && (
          <>
            <div className={styles.filesContainer}>
              <p>Files:</p>
              {currentAssets.length > 0 &&
                currentAssets.map((file, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`file_${index}`}
                    className={styles.fileContainerRow}
                  >
                    <div className={styles.fileName}>
                      {formatFileName(file.assets.original_file_name)}
                    </div>
                    <div className={styles.fileItem}>{file.assets.status}</div>
                  </div>
                ))}
            </div>
            <Text className={styles.inProgressDescription}>
              <CheckCircleOutlineIcon className={styles.success} /> Thanks for
              submitting file(s) for your logo!
            </Text>
          </>
        )}
        <>
          <Box className={styles.logoDropzone}>
            <LogoDropzone
              onFileAccepted={() => {}}
              onFileError={() => {}}
              onDropCallback={onDropCallback}
              // Reference for acceptance criteria: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
              // accept={{
              //   "application/pdf": ["pdf"],
              //   "application/postscript": [".ai", ".eps"],
              //   "image/svg+xml": [".svg"],
              // }}
              accept={{
                "application/pdf": [".pdf"],
                "image/svg+xml": [".svg"],
                "application/postscript": [".ai"],
              }}
              maxSize={1024 * 1024 * 25}
              minSize={0}
              maxFiles={15}
            >
              <Box className={styles.dropArea}>
                <FileUploadOutlinedIcon />
                <Text className={styles.dropzoneText}>
                  Click here or drag file(s) here
                </Text>
              </Box>
            </LogoDropzone>
          </Box>
          <div>
            <div>
              {errorList.map((error) => (
                <Text className={styles.error}>
                  <ErrorOutlineOutlinedIcon className={styles.error} />{" "}
                  {error.message}
                </Text>
              ))}
            </div>
          </div>
          <div className={styles.filesContainer}>
            {currentAssets.length > 0 && <p>Files:</p>}
            {currentAssets.length > 0 &&
              currentAssets.map((file, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`file_${index}`}
                  className={styles.fileContainerRow}
                >
                  <div className={styles.fileName}>
                    {formatFileName(file.assets.original_file_name)}
                  </div>
                  <div className={styles.fileItem}>{file.assets.status}</div>
                </div>
              ))}
            {fileList.map((file, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`file_${index}`}
                className={styles.fileContainerRow}
              >
                <div className={styles.fileName}>
                  {formatFileName(file.name)}
                </div>
                <div className={styles.fileItem}>
                  {Math.ceil(file.size / 1000)} KB
                </div>
                {uploadingList[index] && (
                  <div className={styles.fileItem}>Uploading...</div>
                )}
                {/* eslint-disable-next-line react/button-has-type,jsx-a11y/control-has-associated-label */}
                <button
                  className={styles.removeButton}
                  onClick={() => {
                    const currentIndex = index;
                    const fileListEdited = fileList.filter(
                      (_, i) => currentIndex !== i
                    );
                    setFileList(fileListEdited);
                    setUploadingList(fileListEdited.map((_) => false));
                  }}
                >
                  <i className="fa fa-close" />
                </button>
              </div>
            ))}
          </div>
        </>
      </Box>
      <>
        <Text className={styles.description}>
          When files are uploaded, click Submit Files to proceed to the next
          step.
        </Text>

        <Box className={styles.action}>
          <Button
            variant="contained"
            color="primary"
            disabled={fileList.length === 0 || loading}
            loading={loading}
            onClick={handleSubmitUploadFiles}
            size="small"
          >
            Use logos
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={onBack}
            size="small"
          >
            Nevermind
          </Button>
        </Box>
      </>
    </Stack>
  );
}

export default BrandPlateFileUpload;
