import { Box, Grid, SvgIcon, Typography } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getProducts, orderBrandPlate } from "api";
import lipStick from "assets/images/blanka-lipstick-your-logo.png";
import Button from "components/Buttons/Button";
import {
  AddItemToCartError,
  brandPlateSuccess,
  ClearCartError,
} from "components/Notifications/Notifications";
import Text from "components/Text";
import { CATEGORY, PAGE, PRODUCT_TYPE } from "constants/brandPlate";
import {
  CART_TYPE_BRAND_PLATE,
  PRODUCT_TYPE_BRAND_PLATE,
} from "constants/constants";
import { mockups } from "constants/generatorLogo";

import ConfirmBrandPlateModal from "components/Modals/ConfirmBrandPlateModal";
import { useAnalytics, useCart, useProfile } from "hooks";
import { useAbTests } from "hooks/useABTests";
import useChangePlanModal from "hooks/useChangePlanModal";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getBrandPlateOrdersAssetsAsync,
  setCurrentOrder,
} from "state/brandPlateSlice";
import { clearCart, IDLE } from "state/cartSlice";
import { setIsOpen } from "state/myLogoSlice";
import { selectShop } from "state/profileSlice";
import BrandPlateFileUpload from "views/BrandPlateSetupView/components/BrandPlateFileUpload";
import CheckoutConfirmPayment from "views/CheckoutPaymentView/components/CheckoutConfirmPayment";
import { publishFile } from "../../../../api/endpoints/shopApi";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import newStyles from "./BrandPlateNew.module.scss";

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_KEY));

function BrandPlateNew() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmBrandPlateModal, setConfirmBrandPlateModal] = useState(false);
  const abTests = useAbTests();
  const { logos, isLoading: isLoadingLogos } = useSelector(
    (state) => state.myLogo
  );
  const shop = useSelector(selectShop);
  const profileHook = useProfile();
  const profile = useSelector((state) => state.profile.profile);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [nextActionType, setNextActionType] = useState(null);
  const { add, cart } = useCart();
  const analytics = useAnalytics();
  const [upload, setUpload] = useState(false);
  const [hasPrintReadyLogos, setHasPrintReadyLogos] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const { onOpenModal } = useChangePlanModal();

  const { data: products } = useQuery(
    ["products", shop.plan],
    () => getProducts(CATEGORY, PRODUCT_TYPE, PAGE),
    {
      enabled: !!shop.plan,
    }
  );

  const handleOpenLogoWizard = useCallback(() => {
    navigate("/my-logo");
    dispatch(setIsOpen(true));
    analytics.sendEvent(analytics.PAYWALL_HIT, {
      location: "brand_plate_upload_files",
    });
  }, []);

  const onClickUseLogos = () => {
    if (profileHook.isFreePlan()) {
      onOpenModal({
        title:
          "Please upgrade to a paid plan to continue with your Brand Plate setup",
        isFreePlanLimit: true,
        onSuccess: () => {
          setConfirmModal(true);
        },
      });
      return;
    }

    // show the payment modal confirmation if the user has a brand plate order
    if (!shop?.billing_address) {
      // ask doug about this logic
      setConfirmModal(true);
    } else {
      setConfirmModal(true);
    }

    if (shop.has_brand_plate_order) {
      setConfirmBrandPlateModal(true);
    }
  };

  function createBrandPlateCartItem() {
    const brandPlateProduct = products?.data?.results[0];
    return {
      type: CART_TYPE_BRAND_PLATE,
      item: {
        id: brandPlateProduct.id,
        sku: brandPlateProduct.sku,
        quantity: 1,
        product: {
          ...brandPlateProduct,
          product_type: PRODUCT_TYPE_BRAND_PLATE,
        },
      },
    };
  }

  const handleCheckout = async () => {
    if (
      cart.status === IDLE &&
      cart.items.length > 0 &&
      cart.type !== CART_TYPE_BRAND_PLATE
    ) {
      toast.error(ClearCartError);
    }
    const item = createBrandPlateCartItem();
    // Create and update Cart
    try {
      await add(item, CART_TYPE_BRAND_PLATE);
    } catch (err) {
      toast.error(() => AddItemToCartError(err));
    }
  };

  const publishAllFiles = (files, orderId) =>
    Promise.all(
      files.map((file) => publishFile({ fileName: file.fileName, orderId }))
    );

  const handleSuccess = async () => {};

  async function placeOrder() {
    setIsLoading(true);
    // this needs to be the free product
    const brandPlateProduct = products?.data?.results[0];
    const useGennyImages = !hasPrintReadyLogos;
    const orderData = {
      use_genny_images: useGennyImages,
    };

    const response = await orderBrandPlate(
      brandPlateProduct?.color_name,
      cart,
      orderData
    );

    if (response.status === 200) {
      dispatch(setCurrentOrder(response.data));
      if (response.data.client_secret) {
        setClientSecret(response.data.client_secret);
        setNextActionType(response.data.next_action?.type || null);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      toast.error(`Error placing Brand Plate order`);
    }

    setIsLoading(false);
    return response;
  }

  const onConfirmUseLogos = async () => {
    if (
      cart.status === IDLE &&
      cart.items.length > 0 &&
      cart.type !== CART_TYPE_BRAND_PLATE
    ) {
      toast.error(ClearCartError);
      return;
    }

    dispatch(clearCart());
    const item = createBrandPlateCartItem();
    await add(item, CART_TYPE_BRAND_PLATE);
  };

  useEffect(() => {
    // this triggers once cart is updated from the backend
    if (
      cart.status === IDLE &&
      cart.items.length > 0 &&
      cart.confirmation_url === ""
    ) {
      if (cart.type === CART_TYPE_BRAND_PLATE) {
        setIsLoading(true);
        placeOrder().then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            if (hasPrintReadyLogos) {
              publishAllFiles(fileNames, response.data.id).then(() => {
                dispatch(getBrandPlateOrdersAssetsAsync(response.data.id));
              });
            }
            dispatch(clearCart());
            toast.success(brandPlateSuccess);
            navigate("/brand-plate/setup");
          }
        });
      }
    }
  }, [cart]);

  const handleMockupImageClassName = (index) => {
    switch (index) {
      case 0:
        return newStyles.mockupLogoDefault;
      case 1:
        return newStyles.mockupLogoInverted;
      case 2:
        return newStyles.mockupLogoThin;
      case 3:
        return newStyles.mockupLogoInvertedThin;
      default:
        return newStyles.mockupLogoDefault;
    }
  };

  const setUseSVGLogos = () => {
    if (profileHook.isFreePlan()) {
      onOpenModal({
        title:
          "Please upgrade to a paid plan to continue with your Brand Plate setup",
        isFreePlanLimit: true,
      });
      analytics.sendEvent(analytics.PAYWALL_HIT, {
        location: "brand_plate_upload_files",
      });
    } else {
      setUpload(true);
    }
  };

  const onFilesUploaded = (fileNames) => {
    setFileNames(fileNames);
    setHasPrintReadyLogos(true);
    onClickUseLogos();
  };

  const renderContent = () => {
    if (upload) {
      return (
        <Box>
          <BrandPlateFileUpload
            onFilesUploaded={onFilesUploaded}
            onBack={() => setUpload(false)}
          />
        </Box>
      );
    }

    if (logos.length > 0) {
      return (
        <Box>
          <Box className={newStyles.logosCard}>
            <Box className={newStyles.logosCardHeader}>
              <Typography
                variant="h3"
                fontSize={{ xs: 16, md: 18 }}
                color="#5E5E5E"
              >
                Your current logos
              </Typography>
              <Button
                variant="text"
                color="secondary"
                onClick={handleOpenLogoWizard}
                className={newStyles.logoWizardBtn}
                size="small"
              >
                <Box className={newStyles.logoWizardBtnContent}>
                  <SvgIcon
                    data-testid="MyLogoIcon"
                    className="icon icon--normal"
                    viewBox="0 0 20 20"
                  >
                    <g clipPath="url(#clip0_6164_81035)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.540039 14.335V18.075H4.28004L15.31 7.05501L11.57 3.31501L0.540039 14.335ZM3.45004 16.075H2.53004V15.155L11.57 6.13501L12.49 7.05501L3.45004 16.085V16.075ZM18.21 2.76501L15.87 0.42501C15.67 0.22501 15.42 0.13501 15.16 0.13501C14.9 0.13501 14.65 0.23501 14.46 0.42501L12.63 2.24501L16.37 5.98501L18.2 4.16501C18.59 3.77501 18.59 3.14501 18.2 2.76501H18.21Z"
                        fill="#246071"
                      />
                      <path
                        d="M0.5 3.08496C0.65 3.00496 0.78 2.92496 0.92 2.85496C1.41 2.59496 1.89 2.32496 2.38 2.06496C2.43 2.03496 2.48 1.98496 2.51 1.93496C2.7 1.59496 2.88 1.26496 3.07 0.924961C3.22 0.654961 3.36 0.394961 3.51 0.124961C3.51 0.104961 3.54 0.0949609 3.56 0.0849609C3.57 0.0949609 3.58 0.114961 3.59 0.124961C3.76 0.444961 3.94 0.774961 4.11 1.09496C4.25 1.34496 4.39 1.59496 4.52 1.84496C4.58 1.97496 4.68 2.05496 4.8 2.11496C5.37 2.42496 5.94 2.73496 6.51 3.04496C6.53 3.05496 6.55 3.08496 6.57 3.09496C6.04 3.38496 5.52 3.67496 4.99 3.95496C4.89 4.01496 4.78 4.06496 4.68 4.12496C4.64 4.14496 4.61 4.17496 4.59 4.21496C4.29 4.75496 3.99 5.30496 3.69 5.84496C3.66 5.90496 3.63 5.97496 3.6 6.03496C3.6 6.05496 3.57 6.06496 3.56 6.08496C3.54 6.06496 3.52 6.05496 3.51 6.03496C3.35 5.74496 3.2 5.45496 3.04 5.16496C2.87 4.86496 2.71 4.55496 2.54 4.25496C2.5 4.18496 2.45 4.12496 2.38 4.08496C1.8 3.77496 1.22 3.45496 0.64 3.13496C0.6 3.11496 0.56 3.09496 0.51 3.06496L0.5 3.08496Z"
                        fill="#246071"
                      />
                      <path
                        d="M13.27 14.0549C13.37 13.9949 13.46 13.9449 13.55 13.9049C13.88 13.7249 14.2 13.5549 14.53 13.3749C14.57 13.3549 14.6 13.3249 14.62 13.2849C14.75 13.0549 14.87 12.8349 15 12.6049C15.1 12.4249 15.2 12.2449 15.29 12.0649C15.29 12.0549 15.31 12.0449 15.32 12.0349C15.32 12.0349 15.34 12.0549 15.34 12.0649C15.46 12.2849 15.57 12.4949 15.69 12.7149C15.78 12.8849 15.88 13.0449 15.97 13.2249C16.01 13.3149 16.08 13.3649 16.16 13.4049C16.54 13.6149 16.93 13.8249 17.31 14.0349C17.33 14.0349 17.34 14.0549 17.35 14.0649C16.99 14.2649 16.64 14.4549 16.29 14.6449C16.22 14.6849 16.15 14.7149 16.08 14.7549C16.06 14.7649 16.03 14.7949 16.02 14.8149C15.82 15.1849 15.62 15.5449 15.41 15.9149C15.39 15.9549 15.37 16.0049 15.35 16.0449C15.35 16.0549 15.33 16.0649 15.32 16.0749C15.31 16.0649 15.3 16.0549 15.29 16.0449C15.18 15.8549 15.08 15.6549 14.97 15.4649C14.86 15.2649 14.75 15.0549 14.64 14.8549C14.61 14.8049 14.58 14.7649 14.53 14.7449C14.14 14.5349 13.75 14.3249 13.36 14.1049C13.33 14.0949 13.31 14.0749 13.27 14.0549Z"
                        fill="#246071"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6164_81035">
                        <rect
                          width="18"
                          height="18"
                          fill="white"
                          transform="translate(0.5 0.0749512)"
                        />
                      </clipPath>
                    </defs>
                  </SvgIcon>{" "}
                  <Typography
                    fontSize={14}
                    letterSpacing={2}
                    color="secondary"
                    fontWeight={600}
                  >
                    MY LOGO WIZARD
                  </Typography>
                </Box>
              </Button>
            </Box>

            <Box>
              <Grid container className={newStyles.mockupGridWrapper}>
                {mockups.map((mockup, index) => (
                  <Grid
                    item
                    key={`${mockup.image}-${mockup.caption}`}
                    className={newStyles.mockupGrid}
                  >
                    <Box className={newStyles.mockupImageWrapper}>
                      <Box className={handleMockupImageClassName(index)}>
                        <img
                          src={logos[index]?.logo || logos[0]?.logo}
                          alt=""
                          className={newStyles.mockupLogoImg}
                        />
                      </Box>
                      <img
                        src={mockup.image}
                        alt=""
                        className={newStyles.mockupImage}
                      />
                    </Box>
                    <Text className={newStyles.mockupImageCaption}>
                      {mockup.caption}
                    </Text>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>

          <Box className={newStyles.brandPlateStepOneAction}>
            <Button
              onClick={onClickUseLogos}
              color="primary"
              variant="contained"
              size="small"
            >
              USE THESE LOGOS
            </Button>
            <div
              onClick={() => {
                setUseSVGLogos();
              }}
            >
              <Typography
                fontSize={{ xs: 14, md: 16 }}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Or, upload print-ready logos
              </Typography>
            </div>
          </Box>
        </Box>
      );
    }

    return (
      <Box>
        <Box className={newStyles.noLogos}>
          <Box className={newStyles.wizardCard}>
            <Box className={newStyles.wizardCardHeader}>
              <SvgIcon
                style={{ width: 48, height: 48 }}
                className="icon icon--normal"
                viewBox="0 0 20 20"
              >
                <g clipPath="url(#clip0_6164_81035)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.540039 14.335V18.075H4.28004L15.31 7.05501L11.57 3.31501L0.540039 14.335ZM3.45004 16.075H2.53004V15.155L11.57 6.13501L12.49 7.05501L3.45004 16.085V16.075ZM18.21 2.76501L15.87 0.42501C15.67 0.22501 15.42 0.13501 15.16 0.13501C14.9 0.13501 14.65 0.23501 14.46 0.42501L12.63 2.24501L16.37 5.98501L18.2 4.16501C18.59 3.77501 18.59 3.14501 18.2 2.76501H18.21Z"
                    fill="#ebc5c0"
                  />
                  <path
                    d="M0.5 3.08496C0.65 3.00496 0.78 2.92496 0.92 2.85496C1.41 2.59496 1.89 2.32496 2.38 2.06496C2.43 2.03496 2.48 1.98496 2.51 1.93496C2.7 1.59496 2.88 1.26496 3.07 0.924961C3.22 0.654961 3.36 0.394961 3.51 0.124961C3.51 0.104961 3.54 0.0949609 3.56 0.0849609C3.57 0.0949609 3.58 0.114961 3.59 0.124961C3.76 0.444961 3.94 0.774961 4.11 1.09496C4.25 1.34496 4.39 1.59496 4.52 1.84496C4.58 1.97496 4.68 2.05496 4.8 2.11496C5.37 2.42496 5.94 2.73496 6.51 3.04496C6.53 3.05496 6.55 3.08496 6.57 3.09496C6.04 3.38496 5.52 3.67496 4.99 3.95496C4.89 4.01496 4.78 4.06496 4.68 4.12496C4.64 4.14496 4.61 4.17496 4.59 4.21496C4.29 4.75496 3.99 5.30496 3.69 5.84496C3.66 5.90496 3.63 5.97496 3.6 6.03496C3.6 6.05496 3.57 6.06496 3.56 6.08496C3.54 6.06496 3.52 6.05496 3.51 6.03496C3.35 5.74496 3.2 5.45496 3.04 5.16496C2.87 4.86496 2.71 4.55496 2.54 4.25496C2.5 4.18496 2.45 4.12496 2.38 4.08496C1.8 3.77496 1.22 3.45496 0.64 3.13496C0.6 3.11496 0.56 3.09496 0.51 3.06496L0.5 3.08496Z"
                    fill="#ebc5c0"
                  />
                  <path
                    d="M13.27 14.0549C13.37 13.9949 13.46 13.9449 13.55 13.9049C13.88 13.7249 14.2 13.5549 14.53 13.3749C14.57 13.3549 14.6 13.3249 14.62 13.2849C14.75 13.0549 14.87 12.8349 15 12.6049C15.1 12.4249 15.2 12.2449 15.29 12.0649C15.29 12.0549 15.31 12.0449 15.32 12.0349C15.32 12.0349 15.34 12.0549 15.34 12.0649C15.46 12.2849 15.57 12.4949 15.69 12.7149C15.78 12.8849 15.88 13.0449 15.97 13.2249C16.01 13.3149 16.08 13.3649 16.16 13.4049C16.54 13.6149 16.93 13.8249 17.31 14.0349C17.33 14.0349 17.34 14.0549 17.35 14.0649C16.99 14.2649 16.64 14.4549 16.29 14.6449C16.22 14.6849 16.15 14.7149 16.08 14.7549C16.06 14.7649 16.03 14.7949 16.02 14.8149C15.82 15.1849 15.62 15.5449 15.41 15.9149C15.39 15.9549 15.37 16.0049 15.35 16.0449C15.35 16.0549 15.33 16.0649 15.32 16.0749C15.31 16.0649 15.3 16.0549 15.29 16.0449C15.18 15.8549 15.08 15.6549 14.97 15.4649C14.86 15.2649 14.75 15.0549 14.64 14.8549C14.61 14.8049 14.58 14.7649 14.53 14.7449C14.14 14.5349 13.75 14.3249 13.36 14.1049C13.33 14.0949 13.31 14.0749 13.27 14.0549Z"
                    fill="#ebc5c0"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6164_81035">
                    <rect
                      width="18"
                      height="18"
                      fill="white"
                      transform="translate(0.5 0.0749512)"
                    />
                  </clipPath>
                </defs>
              </SvgIcon>
              <Box display="flex" flexDirection="column">
                <Typography
                  fontWeight={700}
                  fontSize={{ xs: 16, md: 20 }}
                  color="#5E5E5E"
                  variant="span"
                >
                  Let&apos;s build your brand
                </Typography>
                <Typography
                  fontSize={{ xs: 12, md: 16 }}
                  color="#5E5E5E"
                  variant="p"
                >
                  See your products instantly come to life!
                </Typography>
              </Box>
            </Box>
            <Button
              onClick={handleOpenLogoWizard}
              variant="contained"
              color="secondary"
              size="small"
            >
              Run my logo wizard
            </Button>
          </Box>
          <img src={lipStick} alt="" className={newStyles.lipStick} />
        </Box>
      </Box>
    );
  };

  return (
    <Box className={newStyles.brandPlateStepOne}>
      {!isLoadingLogos && <Box>{renderContent()}</Box>}

      <ConfirmModal
        open={confirmModal}
        handleClose={() => setConfirmModal(false)}
        handleConfirm={onConfirmUseLogos}
        isLoading={isLoading}
      />

      <ConfirmBrandPlateModal
        open={confirmBrandPlateModal}
        handleClose={() => setConfirmBrandPlateModal(false)}
        onConfirm={handleCheckout}
        loading={isLoading}
      />

      <Elements stripe={stripePromise}>
        <CheckoutConfirmPayment
          nextActionType={nextActionType}
          clientSecret={clientSecret}
          handlePaymentSuccess={handleSuccess}
          handlePaymentError={() => {
            setNextActionType();
            setClientSecret();
            setIsLoading(false);
          }}
        />
      </Elements>
    </Box>
  );
}

export default BrandPlateNew;
