import { Box, Grid, Typography } from "@mui/material";
import BrandPlateNav from "components/BrandPlateNav";
import PageHeader from "components/PageHeader";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  approveBrandPlateMockupApi,
  requestChangesToBrandPlateMockupApi,
} from "api/endpoints/ordersApi";
import Button from "components/Buttons/Button";
import { GenericSuccessNotification } from "components/Notifications";
import SuspenseLoading from "components/SuspenseLoading";
import { BRAND_PLATE_PROCESS, BRAND_PLATE_STATUS } from "constants/brandPlate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getBrandPlateOrdersAsync,
  selectBrandPlateOrderStatus,
  selectCurrentOrder,
  updateBrandPlateOrdersAsync,
} from "state/brandPlateSlice";
import { formatDateTime } from "utils/date";
import BrandPlateInProgress from "../BrandPlateView/components/BrandPlateInProgress";
import BrandPlateStatusProcess from "../BrandPlateView/components/BrandPlateStatusProcess";
import styles from "./BrandPlateCurrent.module.scss";
import BrandPlateApproved from "./components/BrandPlateApproved";
import BrandPlateInRevision from "./components/BrandPlateInRevision";
import BrandPlateMockupAccepted from "./components/BrandPlateMockupAccepted";
import BrandPlatePendingApproval from "./components/BrandPlatePendingApproval";

function BrandPlateCurrent() {
  const currentOrder = useSelector(selectCurrentOrder);
  const orderStatus = useSelector(selectBrandPlateOrderStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState("");

  useEffect(() => {
    dispatch(getBrandPlateOrdersAsync());
  }, []);

  const isOrderAvailable = useMemo(() => {
    if (Object.keys(currentOrder).length > 0) {
      return true;
    }
    return false;
  }, [currentOrder]);

  useEffect(() => {
    if (orderStatus === "idle" && currentOrder.id !== undefined) {
      // This api call only mutates the state of the BP order, if the current state is WAITING_FOR_LOGO
      dispatch(
        updateBrandPlateOrdersAsync({
          orderId: currentOrder.id,
          data: currentOrder,
        })
      );
    }
  }, [orderStatus]);

  const approveBrandPlateMockup = useCallback(
    async (id) => {
      try {
        setIsLoading("approve");
        await approveBrandPlateMockupApi(id);
        await dispatch(getBrandPlateOrdersAsync());
        toast.success(
          <GenericSuccessNotification text="You’ve accepted your pending Brand Plate" />
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading("");
      }
    },
    [dispatch, setIsLoading]
  );

  const requestChangesToBrandPlateMockup = useCallback(
    async (id) => {
      try {
        setIsLoading("change");
        await requestChangesToBrandPlateMockupApi(id);
        await dispatch(getBrandPlateOrdersAsync());
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading("");
      }
    },
    [dispatch, setIsLoading]
  );

  const renderStepContent = () => {
    // link other steps here
    // hard code "IN_PROGRESS" for now since status is in WAITING_FOR_LOGO
    switch (currentOrder.status) {
      case BRAND_PLATE_STATUS.PENDING_APPROVAL[0]:
        return (
          // move approveBrandPlateMockup and requestChangesToBrandPlateMockup to BrandPlatePendingApproval
          // only used in that component - let's clean up once we remove old code for branding
          <BrandPlatePendingApproval
            approveBrandPlateMockup={approveBrandPlateMockup}
            isLoading={isLoading}
            requestChangesToBrandPlateMockup={requestChangesToBrandPlateMockup}
          />
        );

      case BRAND_PLATE_STATUS.MOCKUP_ACCEPTED[0]:
        return <BrandPlateMockupAccepted />;

      case BRAND_PLATE_STATUS.APPROVED[0]:
        return <BrandPlateApproved />;

      case BRAND_PLATE_STATUS.IN_REVISION[0]:
        return <BrandPlateInRevision />;

      case BRAND_PLATE_STATUS.IN_PROGRESS[0]:
        return <BrandPlateInProgress order={currentOrder} />;

      case BRAND_PLATE_STATUS.WAITING_FOR_LOGO[0]:
        return <BrandPlateInProgress order={currentOrder} />;

      default:
        return "no Brand Plate found";
    }
  };

  if (orderStatus !== "idle") {
    return <SuspenseLoading />;
  }

  return (
    <Box className={styles.brandPlateCurrentPage}>
      <PageHeader title="My Brand Plate Setup" />
      <BrandPlateNav />

      {isOrderAvailable ? (
        <Box className={styles.brandPlateCardWrapper}>
          <Typography
            fontWeight={700}
            fontSize={{ xs: 20, md: 24 }}
            variant="h2"
            color="#5E5E5E"
          >
            Order #{currentOrder?.blanka_order_id}
          </Typography>
          <Typography
            fontWeight={300}
            fontSize={{ xs: 12, md: 16 }}
            variant="span"
            color="#5E5E5E"
          >
            {formatDateTime(currentOrder?.created_at)}
          </Typography>
          <Box className={styles.brandPlateCard}>
            <Box className={styles.brandPlateContent}>
              {renderStepContent()}
            </Box>
            <Box className={styles.brandPlateProcess}>
              <BrandPlateStatusProcess
                steps={BRAND_PLATE_PROCESS}
                // workaround to catch next status "WAITING_LOGO" when brand plate is created
                currentStep={
                  currentOrder?.status ===
                  BRAND_PLATE_STATUS.WAITING_FOR_LOGO[0]
                    ? BRAND_PLATE_STATUS.IN_PROGRESS[0]
                    : currentOrder.status
                }
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box mt={2}>
          <Typography
            variant="h1"
            fontSize={{ xs: 20, md: 24 }}
            color="#5E5E5E"
          >
            No brand plate order
          </Typography>
          <Box mt={2}>
            <Button
              onClick={() => navigate("/brand-plate/")}
              color="primary"
              variant="contained"
              size="small"
            >
              Create brand plate
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default BrandPlateCurrent;
