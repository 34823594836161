import React from "react";
import { LinearProgress } from "@mui/material";
import Logo from "assets/images/logo.png";
import styles from "./LoadingScreen.module.scss";

export default function LoadingScreen() {
  return (
    <main className={styles.loadingScreen}>
      <img
        alt="Logo"
        src={Logo}
        style={{ width: "200px", padding: "10px", margin: "0 auto" }}
      />
      <LinearProgress style={{ width: "190px" }} />
    </main>
  );
}
