import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// MUI:
import { Box, Stack } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// custom components and constants:
import AccountCard from "components/Cards/AccountCard";
import Text from "components/Text";

// hooks:
import { useProfile } from "hooks";

// view related components:

import YourPlanUsageBar from "./components/YourPlanUsageBar/YourPlanUsageBar";

import styles from "./YourPlanUsage.module.scss";

function YourPlanUsage(props) {
  const { profile, handleOpenChangePlanModal, title } = props;

  const { getPlanLimits } = useProfile();

  const displayUpgradeMessage = useSelector(
    (state) => state.changePlan.displayUpgradeMessage
  );

  return (
    <div>
      <AccountCard>
        <Text className={styles.planFeatures} variant="h2">
          {!title ? (
            "Current Usage"
          ) : (
            <Stack direction="column" gap="12px">
              <Text className={styles.title} variant="body">
                {title}
              </Text>
              <Text className={styles.subtitle} variant="body">
                PLAN Includes
              </Text>
            </Stack>
          )}
        </Text>

        <YourPlanUsageBar
          title="Unbranded Products"
          // description="Only applies to published products"
          count={profile?.shop?.count_number_of_unbranded_products}
          planLimit={getPlanLimits().unbranded}
        />

        <YourPlanUsageBar
          title="Branded Products"
          description="Only applies to published products"
          count={profile?.shop?.count_number_of_custom_products}
          planLimit={getPlanLimits().custom}
        />

        <div className="hidden">
          <YourPlanUsageBar
            title="VIP Exclusive Products"
            className="hidden"
            description=""
            // TODO: integrate the value from the backend
            count={1}
            planLimit={getPlanLimits().vip}
          />
        </div>

        {displayUpgradeMessage && (
          <Box className={styles.boxContainer}>
            <InfoOutlinedIcon className={styles.success} />
            <Text className={styles.upgradeMessage} variant="body2">
              Make the most of your free trial period – upgrade to remove these
              limits and get access to more features!{" "}
              <span
                className={styles.plansLink}
                onClick={handleOpenChangePlanModal}
              >
                Explore other plans
              </span>
            </Text>
          </Box>
        )}
      </AccountCard>
    </div>
  );
}

YourPlanUsage.propTypes = {
  profile: PropTypes.object.isRequired,
  handleOpenChangePlanModal: PropTypes.func.isRequired,
  title: PropTypes.string,
};

YourPlanUsage.defaultProps = {
  title: null,
};

export default YourPlanUsage;
