import { useRef, useEffect, useMemo } from "react";
import debounce from "lodash.debounce";
import { DEBOUNCE_DELAY } from "constants/constants";

// delay is kept with a 800 as default because of backwards compatibility
export const useDebounce = (callback, delay = DEBOUNCE_DELAY) => {
  // This ref will hold the latest arguments passed to the debounced function.
  const argsRef = useRef();

  // This ref will hold the latest version of the callback function.
  const ref = useRef();

  // Whenever the callback function changes, we update our callback ref
  // to hold its latest version.
  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  // Create a debounced function using useMemo to persist across re-renders.
  const debouncedCallback = useMemo(() => {
    // Ccalls the latest version of the callback with the latest arguments.
    const func = (...args) => {
      ref.current?.(...args);
    };

    // Create a debounced version of `func` using lodash's debounce.
    return debounce(func, delay);
  }, [delay]);

  // The function returned by useDebounce. It takes any number of arguments,
  // stores them in argsRef, and calls the debounced version of the function.
  return (...args) => {
    argsRef.current = args;
    debouncedCallback(...args);
  };
};
