import React from "react";
import PropTypes from "prop-types";

// MUI:
import { Box } from "@mui/material";

// Custom components & constants:
import Button from "components/Buttons/Button";
import Text from "components/Text";
import ModalWrapper from "components/ModalWrapper/ModalWrapper";

import { BRAND_PLATES_TYPES } from "constants/brandPlate";

// hooks:
import { useProfile } from "hooks";

import styles from "./ConfirmBrandPlateModal.module.scss";

export default function ConfirmBrandPlateModal(props) {
  const { open, onConfirm, handleClose, loading, brandPlateInfo } = props;

  const profileHook = useProfile();

  // derived state:
  const isOnTrial = profileHook.isOnTrial();

  return (
    <ModalWrapper isOpen={open} handleClose={handleClose}>
      <Box
        className={`${styles.confirmBrandPlateModal}`}
        data-testid="ConfirmBrandPlateModal"
      >
        <Text variant="h1" className={styles.title}>
          Confirm your new Brand Plate purchase!
        </Text>

        <div>
          <Box className={styles.contentContainer}>
            <Text className={styles.bpType}>New Brand Plate</Text>
            <Box className={styles.priceContainer}>
              <Text className={styles.newPrice}>$149</Text>
            </Box>
          </Box>

          <Text className="text--align-left">
            Your credit card on file will be charged $149 immediately and your
            order will be processed.
          </Text>
        </div>

        <Button
          loading={loading}
          variant="contained"
          color="primary"
          size="large"
          onClick={onConfirm}
          className={styles.cta}
        >
          Place Order
        </Button>
        <Text className={styles.warning}>
          Please note all brand plate orders are non-refundable.
        </Text>
      </Box>
    </ModalWrapper>
  );
}

ConfirmBrandPlateModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmBrandPlateModal.defaultProps = {
  open: false,
  loading: false,
};
