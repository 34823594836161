import queryClient from "api/queryClient";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveInnerStep,
  setActiveOuterStep,
  setIsGeneratorCompleted,
  setIsGeneratorStarted,
  setIsLogoUploaded,
  setLogo,
} from "state/myLogoSlice";
import { clearProfile } from "state/profileSlice";
import { logout } from "state/sessionSlice";

// logout view, can be used to rest someones front end
export default function LogoutView() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);

  // save shop url for when they Logout
  useEffect(() => {
    const persistLogin = new URLSearchParams(window.location.search).get(
      "no_persist"
    );
    const redirectSignup = new URLSearchParams(window.location.search).get(
      "redirect_signup"
    );

    // clear profile
    dispatch(logout(persistLogin ? null : profile.username));

    // remove cart items and reset
    // dispatch(clearCart());
    queryClient.clear();

    // reset the logo generator:

    dispatch(setIsGeneratorStarted(false));
    dispatch(setIsGeneratorCompleted(false));
    dispatch(setIsLogoUploaded(false));
    dispatch(setActiveOuterStep(-1));
    dispatch(setActiveInnerStep(0));

    dispatch(setLogo([]));

    // clear session variables for connect store
    sessionStorage.removeItem("woocommerce_store");
    // sessionStorage.removeItem("shopify_store");

    dispatch(clearProfile());

    // clear local storage
    ["featureFlags", "customize"].forEach((k) => localStorage.removeItem(k));

    if (redirectSignup) {
      window.location.href = "/signup";
      return;
    }
    window.location.href = "/login";
  }, []);

  return <div />;
}
