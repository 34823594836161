import { Box, Typography } from "@mui/material";
import BrandSetupWrapper from "components/BrandSetup/BrandSetupWrapper";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Edit } from "@mui/icons-material";
import { QUERY } from "api";
import {
  generateCustomizerConfig,
  postCustomizerAttributeValues,
} from "api/endpoints/brandSetupApi";
import { getProductMockupsV2 } from "api/endpoints/productsAPi";
import BrandSetupCustomizerImages from "components/BrandSetup/BrandSetupCustomizeImages";
import BrandSetupFormCustomize from "components/BrandSetup/BrandSetupFormCustomize";
import Button from "components/Buttons/Button";
import LoadingScreen from "components/LoadingScreen";
import ModalWrapper from "components/ModalWrapper";
import Text from "components/Text";
import { PRODUCT_TYPE_CUSTOM } from "constants/constants";
import useLogoCustomize from "hooks/useLogoCustomize";
import { useMutation, useQuery } from "react-query";
import {
  useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import ROUTE_PATHS from "routes/routePaths";
import { setLoading } from "state/myLogoSlice";
import cn from "utils/cn";
import getLogoContainerDimensions from "utils/getLogoContainerDimensions";
import getRotationAutoValue from "utils/getRotationAutoValue";
import styles from "./BrandSetupCustomizeView.module.scss";

function BrandSetupCustomizeView() {
  const [activeFilter, setActiveFilter] = React.useState("PRODUCT");
  const { setLogoContainerDimensions, setLogoExceedsContainer } =
    useLogoCustomize();
  const [generatorConfig, setGeneratorConfig] = useState(null);
  const [boxGeneratorConfig, setBoxGeneratorConfig] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedValuesProduct, setSelectedValuesProduct] = useState({});
  const [selectedValuesBox, setSelectedValuesBox] = useState({});
  const [isDirtyProduct, setIsDirtyProduct] = useState(false);
  const [isDirtyBox, setIsDirtyBox] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const params = useParams();

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      !isSubmitting && // Don't block during submission
      (isDirtyBox || isDirtyProduct) &&
      currentLocation.pathname !== nextLocation.pathname,
    [isDirtyBox, isDirtyProduct, isSubmitting]
  );

  const blocker = useBlocker(shouldBlock);

  // Handle the beforeunload event to trigger the blocker modal
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if ((isDirtyBox || isDirtyProduct) && !isSubmitting) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = "";
        return blocker.state === "blocked";
      }

      return undefined;
    };

    window.addEventListener("beforeunload", handleBeforeUnload, {
      passive: true,
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirtyBox, isDirtyProduct, blocker, isSubmitting]);

  useEffect(() => {
    const type = searchParams.get("type") || "PRODUCT";
    if (activeFilter !== type) {
      setActiveFilter(type);
    }
  }, [searchParams.get("type")]);

  const updateSearchParams = (type) => {
    const newSearchParams = new URLSearchParams({ type });
    setSearchParams(newSearchParams);
  };

  const onChangeCarouselImage = (index) => {
    const withError = Object.values(errors).length > 0;
    const element = document.getElementById(
      `accordion-${Object.keys(errors)?.[0]}`
    );

    if (withError) {
      if (element) {
        element.style.backgroundColor = "#b519192c";
        element.style.transition = "background-color 0.5s ease";
        setTimeout(() => {
          element.style.backgroundColor = "transparent";
        }, 600);
      }
    } else {
      updateSearchParams(index === 0 ? "PRODUCT" : "BOX");
    }
  };

  const generateConfigMutation = useMutation({
    mutationFn: (productDefinitionId) =>
      generateCustomizerConfig(productDefinitionId),
    onSuccess: (data) => {
      if (data?.data) {
        setGeneratorConfig(data?.data);
      }
    },
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  const generateBoxConfigMutation = useMutation({
    mutationFn: (productDefinitionId) =>
      generateCustomizerConfig(productDefinitionId),
    onSuccess: (data) => {
      if (data?.data) {
        setBoxGeneratorConfig(data?.data);
      }
    },
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  // only run if local data is not available
  const {
    data: apiData,
    isLoading,
    isFetched,
  } = useQuery(
    [QUERY.getProduct, params.sku, PRODUCT_TYPE_CUSTOM],
    () => getProductMockupsV2(params.sku, PRODUCT_TYPE_CUSTOM),
    {
      enabled: !!params?.sku,
      onError: (error) => {
        console.error(error);
      },
      refetchOnWindowFocus: false,
    }
  );

  // check if local data is available, if not fetch from backend
  const data = useMemo(() => {
    if (isFetched && apiData) {
      const boxData = apiData?.data?.product?.custom_boxes?.[0]?.product;

      const productData = apiData?.data?.product?.default_variant;
      const properData = {
        ...productData,
        box_data: { ...boxData, ...boxData?.default_variant },
        generator_attributes: apiData?.data?.product?.generator_attributes,
      };
      return properData;
    }

    return null;
  }, [isLoading, apiData]);

  const isBoxAvailable = useMemo(() => !!data?.box_data?.id, [data]);

  useEffect(() => {
    if (data) {
      generateConfigMutation.mutate(data?.product_definition);
      if (isBoxAvailable) {
        generateBoxConfigMutation.mutate(data?.box_data?.product_definition);
      }
    }
  }, [data, isBoxAvailable]);

  const attributes = useMemo(() => {
    const generatorAttributes = data?.generator_attributes;

    if (generatorAttributes) {
      return generatorAttributes.filter(
        ({ attribute }) => attribute?.input_type === "dropdown"
      );
    }

    return [];
  }, [data]);

  const isExceedsContainerProduct = useMemo(() => {
    const generatorAttributes = data?.generator_attributes;

    if (generatorAttributes) {
      const index = generatorAttributes.findIndex(
        ({ attribute }) => attribute?.slug === "exceeds-container"
      );

      return data?.generator_attributes?.[index]?.options?.[0]?.value_json;
    }

    return false;
  }, [data]);

  const isExceedsContainerBox = useMemo(() => {
    const generatorAttributes = data?.box_data?.generator_attributes;

    if (generatorAttributes) {
      const index = generatorAttributes.findIndex(
        ({ attribute }) => attribute?.slug === "exceeds-container"
      );

      return data?.generator_attributes?.[index]?.options?.[0]?.value_json;
    }

    return false;
  }, [data?.box_data]);

  useEffect(() => {
    // this is how we render the logo container boundary
    if (activeFilter === "PRODUCT") {
      setLogoExceedsContainer(isExceedsContainerProduct);
    } else if (activeFilter === "BOX" && isBoxAvailable) {
      setLogoExceedsContainer(isExceedsContainerBox);
    }
  }, [isExceedsContainerProduct, isExceedsContainerBox, activeFilter]);

  const defaultValues = useMemo(() => {
    const values = {};

    const defaultLogo = attributes
      .find(({ attribute }) => attribute?.slug === "logo-type")
      ?.options?.find((value) => value?.is_default)?.file_url;

    attributes?.forEach(async ({ attribute, options }) => {
      const val = options.findIndex((value) => value?.is_default);
      if (attribute?.slug === "size") {
        // due to the slider component, we need to use the index
        values[attribute?.slug] = val;
      } else if (attribute?.slug === "rotations") {
        // check if default value is rotation auto
        if (options?.[val]?.value_json === "auto") {
          const rotation = await getRotationAutoValue(defaultLogo);
          values[attribute?.slug] = rotation;
        } else {
          values[attribute?.slug] = options?.[val]?.value_json;
        }
      } else {
        values[attribute?.slug] = options?.[val]?.value_json;
      }
    });

    return values;
  }, [attributes]);

  const defaultContainerData = useMemo(() => {
    const generatorAttributes = data?.generator_attributes;

    if (generatorAttributes) {
      const containerData = generatorAttributes.filter(
        ({ attribute }) => attribute?.input_type === "numeric"
      );

      console.log("LOOK CONTAINER DATA", containerData);

      return containerData;
    }

    return [];
  }, [data]);

  const boxAttributes = useMemo(() => {
    if (isBoxAvailable) {
      const generatorAttributes = data?.box_data?.generator_attributes;

      if (generatorAttributes) {
        return generatorAttributes.filter(
          ({ attribute }) => attribute?.input_type === "dropdown"
        );
      }
    }
    return [];
  }, [data, isBoxAvailable]);

  const boxDefaultValues = useMemo(() => {
    if (isBoxAvailable) {
      const values = {};

      const defaultLogo = boxAttributes
        .find(({ attribute }) => attribute?.slug === "logo-type")
        ?.options?.find((value) => value?.is_default)?.file_url;

      boxAttributes?.forEach(async ({ attribute, options }) => {
        const val = options.findIndex((value) => value?.is_default);
        if (attribute?.slug === "size") {
          // due to the slider component, we need to use the index
          values[attribute?.slug] = val;
        } else if (attribute?.slug === "rotations") {
          // check if default value is rotation auto
          if (options?.[val]?.value_json === "auto") {
            const rotation = await getRotationAutoValue(defaultLogo);
            values[attribute?.slug] = rotation;
          } else {
            values[attribute?.slug] = options?.[val]?.value_json;
          }
        } else {
          values[attribute?.slug] = options?.[val]?.value_json;
        }
      });
      return values;
    }

    return null;
  }, [boxAttributes, isBoxAvailable]);

  const boxDefaultContainerData = useMemo(() => {
    if (isBoxAvailable) {
      const generatorAttributes = data?.box_data?.generator_attributes;

      if (generatorAttributes) {
        return generatorAttributes.filter(
          ({ attribute }) => attribute?.input_type === "numeric"
        );
      }
    }
    return [];
  }, [data, isBoxAvailable]);

  useEffect(() => {
    // this is how we render the logo container boundary
    if (activeFilter === "PRODUCT") {
      setLogoContainerDimensions(
        getLogoContainerDimensions(defaultContainerData)
      );
    } else if (activeFilter === "BOX" && isBoxAvailable) {
      setLogoContainerDimensions(
        getLogoContainerDimensions(boxDefaultContainerData)
      );
    }
  }, [defaultContainerData, boxDefaultContainerData, activeFilter]);

  useEffect(() => {
    const updateValues = (config, attributes, setValues, defaultValues) => {
      if (config?.references?.length > 0) {
        const values = {};
        config.references.forEach((reference) => {
          const slug = reference?.attribute?.slug;
          const options = attributes.find(
            ({ attribute }) => attribute?.slug === slug
          )?.options;

          values[slug] =
            slug === "size"
              ? options.findIndex((value) => value.name === reference.name)
              : reference.value_json;
        });
        setValues(values);
      } else if (attributes) {
        setValues(defaultValues);
      }
    };

    updateValues(
      generatorConfig,
      attributes,
      setSelectedValuesProduct,
      defaultValues
    );

    if (isBoxAvailable) {
      updateValues(
        boxGeneratorConfig,
        boxAttributes,
        setSelectedValuesBox,
        boxDefaultValues
      );
    }
  }, [
    generatorConfig,
    boxGeneratorConfig,
    attributes,
    boxAttributes,
    defaultValues,
    boxDefaultValues,
    isBoxAvailable,
  ]);

  const renderGroupFilters = () =>
    ["PRODUCT", "BOX"]
      .filter((val) => {
        if (val === "PRODUCT") {
          return true;
        }
        if (isBoxAvailable && val === "BOX") {
          return true;
        }
        return false;
      })
      .map((filter) => {
        const edited = filter === "BOX" ? isDirtyBox : isDirtyProduct;
        const withError = Object.values(errors).length > 0;
        const element = document.getElementById(
          `accordion-${Object.keys(errors)?.[0]}`
        );

        return (
          <Box
            onClick={() => {
              if (activeFilter !== filter) {
                if (withError) {
                  if (element) {
                    element.style.backgroundColor = "#b519192c";
                    element.style.transition = "background-color 0.5s ease";
                    setTimeout(() => {
                      element.style.backgroundColor = "transparent";
                    }, 600);
                  }
                } else {
                  updateSearchParams(filter);
                  setActiveFilter(filter);
                }
              }
            }}
            className={cn(
              styles.groupFilterLinkWrapper,
              activeFilter === filter && styles.groupFilterLinkWrapperInactive,
              !isBoxAvailable && styles.groupFilterLinkWrapperNoBox
            )}
          >
            <Text
              variant="body1"
              fontSize={12}
              letterSpacing="4.8px"
              textTransform="uppercase"
              color="gray"
              lineHeight="16px"
              className={`${cn(
                styles.groupFilterLink,
                activeFilter === filter && styles.groupFilterLinkActive
              )} target__product-customizer--${filter}`}
            >
              {filter}
            </Text>
            {edited && (
              <div className={styles.editedIcon}>
                <Edit className={styles.icon} />
              </div>
            )}
          </Box>
        );
      });

  const postAttributes = useMutation({
    mutationFn: (payload) => postCustomizerAttributeValues(payload),
    onSuccess: (data) => {
      if (data?.data) {
        console.log("Success:", data?.data);
      }
    },
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  const postAttributesBox = useMutation({
    mutationFn: (payload) => postCustomizerAttributeValues(payload),
    onSuccess: (data) => {
      if (data?.data) {
        console.log("Success:", data?.data);
      }
    },
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  const onConfirm = async () => {
    setIsSubmitting(true);

    const attributeValues = [];

    Object.keys(selectedValuesProduct).forEach((key) => {
      const options = attributes?.find(
        ({ attribute }) => attribute?.slug === key
      )?.options;

      if (key === "size") {
        // size is based on index due to slider component
        const payload = {
          id: options?.[selectedValuesProduct?.[key]]?.id,
          value_json: options?.[selectedValuesProduct?.[key]]?.value_json,
          reference_config_id: generatorConfig?.id,
        };

        if (!isExceedsContainerProduct) {
          attributeValues.push(payload);
        }
      } else {
        const id = options?.find((val) => {
          if (key === "rotations") {
            return val?.value_json === Number(selectedValuesProduct[key]);
          }
          return val?.value_json === selectedValuesProduct[key];
        });

        const payload = {
          id: id?.id,
          value_json: selectedValuesProduct[key],
          reference_config_id: generatorConfig?.id,
        };

        attributeValues.push(payload);
      }
    });

    postAttributes.mutate({ attribute_updates: attributeValues });

    if (isBoxAvailable) {
      const attributeValuesBox = [];

      Object.keys(selectedValuesBox).forEach((key) => {
        const options = boxAttributes?.find(
          ({ attribute }) => attribute?.slug === key
        )?.options;

        if (key === "size") {
          // size is based on index due to slider component
          const payload = {
            id: options?.[selectedValuesBox?.[key]]?.id,
            value_json: options?.[selectedValuesBox?.[key]]?.value_json,
            reference_config_id: boxGeneratorConfig?.id,
          };

          if (!isExceedsContainerBox) {
            attributeValuesBox.push(payload);
          }
        } else {
          const id = options?.find((val) => {
            if (key === "rotations") {
              return val?.value_json === Number(selectedValuesBox[key]);
            }
            return val?.value_json === selectedValuesBox[key];
          });

          const payload = {
            id: id?.id,
            value_json: selectedValuesBox[key],
            reference_config_id: boxGeneratorConfig?.id,
          };

          attributeValuesBox.push(payload);
        }
      });

      postAttributesBox.mutate({ attribute_updates: attributeValuesBox });
    }
  };

  useEffect(() => {
    if (postAttributes.isSuccess) {
      if (isBoxAvailable && !postAttributesBox.isSuccess) {
        setLoading(false);
        return;
      }

      sessionStorage.setItem("generating", data?.product_definition);
      toast.success("Customization saved successfully");
      navigate(ROUTE_PATHS.BRAND_SETUP_REVIEW);
      setLoading(false);
    }
  }, [postAttributes.isSuccess, postAttributesBox, isBoxAvailable]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <BrandSetupWrapper pageTitle="My Brand Setup">
      <Box className={styles.brandSetupHeader}>
        <Box className={styles.brandSetupNav}>
          <Text variant="h1" fontSize="24" color="gray">
            Edit Logo
          </Text>
          <Box className={styles.groupFilters}>{renderGroupFilters()}</Box>
        </Box>
        <Text
          className={styles.productName}
        >{`${data?.name}${activeFilter === "BOX" ? " BOX" : ""}`}</Text>
      </Box>
      <Box className={styles.brandSetupCustomize}>
        <Box className={styles.brandSetupForms}>
          {activeFilter === "PRODUCT" && generateConfigMutation.isSuccess ? (
            <BrandSetupFormCustomize
              id="brandSetupProductForm"
              key="brandSetupProductForm"
              attributes={attributes}
              selectedValues={selectedValuesProduct}
              setSelectedValues={setSelectedValuesProduct}
              blocker={blocker}
              setIsDirty={setIsDirtyProduct}
              onConfirm={onConfirm}
              isSubmitting={isSubmitting}
              defaultValues={defaultValues}
              setErrors={setErrors}
              activeFilter={activeFilter}
            />
          ) : (
            isBoxAvailable &&
            generateBoxConfigMutation.isSuccess && (
              <BrandSetupFormCustomize
                id="brandSetupProductBoxForm"
                key="brandSetupProductBoxForm"
                attributes={boxAttributes}
                selectedValues={selectedValuesBox}
                setSelectedValues={setSelectedValuesBox}
                blocker={blocker}
                setIsDirty={setIsDirtyBox}
                onConfirm={onConfirm}
                isSubmitting={isSubmitting}
                defaultValues={boxDefaultValues}
                setErrors={setErrors}
                activeFilter={activeFilter}
              />
            )
          )}
        </Box>
        {generateConfigMutation.isSuccess && (
          <Box className={styles.brandSetupImages}>
            <BrandSetupCustomizerImages
              onChangeCarouselImage={onChangeCarouselImage}
              selectedItem={activeFilter === "BOX" ? 1 : 0}
              images={
                isBoxAvailable
                  ? [
                      {
                        image: data?.image,
                      },
                      {
                        image: data?.box_data?.image,
                      },
                    ]
                  : [
                      {
                        image: data?.image,
                      },
                    ]
              }
            />
          </Box>
        )}
      </Box>

      <ModalWrapper
        isOpen={blocker.state === "blocked"}
        handleClose={() => blocker.reset?.()}
        data-testid="confirmModal"
        className={styles.confirmModal}
      >
        <Box
          display="flex"
          flexDirection="column"
          rowGap={2}
          py={2}
          px={{ xs: 2, md: 4 }}
          textAlign="center"
        >
          <Box>
            <Typography
              variant="h1"
              fontSize={{ xs: 20, md: 24 }}
              color="#5E5E5E"
            >
              You have unconfirmed changes.
            </Typography>
            <Typography
              display="block"
              mt={2}
              variant="p"
              fontSize={{ xs: 14, md: 16 }}
              color="#5E5E5E"
            >
              Are you sure you want to discard them?
            </Typography>
          </Box>

          <Box
            display="flex"
            gap={2}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="outlined"
              color="secondary"
              type="button"
              size="small"
              fullWidth
              onClick={() => blocker.reset?.()}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="button"
              size="small"
              fullWidth
              onClick={() => blocker.proceed?.()}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </ModalWrapper>
    </BrandSetupWrapper>
  );
}

export default BrandSetupCustomizeView;
