import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { useSelector } from "react-redux";

import Text from "components/Text";

import { CUSTOM_PRODUCT_STATUS_NONE } from "constants/constants";
import styles from "./MyLogoMobileStepper.module.scss";

function MyLogoMobileStepper(props) {
  const { handlePrevious, handleClose, children } = props;
  const { isGeneratorStarted } = useSelector((state) => state.myLogo);
  const shop = useSelector((state) => state.profile.shop);

  return (
    <div className={styles.myLogoMobileStepper}>
      <Box item className={styles.header}>
        <Typography
          onClick={handlePrevious}
          className={isGeneratorStarted ? styles.headerBackIcon : "hidden"}
        >
          <KeyboardBackspaceIcon
            className={
              shop?.custom_product_status !== CUSTOM_PRODUCT_STATUS_NONE &&
              styles.hidden
            }
          />
        </Typography>

        <Text
          fontSize={16}
          color="white"
          className={styles.textTitle}
          variant="h1"
        >
          {shop?.custom_product_status !== CUSTOM_PRODUCT_STATUS_NONE
            ? "Current Brand"
            : "build your brand"}
        </Text>
        <Typography
          onClick={handleClose}
          className={`${styles.modalCloseButton} close__button`}
        >
          [x]
        </Typography>
      </Box>
      <Box item className={styles.body}>
        {children}
      </Box>
    </div>
  );
}

MyLogoMobileStepper.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

MyLogoMobileStepper.defaultProps = {};

export default MyLogoMobileStepper;
