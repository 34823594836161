import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import Button from "components/Buttons/Button";
import Card from "@mui/material/Card";
import Text from "components/Text";
import TextField from "@mui/material/TextField";
import PageHeader from "components/PageHeader/PageHeader";
import PasswordStrengthBar from "components/PasswordStrengthBar";
import { IconButton, InputAdornment, FormControl, Box } from "@mui/material";
import { useMutation } from "react-query";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { ArrowBack } from "@mui/icons-material";
import { changePassword } from "api";
import { toast } from "react-toastify";
import { GenericSuccessNotification } from "components/Notifications";

import styles from "./AccountPasswordUpdateView.module.scss";

const { useDebounce } = require("hooks/useDebounce");

function AccountPasswordUpdateView() {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const profile = useSelector((state) => state.profile.profile);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [error, setError] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const [message, setMessage] = useState("");

  const checkPasswordMatch = useDebounce(() => {
    if (password.new_password !== password.confirm_password) {
      setError("New Password and Confirm Password do not match.");
    } else {
      setError("");
    }
  }, 800);

  useEffect(() => {
    checkPasswordMatch();
  }, [password.confirm_password]);

  const handleCurrentPasswordChange = (event) => {
    setPassword({ ...password, old_password: event.target.value });
  };

  const handleNewPasswordChange = (event) => {
    setMessage("");
    setPassword({ ...password, new_password: event.target.value });
  };

  const handleConfirmPasswordChange = (event) => {
    setPassword({ ...password, confirm_password: event.target.value });
  };

  const isValid = () => {
    if (
      password.new_password &&
      password.new_password.length > 6 &&
      password.new_password === password.confirm_password
    ) {
      return true;
    }

    return false;
  };

  const navigate = useNavigate();

  const updatePasswordMutation = useMutation(changePassword);

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleBackBtn = () => {
    setLoadingCancel(true);
    window.history.back();
  };

  const handleCancelSubmit = () => {
    window.history.back();
  };

  const handleSubmit = () => {
    setLoadingSave(true);
    updatePasswordMutation.mutate(
      {
        old_password: password.old_password,
        new_password: password.new_password,
      },
      {
        onSuccess: (response) => {
          setLoadingSave(false);
          if (response?.status === 200) {
            toast.success(
              <GenericSuccessNotification text="Your password has been updated" />
            );
            navigate("/profile/password");
          }
        },
        onError: () => {
          setLoadingSave(false);
          setPassword({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });

          setMessage(
            `Oops! Please ensure your new password meets the strength requirements and your old password is correct`
          );
        },
      }
    );
  };

  return (
    <div className="template-profile-password root">
      <PageHeader title="My Account" />
      <Box className={styles.backBtn} onClick={handleBackBtn}>
        <ArrowBack /> Back
      </Box>

      <Card className="card" variant="outlined">
        <div className="header-text-container">
          <Text className="text__title text--georgia text--bold" color="gray">
            Password
          </Text>

          <Text
            className="text__secure-message text--nunito"
            variant="body1"
            color="gray"
          >
            All passwords are secure and encrypted. All passwords must meet the
            following requirements:
            <ul className="pl-10">
              <li className="text__secure-message text--nunito">
                Your password can&apos;t be too similar to your other personal
                information.
              </li>
              <li className="text__secure-message text--nunito">
                Your password must contain at least 8 characters.
              </li>
              <li className="text__secure-message text--nunito">
                Your password can’t be a commonly used password or an old
                password.
              </li>
              <li className="text__secure-message text--nunito">
                Your password can’t be entirely numeric.
              </li>
            </ul>
          </Text>

          <div className={`${styles.formControl}`}>
            {profile && profile.has_usable_password && (
              <Text className={styles.passwordLabel}>Current Password</Text>
            )}

            <FormControl className={styles.formControlWrapper}>
              {profile?.has_usable_password && (
                <TextField
                  variant="outlined"
                  required
                  InputLabelProps={{ shrink: true }}
                  id="old_password"
                  value={password.old_password}
                  type={showCurrentPassword ? "text" : "password"}
                  onChange={handleCurrentPasswordChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={toggleShowCurrentPassword}
                          edge="end"
                        >
                          {showCurrentPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <Text className={styles.passwordLabel}>New Password</Text>

              <TextField
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                id="newPassword"
                value={password.new_password}
                type={showPassword ? "text" : "password"}
                onChange={handleNewPasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <PasswordStrengthBar
                password={password.new_password}
                width="300px"
                margin="10px 0"
                handleStrengthChange={(strength) =>
                  setPasswordStrength(strength)
                }
                isTop={false}
              />

              <Text className={styles.passwordLabel}>Confirm Password</Text>
              <TextField
                variant="outlined"
                required
                InputLabelProps={{ shrink: true }}
                id="confirmPassword"
                value={password.confirm_password}
                type={showConfirmPassword ? "text" : "password"}
                onChange={handleConfirmPasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {error ? <Text className={styles.error}>{error}</Text> : <p> </p>}
              {message && <Text className={styles.error}>{message}</Text>}
              <div className={styles.buttonGroup}>
                <Button
                  disabled={!isValid() || passwordStrength < 3}
                  variant="contained"
                  color="primary"
                  loading={loadingSave}
                  onClick={handleSubmit}
                  className={styles.saveCancelButtons}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  loading={loadingCancel}
                  onClick={handleCancelSubmit}
                  className={styles.saveCancelButtons}
                >
                  Cancel
                </Button>
              </div>
            </FormControl>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default AccountPasswordUpdateView;
