import React from "react";

import { Box } from "@mui/material";
import Text from "components/Text";

import BlankaLogo from "assets/images/blanka-logo_trademark.png";
import QuoteCloseIcon from "components/Icons/QuoteCloseIcon";
import QuoteOpenIcon from "components/Icons/QuoteOpenIcon";
import { SMALL } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";
import OnboardingWrapperB from "../OnboardingWrapperB";
import OnboardingQuestionForms from "./OnboardingQuestionForms";
import styles from "./OnboardingQuestionsStep.module.scss";

export default function OnboardingQuestionsStep() {
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  return (
    <OnboardingWrapperB>
      <Box className={styles.leftSide}>
        {!isMobile ? (
          <>
            <img
              className={styles.blankaLogo}
              src={BlankaLogo}
              alt="Blanka Logo"
            />
            <div className={styles.quoteContainer}>
              <div className={styles.quote}>
                <QuoteOpenIcon className={styles.openQuote} />
                <QuoteCloseIcon className={styles.closeQuote} />
                <Text
                  variant="body1"
                  className={styles.quoteText}
                  fontSize="16"
                >
                  The team from Blanka has been amazing! Friendly, quick and
                  accurate help to get things organised and kick start my
                  skincare venture. The products are high quality and the
                  experience getting my brand on them has been seemless :)
                  Highly recommend!!
                </Text>

                <Text variant="body1" className={styles.quoteAuthor}>
                  ANTHONY
                </Text>
              </div>
            </div>
            <div />
          </>
        ) : (
          <img
            className={styles.blankaLogo}
            src={BlankaLogo}
            alt="Blanka Logo"
          />
        )}
      </Box>

      <Box className={styles.rightSide}>
        <OnboardingQuestionForms />
      </Box>
    </OnboardingWrapperB>
  );
}
