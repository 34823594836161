import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function QuoteCloseIcon(props) {
  return (
    <SvgIcon {...props} width="32" height="26" viewBox="0 0 32 26" fill="none">
      <path
        id="close quote"
        d="M0.393736 22.5861C3.09023 21.2737 5.20209 19.7465 6.72931 18.0045C8.25653 16.2625 9.13945 14.1506 9.37808 11.6689H7.3736C5.89411 11.6689 4.68904 11.5019 3.75839 11.1678C2.82774 10.8337 2.07606 10.3684 1.50336 9.77181C0.954512 9.19911 0.560776 8.56674 0.322148 7.87472C0.107383 7.1827 0 6.46682 0 5.72707C0 4.19985 0.608501 2.86353 1.8255 1.71812C3.06637 0.572707 4.522 0 6.19239 0C8.8173 0 10.8337 0.870992 12.2416 2.61298C13.6495 4.35496 14.3535 6.7651 14.3535 9.8434C14.3535 12.7069 13.1365 15.5943 10.7025 18.5056C8.29232 21.4169 5.38106 23.6242 1.96868 25.1275L0.393736 22.5861ZM18.0403 22.5861C20.7368 21.2737 22.8486 19.7465 24.3758 18.0045C25.9031 16.2625 26.786 14.1506 27.0246 11.6689H25.0201C23.5406 11.6689 22.3356 11.5019 21.4049 11.1678C20.4743 10.8337 19.7226 10.3684 19.1499 9.77181C18.601 9.19911 18.2073 8.56674 17.9687 7.87472C17.7539 7.1827 17.6465 6.46682 17.6465 5.72707C17.6465 4.19985 18.255 2.86353 19.472 1.71812C20.7129 0.572707 22.1685 0 23.8389 0C26.4638 0 28.4802 0.870992 29.8881 2.61298C31.296 4.35496 32 6.7651 32 9.8434C32 12.7069 30.783 15.5943 28.349 18.5056C25.9389 21.4169 23.0276 23.6242 19.6152 25.1275L18.0403 22.5861Z"
        fill="#D56F5B"
      />
    </SvgIcon>
  );
}
