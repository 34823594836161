import React from "react";

// MUI components
import { Box } from "@mui/material";

// Custom components
import Button from "components/Buttons/Button";
import Text from "components/Text";
import ribbonBlackFriday from "assets/images/black-friday/modal-ribbon.svg";

import styles from "./BlackFridayModalNotification.module.scss";

export default function BlackFridayModalNotification() {
  return (
    <Box className={styles.blackFridayModalNotification}>
      <img
        src={ribbonBlackFriday}
        alt="Black Friday ribbon"
        className={styles.ribbon}
      />
      <Text variant="body1" className={styles.info}>
        Limited time only
      </Text>
      <Text variant="h1" className={styles.title}>
        Get a $200 credit for Marketing Services when you upgrade to any Annual
        plan.*
      </Text>
      <Text variant="body1" className={styles.disclaimer}>
        *Promo ends 11:59 pm PT December 2nd.{` `}
        <a
          className={styles.terms}
          target="_blank"
          href="https://faq.blankabrand.com/en/articles/10034545-black-friday-cyber-monday-promo-2024"
          rel="noreferrer"
        >
          Terms & Conditions
        </a>
      </Text>
    </Box>
  );
}
