import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";

import { SMALL } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import ROUTE_PATHS from "routes/routePaths";
import styles from "./BrandSetupSteps.module.scss";

const BRAND_STEP_STEPS = [
  {
    title: "Upload Your Logo",
    path: ROUTE_PATHS.BRAND_SETUP_UPLOAD,
    substep: {
      title: "",
      path: ROUTE_PATHS.BRAND_SETUP_MOCKUPS_CHECKOUT,
    },
  },
  { title: "Review Mockups", path: ROUTE_PATHS.BRAND_SETUP_REVIEW },
  { title: "Approve Your Logo", path: ROUTE_PATHS.BRAND_SETUP },
];

function CustomStepIcon(props) {
  const { active, completed, icon } = props;

  return (
    <div
      className={`${styles.stepIconRoot} ${
        active ? styles.stepIconActive : ""
      } ${completed ? styles.stepIconCompleted : ""}`}
    >
      <div className={styles.stepIconCircle}>
        <span className={styles.stepIconText}>{icon}</span>
      </div>
    </div>
  );
}

function BrandSetupSteps() {
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const { pathname } = useLocation();

  const activeStep = useMemo(() => {
    const currentStepIndex = BRAND_STEP_STEPS.findIndex(
      (step) => step.path === pathname || step.substep?.path === pathname
    );

    return currentStepIndex;
  }, [pathname]);

  return (
    <Box>
      <Typography
        mb={1}
        className={styles.brandPlateStatusProcessTitle}
        variant="h3"
      >
        {!isMobile ? "Steps" : " "}
      </Typography>
      <Box className={styles.brandPlateStatusProcess}>
        <Stepper
          activeStep={activeStep}
          orientation={isMobile ? "horizontal" : "vertical"}
          classes={{
            root: styles.stepperRoot,
            vertical: styles.stepperVertical,
          }}
          connector={
            <StepConnector classes={{ line: styles.stepConnectorLine }} />
          }
          alternativeLabel={isMobile}
        >
          {BRAND_STEP_STEPS.map((step) => (
            <Step key={step.title}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: styles.stepIconRoot,
                    active: styles.stepIconActive,
                    // completed: styles.stepIconCompleted, // @Rain, I changed the completed tag per request from Karen.
                    completed: styles.stepIconDisabled,
                    error: styles.stepIconError,
                    text: styles.stepIconText,
                  },
                }}
                StepIconComponent={CustomStepIcon}
                classes={{
                  root: styles.stepLabelRoot,
                  active: styles.stepLabelActive,
                  // completed: styles.stepLabelCompleted, // @Rain, I changed the completed tag per request from Karen.
                  // completed: styles.stepLabelDisabled,
                  error: styles.stepLabelError,
                  alternativeLabel: styles.stepLabelAlternativeLabel,
                  disabled: styles.stepLabelDisabled,
                }}
              >
                {step.title}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
}

export default BrandSetupSteps;
