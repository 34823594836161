import React from "react";
import PropTypes from "prop-types";

import ModalWrapper from "components/ModalWrapper";
import Text from "components/Text";
import Button from "components/Buttons/Button";

import { Box } from "@mui/material";

import styles from "./EnableAutomaticPaymentModal.module.scss";

function EnableAutomaticPaymentModal(props) {
  const { open, handleClose, setEnableAutomaticPayment } = props;

  const handleEnableAutomaticPayments = () => {
    setEnableAutomaticPayment(true);
    handleClose();
  };

  return (
    <ModalWrapper
      isOpen={open}
      handleClose={handleClose}
      headerType="default"
      title="Enable automatic payments"
    >
      <Box className={styles.enableAutomaticPaymentModal}>
        <Text className={styles.textHeader} variant="h1">
          Enable automatic payments
        </Text>

        <Text variant="body1" className={styles.textWarning}>
          Your credit card on file will automatically be charged for each new
          Customer order when it is placed.
        </Text>

        <Text variant="body1" className={styles.textDanger}>
          If you exceed any limits on your plan, you will need to upgrade before
          new customer orders can be processed.
        </Text>

        <Button
          variant="contained"
          color="primary"
          hasChevron
          size="small"
          fullWidth
          onClick={handleEnableAutomaticPayments}
        >
          Yes, I agree
        </Button>
      </Box>
    </ModalWrapper>
  );
}

EnableAutomaticPaymentModal.propTypes = {
  open: PropTypes.bool.isRequired,

  handleClose: PropTypes.func,
};

EnableAutomaticPaymentModal.defaultProps = {
  handleClose: () => {},
};

export default EnableAutomaticPaymentModal;
