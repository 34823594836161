import React, { useState } from "react";
import PropType from "prop-type";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

// mui:
import { Box, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// components and constants:
import Text from "components/Text";
import PlanVisualizerCard from "components/Cards/PlanVisualizerCard/PlanVisualizerCard";
import BillingCycleSelector from "components/Selectors/BillingCycleSelector/BillingCycleSelector";
import { SMALL } from "constants/breakpoints";

// assets:
import WhiteBlankaLogoImg from "assets/images/white-blanka-logo.svg";

import styles from "./SidePanelPlanSelectionB.module.scss";

export default function SidePanelPlanSelectionB(props) {
  const { plan, planPair, onPrevious, onPlanSelect, isAnnualSelected } = props;

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const handleOnPrevious = () => {
    onPrevious();
  };

  const handleClick = async (data) => {
    onPlanSelect(data);
  };

  return (
    <Box className={styles.sidePanelPlanSelectionB}>
      {!isMobile && (
        <Box className={styles.link} onClick={handleOnPrevious}>
          <ArrowBackIcon /> Pick Your Plan
        </Box>
      )}
      <Stack className={styles.stackContainer}>
        <Box className={styles.stackItem}>
          {isMobile ? (
            <Box className={styles.linkMobile} onClick={handleOnPrevious}>
              <ArrowBackIcon className={styles.iconMobile} />
              <Text className={styles.stackTextMobile}>Selected Plan</Text>
            </Box>
          ) : (
            <Text className={styles.stackText}>Selected Plan</Text>
          )}
          <PlanVisualizerCard plan={plan} />
        </Box>
        <Box className={styles.stackItem}>
          <Text className={styles.stackText}>Billing Cycle</Text>
          <BillingCycleSelector
            isAnnualSelected={isAnnualSelected}
            handlePlanSelection={handleClick}
            planPair={planPair}
          />
        </Box>
        {!isMobile && (
          <img
            src={WhiteBlankaLogoImg}
            alt="Blanka Logo"
            className={styles.logo}
          />
        )}
      </Stack>
    </Box>
  );
}

SidePanelPlanSelectionB.propType = {
  onClick: PropType.func,
};

SidePanelPlanSelectionB.defaultProps = {
  onClick: () => {},
};
