/* eslint-disable camelcase */

// Trace
// eslint-disable-next-line import/no-cycle
import apiService from "api/apiService";

export const createTransaction = (transaction) => {
  const transaction_name = transaction.name;
  return apiService.post("trace/transaction/", { transaction_name });
};

export const createSpan = (span) => {
  const {
    description,
    operation,
    startTime,
    endTime,
    transaction,
    data,
    parent, // parent span
  } = span;

  const parentSpanId = parent !== null ? parent.id : null;

  return apiService.post("trace/span/", {
    description,
    operation,
    start_time: startTime,
    end_time: endTime,
    transaction: transaction.id,
    parent: parentSpanId,
    data,
  });
};
