/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
/* eslint-disable import/no-cycle */
import {
  createCart,
  getActiveCart,
  getCartById,
  getTiersByProductId,
  updateCartItems,
} from "api/endpoints/cartApi";

export const getCartByIdAsyncThunk = createAsyncThunk(
  "cart/getCartById",
  async (id, thunkAPI) => {
    try {
      // console.log("getCartByIdAsyncThunk", id);
      const response = await getCartById(id);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createCartAsyncThunk = createAsyncThunk(
  "cart/createCart",
  async (cartData, thunkAPI) => {
    // console.log("createCartAsyncThunk", cartData);
    const response = await createCart(cartData);
    return response.data;
  }
);

export const updateCartAsyncThunk = createAsyncThunk(
  "cart/updateCartStatus",
  async ({ id, cartData }, thunkAPI) => {
    // console.log("updateCartAsyncThunk", id, cartData);
    try {
      const response = await updateCartItems(id, { cart_data: cartData });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getActiveCartAsyncThunk = createAsyncThunk(
  "cart/getActiveCart",
  async (id, thunkAPI) => {
    try {
      // console.log("getActiveCartAsyncThunk", id);
      const response = await getActiveCart();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getTiersByProductIdAsync = createAsyncThunk(
  "vouchers/getTiers",
  async ({ productId }, thunkAPI) => {
    try {
      const response = await getTiersByProductId(productId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 *
 *
 Cart JSON structure
 {
 cartId: "",
 type: 'SAMPLE|INVENTORY',
 items: [{
 id: '',
 sku: '',
 quantity: 0,
 branded_box: false|true
 product = {} //the full product
 }]
 shipping:{
 address_one
 }
 discount:{
 code:
 type: amount|percentage
 value:
 }
 merchant_hold_inventory: false
 subtotal: 0,
 subtotal_with_savings: 0,
 fees: 0,
 total_branded_box_fees: 0,
 total: 0,
 };
 */

const cart = {};

export const LOADING = "loading";
export const IDLE = "idle";

const initialState = {
  cartId: cart?.cartId ? cart.cartId : "",
  type: cart?.type ? cart.type : "",
  items: cart?.items ? cart.items : [],
  discount: cart?.discount
    ? cart.discount
    : { code: null, type: null, value: 0 },
  shipping: cart?.shipping
    ? cart.shipping
    : {
        store_at_blanka: true,
        shipping_cost: 0,
      },
  subtotal: 0,
  subtotal_with_savings: 0,
  fees: 0,
  total_branded_box_fees: 0,
  total: 0,
  total_discount: 0,
  coupon: cart?.coupon ? cart.coupon : null,
  status: LOADING,
  tiersByProductId: {
    allProducts: [],
    status: LOADING,
  },
  confirmation_url: "",
  errors: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart: (state, action) => {
      state.cartId = action.payload.cartId;
      state.type = action.payload.type;
      state.items = action.payload.items;
      state.discount = action.payload.discount;
      state.shipping = action.payload.shipping;
      state.subtotal = action.payload.subtotal;
      state.fees = action.payload.fees;
      state.total_branded_box_fees = action.payload.total_branded_box_fees;
      state.total = action.payload.total;
      state.total_discount = action.payload.total_discount;
      state.subtotal_with_savings = action.payload.subtotal_with_savings;
    },
    setCartType: (state, action) => {
      state.type = action.type;
    },
    setConfirmationUrl: (state, action) => {
      state.confirmation_url = action.payload;
    },
    addCart: (state, action) => {
      // check if any items in the cart
      if (current(state.items).length > 0) {
        // check if adding the same type

        if (state.type !== action.payload.type) {
          throw Error;
        }

        // need to check if product already in cart, then add to existing line item
        const { item } = action.payload;
        let found = false;
        const { id, quantity } = item;
        const updatedItem = { ...item, quantity: Number(quantity) };
        const newArray = [];

        current(state.items).map((currentItem) => {
          if (currentItem.id === id) {
            newArray.push({
              ...updatedItem,
              quantity: currentItem.quantity + updatedItem.quantity,
            });
            found = true;
          } else {
            newArray.push(currentItem);
          }
        });

        if (!found) {
          newArray.push(updatedItem);
        }

        state.items = newArray;
      } else {
        state.type = action.payload.type;
        state.items = [action.payload.item];
        state.confirmation_url = action.payload?.confirmation_url
          ? action.payload.confirmation_url
          : "";
      }

      // localStorage.setItem("cart", JSON.stringify(state));
      // send to server
    },
    updateCart: (state, action) => {
      const { item, quantity } = action.payload;

      const updatedItem = { ...item, quantity: Number(quantity) };
      const newArray = [];

      if (item) {
        current(state.items).map((currentItem) => {
          if (currentItem.id === item.id) {
            newArray.push({ ...updatedItem });
          } else {
            newArray.push(currentItem);
          }
        });

        state.items = newArray;
      }

      // localStorage.setItem("cart", JSON.stringify(state));
    },
    removeCart: (state, action) => {
      const id = action.payload;
      const newItems = state.items.filter((el) => el.id !== id);
      state.items = newItems;
      // localStorage.setItem("cart", JSON.stringify(state));
    },
    clearCart: (state, action) => {
      state.items = [];
      state.type = "";
      state.shipping = {};
      state.discount = { code: null, type: null, value: 0 };
      state.subtotal = 0;
      state.fees = 0;
      state.total_branded_box_fees = 0;
      state.total = 0;
      state.subtotal_with_savings = 0;
      state.total_discount = 0;
      state.cartId = "";
      state.confirmation_url = "";
      // localStorage.setItem("cart", JSON.stringify(state));
    },
    setShippingCart: (state, action) => {
      state.shipping = action.payload;
      // localStorage.setItem("cart", JSON.stringify(state));
    },
    clearShipping: (state, action) => {
      state.shipping = {};
      // localStorage.setItem("cart", JSON.stringify(state));
    },
    setCartDiscount: (state, action) => {
      state.discount = action.payload;
      // localStorage.setItem("cart", JSON.stringify(state));
    },
    clearCartDiscount: (state, action) => {
      state.discount = null;
      state.coupon = null;
      // localStorage.setItem("cart", JSON.stringify(state));
    },
    setSampleOrderDiscount: (state, action) => {
      state.total_discount = action.payload;
      // localStorage.setItem("cart", JSON.stringify(state));
    },
    toggleBrandedBoxForAllItems: (state, action) => {
      if (action.payload.item) {
        // If an item is provided, toggle only that item's branded_box
        const itemToUpdate = action.payload.item;
        const items = state.items.map((item) => {
          if (item.id === itemToUpdate.id) {
            return { ...item, branded_box: !item.branded_box };
          }
          return item;
        });
        state.items = items;
      } else {
        // If no specific item is provided, toggle all items' branded_box
        const { selectAll } = action.payload;
        state.items.forEach((item) => {
          if (item.product?.branded_box_available !== false) {
            item.branded_box = selectAll;
          }
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveCartAsyncThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getActiveCartAsyncThunk.fulfilled, (state, action) => {
        const activeCart = action.payload[0];
        if (
          activeCart &&
          activeCart.cart_data.items.length > 0 &&
          activeCart.cart_id !== ""
        ) {
          state.cartId = activeCart.cart_id;
          state.type = activeCart.cart_data.type;
          state.items = activeCart.cart_data.items;
          state.discount = activeCart.cart_data.discount;
          state.coupon = activeCart.cart_data.coupon;
          state.shipping = activeCart.cart_data.shipping;
          state.subtotal = activeCart.cart_data.subtotal;
          state.fees = activeCart.cart_data.fees;
          state.total_branded_box_fees =
            activeCart.cart_data.total_branded_box_fees;
          state.total = activeCart.cart_data.total;
          state.total_discount = activeCart.cart_data.total_discount;
          state.subtotal_with_savings =
            activeCart.cart_data.subtotal_with_savings;
          state.confirmation_url = activeCart.cart_data.confirmation_url;
          state.errors = activeCart.cart_data.errors || [];
        }
        state.status = "idle";
      })
      .addCase(getCartByIdAsyncThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCartByIdAsyncThunk.fulfilled, (state, action) => {
        state.cartId = action.payload.cart_id;
        state.type = action.payload.cart_data.type;
        state.items = action.payload.cart_data.items;
        state.discount = action.payload.cart_data.discount;
        state.shipping = action.payload.cart_data.shipping;
        state.coupon = action.payload.cart_data.coupon;
        state.subtotal = action.payload.cart_data.subtotal;
        state.fees = action.payload.cart_data.fees;
        state.total_branded_box_fees =
          action.payload.cart_data.total_branded_box_fees;
        state.total = action.payload.cart_data.total;
        state.total_discount = action.payload.cart_data.total_discount;
        state.subtotal_with_savings =
          action.payload.cart_data.subtotal_with_savings;
        state.confirmation_url = action.payload.cart_data.confirmation_url;
        state.errors = action.payload.cart_data.errors || [];
        state.status = IDLE;
      })
      .addCase(createCartAsyncThunk.pending, (state, action) => {
        state.status = LOADING;
      })
      .addCase(createCartAsyncThunk.fulfilled, (state, action) => {
        state.cartId = action.payload.cart_id;
        state.type = action.payload.cart_data.type;
        state.items = action.payload.cart_data.items;
        state.discount = action.payload.cart_data.discount;
        state.shipping = action.payload.cart_data.shipping;
        state.subtotal = action.payload.cart_data.subtotal;
        state.fees = action.payload.cart_data.fees;
        state.total_branded_box_fees =
          action.payload.cart_data.total_branded_box_fees;
        state.total = action.payload.cart_data.total;
        state.total_discount = action.payload.cart_data.total_discount;
        state.subtotal_with_savings =
          action.payload.cart_data.subtotal_with_savings;
        state.confirmation_url = action.payload.cart_data.confirmation_url;
        state.errors = action.payload.cart_data.errors || [];
        state.status = IDLE;
      })
      .addCase(updateCartAsyncThunk.pending, (state, action) => {
        state.status = LOADING;
      })
      .addCase(updateCartAsyncThunk.fulfilled, (state, action) => {
        state.type = action.payload.cart_data.type;
        state.items = action.payload.cart_data.items;
        state.discount = action.payload.cart_data.discount;
        state.shipping = action.payload.cart_data.shipping;
        state.subtotal = action.payload.cart_data.subtotal;
        state.fees = action.payload.cart_data.fees;
        state.total_branded_box_fees =
          action.payload.cart_data.total_branded_box_fees;
        state.total = action.payload.cart_data.total;
        state.total_discount = action.payload.cart_data.total_discount;
        state.subtotal_with_savings =
          action.payload.cart_data.subtotal_with_savings;
        state.confirmation_url = action.payload.cart_data.confirmation_url;
        state.errors = action.payload.cart_data.errors || [];
        state.status = IDLE;
      })
      .addCase(getTiersByProductIdAsync.pending, (state, action) => {
        state.tiersByProductId.status = LOADING;
      })
      .addCase(getTiersByProductIdAsync.fulfilled, (state, action) => {
        state.tiersByProductId.allProducts = action.payload;
        state.tiersByProductId.status = IDLE;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setCart,
  addCart,
  updateCart,
  removeCart,
  clearCart,
  setShippingCart,
  clearCartDiscount,
  setSampleOrderDiscount,
  toggleBrandedBoxForAllItems,
  setConfirmationUrl,
} = cartSlice.actions;

export const selectTiersByProductId = (state) => state.cart.tiersByProductId;

export const deleteCartItem = (itemId) => (dispatch, getState) => {
  const { cart } = getState();
  if (cart.items.length > 0) {
    dispatch(removeCart(itemId));
  }
};

export const createOrUpdate = () => (dispatch, getState) => {
  const { cart } = getState();
  if (cart.cartId !== "" && cart.items.length === 0) {
    dispatch(
      updateCartAsyncThunk({
        id: cart.cartId,
        cartData: cart,
      })
    );
    dispatch(clearCart());
  } else if (cart.cartId !== "") {
    dispatch(
      updateCartAsyncThunk({
        id: cart.cartId,
        cartData: cart,
      })
    );
  } else if (cart.items.length > 0) {
    dispatch(createCartAsyncThunk({ cart_data: cart }));
  }
};

export default cartSlice.reducer;

// Utils

export const handleVoucherSuggestions = (tiers, item) => {
  const currentQuantity = item.quantity;
  const voucherTier = tiers.allProducts.find(
    (tier) => tier.min > currentQuantity
  );

  if (voucherTier) {
    return `Order ${voucherTier.min - currentQuantity} more to get ${
      voucherTier.value
    }% discount!`;
  }

  return null;
};
