import React from "react";
import Button from "components/Buttons/Button";
import Text from "components/Text";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import styles from "./ErrorView.module.scss";

export default function ErrorView({ resetErrorBoundary }) {
  return (
    <Grid container className={styles.errorContainer}>
      <Card className={styles.errorCard}>
        <Stack spacing={2}>
          <Text
            color="white"
            fontSize={22}
            variant="body1"
            className="text--upper"
          >
            Oops
          </Text>
          <Text color="white" fontSize={18} variant="body1">
            Something went wrong, <br /> Please click the button to refresh the
            page and try again
          </Text>

          <Button
            color="secondary"
            variant="contained"
            className={styles.button}
            onClick={resetErrorBoundary}
          >
            Refresh
          </Button>
        </Stack>
      </Card>
    </Grid>
  );
}
