import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { Stack } from "@mui/system";

import DrawerWrapper from "components/DrawerWrapper";

import { getMyLogoGenerated } from "api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addLogo,
  setActiveInnerStep,
  setActiveOuterStep,
  setIsGeneratorCompleted,
  setIsGeneratorStarted,
  setIsLogoUploaded,
  setIsOpen,
  setLoading,
} from "state/myLogoSlice";

import { SMALL } from "constants/breakpoints.js";
import { steps } from "constants/generatorLogo.js";
import { useAnalytics } from "hooks";
import { useMediaQuery } from "react-responsive";

import {
  CUSTOM_PRODUCT_STATUS_COMPLETE,
  CUSTOM_PRODUCT_STATUS_IN_PROGRESS,
  CUSTOM_PRODUCT_STATUS_NONE,
} from "constants/constants";

import useChangePlanModal from "hooks/useChangePlanModal";
import OuterStepper from "./components/MyLogoStepper/OuterStepper/OuterStepper";
import MyLogoStepperProgress from "./components/MyLogoStepperProgress/MyLogoStepperProgress";

import styles from "./MyLogoView.module.scss";

function MyLogoView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { isOpen } = useSelector((state) => state.myLogo);
  const shop = useSelector((state) => state.profile.shop);

  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const { onOpenModal } = useChangePlanModal();

  useEffect(() => {
    const getMyLogosGenerated = async () => {
      try {
        dispatch(setLoading(true));
        const { data } = await getMyLogoGenerated();

        if (
          data.length > 0 &&
          (shop?.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE ||
            shop?.custom_product_status === CUSTOM_PRODUCT_STATUS_IN_PROGRESS)
        ) {
          data.map((logo) => dispatch(addLogo(logo)));
          dispatch(setIsGeneratorStarted(true));
          dispatch(setIsGeneratorCompleted(true));
          dispatch(setIsLogoUploaded(true));
          dispatch(setActiveOuterStep(2));
          dispatch(setActiveInnerStep(0));
        }

        if (
          data.length > 0 &&
          shop?.custom_product_status === CUSTOM_PRODUCT_STATUS_NONE
        ) {
          data.map((logo) => dispatch(addLogo(logo)));
          dispatch(setIsGeneratorStarted(true));
          dispatch(setIsGeneratorCompleted(false));
          dispatch(setIsLogoUploaded(true));
          dispatch(setActiveOuterStep(0));
          dispatch(setActiveInnerStep(0));
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    };
    getMyLogosGenerated();

    if (isOpen) {
      analytics.sendEvent(analytics.GENNY_START, {});
    }
  }, [isOpen]);

  const handleClose = () => {
    analytics.sendEvent(analytics.GENNY_CLOSE, {});
    dispatch(setIsOpen(false));
    navigate(-1);
  };

  const handleOpenPaywall = () => {
    onOpenModal({ isFreePlanLimit: true });
    analytics.sendEvent(analytics.PAYWALL_HIT, {
      location: "logo_genny_final_step",
    });
  };

  return (
    <DrawerWrapper anchor="bottom" isOpen={isOpen} handleClose={handleClose}>
      {!isMobile && (
        <Grid container className={styles.myLogoView}>
          <Grid item xs={4} className={styles.leftPanel}>
            <OuterStepper steps={steps} />
          </Grid>
          <Grid item xs={8} className={styles.rightPanel}>
            <div className={styles.rightPanelChildren}>
              <MyLogoStepperProgress
                handlePaywallOpen={handleOpenPaywall}
                handleClose={handleClose}
              />
            </div>
          </Grid>
        </Grid>
      )}
      {isMobile && (
        <Stack direction="column" className={styles.myLogoView}>
          <Grid item className={styles.rightPanel}>
            <MyLogoStepperProgress
              handlePaywallOpen={handleOpenPaywall}
              handleClose={handleClose}
            />
          </Grid>
        </Stack>
      )}
    </DrawerWrapper>
  );
}

MyLogoView.propTypes = {};

MyLogoView.defaultProps = {};

export default MyLogoView;
