import { LowStockIcon } from "components/Icons";
import Text from "components/Text";
import PropTypes from "prop-types";
import React from "react";
import styles from "./LowStockTag.module.scss";

export default function LowStockTag({ text, isFullWidth }) {
  return (
    <div className={isFullWidth ? styles.lowStockTagFull : styles.lowStockTag}>
      <div className={styles.tagIcon}>
        <LowStockIcon />
      </div>
      <Text className={styles.tag}>LOW STOCK{text ? ` ${text}` : ""}</Text>
    </div>
  );
}

LowStockTag.propTypes = {
  text: PropTypes.string,
  isFullWidth: PropTypes.bool,
};

LowStockTag.defaultProps = {
  text: "",
  isFullWidth: false,
};
