import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { updatePlan, updateShop } from "api";
import WhiteBlankaLogoImg from "assets/images/white-blanka-logo.svg";
import PlanSwitch from "components/Switch/components/PlanSwitch";
import Text from "components/Text";
import { SMALL } from "constants/breakpoints";
import { SHOP_ONBOARDING_SHOPIFY, TRY_FOR_FREE } from "constants/constants";
import { useAnalytics } from "hooks";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setShop } from "state/profileSlice";

import PlanTable from "components/Tables/PlanTable";
import OnboardingWrapperB from "../OnboardingWrapperB";
import styles from "./OnboardingPickPlanStep.module.scss";

export default function OnboardingStepTwoB() {
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.profile.shop);
  const [loading, setLoading] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const analytics = useAnalytics();

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const handleChange = (event) => {
    setSwitchChecked(event.target.checked);
  };

  const handlePaidPlanSelect = async (id) => {
    setLoading(true);

    let affiliateID = null;
    // check if user has come from an affiliate link

    try {
      affiliateID = Cookies.get("irclickid");
    } catch (err) {
      affiliateID = null;
    }

    if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
      await updateShop(shop.id, {
        onboarding_step: shop.onboarding_step + 1,
        plan: id,
        affiliate_id: affiliateID || "",
      });

      const response = await updatePlan(id);
      window.location.href = response.data.confirmation_url;

      analytics.sendEvent(analytics.ONBOARDING_SELECT_PLAN, { plan_id: id });
    } else {
      await updateShop(shop.id, {
        onboarding_step: shop.onboarding_step + 1,
        plan: id,
        affiliate_id: affiliateID || "",
      });
      analytics.sendEvent(analytics.ONBOARDING_SELECT_PLAN, { plan_id: id });
    }

    // dispatch(setShop(response.data));
  };

  const handleFreePlanSelect = async (id) => {
    setLoading(true);

    const response = await updateShop(shop.id, {
      onboarding_step: shop.onboarding_step + 2,
      plan: id,
    });

    analytics.sendEvent(analytics.ONBOARDING_SELECT_PLAN, { plan_id: id });

    dispatch(setShop(response.data));
  };

  return (
    <Stack>
      <Box className={styles.header}>
        {!isMobile && (
          <img
            src={WhiteBlankaLogoImg}
            alt="Blanka Logo"
            className={styles.logo}
          />
        )}
        <Box className={styles.headerBox}>
          <Text className={styles.headerTitle}>Try any plan for FREE </Text>
          <Text className={styles.headerText}>Enjoy 2 weeks on us! </Text>
        </Box>
      </Box>

      <OnboardingWrapperB>
        <Box className={styles.onboardingStepTwoB}>
          <Text variant="h1" className={styles.attention}>
            Save up to 50% with an annual plan
          </Text>
          <Box className={styles.boxContainer}>
            <Text
              className={`text__body ${
                switchChecked ? "text--nunito" : "text--peach text--bold"
              }`}
            >
              Annually
            </Text>
            <PlanSwitch
              disabled={!!loading}
              handleChange={handleChange}
              checked={switchChecked}
            />
            <Text
              className={`text__body ${
                !switchChecked ? "text--nunito" : "text--peach text--bold"
              }`}
            >
              Monthly
            </Text>
          </Box>
          <div className={styles.planCardsContainer}>
            <PlanTable
              onPaidPlanSelect={handlePaidPlanSelect}
              onFreePlanSelect={handleFreePlanSelect}
              loading={loading}
              constant={TRY_FOR_FREE}
              switchChecked={switchChecked}
              isOnboarding
            />
            <Text className={styles.warning}>
              <span>* applies to published products</span>
              <span>
                **1 free Brand Plate setup is included with each paid plan.
                Subsequent Brand Plates are an additional cost.
              </span>
            </Text>
          </div>
        </Box>
      </OnboardingWrapperB>
    </Stack>
  );
}
