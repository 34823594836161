import React, { useState } from "react";

// mui components:
import { Box } from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./PublishedDiscontinuedProductNotification.module.scss";

export default function PublishedDiscontinuedProductNotification() {
  return (
    <Box className={`${styles.publishedDiscontinuedProductNotification}`}>
      <span className={styles.title}>
        <ReportProblemOutlinedIcon fontSize="small" />
        Oops...
      </span>
      <span className={styles.text}>
        You have one or more published products that are discontinued.
      </span>{" "}
      <a
        className={styles.link}
        href="https://faq.blankabrand.com/en/articles/8913076-what-happens-if-a-product-has-been-discontinued"
        target="_blank"
        rel="noreferrer"
      >
        What should I do?
      </a>
    </Box>
  );
}
