function checkIfDefaultAttribute(obj1, obj2) {
  // these values are based on genny values
  try {
    return (
      obj1?.rotations === obj2?.rotations &&
      obj1?.["logo-type"] === obj2?.["logo-type"] &&
      obj1?.size.width === obj2?.size?.width &&
      obj1?.size.height === obj2?.size?.height &&
      obj1?.size.width_mm === obj2?.size?.width_mm &&
      obj1?.size.height_mm === obj2?.size?.height_mm
    );
  } catch (error) {
    console.log("error - genny values not available");
    return true;
  }
}

export default checkIfDefaultAttribute;
