/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

// constants:
import {
  ANNUAL_PLAN_IDS,
  MONTHLY_PLAN_IDS,
  FREE_PLAN_ID,
} from "constants/plans";

const initialState = {
  displayUpgradeMessage: false,
  isDowngradeToFree: false,
  isDowngradeToMonthly: false,
  planChangeLoading: false,
  downgradeReason: "",
  downgradeResponse: "",
  isDowngraded: false,
  hasSelectedDowngradeReason: false,
  trackPlan: {},
  choosenPlan: null,
  questions: [
    {
      id: 0,
      question: "Didn’t use or need the additional features",
      answer: "",
      hasReply: false,
      isChecked: false,
    },
    {
      id: 1,
      question: "Missing software features I needed",
      answer: "",
      hasReply: true,
      isChecked: false,
    },
    {
      id: 2,
      question: "Plan is too expensive",
      answer: "",
      hasReply: false,
      isChecked: false,
    },
    {
      id: 3,
      question: "Shipping took too long",
      answer: "",
      hasReply: false,
      isChecked: false,
    },

    {
      id: 4,
      question: "Did not have the products I was expecting.",
      answer: "",
      hasReply: false,
      isChecked: false,
    },
    {
      id: 5,
      question: "I’m pausing my business",
      answer: "",
      hasReply: false,
      isChecked: false,
    },
    {
      id: 6,
      question: "I’m not continuing my business",
      answer: "",
      hasReply: false,
      isChecked: false,
    },

    {
      id: 7,
      question: "Other reasons",
      answer: "",
      hasReply: true,
      isChecked: false,
    },
  ],
};

export const changePlanSlice = createSlice({
  name: "changePlan",
  initialState,
  reducers: {
    setDisplayUpgradeMessage: (state, action) => {
      state.displayUpgradeMessage = action.payload;
    },
    resetDisplayUpgradeMessage: (state) => {
      state.displayUpgradeMessage = false;
    },
    setPlanChangeLoading: (state, action) => {
      state.planChangeLoading = action.payload;
    },
    setIsDowngradeToFree: (state, action) => {
      const { plan } = action.payload;

      const isAnnualPlan = ANNUAL_PLAN_IDS.includes(plan);
      const isMonthlyPlan = MONTHLY_PLAN_IDS.includes(plan);
      const isFreePlan = plan === FREE_PLAN_ID;

      state.isDowngradeToMonthly = false;
      state.isDowngradeToFree = isFreePlan;
      state.isDowngraded = false;

      if (isAnnualPlan || isMonthlyPlan) {
        state.isDowngradeToFree = false;
      } else if (isFreePlan) {
        state.isDowngradeToFree = true;
      }
    },
    updateDowngradeStatus: (state, action) => {
      const { plan } = action.payload;
      const isAnnualPlan = ANNUAL_PLAN_IDS.includes(plan);
      const isMonthlyPlan = MONTHLY_PLAN_IDS.includes(plan);
      const isFreePlan = plan === FREE_PLAN_ID;
      state.isDowngradeToMonthly = isAnnualPlan && !isMonthlyPlan;
      state.isDowngradeToFree = isFreePlan;
      state.isDowngraded = isAnnualPlan || isMonthlyPlan;
    },
    setDowngradeStates: (state, action) => {
      state.isDowngradeToMonthly = action.payload;
      state.isDowngradeToFree = action.payload;
      state.isDowngraded = action.payload;
    },
    resetDowngradeState: (state) => initialState,
    choosenPlan: (state, action) => {
      state.trackPlan = action.payload;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;

      state.hasSelectedDowngradeReason = action.payload.some((question) => {
        if (
          question.hasReply &&
          question.isChecked === true &&
          question.answer !== ""
        ) {
          state.downgradeReason = question.question;
          state.downgradeResponse = question.answer;
          return true;
        }
        if (question.isChecked === true && question.hasReply === false) {
          state.downgradeReason = question.question;
          state.downgradeResponse = question.answer;
          return true;
        }
        return false;
      });
    },
    resetQuestions: (state) => {
      state.questions = initialState.questions;
      state.hasSelectedDowngradeReason = false;
    },

    updateQuestions: (state, action) => {
      const { id, isChecked, answer } = action.payload;
      const questionIndex = state.questions.findIndex((q) => q.id === id);
      if (questionIndex !== -1) {
        state.questions[questionIndex] = {
          ...state.questions[questionIndex],
          isChecked,
          answer,
        };
      }
    },
    setChoosenPlan: (state, action) => {
      state.choosenPlan = action.payload;
    },
  },
});

export const {
  resetDowngradeState,
  setDisplayUpgradeMessage,
  resetDisplayUpgradeMessage,
  setIsDowngradeToFree,
  updateDowngradeStatus,
  setDowngradeStates,
  setPlanChangeLoading,
  choosenPlan,
  setQuestions,
  updateQuestions,
  setChoosenPlan,
  hasSelectedDowngradeReason,
  resetQuestions,
} = changePlanSlice.actions;

export default changePlanSlice.reducer;
