import React, { useRef, useState } from "react";

// Material-UI
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Box, Stack } from "@mui/material";

// Custom components
import Button from "components/Buttons/Button";
import Text from "components/Text";
import { BRAND_PLATE_FILE_UPLOAD_STATUS } from "constants/brandPlate";
import { FILE_UPLOAD_REQUIREMENTS } from "constants/generatorLogo";

import styles from "./BrandSetupUploadValidator.module.scss";

export default function BrandSetupUploadValidator(props) {
  const { onLogoUpload, validationResults, loading } = props;
  const fileInputRef = useRef(null);

  const handleValidatorIcon = (index) => {
    const requirement = FILE_UPLOAD_REQUIREMENTS[index];

    if (!requirement) {
      // If no requirement is found at the given index, return the default icon
      return <CircleOutlinedIcon />;
    }

    // Use the validation result from the props
    const validationResult = validationResults[requirement.requirement];

    if (validationResult === true) {
      // Validation passed, return CheckCircleIcon
      return <CheckCircleIcon color="success" />;
    }
    if (validationResult === false) {
      // Validation failed, return ErrorIcon
      return <CancelIcon color="error" />;
    }

    // Default case when validation has not yet been performed
    return <CircleOutlinedIcon />;
  };

  const handleValidatorDescription = (description, index) => {
    const requirement = FILE_UPLOAD_REQUIREMENTS[index];

    if (!requirement) {
      // If no requirement is found at the given index, return the default icon
      return <span>{description}</span>;
    }

    // Use the validation result from the props
    const validationResult = validationResults[requirement.requirement];

    if (validationResult === true) {
      return <span>{description}</span>;
    }
    if (validationResult === false) {
      return <span className={styles.descriptionError}>{description}</span>;
    }

    return <span>{description}</span>;
  };

  const handleButtonClick = () => {
    // Trigger the hidden file input click event
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onLogoUpload(file);
    }
  };

  return (
    <Stack className={styles.brandSetupUploadValidator}>
      <Box>
        <Text variant="h1" className={styles.title}>
          Light Products
        </Text>
      </Box>
      <ul>
        {BRAND_PLATE_FILE_UPLOAD_STATUS.map((description, index) => (
          <li key={description}>
            {handleValidatorIcon(index)}
            {handleValidatorDescription(description, index)}
          </li>
        ))}
      </ul>
      <Button
        color="primary"
        variant="outlined"
        onClick={handleButtonClick}
        loading={loading}
        className={styles.uploadCta}
      >
        <FileUploadOutlinedIcon className={styles.iconCta} />
        Upload file
      </Button>
      <input
        type="file"
        accept="image/png"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </Stack>
  );
}
