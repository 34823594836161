import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import BlankaMyLogoDrop from "assets/images/blanka-my-logo-drop.png";
import { LOGO_DEFAULT } from "constants/generatorLogo";
import MyLogoStepTemplate from "./MyLogoStepTemplate";

function MyLogoStepTwo(props) {
  const { handleNext, handlePrevious } = props;

  return (
    <div>
      <MyLogoStepTemplate
        step="Step 1"
        subTitle="Light product preview"
        lead="We recommend uploading a dark logo"
        caption="Upload your dark logo"
        imagePreview={BlankaMyLogoDrop}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        logoType={LOGO_DEFAULT}
      />
    </div>
  );
}

MyLogoStepTwo.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
};

MyLogoStepTwo.defaultProps = {};

export default MyLogoStepTwo;
