import React from "react";
import { Carousel } from "react-responsive-carousel";
import styles from "./ImageCarousel.module.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function ImageCarousel({ images }) {
  return (
    <Carousel
      autoPlay={false}
      infiniteLoop
      dynamicHeight
      className={styles.carousel}
      data-testid="ImageCarousel"
      showArrows={false}
      showStatus={false}
      renderIndicator={false}
    >
      {images.map((image) => (
        <div key={image}>
          <img src={image.image} alt="Product" />
        </div>
      ))}
    </Carousel>
  );
}

ImageCarousel.defaultProps = {
  images: [],
};
