import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function InventoryOrderIcon(props) {
  return (
    <div className="icon-root">
      <SvgIcon {...props} className="icon icon--hover" viewBox="0 0 20 20">
        <g id="Layer_2_00000170276419779991785330000007007557705243344059_">
          <g id="Inventory_Orders_Icon">
            <rect
              y="0"
              className="st0"
              width="18"
              height="18"
              style={{ fill: "#FFFFFF" }}
            />
            <g id="Inventory_Colour">
              <rect
                x="2"
                y="6.3"
                className="st1"
                width="9.8"
                height="9.8"
                style={{ fill: "#E88A80" }}
              />
              <path
                className="st2"
                style={{ fill: "#5E5F5F" }}
                d="M4.9,0L0,4.9V18h13.1l4.9-4.9V0H4.9z M5.7,2h3.1L6.5,4.3H3.4L5.7,2z M11.7,16H2V6.3h9.8L11.7,16z M12.3,4.2
				H9.3L11.5,2h3.1L12.3,4.2z M16,12.3l-2.3,2.3V5.7L16,3.4V12.3z"
              />
            </g>
          </g>
        </g>
      </SvgIcon>

      <SvgIcon {...props} className="icon icon--normal" viewBox="0 0 20 20">
        <g id="Layer_2_00000004510897912306873430000011711252617933363846_">
          <g id="Inventory_Orders_Icon">
            <g id="Inventory_Grey">
              <path
                className="st1"
                d="M4.9,0L0,4.9V18h13.1l4.9-4.9V0H4.9z M5.7,2h3.1L6.5,4.3H3.4L5.7,2z M11.7,16H2V6.3h9.8L11.7,16z M12.3,4.2
				H9.3L11.5,2h3.1L12.3,4.2z M16,12.3l-2.3,2.3V5.7L16,3.4V12.3z"
              />
            </g>
          </g>
        </g>
      </SvgIcon>
    </div>
  );
}
