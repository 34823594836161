import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function BlankaFormulatorStepTwoIcon(props) {
  return (
    <SvgIcon
      {...props}
      className="icon"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        opacity="0.2"
        d="M29 7V25H20C18.9391 25 17.9217 25.4214 17.1716 26.1716C16.4214 26.9217 16 27.9391 16 29V11C16 9.93913 16.4214 8.92172 17.1716 8.17157C17.9217 7.42143 18.9391 7 20 7H29Z"
        fill="#246071"
      />
      <path
        d="M29 6H20C19.2238 6 18.4582 6.18073 17.7639 6.52786C17.0697 6.875 16.4657 7.37902 16 8C15.5343 7.37902 14.9303 6.875 14.2361 6.52786C13.5418 6.18073 12.7762 6 12 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V25C2 25.2652 2.10536 25.5196 2.29289 25.7071C2.48043 25.8946 2.73478 26 3 26H12C12.7956 26 13.5587 26.3161 14.1213 26.8787C14.6839 27.4413 15 28.2044 15 29C15 29.2652 15.1054 29.5196 15.2929 29.7071C15.4804 29.8946 15.7348 30 16 30C16.2652 30 16.5196 29.8946 16.7071 29.7071C16.8946 29.5196 17 29.2652 17 29C17 28.2044 17.3161 27.4413 17.8787 26.8787C18.4413 26.3161 19.2044 26 20 26H29C29.2652 26 29.5196 25.8946 29.7071 25.7071C29.8946 25.5196 30 25.2652 30 25V7C30 6.73478 29.8946 6.48043 29.7071 6.29289C29.5196 6.10536 29.2652 6 29 6ZM12 24H4V8H12C12.7956 8 13.5587 8.31607 14.1213 8.87868C14.6839 9.44129 15 10.2044 15 11V25C14.1353 24.3493 13.0821 23.9983 12 24ZM28 24H20C18.9179 23.9983 17.8647 24.3493 17 25V11C17 10.2044 17.3161 9.44129 17.8787 8.87868C18.4413 8.31607 19.2044 8 20 8H28V24ZM20 11H25C25.2652 11 25.5196 11.1054 25.7071 11.2929C25.8946 11.4804 26 11.7348 26 12C26 12.2652 25.8946 12.5196 25.7071 12.7071C25.5196 12.8946 25.2652 13 25 13H20C19.7348 13 19.4804 12.8946 19.2929 12.7071C19.1054 12.5196 19 12.2652 19 12C19 11.7348 19.1054 11.4804 19.2929 11.2929C19.4804 11.1054 19.7348 11 20 11ZM26 16C26 16.2652 25.8946 16.5196 25.7071 16.7071C25.5196 16.8946 25.2652 17 25 17H20C19.7348 17 19.4804 16.8946 19.2929 16.7071C19.1054 16.5196 19 16.2652 19 16C19 15.7348 19.1054 15.4804 19.2929 15.2929C19.4804 15.1054 19.7348 15 20 15H25C25.2652 15 25.5196 15.1054 25.7071 15.2929C25.8946 15.4804 26 15.7348 26 16ZM26 20C26 20.2652 25.8946 20.5196 25.7071 20.7071C25.5196 20.8946 25.2652 21 25 21H20C19.7348 21 19.4804 20.8946 19.2929 20.7071C19.1054 20.5196 19 20.2652 19 20C19 19.7348 19.1054 19.4804 19.2929 19.2929C19.4804 19.1054 19.7348 19 20 19H25C25.2652 19 25.5196 19.1054 25.7071 19.2929C25.8946 19.4804 26 19.7348 26 20Z"
        fill="#246071"
      />
    </SvgIcon>
  );
}

export default BlankaFormulatorStepTwoIcon;
