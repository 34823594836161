import {
  TEST_ONBOARDING_COOKIE_NAME,
  TEST_ONBOARDING_VERSION_A,
  TEST_ONBOARDING_VERSION_B,
  TEST_ONBOARDING_VERSION_B_PERCENTAGE,
} from "constants/ab_tests";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useAnalytics from "hooks/useAnalytics";
import OnboardingStepOne from "./forms/OnboardingStepOne";
import OnboardingStepOneB from "./forms/OnboardingStepOneB";

export default function SignupView() {
  const [onboardingType, setOnboardingType] = useState(null);
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    // Check if the test cookie is already set
    const testCookie = Cookies.get(TEST_ONBOARDING_COOKIE_NAME);
    if (!testCookie) {
      // Randomly assign user to test_onboarding_a or test_onboarding_b
      const randomNumber = Math.random();

      // set signup to be 10% to onboarding_b

      const testGroup =
        randomNumber < TEST_ONBOARDING_VERSION_B_PERCENTAGE
          ? TEST_ONBOARDING_VERSION_B
          : TEST_ONBOARDING_VERSION_A;
      Cookies.set(TEST_ONBOARDING_COOKIE_NAME, testGroup, { expires: 30 }); // Setting cookie for 30 days
      setOnboardingType(testGroup);
    } else {
      setOnboardingType(testCookie);
    }
  }, []);

  useEffect(() => {
    // catch gtag for testing purposes
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const utmParams = {
        utm_source: urlParams.get("utm_source"),
        utm_medium: urlParams.get("utm_medium"),
        utm_campaign: urlParams.get("utm_campaign"),
        utm_term: urlParams.get("utm_term"),
        utm_content: urlParams.get("utm_content"),
      };

      const referringDomain = document.referrer
        ? new URL(document.referrer).hostname
        : null;

      analytics.sendEvent(analytics.PAGE_VIEW, {
        page_title: document.title,
        page_location: location.pathname,
        url: window.location.href,
        referrer: document.referrer,
        referring_domain: referringDomain,
        ...utmParams,
      });

      window.gtag("event", "page_view", {
        page_title: location.pathname,
        page_location: location.pathname,
      });

      window.analytics.page(location.name, {
        title: location.name,
        path: location.pathname,
        url: window.location.href,
      });
    } catch (err) {
      console.log("Error loading gtag");
    }
  }, [location]);

  return (
    <div>
      {onboardingType === TEST_ONBOARDING_VERSION_B ? (
        <OnboardingStepOneB />
      ) : (
        <OnboardingStepOne />
      )}
    </div>
  );
}
