const getLogoContainerDimensions = (containerData) => ({
  maxWidth: containerData?.find(
    ({ attribute }) => attribute?.slug === "max-width"
  )?.options?.[0]?.value_json,
  maxHeight: containerData?.find(
    ({ attribute }) => attribute?.slug === "max-height"
  )?.options?.[0]?.value_json,
  positionX: containerData?.find(
    ({ attribute }) => attribute?.slug === "x-axis"
  )?.options?.[0]?.value_json,
  positionY: containerData?.find(
    ({ attribute }) => attribute?.slug === "y-axis"
  )?.options?.[0]?.value_json,
});

export default getLogoContainerDimensions;
