import React from "react";
import PropTypes from "prop-types";

import Drawer from "@mui/material/Drawer";

import styles from "./DrawerWrapper.module.scss";

export default function DrawerWrapper(props) {
  const { anchor, isOpen, handleClose, children } = props;

  return (
    <Drawer
      data-testid="DrawerWrapper"
      aria-labelledby="Drawer Wrapper"
      aria-describedby="Drawer Wrapper"
      anchor={anchor}
      open={isOpen}
      onClose={handleClose}
      className={styles.drawerWrapper}
    >
      {children}
    </Drawer>
  );
}

DrawerWrapper.propTypes = {
  anchor: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

DrawerWrapper.defaultProps = {
  isOpen: false,
};
