import { useEffect } from "react";

const useConversionTracking = () => {
  // Function to be called after the user signs up
  const signup = (planValue) => {
    /*
    // Meta Pixel CompleteRegistration event
    try {
      if (typeof window.fbq !== "undefined") {
        console.log("META: signup event");
        window.fbq("track", "CompleteRegistration", {
          value: planValue,
          currency: "USD",
        });
      } else {
        console.warn("Meta Pixel is not initialized.");
      }
    } catch (e) {
      console.log(e);
    }

    try {
      // Google Analytics SignUp event
      if (typeof window.gtag !== "undefined") {
        console.log("GA: signup event");
        window.gtag("event", "sign_up", {
          event_category: "engagement",
          event_label: "Signup Completion",
          value: planValue, // The value of the plan passed to the function
          // send_to: "AW-CONVERSION_ID", // Replace with your Google Ads conversion ID
        });
      } else {
        console.warn("Google Tag is not initialized.");
      }
    } catch (e) {
      console.log(e);
    }
      */
    // Reddit Pixel signup event
    try {
      if (typeof window.rdt !== "undefined") {
        console.log("Reddit: signup event");
        window.rdt("track", "SignUp", {});
      } else {
        console.warn("Reddit Pixel is not initialized.");
      }
    } catch (e) {
      console.log("Reddit Pixel error:", e);
    }
  };

  return { signup };
};

export default useConversionTracking;
