/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  product: null,
};

const productModalSlice = createSlice({
  name: "productModal",
  initialState,
  reducers: {
    toggleProductModal: (state, action) => {
      state.isOpen = action.payload;
    },
    onSetProduct: (state, action) => {
      state.product = action.payload;
    },
  },
});

export const { toggleProductModal, onSetProduct } = productModalSlice.actions;

export default productModalSlice.reducer;
